<template>
  <!-- 资源列表 -->
  <div>
    <!-- @mouseleave="mouseLeave" -->
    <div class="box" v-loading.fullscreen.lock="fullscreenLoading">
      <div @contextmenu.prevent.stop="show({}, 2)" class="content"
        :style="bottomHeight ? `height:${bottomHeight - 119}px;` : ''">
        <div v-for="item in FileDirectoryList" :key="item.id" class="documents" @contextmenu.prevent.stop="show(item, 1)"
          @dblclick="doubleOpen(item)" @click="checked(item)">
          <div class="oneFile">
            <div class="expression">
              <!-- 场景 -->
              <img v-if="item.property == 1 && item.id != checkedId" class="expressionImg"
                :src="item.imageUrl | filtrationImg" alt />
              <!--场景选中 -->
              <img v-if="item.property == 1 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/icon_scene_selected.png" alt />

              <!-- 模型 -->
              <img v-if="item.property == 2 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/icon_model.png" alt />
              <img v-if="
                item.property == 2 &&
                item.id == checkedId &&
                item.id == checkedId
              " class="expressionImg" src="../../assets/Resource/icon_model_selected.png" alt />
              <!-- 组   -->
              <img v-if="item.property == 6 && item.id != checkedId" class="expressionImg"
                src="../../assets/ToolBar/group.png" alt />
              <img v-if="item.property == 6" class="expressionImg" src="../../assets/ToolBar/checkedGroup.png" alt />

              <!-- 材质 -->

              <img v-if="item.property == 3 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/icon_chartlet.png" alt />
              <img v-if="item.property == 3 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/icon_chartlet_selected.png" alt />

              <!-- 基础 -->
              <img v-if="item.property == 9 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/baseMaterial.png" alt />
              <img v-if="item.property == 9 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/baseMaterial.png" alt />
              <!-- 标准 -->
              <img v-if="item.property == 7 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/icon_save_ball .png" alt />
              <img v-if="item.property == 7 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/icon_texture_selected.png" alt />
              <!-- 脚本 -->
              <img v-if="item.property == 5 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/icon_script.png" alt />
              <img v-if="item.property == 5 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/icon_script_scene.png" alt />

              <img v-if="item.property == 8 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/multimedia.png" alt />
              <img v-if="item.property == 8 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/selectMultimedia.png" alt />

              <img v-if="item.property == 10 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/PhongShader.png" alt />
              <img v-if="item.property == 10 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/selectPhongShader.png" alt />
              <!-- 粒子 -->
              <img v-if="item.property == 11 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/particleIcon.png" alt />
              <img v-if="item.property == 11 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/selectParticleIcon.png" alt />
              <img v-if="item.property == 12 && item.id != checkedId" class="expressionImg"
                src="../../assets/Resource/icon_script.png" alt />
              <img v-if="item.property == 12 && item.id == checkedId" class="expressionImg"
                src="../../assets/Resource/icon_script_scene.png" alt />




                

                <img v-if="item.property == 13 && item.id != checkedId" class="expressionImg"
                src="../../assets/Property/texture.png" alt />
              <img v-if="item.property == 13 && item.id == checkedId" class="expressionImg"
                src="../../assets/Property/texture.png" alt />
                <img v-if="item.property == 14 && item.id != checkedId" class="expressionImg"
                src="../../assets/Property/controller.png" alt />
              <img v-if="item.property == 14 && item.id == checkedId" class="expressionImg"
                src="../../assets/Property/controller.png" alt />
                <img v-if="item.property == 15 && item.id != checkedId" class="expressionImg"
                src="../../assets/Property/text.png" alt />
              <img v-if="item.property == 15 && item.id == checkedId" class="expressionImg"
                src="../../assets/Property/text.png" alt />
                <img v-if="item.property == 16 && item.id != checkedId" class="expressionImg"
                src="../../assets/Property/path.png" alt />
              <img v-if="item.property == 16 && item.id == checkedId" class="expressionImg"
                src="../../assets/Property/path.png" alt />

                <img v-if="item.property == 17 && item.id != checkedId" class="expressionImg"
                src="../../assets/Property/hud.png" alt />
              <img v-if="item.property == 17 && item.id == checkedId" class="expressionImg"
                src="../../assets/Property/hud.png" alt />
                <img v-if="item.property == 18 && item.id != checkedId" class="expressionImg"
                src="../../assets/Property/Ai.png" alt />
              <img v-if="item.property == 18 && item.id == checkedId" class="expressionImg"
                src="../../assets/Property/Ai.png" alt />





              <!--  -->
            </div>
            <div class="share" v-if="
              item.isUpload == '1' && $store.state.chosenWarehouse.type == 0
            ">
              <img class="shareImg" src="../../assets/sharedFile/filesHare (2).png" alt />
            </div>

            <div :class="{
              checkedScene: item.id == checkedId,
              noCheckedScene: true,
              sceneChecked: true,
            }">
              <!-- {{ResourceType}} -->
              <div v-if="item.local == 1" :class="{
                lockMask: true,

                lockMask1: ResourceType == '1' || ResourceType == '6',
                lockMask2: ResourceType != '1' && ResourceType != '6',
              }">
                <img src="../../assets/Resource/lock.png" alt="" />
              </div>


              <el-popover v-if="ResourceType == 2 || ResourceType == 3" placement="top-start" trigger="hover">
                <div>
                  <!-- <img style="width:9.76563vw;height: 9.76563vw;" :src="item.imageUrl | filtrationImg"  alt=""> -->

                  <el-image style="width: 9.76563vw; height: 9.76563vw" :src="item.imageUrl | filtrationImg"
                    :preview-src-list="item.imageUrl | filtrationImgList">
                  </el-image>




                </div>

                <img v-if="item.type == 0" class="documents_img" src="../../assets/Resource/icon_folder.png" alt />




                <img slot="reference" v-else :class="{
                  userDocumentsImg: ResourceType != 1 && ResourceType != 5,
                  documents_img: ResourceType == 1 || ResourceType == 5,
                  optUserDocumentsImg: item.id == checkedId,
                }" :src="item.imageUrl | filtrationImg" alt />


              </el-popover>
              <span v-else>
                <img v-if="item.type == 0" class="documents_img" src="../../assets/Resource/icon_folder.png" alt />




                <img slot="reference" v-else :class="{
                  userDocumentsImg: ResourceType != 1 && ResourceType != 5,
                  documents_img: ResourceType == 1 || ResourceType == 5,
                  optUserDocumentsImg: item.id == checkedId,
                }" :src="item.imageUrl | filtrationImg" alt />



              </span>




              <!-- checkedDocuments_title -->
              <div :class="{
                title_text2: ResourceType != 1 && ResourceType != 5,
                title_text: ResourceType == 1 || ResourceType == 5,
              }">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="top">
                  <div :class="{
                    checkedDocuments_title: item.id == checkedId,
                    documents_title: true,
                  }">
                    {{ item.name }}
                  </div>
                </el-tooltip>
                <div :class="{
                  time: true,
                  checkedDocuments_title: item.id == checkedId,
                  documents_title: true,
                }">
                  {{ item.updateTime }}
                </div>
              </div>
            </div>

            <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom">
              <!-- <div
                :class="{
                  checkedDocuments_title: item.id == checkedId,
                  documents_title: true,
                }"
              >
                {{ item.name }}
              </div>-->
            </el-tooltip>
          </div>
        </div>

        <!-- <div class="empty" v-if="FileDirectoryList.length==0">
      <img class="empty_img" src="../../assets/Resource/empty.png" alt="">
        </div>-->
        <input type="file" id="file" @change="getUrlParticle" ref="particle" style="width: undefined; height: 0"
          :multiple="false" accept=".json" value />
      </div>
      <div class="myMenu">
        <div v-show="menu">
          <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">
            <div v-if="
              (ResourceType == 1 &&
                $store.state.userPermissionData.SCENE_FILE_CREATION_SCENE &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 2 &&
                $store.state.userPermissionData.MODEL_FILE_UPLOAD &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 3 &&
                $store.state.userPermissionData.PICTUREMATERIAL_FILE_UPLOAD &&
                $store.state.chosenWarehouse.type == 0
                && $store.state.terrainType != 'terrainType'
              ) ||
              (ResourceType == 3 &&
                $store.state.userPermissionData.PICTUREMATERIAL_FILE_UPLOAD &&
                $store.state.chosenWarehouse.type == 0
                && $store.state.terrainType == 'terrainType' &&
                isHdr != '.hdr'


              ) ||
              (ResourceType == 8 &&
                $store.state.userPermissionData.MEDIA_FILE_UPLOAD &&
                $store.state.chosenWarehouse.type == 0)
            ">
              <el-menu-item index="1" class="menuItem" v-if="handleSelect && ResourceType != 6 && rightClickType == 1">
                <span slot="title">
                  <!-- 加载 -->

                  <img v-if="
                    (ResourceType == 2 &&
                      node.type != 0 &&
                      rightClickType == 1) ||
                    (ResourceType == 3 &&
                      node.type != 0 &&
                      rightClickType == 1) ||
                    (ResourceType == 4 &&
                      node.type != 0 &&
                      rightClickType == 1)
                  " src="../../assets/Resource/load.png" alt class="menuIcon" />
                  <img v-else src="../../assets/Resource/group.png" alt class="menuIcon" />

                  <!-- {{ResourceType==1 ?"打开":ResourceType==2?"加载":"打开"}} -->

                  <span v-if="ResourceType == 1 && rightClickType == 1">打开</span>
                  <span v-else-if="
                    ResourceType == 2 && node.type != 0 && rightClickType == 1
                  ">加载</span>
                  <span v-else-if="
                    ResourceType == 3 && node.type != 0 && rightClickType == 1
                  ">加载</span>
                  <span v-else-if="
                    ResourceType == 4 && node.type != 0 && rightClickType == 1
                  ">加载</span>

                  <span v-else-if="
                    ResourceType == 5 && node.type != 0 && rightClickType == 1
                  ">编辑</span>
                  <span v-else-if="node.type == 0">打开</span>
                  <span v-else>加载</span>
                </span>
              </el-menu-item>
            </div>

            <!-- <div v-if="ResourceType == 3 && isHdr != '.hdr'">
              <el-menu-item index="27" class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/TerrainMaterial.png" alt class="menuIcon" />
                  加载到地形
                </span>
              </el-menu-item>
            </div> -->
            <div v-if="estimateLock == 0">
              <div v-if="
                (ResourceType == 2 &&
                  $store.state.userPermissionData.MODEL_FILE_UPDATE &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 3 &&
                  $store.state.userPermissionData
                    .PICTUREMATERIAL_FILE_UPDATE &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 8 &&
                  $store.state.userPermissionData.MEDIA_FILE_UPDATE &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 5 &&
                  $store.state.userPermissionData.MEDIA_FILE_UPDATE &&
                  $store.state.chosenWarehouse.type == 0)
              ">
                <el-menu-item index="2" class="menuItem" v-if="
                  (ResourceType == 2 &&
                    node.type != 0 &&
                    node.local != 1 &&
                    rightClickType == 1 &&
                    node.property != 6 &&
                    !$store.state.textureBallShow) ||
                  (ResourceType == 3 &&
                    node.type != 0 &&
                    node.local != 1 &&
                    rightClickType == 1 &&
                    node.property != 6 &&
                    !$store.state.textureBallShow) ||
                  (ResourceType == 4 &&
                    node.type != 0 &&
                    node.local != 1 &&
                    rightClickType == 1 &&
                    node.property != 6 &&
                    !$store.state.textureBallShow) ||
                  (ResourceType == 8 &&
                    node.type != 0 &&
                    node.local != 1 &&
                    rightClickType == 1 &&
                    node.property != 6 &&
                    !$store.state.textureBallShow) ||
                  (ResourceType == 5 &&
                    node.type != 0 &&
                    node.local != 1 &&
                    rightClickType == 1 &&
                    node.property != 6 &&
                    !$store.state.textureBallShow)
                ">
                  <span slot="title">
                    <img v-if="
                      node.property != 7 &&
                      node.property != 9 &&
                      node.property != 10 &&
                      node.property != 12
                    " src="../../assets/Resource/renewal.png" alt class="menuIcon" />
                    <img v-else src="../../assets/Resource/materialBall.png" alt class="menuIcon" />

                    <span v-if="
                      node.property != 7 &&
                      node.property != 9 &&
                      node.property != 10 &&
                      node.property != 12

                    ">更新</span>
                    <span v-else>编辑</span>
                  </span>
                </el-menu-item>
              </div>
            </div>







            <div v-if="estimateLock == 0">
              <div v-if="
                (ResourceType == 1 &&
                  $store.state.userPermissionData.SCENE_FILE_RENAME &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 2 &&
                  $store.state.userPermissionData.MODEL_FILE_RENAME &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 3 &&
                  $store.state.userPermissionData
                    .PICTUREMATERIAL_FILE_RENAME &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 8 &&
                  $store.state.userPermissionData.MEDIA_FILE_RENAME &&
                  $store.state.chosenWarehouse.type == 0)
              ">
                <el-menu-item index="4" v-if="
                  ResourceType != 6 &&
                  node.local != 1 &&
                  rightClickType == 1 &&
                  node.sceneLocal &&
                  !$store.state.textureBallShow
                " class="menuItem">
                  <span slot="title">
                    <img src="../../assets/Resource/newName.png" alt class="menuIcon" />
                    重命名
                  </span>
                </el-menu-item>
              </div>
            </div>

            <div v-if="estimateLock == 0">
              <div v-if="
                (ResourceType == 1 &&
                  $store.state.userPermissionData.SCENE_FILE_DELETE &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 2 &&
                  $store.state.userPermissionData.MODEL_FILE_DELETE &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 3 &&
                  $store.state.userPermissionData
                    .PICTUREMATERIAL_FILE_DELETE &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 8 &&
                  $store.state.userPermissionData.MEDIA_FILE_DELETE &&
                  $store.state.chosenWarehouse.type == 0)||
                  (ResourceType == 5 &&
                  $store.state.chosenWarehouse.type == 0)
              ">
                <el-menu-item index="3" v-if="
                  ResourceType != 6 &&
                  node.local != 1 &&
                  rightClickType == 1 &&
                  node.sceneLocal &&
                  !$store.state.textureBallShow
                " class="menuItem">
                  <span slot="title">
                    <img src="../../assets/Resource/icon-67.png" alt class="menuIcon" />
                    删除
                  </span>
                </el-menu-item>
              </div>
            </div>
            <div v-if="estimateLock == 0">
              <div v-if="
                ResourceType == 6 &&
                $store.state.userPermissionData.RECYCLED &&
                $store.state.chosenWarehouse.type == 0
              ">
                <el-menu-item index="5" v-if="
                  ResourceType == 6 &&
                  node.local != 1 &&
                  rightClickType == 1 &&
                  !$store.state.textureBallShow
                " class="menuItem">
                  <span slot="title">
                    <img src="../../assets/Resource/renew.png" alt class="menuIcon" />

                    恢复
                  </span>
                </el-menu-item>
              </div>
            </div>

            <el-menu-item index="24" v-if="
              ResourceType == 6 &&
              rightClickType == 2 &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Resource/emptyRecycleBin.png" alt class="menuIcon" />
                清空回收站
              </span>
            </el-menu-item>

            <div v-if="estimateLock == 0">
              <div v-if="
                (ResourceType == 1 &&
                  $store.state.userPermissionData.SCENE_FILE_CUT &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 2 &&
                  $store.state.userPermissionData.MODEL_FILE_CUT &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 3 &&
                  $store.state.userPermissionData.PICTUREMATERIAL_FILE_CUT &&
                  $store.state.chosenWarehouse.type == 0) ||
                (ResourceType == 8 &&
                  $store.state.userPermissionData.MEDIA_FILE_CUT &&
                  $store.state.chosenWarehouse.type == 0)
              ">
                <el-menu-item index="6" v-if="
                  ResourceType != 6 &&
                  node.local != 1 &&
                  rightClickType == 1 &&
                  node.sceneLocal &&
                  !$store.state.textureBallShow
                " class="menuItem">
                  <span slot="title">
                    <img src="../../assets/Resource/shear.png" alt class="menuIcon" />
                    剪切
                  </span>
                </el-menu-item>
              </div>
            </div>
            <div v-if="
              (ResourceType == 1 &&
                $store.state.userPermissionData.SCENE_FILE_COPY &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 2 &&
                $store.state.userPermissionData.MODEL_FILE_COPY &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 3 &&
                $store.state.userPermissionData.PICTUREMATERIAL_FILE_COPY &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 8 &&
                $store.state.userPermissionData.MEDIA_FILE_COPY_URL &&
                $store.state.chosenWarehouse.type == 0)
            ">
              <!-- node.local != 1 && -->

              <el-menu-item index="9" v-if="
                ResourceType == 1 &&
                rightClickType == 1 &&
                node.sceneLocal &&
                !$store.state.textureBallShow
              " class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/copy.png" alt class="menuIcon" />
                  复制
                </span>
              </el-menu-item>
            </div>
            <!-- v-if="estimateLock==0" -->



            <div>
              <div>
                <div v-if="
                  (ResourceType == 1 &&
                    $store.state.userPermissionData
                      .SCENE_FILE_CREATION_SCENE &&
                    $store.state.chosenWarehouse.type == 0) ||
                  ($store.state.userPermissionData.MODEL_FILE_UPLOAD_MODEL &&
                    ResourceType == 2 &&
                    $store.state.chosenWarehouse.type == 0) ||
                  ($store.state.userPermissionData
                    .PICTUREMATERIAL_FILE_UPLOAD_PICTURE &&
                    ResourceType == 3 &&
                    $store.state.chosenWarehouse.type == 0) ||
                  ($store.state.userPermissionData.MEDIA_FILE_UPLOAD_MEDIA &&
                    ResourceType == 8 &&
                    $store.state.chosenWarehouse.type == 0)
                ">
                  <el-menu-item index="8" v-if="
                    ResourceType != 6 &&
                    rightClickType == 2 &&
                    FileDirectoryId &&
                    !$store.state.textureBallShow
                  " class="menuItem">
                    <span slot="title" v-show="ResourceType == 1">
                      <img src="../../assets/Resource/CreateScenario.png" alt class="menuIcon" />
                      创建场景
                    </span>
                    <span slot="title" v-show="ResourceType == 2">
                      <img src="../../assets/Resource/uploading.png" alt class="menuIcon" />
                      上传模型
                    </span>
                    <span slot="title" v-show="ResourceType == 3">
                      <img src="../../assets/Resource/uploading.png" alt class="menuIcon" />
                      上传贴图
                    </span>
                    <span slot="title" v-show="ResourceType == 4">
                      <img src="../../assets/Resource/CreateScenario.png" alt class="menuIcon" />
                      创建材质
                    </span>
                    <span slot="title" v-show="ResourceType == 5">
                      <img src="../../assets/Resource/CreateScenario.png" alt class="menuIcon" />
                      创建脚本
                    </span>
                    <span slot="title" v-show="ResourceType == 8">
                      <img src="../../assets/Resource/CreateScenario.png" alt class="menuIcon" />
                      上传媒体
                    </span>
                  </el-menu-item>
                </div>
              </div>
            </div>

            <div>
              <div>
                <div v-if="
                  $store.state.userPermissionData.MODEL_FILE_UPLOAD_MODEL &&
                  ResourceType == 2 &&
                  $store.state.chosenWarehouse.type == 0
                ">
                  <el-menu-item index="25" v-if="
                    ResourceType != 6 &&
                    rightClickType == 2 &&
                    FileDirectoryId &&
                    !$store.state.textureBallShow
                  " class="menuItem">
                    <span slot="title" v-show="ResourceType == 2">
                      <img src="../../assets/Resource/CreateScenario.png" alt class="menuIcon" />
                      上传粒子
                    </span>
                  </el-menu-item>
                </div>
              </div>
            </div>


            <el-menu-item index="26" v-if="rightClickType == 2 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow" class="menuItem">
              <span slot="title">
                <img src="../../assets/Resource/CreateScenario.png" alt class="menuIcon" />
                新建动作控制器
              </span>
            </el-menu-item>


            <div v-if="estimateLock == 0">
              <div v-if="
                $store.state.userPermissionData
                  .PICTUREMATERIAL_FILE_CREATION_BASICS_MATERIAL &&
                ResourceType == 3 &&
                $store.state.chosenWarehouse.type == 0
              ">
                <el-menu-item index="10" v-if="
                  ResourceType == 3 &&
                  rightClickType == 2 &&
                  FileDirectoryId &&
                  !$store.state.textureBallShow
                " class="menuItem">
                  <span slot="title" v-show="ResourceType == 3">
                    <img src="../../assets/Resource/baseMaterial.png" alt class="menuIcon" />
                    创建基础材质
                  </span>
                </el-menu-item>
              </div>
            </div>

            <div v-if="
              $store.state.userPermissionData
                .PICTUREMATERIAL_FILE_CREATION_BASICS_MATERIAL &&
              ResourceType == 3 &&
              $store.state.chosenWarehouse.type == 0 && estimateLock == 0
            ">
              <el-menu-item index="16" v-if="
                ResourceType == 3 &&
                rightClickType == 2 &&
                FileDirectoryId &&
                !$store.state.textureBallShow
              " class="menuItem">
                <span slot="title" v-show="ResourceType == 3">
                  <img src="../../assets/Resource/PhongShader.png" alt class="menuIcon" />
                  创建冯氏材质
                </span>
              </el-menu-item>
            </div>


            <div v-if="
              $store.state.userPermissionData
                .PICTUREMATERIAL_FILE_CREATION_STANDARD_MATERIAL &&
              ResourceType == 3 &&
              $store.state.chosenWarehouse.type == 0 && estimateLock == 0
            ">
              <el-menu-item index="12" v-if="
                ResourceType == 3 &&
                rightClickType == 2 &&
                FileDirectoryId &&
                !$store.state.textureBallShow
              " class="menuItem">
                <span slot="title" v-show="ResourceType == 3">
                  <img src="../../assets/Resource/icon_save_ball .png" alt class="menuIcon" />
                  创建标准材质
                </span>
              </el-menu-item>
            </div>

            <el-menu-item index="7" v-if="
              ResourceType != 6 &&
              rightClickType == 2 &&
              stickShow &&
              !$store.state.textureBallShow &&
              copyShearType == 1
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Resource/stickup.png" alt class="menuIcon" />
                粘贴
              </span>
            </el-menu-item>

            <div v-if="
              (ResourceType == 1 &&
                $store.state.userPermissionData.SCENE_FILE_COPY_URL &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 2 &&
                $store.state.userPermissionData.MODEL_FILE_COPY_URL &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 3 &&
                $store.state.userPermissionData
                  .PICTUREMATERIAL_FILE_COPY_URL &&
                $store.state.chosenWarehouse.type == 0) ||
              ($store.state.userPermissionData.MEDIA_FILE_COPY_URL &&
                ResourceType == 8 &&
                $store.state.chosenWarehouse.type == 0)
            ">
              <el-menu-item index="11" v-if="rightClickType == 1" class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/copy.png" alt class="menuIcon" />
                  复制url
                </span>
              </el-menu-item>
            </div>
            <!-- <el-menu-item
              index="13"
              v-if="checkedId && checkedId != node.id&& rightClickType == 1"
              class="menuItem"
            >
              <span slot="title">
                <img src="../../assets/Resource/copy.png" alt class="menuIcon" />
                与选中文件交换位置
              </span>
            </el-menu-item> -->
            <div v-if="
              (ResourceType == 2 &&
                $store.state.userPermissionData.MODEL_FILE_COPY &&
                $store.state.chosenWarehouse.type == 0) ||
              (ResourceType == 3 &&
                $store.state.userPermissionData.PICTUREMATERIAL_FILE_COPY &&
                $store.state.chosenWarehouse.type == 0)
            ">
              <el-menu-item index="14" v-if="
                (rightClickType == 1 && ResourceType == 2) ||
                (rightClickType == 1 && ResourceType == 3)
              " class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/copy.png" alt class="menuIcon" />
                  <!-- 模型贴图的复制 -->
                  复制
                </span>
              </el-menu-item>
            </div>

            <el-menu-item index="15" v-if="
              ((rightClickType == 2 && ResourceType == 2) ||
                (rightClickType == 2 && ResourceType == 3)) &&
              copyShearType == 2 &&
              $store.state.chosenWarehouse.type == 0
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Resource/copy.png" alt class="menuIcon" />
                <!-- 模型贴图的粘贴 -->
                粘贴
              </span>
            </el-menu-item>
            <el-menu-item index="17" v-if="
              node.local == 0 &&
              $store.state.UserRank == 3 &&
              rightClickType == 1 &&
              $store.state.chosenWarehouse.type == 0
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Resource/icon_lock.png" alt class="menuIcon" />
                <!-- 上锁 -->
                上锁
              </span>
            </el-menu-item>
            <el-menu-item index="18" v-if="
              node.local == 1 &&
              $store.state.UserRank == 3 &&
              rightClickType == 1 &&
              $store.state.chosenWarehouse.type == 0
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Resource/copy.png" alt class="menuIcon" />
                <!-- 解锁 -->
                解锁
              </span>
            </el-menu-item>
            <div v-if="
              $store.state.chosenWarehouse.type == 0 &&
              ResourceType != 1 &&
              ResourceType != 6 &&
              $store.state.UserRank == 3 &&
              rightClickType == 1
            ">
              <el-menu-item index="19" class="menuItem">
                <img src="../../assets/sharedFile/uploading.png" alt class="menuIcon" />

                <span slot="title">上传共享库</span>
              </el-menu-item>
            </div>
            <div v-if="
              ($store.state.chosenWarehouse.type == 1 &&
                rightClickType == 1) ||
              ($store.state.chosenWarehouse.type == 2 && rightClickType == 1)
            ">
              <el-menu-item index="20" class="menuItem">
                <span slot="title">
                  <img src="../../assets/sharedFile/privateIcon.png" alt class="menuIcon" />

                  <!-- 同步到本地 -->
                  同步到本地
                </span>
              </el-menu-item>
            </div>
            <div v-if="
              ($store.state.chosenWarehouse.type == 1 &&
                rightClickType == 1 &&
                FileDirectoryId == '2') ||
              ($store.state.chosenWarehouse.type == 2 &&
                rightClickType == 1 &&
                FileDirectoryId == '2')
            ">
              <el-menu-item index="22" class="menuItem">
                <span slot="title">
                  <img src="../../assets/sharedFile/remove.png" alt class="menuIcon" />

                  <!-- 同步到本地 -->
                  从共享库移除
                </span>
              </el-menu-item>
            </div>
            <div v-if="ResourceType == 3">
              <el-menu-item index="21" v-if="
                node.local == 0 &&
                rightClickType == 1 &&
                node.property == 3 &&
                isHdr == '.hdr'
              " class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/copy.png" alt class="menuIcon" />
                  <!-- 设置为环境贴图 -->
                  设置为环境贴图
                </span>
              </el-menu-item>
            </div>
            <el-menu-item index="28" v-if="
              rightClickType == 2 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Property/texture.png" alt class="menuIcon" />
                <!-- 解锁 -->
                新建纹理控制器
              </span>
            </el-menu-item>
            <el-menu-item index="29" v-if="
              rightClickType == 2 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Property/controller.png" alt class="menuIcon" />
                <!-- 解锁 -->
                新建动态控制器
              </span>
            </el-menu-item>
            <el-menu-item index="30" v-if="
              rightClickType == 2 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Property/text.png" alt class="menuIcon" />
                <!-- 解锁 -->
                新建文本控制器
              </span>
            </el-menu-item>
            <el-menu-item index="31" v-if="
              rightClickType == 2 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Property/path.png" alt class="menuIcon" />
                <!-- 解锁 -->
                新建曲线控制器
              </span>
            </el-menu-item>
            <el-menu-item index="32" v-if="
              rightClickType == 2 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Property/hud.png" alt class="menuIcon" />
                <!-- 解锁 -->
                新建HUD控制器
              </span>
            </el-menu-item>
            <el-menu-item index="33" v-if="
              rightClickType == 2 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Property/Ai.png" alt class="menuIcon" />
                <!-- 解锁 -->
                新建AI控制器
              </span>
            </el-menu-item>

            <el-menu-item index="34" v-if="
              rightClickType == 1 && ResourceType == 5 && FileDirectoryId &&
              !$store.state.textureBallShow
            " class="menuItem">
              <span slot="title">
                <img src="../../assets/Property/Ai.png" alt class="menuIcon" />
                <!-- 解锁 -->
                应用脚本
              </span>
            </el-menu-item>


            <!-- <div v-if="ResourceType == 2">


              <el-menu-item index="23"
                v-if="rightClickType == 2 && FileDirectoryId && !$store.state.textureBallShow && $store.state.chosenWarehouse.type == 0"
                class="menuItem">
                <span slot="title">
                  <img src="../../assets/Resource/particle.png" alt class="menuIcon" />
               
                  创建粒子
                </span>
              </el-menu-item>
            </div> -->
          </el-menu>
        </div>
      </div>

      <!-- js编辑 -->
      <div class="codeEditor" v-show="codeEditor">
        <div class="close" @click="close">
          <img src="../../assets/Project/close.png" alt />
        </div>
        <CodeEditor ref="CodeEditor" @close="close" />
      </div>
    </div>
    <div class="masking" v-show="codeEditor" @contextmenu.prevent="maskingHint()" @click="maskingHint()"></div>
    <div class="masking" v-show="deleteShow"></div>
    <div class="delete" v-show="deleteShow">
      <p class="close" @click="affirm">
        <img src="../../assets/Project/close.png" alt />
      </p>
      <div class="deletNameDiv">
        <div class="deleteName">
          <img v-if="node.property != 7" src="../../assets/Resource/hint.png" alt class="hint" />

          <div class="nameText" v-if="shearType == 2">
            <!-- {{message}} -->
            文件名已存在请输入新的文件名
          </div>
          <div class="nameText" v-else>
            <!-- {{message}} -->
            此目录下有重名文件请先修改
          </div>

          <div class="ipt_box" v-if="shearType == 2">
            <input class="input" type="text" v-model="newFileName" placeholder="请输入新的文件名" maxlength="20" />
          </div>
        </div>
      </div>
      <div class="enter">
        <div class="set verify deleteOK" @click="confirm">确认</div>
        <div class="set cancel deleteCancel" @click="affirm">取消</div>
      </div>
    </div>


    <el-dialog title="创建脚本组件" :modal="false" :visible.sync="scriptModuleShow" @close="scriptModuleClose()" top="10vh">
      <CreatedAddComponent v-if="scriptModuleShow" ref="CreatedAddComponent" :scriptType="scriptType" :folderId="FileDirectoryId"
        :scriptEditId="scriptEditId" :ResourceType="ResourceType" :node="node"></CreatedAddComponent>
      <div slot="footer" class="dialog-footer">
        <el-button @click="scriptModuleShow = false">取 消</el-button>
        <el-button type="primary" @click="cleactComponent()">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>
<script>
import { INetwork } from "../../threejs/interface/INetwork";
import { ILoader } from "../../threejs/interface/ILoader";
import { IScene } from "../../threejs/interface/IScene";
import { ISetting } from "../../threejs/interface/ISetting";

import { IBehavior } from "../../threejs/interface/IBehavior";
import CodeEditor from "../property/CodeEditor";
import CreatedAddComponent from "../property/CreatedAddComponent";




import { ResourceList } from "./ResourceList";
import { fileURLToPath } from "url";
import { log } from "util";

export default {
  name: "Resource",
  data() {
    return {
      monofileList: [],
      menu: false,
      //右击选中的节点
      item: {},
      //右击的id
      id: "",
      node: {},
      //进度条
      progressBar: 50,

      //添加文字的回显
      addition: "打开",
      //当前打开的场景
      presentSceneId: "",
      //js编辑
      codeEditor: false,
      //树
      tree: [],
      //选中的id
      checkedId: "",
      //当前场景锁的状态
      sceneLockState: false,
      //右击类型
      rightClickType: "",

      //复制

      //文件id
      fileId: "",
      //粘贴显示
      stickShow: false,
      //重复提示
      deleteShow: false,
      //提示
      message: "",
      // 粘贴类型
      shearType: "",
      //新的文件名
      newFileName: "",
      // 场景加载loding
      loadScene: "",

      //复制剪切类型
      copyShearType: "",

      //右击的是否上锁
      estimateLock: "",
      //3d资源是否加载完成
      fullscreenLoading: false,
      //是否是hdr后缀的路径
      isHdr: false,



      //粒子
      MultimediaName: "",
      MultimediaUpdateOrAdd: "",
      particleData: {},
      MultimediaFileId: "",

      particleSize: 0,
      // 是否提示黏贴
      paste: false,

      scriptModuleShow: false,

      scriptTypeMap: {
        "动态纹理": "1",
        "动态控制器": "2",
        "文本控制器": "3",
        "路径模拟器": "4",
        "HUD": "5",
        "AI寻路": "6",
      },

      scriptType: "0",

      scriptEditId: "",



    };
  },
  props: [
    //传过来的文件列表
    "FileDirectoryList",

    //传过来的id
    "FileDirectoryId",

    //传过来的文件树类型 1场景，2模型，3贴图，4材质，5脚本，6 回收站
    "ResourceType",

    "childSia",

    "temppath",

    "bottomHeight",
    //材质球显示
    "textureBall",

    //更改还是编辑
    "upgrading",

    //球材质
    "ballChildSia",
  ],
  components: {
    CodeEditor,
    CreatedAddComponent,
  },
  created() { },
  mounted() {
    var sceneName = localStorage.getItem("sceneName");

    if (sceneName) {
      this.fullscreenLoading = true;
    }

    INetwork.f_loadSceneFinishCallback = this.loadSceneFinish.bind(this);

    window.addEventListener("click", (e) => {
      var textureBallMasking = this.$store.state.textureBallMasking;

      if (
        this.menu == true &&
        textureBallMasking == false &&
        this.deleteShow == false
      ) {
        this.$parent.setDragShow(true);
      }
      this.menu = false;
    });

    if (this.ResourceType == 1) {
      this.initializeCheckedId();
    }
  },
  methods: {
    async emptyRecycleBin(id) {
      let data = {
        projectId: id,
      };

      const res = await this.$https.emptyRecycleBin(data);

      if (res.success == true) {
        this.$parent.getDataTree();
        this.$parent.getSubCatalog(1);
      }
    },

    //场景加载完成回调
    loadSceneFinish() {
      this.fullscreenLoading = false;
      // this.loadScene.close();
      // console.log('加载完成')
    },

    //确定

    confirm() {
      this.deleteShow = false;
      ISetting.isInput(false);

      this.$parent.setDragShow(true);
      this.stickShow = false;
      //粘贴
      //当前所在的类型

      var ResourceType = this.ResourceType;
      // 存储的剪切
      var shear = localStorage.getItem("shear");

      shear = JSON.parse(shear);
      if (shear) {
        // var
        //剪切
        if (shear.copyOrCut == 1) {
          this.deleteShow = false;
          ISetting.isInput(false);

          this.$parent.setDragShow(true);
        } else if (shear.copyOrCut == 2) {
          INetwork.downloadJson(shear.node.fileUrl, (data) => {
            this.addFile(this.newFileName).then(() => {
              if (this.fileId && this.fileId != "") {
                var json = data;

                var newData = INetwork.uploadJsonToSql(
                  this.fileId,
                  "scene",
                  JSON.stringify(json),
                  () => { }
                );

                this.updateFile(newData).then(() => {
                  this.$emit("setFileDirectoryId", this.FileDirectoryId);
                });
              }
            });
          });
        }
      }
    },

    affirm() {
      this.deleteShow = false;
      ISetting.isInput(false);

      this.$parent.setDragShow(true);
      this.newFileName = "";
    },
    //设置选中的id
    setCheckedId(msg) {
      this.checkedId = msg;
    },
    //初始化选中的文件
    initializeCheckedId() {
      var checkedId = localStorage.getItem("presentSceneId");

      if (checkedId != undefined && checkedId != null && checkedId != "") {
        this.checkedId = checkedId;
      }
      //初始化文件夹id
      var FileDirectoryId = localStorage.getItem("FileDirectoryId");

      if (
        FileDirectoryId != null &&
        FileDirectoryId != undefined &&
        FileDirectoryId != ""
      ) {
        this.$parent.setFileDirectoryId(FileDirectoryId);

        // this.$emit("setFileDirectoryId", FileDirectoryId);
      }
      //初始化类型
      var ResourceType = localStorage.getItem("ResourceType");

      if (
        ResourceType != null &&
        ResourceType != undefined &&
        ResourceType != ""
      ) {
        this.$emit("setResourceType", ResourceType);

        var temppath = localStorage.getItem("temppath");

        if (temppath != "null" && temppath != undefined && temppath != "") {
          var newTemppath = temppath.split(",");

          this.$emit("getPath", newTemppath);
        }
      }
    },

    //单击选择
    checked(item) {
      this.checkedId = item.id;

      this.$store.commit("setSelectNode", item);

      // this.$store.commit("changeCloudsScene", item);
    },
    setChecked() {
      this.checkedId = "";
    },

    estimateAddItion() {
      if (this.ResourceType == 1) {
        this.addition = "打开场景";
      } else if (this.ResourceType == 2) {
        this.addition = "添加模型";
      } else if (this.ResourceType == 3) {
        this.addition = "创建材质";
      }
    },

    async handleSelect(key) {
      let _this = this;
      this.estimateAddItion();
      await this.getUserProjectAuthority();

      if (key == 1) {
        if (
          (this.ResourceType == 1 &&
            this.$store.state.userPermissionData.SCENE_FILE_CREATION_SCENE) ||
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_UPLOAD) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_UPLOAD) ||
          (this.ResourceType == 8 &&
            this.$store.state.userPermissionData.MEDIA_FILE_UPLOAD) ||
          this.ResourceType == 5
        ) {
          if (this.node.type == 0) {
            this.doubleOpen(this.node);
          } else {
            if (this.ResourceType == 1) {
              //场景
              this.getSceneStatus(this.node.id).then(() => {
                var oldId = localStorage.getItem("openSceneId");

                if (this.node.id != oldId && this.node.fileUrl != "") {
                  if (this.sceneLockState == true) {
                    // 先锁上新的
                    this.setSceneLocal(this.node.id, 1);

                    //在解开旧的

                    this.setSceneLocal(oldId, 0);
                    //打开的场景id
                    localStorage.setItem("openSceneId", this.node.id);
                    //打开的文件夹id
                    localStorage.setItem(
                      "FileDirectoryId",
                      this.FileDirectoryId
                    );
                    //打开的树的类型
                    localStorage.setItem("ResourceType", this.ResourceType);
                    //路径
                    localStorage.setItem("temppath", this.temppath);

                    var presentSceneId = localStorage.getItem("presentSceneId");
                    if (
                      presentSceneId != "null" &&
                      presentSceneId != undefined
                    ) {
                      // IScene.save()
                    }

                    ISetting.exit();
                    this.$store.commit("changeCloudsScene", this.node);

                    ////场景添加

                    // this.loadScene = this.$loading({
                    //   lock: true,
                    //   text: "Loading",
                    //   spinner: "el-icon-loading",
                    //   background: "rgba(0, 0, 0, 0.7)"
                    // });

                    //传主id // 回调

                    INetwork.openSceneJson(this.node.fileUrl, () => {
                      localStorage.setItem("presentSceneId", this.node.id);

                      this.$emit("setFileDirectoryId", this.FileDirectoryId);

                      localStorage.setItem("sceneName", this.node.name);

                      this.$message({
                        message: "场景切换成功",
                        // type: "success"
                      });

                      this.sceneLockState = false;

                      location.reload();
                    });
                  }
                }
              });
            } else if (this.ResourceType == 2) {
              //模型 添加

              // this.$emit('uploading','1')
              // 1.主路径 需要走进度条  // 2. 传id 3. 回调 4.完成

              // 材质球加载

              if (this.node.property == 6) {
                ILoader.loadGroupToScene(
                  this.node.fileUrl,
                  this.node.name,
                  this.process.bind(this),
                  this.finish.bind(this)
                );
                //粒子加载
              } else if (this.node.property == 11) {

                // console.log("加载了粒子")
                ILoader.loadParticleSystemToScene(
                  this.node.fileUrl,
                  this.node.id,
                  this.node.name,
                  this.process.bind(this),
                  this.finish.bind(this)
                );
              }

              else {
                //其他加载
                ILoader.loadModelToScene(
                  this.node.fileUrl,
                  this.node.id,
                  this.node.name,
                  this.process.bind(this),
                  this.finish.bind(this)
                );
              }
            } else if (this.ResourceType == 3) {
              //添加纹理  名字  id  类型 从别的组件接受 进度条  回调
              if (this.node.property == 7 || this.node.property == 9 || this.node.property == 10) {
                ILoader.loadMaterialToObject(
                  this.node.fileUrl,
                  this.node.id,
                  this.process.bind(this),
                  () => { }
                );
              } else {






                let terrainType = this.$store.state.terrainType
                console.log(terrainType, "terrainType")




                if (terrainType) {
                  if (terrainType == "modelType") {


                    if (
                      this.node.property == 7 ||
                      this.node.property == 9 ||
                      this.node.property == 10
                    ) {

                      //  "材质球"
                      ILoader.loadMaterialToObject(
                        this.node.fileUrl,
                        this.node.id,
                        this.process.bind(this),
                        () => { }
                      );


                    } else if (this.node.property == 3) {
                      if (this.textureBall == true && this.upgrading == 2) {

                        ILoader.loadTextureToUserMat(
                          this.node.fileUrl,
                          this.node.id,
                          this.ballChildSia,
                          this.process.bind(this),
                          () => { }
                        );
                      } else {
                        //其他加载

                        ILoader.loadTextureToMat(
                          this.node.fileUrl,
                          this.node.id,
                          this.childSia,
                          this.process.bind(this),
                          (data) => { }
                        );
                      }
                    }
                  } else if (terrainType == "terrainType") {
                    ILoader.loadTextureToTerrain(this.node.fileUrl, this.node.id, this.$store.state.TerrainMaterial.type, this.$store.state.TerrainMaterial.subTexIndex, () => { }, () => {
                      this.$message({
                        // type: "info",
                        message: "加载成功"
                      });

                    })
                  } else if (terrainType == "grass_map") {

                    ILoader.loadTextureToTerrain(this.node.fileUrl, this.node.id, this.$store.state.TerrainMaterial.type, this.$store.state.TerrainMaterial.subTexIndex, () => { }, () => {
                      this.$message({
                        // type: "info",
                        message: "加载成功"
                      });

                    })


                  }
                  else {
                    this.$message({
                      // type: "info",
                      message: "请在模型或贴图里选择"
                    });
                  }



                } else {
                  ILoader.loadTextureToMat(
                    this.node.fileUrl,
                    this.node.id,
                    this.childSia,
                    this.process.bind(this),
                    (data) => { }
                  );
                }
              }

            } else if (this.ResourceType == 5) {
              if (this.node.property == 12) {
                // INetwork.
                let _this = this

                INetwork.openAnimationBehaviorJson(this.node.fileUrl, (data) => {

                  _this.$store.commit("changeMotionPlayerData", data)
                  _this.$store.commit("changeMacState", true)
                  _this.$store.commit("changeMotionPlayerType", 2)
                  _this.$store.commit("changeMotionPlayerParentId", this.FileDirectoryId)

                  _this.$store.commit("changeMotionPlayerSqlData", this.node)





                })

              }

            } else if (this.ResourceType == 8) {
              //媒体加载


              ILoader.loadAudioToScene(
                this.node.fileUrl,
                this.node.id,
                this.node.name,
                this.process.bind(this),
                this.finish.bind(this)
              );
            }
          }
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 2) {
        if (
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_UPDATE) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_UPDATE) ||
          (this.ResourceType == 8 &&
            this.$store.state.userPermissionData.MEDIA_FILE_UPDATE) ||
          this.ResourceType == 5

        ) {
          if (
            this.node.property == 7 ||
            this.node.property == 9 ||
            this.node.property == 10
          ) {
            //编辑
            localStorage.setItem("updateFileId", this.node.id);

            this.$emit("uploading", "2", this.ResourceType, "", 2);

            this.$parent.setUpDataNode(this.node);
          } else if (this.node.property == 8) {
            this.$parent.updateTheMedia(2, this.node);
          } else if (this.node.property == 11) {

            // 粒子更新
            // this.$store.commit("changePartiClecompile", true);
            // this.$store.commit("changePartiClecompileData", this.node);

            this.$refs.particle.value = "";
            this.$refs.particle.click();

            this.MultimediaUpdateOrAdd = 2;
            this.MultimediaName = this.node.name;
            this.MultimediaFileId = this.node.id;


            // console.log("更新");
          } else if (this.node.property == 12) {


            let _this = this

            INetwork.openAnimationBehaviorJson(this.node.fileUrl, (data) => {
              // console.log(data);
              _this.$store.commit("changeMotionPlayerData", data)
              _this.$store.commit("changeMacState", true)
              _this.$store.commit("changeMotionPlayerType", 2)
              _this.$store.commit("changeMotionPlayerParentId", this.FileDirectoryId)

              _this.$store.commit("changeMotionPlayerSqlData", this.node)


            })

          } else if (this.node.property == 13 ||
            this.node.property == 14 ||
            this.node.property == 15 ||
            this.node.property == 16 ||
            this.node.property == 17 ||
            this.node.property == 18) {
            this.scriptEditId = this.node.id

            let scriptTypeMap = {
              13: "1",
              14: "2",
              15: "3",
              16: "4",
              17: "5",
              18: "6",
            }

            this.scriptModuleShow = true
            this.$parent.setDragShow(false);

            this.scriptType = scriptTypeMap[this.node.property]

          }

          else {
            //更新
            localStorage.setItem("updateFileId", this.node.id);

            this.$emit("uploading", "2", this.ResourceType, "", 1);

            this.$parent.setUpDataNode(this.node);
          }
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 3) {
        if (
          (this.ResourceType == 1 &&
            this.$store.state.userPermissionData.SCENE_FILE_DELETE) ||
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_DELETE) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_DELETE) ||
          (this.ResourceType == 8 &&
            this.$store.state.userPermissionData.MEDIA_FILE_DELETE)||
            (this.ResourceType == 5)
        ) {
          this.$confirm("此操作会将该文件移到回收站, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              //删除

              this.$parent.ondelFile(
                this.node.type,
                this.node.id,
                this.FileDirectoryId
              );
              this.$parent.getDataTree();

              var id = this.node.parentId;

              var parentNode = this.inquireNode(this.tree, id);

              var oldId = localStorage.getItem("openSceneId");

              if (this.node.type == 1 && this.node.id == oldId) {
                // 判断是场景
                // 移除场景
                ISetting.exit();
                location.reload();
              }

              //调用点击父节点
              // this.handleNodeClick(parentNode)
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 4) {
        if (
          (this.ResourceType == 1 &&
            this.$store.state.userPermissionData.SCENE_FILE_RENAME) ||
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_RENAME) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_RENAME) ||
          (this.ResourceType == 8 &&
            this.$store.state.userPermissionData.MEDIA_FILE_RENAME)
        ) {
          //重命名
          ISetting.isInput(true);
          var containSpecial = RegExp(
            /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
          );

          this.$prompt(``, "请输入新的名字", {
            confirmButtonText: "确定",
            showClose: true,
            closeOnPressEscape: false,
            closeOnClickModal: false,
            inputValue: this.node.name,
            // center: true,

            inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
            inputValidator: (value) => {
              // 点击按钮时，对文本框里面的值进行验证
              if (!value) {
                return "输入不能为空";
              } else if (containSpecial.test(value)) {
                return "输入不能为特殊字符";
              } else if (value.length > 20) {
                return "输入文字不能超过20个字符";
              }
            },
          })
            .then(({ value }) => {
              ISetting.isInput(false);
              this.newName = value;
              this.updateFileName(this.node, value).then(() => {
                this.$parent.getDataTree();
              });
            })
            .catch(() => {
              ISetting.isInput(false);

              this.$message({
                // type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 5) {
        if (
          this.ResourceType == 6 &&
          this.$store.state.userPermissionData.RECYCLED
        ) {
          this.recoverFolder();
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 6) {
        if (
          (this.ResourceType == 1 &&
            this.$store.state.userPermissionData.SCENE_FILE_CUT) ||
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_CUT) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_CUT) ||
          (this.ResourceType == 8 &&
            this.$store.state.userPermissionData.MEDIA_FILE_CUT)
        ) {
          this.copyShearType = 1;
          this.stickShow = true;

          //要剪切的文件
          // var shearNode= JSON.stringify(this.node)

          //缓存剪切文件类型
          var fileType = this.ResourceType;
          //剪切
          var shear = {
            //1是剪切2是复制
            copyOrCut: 1,
            //文件
            node: this.node,
            //文件类型
            type: fileType,
          };
          shear = JSON.stringify(shear);
          //缓存存储剪切文件
          localStorage.setItem("shear", shear);
          //缓存存储剪切文件类型
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 7) {
        this.stickShow = false;
        //粘贴
        //当前所在的类型

        var ResourceType = this.ResourceType;
        // 存储的剪切
        var shear = localStorage.getItem("shear");

        shear = JSON.parse(shear);
        if (shear) {
          this.shearType = shear.copyOrCut;
          // var

          if (
            (shear.node.property == 1 && this.ResourceType != 1) ||
            (shear.node.property == 2 && this.ResourceType != 2) ||
            ((shear.node.property == 3 ||
              shear.node.property == 9 ||
              shear.node.property == 7) &&
              this.ResourceType != 3) ||
            (shear.node.property == 8 && this.ResourceType != 8)
          ) {
            this.$message("请在相同类型的文件夹下粘贴");
          } else {
            //剪切
            if (shear.copyOrCut == 1) {
              if (ResourceType == shear.type) {
                this.moveFolderFile(
                  shear.node.id,
                  shear.node.type,
                  this.FileDirectoryId
                ).then(() => {
                  this.$message("黏贴成功");

                  this.$emit("setFileDirectoryId", this.FileDirectoryId);
                });
                //获取文件列表
              }
            } else if (shear.copyOrCut == 2) {
              this.paste = true

              INetwork.downloadJson(shear.node.fileUrl, (data) => {
                this.addFile(shear.node.name).then(() => {
                  if (this.fileId && this.fileId != "") {
                    var json = data;
                    var newData = INetwork.uploadJsonToSql(
                      this.fileId,
                      "scene",
                      JSON.stringify(json),
                      () => { }
                    );

                    this.updateFile(newData).then(() => {


                      this.$emit("setFileDirectoryId", this.FileDirectoryId);
                    });
                  }
                });
              });
            }
          }
        }
      } else if (key == 8) {
        if (
          (this.ResourceType == 1 &&
            this.$store.state.userPermissionData.SCENE_FILE_CREATION_SCENE) ||
          (this.$store.state.userPermissionData.MODEL_FILE_UPLOAD_MODEL &&
            this.ResourceType == 2) ||
          (this.$store.state.userPermissionData
            .PICTUREMATERIAL_FILE_UPLOAD_PICTURE &&
            this.ResourceType == 3) ||
          (this.$store.state.userPermissionData.MEDIA_FILE_UPLOAD_MEDIA &&
            this.ResourceType == 8)
        ) {
          // 新建

          var parentNode = this.inquireNode(this.tree, this.FileDirectoryId);

          //调用点击父节点
          // this.handleNodeClick(parentNode)
          this.$parent.resourceFile(this.ResourceType);
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 9) {
        if (
          (this.ResourceType == 1 &&
            this.$store.state.userPermissionData.SCENE_FILE_COPY) ||
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_COPY) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_COPY) ||
          (this.ResourceType == 8 &&
            this.$store.state.userPermissionData.MEDIA_FILE_COPY_URL)
        ) {
          this.copyShearType = 1;
          this.stickShow = true;
          //复制
          //缓存剪切文件类型
          var fileType = this.ResourceType;
          //剪切
          var shear = {
            //1是剪切2是复制
            copyOrCut: 2,
            //文件
            node: this.node,
            //文件类型
            type: fileType,
          };
          shear = JSON.stringify(shear);
          //缓存存储剪切文件
          localStorage.setItem("shear", shear);

          this.$message("复制成功");


        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 10) {
        if (
          this.$store.state.userPermissionData
            .PICTUREMATERIAL_FILE_CREATION_BASICS_MATERIAL &&
          this.ResourceType == 3
        ) {
          //创建基础材质
          // _this.$store.commit("setCreatingMaterialTypes", 0);
          var parentNode = this.inquireNode(this.tree, this.FileDirectoryId);

          this.$parent.creationTextureBall(this.ResourceType, 0);
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 12) {
        // debugger
        // 创建标准材质
        //  _this.$store.commit("setCreatingMaterialTypes", 1);

        if (
          this.$store.state.userPermissionData
            .PICTUREMATERIAL_FILE_CREATION_STANDARD_MATERIAL &&
          this.ResourceType == 3
        ) {
          this.$parent.creationTextureBall(this.ResourceType, 1);
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 11) {
        if (
          (this.ResourceType == 1 &&
            this.$store.state.userPermissionData.SCENE_FILE_COPY_URL) ||
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_COPY_URL) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData
              .PICTUREMATERIAL_FILE_COPY_URL) ||
          (this.$store.state.userPermissionData.MEDIA_FILE_COPY_URL &&
            this.ResourceType == 8)
        ) {
          //复制场景ID

          this.doCopy();
        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 13) {
        this.transpositionFolderFile(this.node.id, this.checkedId);
        this.FileDirectoryList = this.exchange(
          this.FileDirectoryList,
          this.checkedId,
          this.node.id
        );
      } else if (key == 14) {
        if (
          (this.ResourceType == 2 &&
            this.$store.state.userPermissionData.MODEL_FILE_COPY) ||
          (this.ResourceType == 3 &&
            this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_COPY)
        ) {
          this.copyShearType = 2;

          //复制模型/贴图
          this.duplicatingModel();

        } else {
          this.$message("您没有权限请联系超级管理员");
        }
      } else if (key == 15) {
        //模型/贴图粘贴
        this.stickModel();
      } else if (key == 16) {
        //创建冯氏材质
        // if((this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_CREATION_STANDARD_MATERIAL && this.ResourceType == 3)){

        this.$parent.creationTextureBall(this.ResourceType, 2);

        // }else{
        // this.$message("您没有权限请联系超级管理员")

        // }
      } else if (key == 17) {
        //上锁
        // if((this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_CREATION_STANDARD_MATERIAL && this.ResourceType == 3)){
        // console.log(this.node, "item");

        this.projectLocal(this.node.id, 1, 1);

        // }else{
        // this.$message("您没有权限请联系超级管理员")

        // }
      } else if (key == 18) {
        //解锁
        // if((this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_CREATION_STANDARD_MATERIAL && this.ResourceType == 3)){
        // console.log(this.node, "item");

        this.projectLocal(this.node.id, 0, 1);

        // }else{
        // this.$message("您没有权限请联系超级管理员")

        // }
      } else if (key == 19) {
        // 上传共享库
        this.$store.commit("changeHistoryShow", true);
        this.$store.commit("changeHistoryData", this.node);
        this.$store.commit("changeUploadingParentType", this.ResourceType);

        // console.log(this.ResourceType, "111");
      } else if (key == 20) {
        // 同步到本地库

        this.$store.commit("changeFolderType", this.ResourceType);
        this.$store.commit("changeDownloadData", this.node);

        this.$store.commit("changeSynchronization", "2");

        this.$store.commit("changeDownloadShow", true);

        // console.log(this.ResourceType, "111");
      } else if (key == 21) {
        //设置为环境贴图
        // if((this.$store.state.userPermissionData.PICTUREMATERIAL_FILE_CREATION_STANDARD_MATERIAL && this.ResourceType == 3)){
        // console.log(this.node, "item");
        let len = this.node.imageUrl.split("?")[0].length;
        let imageUrl = this.node.imageUrl.split("?")[0];

        ILoader.loadTextureToEnvmap(this.node.fileUrl, imageUrl);

        // }else{
        // this.$message("您没有权限请联系超级管理员")

        // }
      } else if (key == 22) {
        this.$confirm("此操作将从共享库删除该文件, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //删除
            this.deleteUpload();
            //调用点击父节点
            // this.handleNodeClick(parentNode)
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      } else if (key == 23) {
        //创建粒子
        this.$store.commit("setCreatingMaterialTypes", 3);
        this.$store.commit("changeNweParticleShow", true);

        // this.$parent.creationTextureBall(this.ResourceType, 3);
      } else if (key == 24) {
        //清空回收站

        this.$confirm("确认后将清空所有回收站内容", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let projectId = localStorage.getItem("projectId");
            this.emptyRecycleBin(projectId);
            //调用点击父节点
            // this.handleNodeClick(parentNode)
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      } else if (key == 25) {

        ISetting.isInput(true);
        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        );

        this.$prompt(``, `上传粒子文件提示`, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // center: true,
          inputPlaceholder: `请输入粒子文件名称`,
          closeOnClickModal: false,
          inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
          inputValidator: value => {
            // 点击按钮时，对文本框里面的值进行验证
            if (!value) {
              return "输入不能为空";
            } else if (containSpecial.test(value)) {
              return "输入不能为特殊字符";
            } else if (value.length > 20) {
              return "输入文字不能超过20个字符";
            }
          }
        })
          .then(({ value }) => {
            ISetting.isInput(false);
            this.$refs.particle.value = "";
            this.$refs.particle.click();

            this.MultimediaUpdateOrAdd = 1;
            this.MultimediaName = value;

          })
          .catch(() => {
            ISetting.isInput(false);
            this.$message({
              // type: "info",
              message: "取消输入"
            });
          });



      } else if (key == 26) {
        // this.$store.state.macState = true;

        this.$store.commit("changeMacState", true)

        this.$store.commit("changeMotionPlayerParentId", this.FileDirectoryId)
        _this.$store.commit("changeMotionPlayerType", 1)

      } else if (key == 27) {
        // 贴图加载到地形
        // name, id, type, subTexIndex, processCallback, finishCallback


        // console.log("传过来的参数", this.$store.state.TerrainMaterial)
        ILoader.loadTextureToTerrain(this.node.fileUrl, this.node.id, this.$store.state.TerrainMaterial.type, this.$store.state.TerrainMaterial.subTexIndex, () => { }, () => {
          this.$message({
            // type: "info",
            message: "加载成功"
          });

        })






      } else if (key == 28) {
        this.scriptEditId = ""

        this.scriptModuleShow = true
        this.$parent.setDragShow(false);

        this.scriptType = this.scriptTypeMap["动态纹理"]

        IBehavior.createBehavior(IBehavior.m_textureBehavior)

        
      } else if (key == 29) {
        this.scriptEditId = ""

        this.scriptModuleShow = true
        this.$parent.setDragShow(false);

        this.scriptType = this.scriptTypeMap["动态控制器"]
        IBehavior.createBehavior(IBehavior.m_dymaticBehavior)

      } else if (key == 30) {
        this.scriptEditId = ""

        this.scriptModuleShow = true
        this.$parent.setDragShow(false);



        this.scriptType = this.scriptTypeMap["文本控制器"]
        IBehavior.createBehavior(IBehavior.m_mutilTextBehavior)

      } else if (key == 31) {
        this.scriptEditId = ""

        this.scriptModuleShow = true
        this.$parent.setDragShow(false);
        this.scriptType = this.scriptTypeMap["路径模拟器"]



        IBehavior.createBehavior(IBehavior.m_curveBehavior)

      } else if (key == 32) {
        this.scriptEditId = ""

        this.scriptModuleShow = true
        this.$parent.setDragShow(false);
        this.scriptType = this.scriptTypeMap["HUD"]

        IBehavior.createBehavior(IBehavior.m_hudBehavior)

      } else if (key == 33) {
        this.scriptEditId = ""

        this.scriptModuleShow = true
        this.$parent.setDragShow(false);
        this.scriptType = this.scriptTypeMap["AI寻路"]
        IBehavior.createBehavior(IBehavior.m_navMeshBehavior)

      } else if (key == 34) {
        let scriptData = {}
        let typeMap = {
          13: "TextureBehavior",
          14: "DymaticBehavior",
          15: "MutilTextBehavior",
          16: "CurveBehavior",
          17: "HudBehavior",
          18: "NavMeshBehavior",
        }
        scriptData.scripType = typeMap[this.node.property]

        INetwork.openBehaviorJson(this.node.fileUrl, typeMap[this.node.property], (item) => {
          scriptData.data = item
          var jsonScriptData = JSON.stringify(scriptData);
          this.$store.commit("ScriptDataChange", jsonScriptData)

        })
      }

    },
    // 创建脚本
    cleactComponent() {
      this.scriptModuleShow = false
      this.$refs.CreatedAddComponent.newFile()

    },


    scriptModuleClose() {
      this.$parent.setDragShow(true);

    },
    getUrlParticle() {

      // MultimediaUpdateOrAdd


      let url = this.$refs.particle.value;
      let files = "";
      let name = "";

      if (url != "") {
        files = this.$refs.particle.files;
        name = this.MultimediaName;

        let size = 0

        files.forEach(element => {

          size = size + element.size

        });
        this.particleSize = size


      }


      let particleData = {
        url: url,
        files: files,
        name: name,
      };



      this.particleData = particleData;

      INetwork.uploadFilesToPreview(
        files,
        this.ParticleProcess.bind(this),
        this.ParticleFinish.bind(this)
      );
    },

    ParticleProcess(p) {
      // this.progressBar = parseInt(p * 100);

      // this.progressText = "正在上传";

      // this.setModelProcess(parseInt(p * 100));
      if (this.MultimediaUpdateOrAdd == 1 && p >= 1) {
        this.addParticleToSql();
      } else if (this.MultimediaUpdateOrAdd == 2 && p >= 1) {
        INetwork.copyFilesToSql(
          this.particleData.files,
          this.node.id,
          "json",
          () => {
            this.updataParticleToSql().then(() => { });
          }
        );

      }



      // INetwork.copyFilesToSql(
      //   this.MultimediaData.files,
      //   this.MultimediaFileId,
      //   "mp3",
      //   () => {
      //     this.updataMultimediaToSql().then(() => {
      //       this.setFileDirectoryId(this.MultimediaFolderId);
      //     });
      //   }
      // );
      // }
    },

    async addParticleToSql() {


      const res = await this.$https.addFile({
        //    this.url_list=url_list
        // this.mainUrl=mainUrl
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        folderId: this.FileDirectoryId,
        name: this.particleData.name,
        type: 11,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "",
        deputyUrl: "",
      });
      if (res.success == true) {
        this.MultimediaFileId = res.data;

        // console.log(this.MultimediaData.files, res.data, "mp3", "创建");

        INetwork.copyFilesToSql(
          this.particleData.files,
          res.data,
          "json",
          () => {
            this.updataParticleToSql().then(() => { });
          }
        );
      } else {
        this.$message({
          message: res.errMessage,
          // type: "warning"
        });
      }
    },

    async updataParticleToSql() {
      var fileId = this.MultimediaFileId;

      var name = this.particleData.name;

      //获取真实的数据 这里需要传文件类型

      //   更新

      var data = INetwork.getParticleFilesNameToSql(
        this.particleData.files,
        this.MultimediaFileId,
        "json"
      );

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var time = new Date().getTime();

      const res = await this.$https.updateFile({
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        id: fileId,
        // name: name,
        fileUrl: data.mainName,
        imageUrl: data.imageName + "?" + "time=" + time,
        deputyUrl: data.otherNameList.toString(),
        fileSize: this.particleSize,
      });

      if (res.errCode == null) {
        loading.close();

        this.$emit("setFileDirectoryId", this.FileDirectoryId);
      } else {
        loading.close();
      }
    },

    ParticleFinish(data) { },

    //从共享库移除

    async deleteUpload() {
      let data = {
        libraryId: this.$store.state.chosenWarehouse.id,
        fId: this.node.id,
        type: 1,
      };
      const res = await this.$https.deleteUpload(data);
      if (res.success == true) {
        this.$message({
          message: "操作成功",
          // type: "success"
        });
        this.$parent.getSubCatalog(this.FileDirectoryId);
      } else {
        this.$message({
          message: res.errMessage,
          // type: "success"
        });
      }
    },

    //文件上锁
    async projectLocal(id, local, type) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let data = {
        id: id,
        local: local,
        type,
      };

      const res = await this.$https.projectLocal(data);

      if (res.success == true) {
        loading.close();
        this.$message({
          message: "操作成功",
          // type: "success"
        });
        this.$parent.getSubCatalog(this.FileDirectoryId);
      } else {
        loading.close();

        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },

    //复制模型
    duplicatingModel() {
      let duplicatingModelData = JSON.stringify(this.node);

      localStorage.setItem("duplicatingModelData", duplicatingModelData);

      this.$message("复制成功");


      // let data= INetwork.copyPaseFilesToSql(this.node.deputyUrl.split(","),this.node.id,()=>{

      // })
    },

    //黏贴
    stickModel() {
      let duplicatingModelData = localStorage.getItem("duplicatingModelData");

      duplicatingModelData = JSON.parse(duplicatingModelData);

      if (
        (this.ResourceType == 2 && duplicatingModelData.property == 2) ||
        (this.ResourceType == 2 && duplicatingModelData.property == 6) ||
        (this.ResourceType == 2 && duplicatingModelData.property == 11)
      ) {
        this.createModels(duplicatingModelData);
      } else if (
        this.ResourceType == 3 &&
        (duplicatingModelData.property == 3 ||
          duplicatingModelData.property == 7 ||
          duplicatingModelData.property == 9)
      ) {
        this.createModels(duplicatingModelData);


      } else {
        this.$message("只能在相同类型的文件夹下粘贴");
      }
    },

    //调用后台接口 添加文件（粘贴）
    async createModels(data) {
      // let fileName = this.mainUrl.split("/");
      // let name = fileName[fileName.length - 1];
      // name = name.split("?")[0];

      const res = await this.$https.addFile({
        folderId: this.FileDirectoryId,
        projectId: localStorage.getItem("projectId"),
        name: data.name,
        // name :"1",
        type: data.property,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "",
        deputyUrl: "",
      });
      if (res.success == true) {
        // this.fileId = res.data;

        // INetwork.copyFilesToSql(this.files, res.data, this.ext, () => {
        //   this.updateFile().then(() => {
        //     this.$emit("setFileDirectoryId", this.FileDirectoryId);
        //   });
        // });
        // .catch(()=>{
        //     this.$emit("setFileDirectoryId", this.FileDirectoryId);

        // })

        //获取文件
        // this.$parent.getSubCatalog(this.upDataId);
        // this.dialogFormVisible = false;
        // this.$emit("transfer", false);
        this.changeModel(data, res.data);
      } else if (res.errCode == 410) {
        ISetting.isInput(true);
        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        );

        this.$prompt(``, "检测到重名请输入新名字", {
          confirmButtonText: "确定",
          showClose: true,
          closeOnPressEscape: false,
          closeOnClickModal: false,
          // center: true,

          inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
          inputValidator: (value) => {
            // 点击按钮时，对文本框里面的值进行验证
            if (!value) {
              return "输入不能为空";
            } else if (containSpecial.test(value)) {
              return "输入不能为特殊字符";
            } else if (value.length > 20) {
              return "输入文字不能超过20个字符";
            }
          },
        })
          .then(({ value }) => {
            ISetting.isInput(false);
            data.name = value;

            this.createModels(data);
          })
          .catch(() => {
            ISetting.isInput(false);

            this.$message({
              // type: "info",
              message: "已取消",
            });
          });
      } else {
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },

    //调用后台接口 更改文件
    async changeModel(node, id) {
      let imageUrl = [];
      imageUrl.push(node.imageUrl.split("?")[0]);

      node.deputyUrl = INetwork.copyPaseFilesToSql(
        node.deputyUrl.split(","),
        this.node.id,
        () => { }
      );
      node.fileUrl = INetwork.copyPaseFilesToSql(
        node.fileUrl.split(","),
        this.node.id,

        () => { }
      );
      node.imageUrl = INetwork.copyPaseFilesToSql(
        imageUrl,
        this.node.id,
        () => { }
      );

      //获取真实的数据 这里需要传文件类型
      // var data = INetwork.getFilesNameToSql(this.files, fileId, this.ext);

      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var time = new Date().getTime();

      // console.log("data", {
      //   //文件夹id
      //   // folderId:this.id,
      //   projectId: localStorage.getItem("projectId"),
      //   id: id,
      //   // name: name,
      //   fileUrl: node.fileUrl[0],
      //   imageUrl: node.imageUrl[0] + "?" + "time=" + time,
      //   deputyUrl: node.deputyUrl.toString(),

      //   fileSize: node.fileSize
      // });

      const res = await this.$https.updateFile({
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        id: id,
        // name: name,
        fileUrl: node.fileUrl[0],
        imageUrl: node.imageUrl[0] + "?" + "time=" + time,
        deputyUrl: node.deputyUrl.toString(),

        fileSize: node.fileSize,
      });

      if (res.errCode == null) {
        // IResousePreview.close();
        // loading.close();
        // this.fileName = "";

        // this.$parent.setFileDirectoryId(this.FileDirectoryId);

        // localStorage.removeItem("updateFileId");

        // this.dialogFormVisible = false;

        // this.$emit("transfer", false);
        this.$message("黏贴成功");


        this.$parent.getSubCatalog(this.FileDirectoryId);

        loading.close();
        // this.$parent.resourceFile(this.ResourceType);
        this.copyShearType = "";

      } else {
        loading.close();
      }
    },

    //复制场景id
    async doCopy() {
      let { text } = await this.$copyText(this.node.fileUrl);
      // "这是已复制的文本，使用ctrl+v即可粘贴
      this.$message("复制成功");
    },
    //数组交换位置
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },

    //文件交换位置的前端实现

    exchange(arr, id1, id2) {
      let index1, index2;

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id == id1) {
          index1 = i;
        } else if (arr[i].id == id2) {
          index2 = i;
        }
      }

      return this.swapArray(arr, index1, index2);
    },

    //交换文件位置
    async transpositionFolderFile(id1, id2) {
      let data = {
        id1: id1,
        id2: id2,
        type: 1,
      };
      const res = await this.$https.transpositionFolderFile(data);
      if (res.success == true) {
        // this.$parent.getDataTree();
      } else {
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },

    //通过id查找节点
    inquireNode(tree, id) {
      for (let index = 0; index < tree.length; index++) {
        if (tree[index].id == id) return tree[index];
        if (tree[index].subCatalog) {
          const res = this.inquireNode(tree[index].subCatalog, id);
          if (res) return res;
        }
      }

      return null;
    },

    //新建场景
    async addFile(value) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const res = await this.$https.addFile({
        //文件夹id
        // folderId:this.id,
        folderId: this.FileDirectoryId,

        name: value,
        projectId: localStorage.getItem("projectId"),
        type: 1,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "res/ui/scene.png",
        deputyUrl: "",
      });
      if (res.success == true) {
        loading.close();
        // this.$message({
        //   message: "创建成功"
        //   // type: "success"
        // });

        this.fileId = res.data;

        return res.data;
      } else {
        if (res.errCode == 410) {
          loading.close();
          var token = localStorage.getItem("token");
          if (token) {
            // this.$message({
            //   message: res.errMessage
            //   // type: "warning"
            // });
            this.message = res.errMessage;
            (this.deleteShow = true), ISetting.isInput(true);
            this.$parent.setDragShow(false);
          }
        } else if (res.errCode == 400) {
          loading.close();

          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage,
              // type: "warning"
            });
          }
        }

        return false;
      }
    },

    //调用后台接口 更改文件
    async updateFile(_data) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      //获取真实的数据 这里需要传文件类型

      const res = await this.$https.updateFile({
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        id: this.fileId,
        // name: this.fileName,
        fileUrl: _data.mainName,
        imageUrl: _data.imageName,
        deputyUrl: _data.otherNameList,
        fileSize: 0,
      });

      if (res.success == true) {
        this.copyShearType = "";
        loading.close();
        // this.$message({
        //   message: "保存成功"
        //   // type: "success"
        // });

        if (this.paste == true) {
          this.$message("黏贴成功");
          this.paste == false

        }


        this.getDataTree();
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },

    //粘贴
    async moveFolderFile(_id, _type, _folderId) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await this.$https.moveFolderFile({
        id: _id,
        type: _type,
        folderId: _folderId,
      });
      if (res.success == true) {
        loading.close();
        this.copyShearType = "";

        this.getDataTree();
      } else {
        if (res.errCode == 410) {
          var token = localStorage.getItem("token");
          if (token) {
            // this.$message({
            //   message: res.errMessage
            //   // type: "warning"
            // });
            this.message = res.errMessage;

            this.deleteShow = true;
            ISetting.isInput(true);

            this.$parent.setDragShow(false);
            this.$message({
              message: "黏贴成功",
              // type: "warning"
            });
          }
        } else if (res.errCode == 400) {
          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage,
              // type: "warning"
            });
          }
        }

        loading.close();
      }
    },

    //文件夹恢复
    async recoverFolder() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const res = await this.$https.recoverFolder({
        id: this.node.id,
        type: this.node.type,
      });
      if (res.success == true) {
        loading.close();

        this.$parent.clickRecycle();
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },

    async getUserProjectAuthority() {
      let userId = localStorage.getItem("phone");
      let groupId = localStorage.getItem("teamId");

      let data = {
        userId: userId,
        groupId: groupId,
      };
      const res = await this.$https.getUserProjectAuthority(data);

      if (res.success == true) {
        let list = res.data;
        let userPermissionData = {};

        for (let index = 0; index < list.length; index++) {
          userPermissionData[list[index]] = true;
        }

        this.$store.commit("changeUserPermissionData", userPermissionData);
      } else {
        this.$message(res.errMessage);
      }
    },

    async fileFolderLocalState(id) {
      let data = {
        fileId: id,
      };
      const res = await this.$https.fileFolderLocalState(data);
      if (res.success == true) {
        this.estimateLock = res.data;
      }
    },

    //右击
    show(item, type) {

      // console.log(item,item);
      this.getUserProjectAuthority();
      if (type == 1) {
        this.fileFolderLocalState(item.id);
        this.rightClickType = 1;
        // 控制菜单隐藏
        this.$parent.ControlHidden();
        //控制拖拽隐藏
        this.$parent.setDragShow(false);

        this.menu = true;
        //获取到菜单
        let menu = this.$refs.menu;
        let x = event.clientX;
        let y = event.clientY;

        // menu.$el.style.top = y + "px";
        menu.$el.style.left = x + "px";

        if (y > 630) {
          if (this.$store.state.chosenWarehouse.type != 0) {
            menu.$el.style.top = y - 80 + "px";
          } else {
            if (this.ResourceType == 1 || this.ResourceType == 6 || this.ResourceType == 5) {
              menu.$el.style.top = y - 100 + "px";
            } else {
              menu.$el.style.top = y - 400 + "px";
            }
          }
        } else {
          menu.$el.style.top = y + "px";
        }
        this.node = item;
        let len = item.fileUrl.length;
        let isHdr = item.fileUrl.substring(len - 4, len);

        this.isHdr = isHdr;
      } else if (type == 2) {
        this.rightClickType = 2;

        // 控制菜单隐藏
        this.$parent.ControlHidden();
        //控制拖拽隐藏
        this.$parent.setDragShow(false);

        this.menu = true;


        //获取到菜单
        let menu = this.$refs.menu;
        let x = event.clientX;
        let y = event.clientY;

        // menu.$el.style.top = y + "px";
        menu.$el.style.left = x + "px";
        if (y > 780) {
          menu.$el.style.top = y - 150 + "px";
          if (this.ResourceType == 5) {
            menu.$el.style.top = y - 300 + "px";

          }
        } else {
          menu.$el.style.top = y + "px";
          if (this.ResourceType == 5) {
            menu.$el.style.top = y - 150 + "px";

          }

        }
      }
    },
    //移出
    mouseLeave() {
      this.menu = false;
    },
    //双击打开
    doubleOpen(item) {
      // this.getSubCatalog(item.id)

      if (item.type == 0) {
        // if (this.ResourceType==6) {

        //   this.$parent.setRecycleBinData(item.subCatalog)
        // }else{
        this.$emit("setFileDirectoryId", item.id);
        this.getDataTree().then(() => {
          this.getPathById(item.id, this.tree[0], (temppath) => {
            this.$emit("getPath", temppath);
          });
        });
        // }
      } else {
        this.node = item;
        this.handleSelect(1);
      }
    },
    //加载进度条
    process(p) {
      this.progressBar = parseInt(p * 100);
      // console.log(p);
      this.$emit("setModelProcess", this.progressBar);
    },
    //加载完成
    finish(data) {
      this.$message({
        message: "加载完成",
        duration: 1000,
        // type: "success"
      });
    },

    //修改文件路径
    // async updateFile(){
    //   const res
    // },

    //更新js
    updateJs() {
      this.codeEditor = true;
      this.$parent.setDragShow(false);
      var CodeEditor = this.$refs.CodeEditor;

      var defaultData;
      INetwork.downloadJs(this.node.fileUrl, (data) => {
        defaultData = data;

        CodeEditor.setCode(defaultData);
        CodeEditor.setJsFileId(this.node.id);
      });
    },

    //打开js
    openJs() {
      this.codeEditor = true;
      this.$parent.setDragShow(false);
    },
    //关闭js
    close() {
      this.codeEditor = false;
      this.$parent.setDragShow(true);

      var CodeEditor = this.$refs.CodeEditor;
      CodeEditor.setCode("");
      CodeEditor.setJsFileId("");
    },
    //蒙版提示
    maskingHint() {
      this.$message({
        message: "请在js编辑框内编写js代码",
        // type: "warning"
      });
    },
    //文件改名字
    async updateFileName(data, newName) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await this.$https.updateFileName({
        // projectId: localStorage.getItem("projectId"),

        id: data.id,
        name: newName,
        type: data.type,
      });

      if (res.errCode == null) {
        loading.close();
        this.$emit("setFileDirectoryId", this.FileDirectoryId);
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },
    //获取树状结构
    async getDataTree() {
      if (this.ResourceType == 6) {
        await this.delFolderList();
      } else {
        await this.getFolderList(this.ResourceType);
      }
    },

    //回收站
    async delFolderList(_type) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });
      const res = await this.$https.delFolderList({
        projectId: localStorage.getItem("projectId"),
      });

      // loading.close();

      if (res.errCode == null) {
        this.tree = res.data;
      } else {
        // loading.close();

        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },
    //获取文件列表
    async getFolderList(_type) {
      const res = await this.$https.getFolderList({
        // projectId: localStorage.getItem("projectId"),
        projectId: this.$store.state.chosenWarehouse.id,
        show: this.$store.state.chosenWarehouse.type,

        type: _type,
        // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5"
      });

      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });

      if (res.errCode == null) {
        this.tree = res.data;
        // loading.close();
      } else {
        // loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },
    //生成路径
    getPathById(id, catalog, callback) {
      //定义变量保存当前结果路径
      var temppath = "";
      try {
        function getNodePath(node) {
          temppath += node.name + ">";
          //找到符合条件的节点，通过throw终止掉递归
          if (node.id == id) {
            throw "GOT IT!";
          }
          if (node.subCatalog && node.subCatalog.length > 0) {
            for (var i = 0; i < node.subCatalog.length; i++) {
              getNodePath(node.subCatalog[i]);
            }

            //当前节点的子节点遍历完依旧没找到，则删除路径中的该节点
            temppath = temppath.substring(0, temppath.length - 1);
            temppath = temppath.substring(0, temppath.lastIndexOf(">")) + ">";
          } else {
            //找到叶子节点时，删除路径当中的该叶子节点
            temppath = temppath.substring(0, temppath.length - 1);
            temppath = temppath.substring(0, temppath.lastIndexOf(">")) + ">";
          }
        }

        getNodePath(catalog);
      } catch (e) {
        callback(temppath);
      }
    },

    //获取场景锁状态
    async getSceneStatus(_id) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });

      const res = await this.$https.sceneStatus({
        id: _id,
      });
      if (res.success == true) {
        this.sceneLockState = true;
        // loading.close();
      } else {
        // loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },
    //设置场景锁状态
    async setSceneLocal(_id, _local) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });

      const res = await this.$https.sceneLocal({
        id: _id,
        local: _local,
      });
      if (res.success == true) {
        // loading.close();
      } else {
        // loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage,
            // type: "warning"
          });
        }
      }
    },

    //查询列表
    async findByName() {
      if (this.searchName == "") {
        this.getDataTree();
      } else {
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });

        const res = await this.$https.findByName({
          projectId: localStorage.getItem("projectId"),
          type: this.ResourceType,
          name: this.searchName,
        });
        // loading.close();
        if (res.errCode == null) {
          loading.close();

          this.dataList = res.data;
        } else {
          loading.close();
          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage,
              // type: "warning"
            });
          }
        }
      }
    },
  },
  filters: {
    ///图标过滤器
    filtrationImg: function (value) {
      if (value != null && value != "" && value != undefined) {
        var realUrl = INetwork.getUrl(value);

        return realUrl;
      } else {
        return "";
      }
    },
    // 图片预览过滤器
    filtrationImgList: function (value) {

      let imgList = new Array()
      if (value != null && value != "" && value != undefined) {
        var realUrl = INetwork.getUrl(value);
        imgList.push(realUrl)
        return imgList;
      } else {
        return [];
      }
    },

  },
  watch: {
    FileDirectoryList: {
      deep: true,
      handler(newValue) {
        this.presentSceneId = localStorage.getItem("presentSceneId");
      },
    },
  },
};
</script>
<style scoped>
.box {
  width: 100%;
  height: inherit;
}

.content {
  width: 100%;
  margin: -1px;
  /* height:inherit ; */
  /* height: 500px; */

  border-radius: 5px;
  /* overflow: hidden; */
  overflow: auto;
  /* padding: px; */
}

/* 滚动条 */
/* .content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}
.content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}
.content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  background-color: rgba(240, 240, 240, 0.5);
} */

.content::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.content::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
}

.content::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #e9f1fe;
}

.documents {
  box-sizing: border-box;
  /* padding: 10px; */
  /* width: 20%; */
  width: 200px;
  height: 80px;
  margin-top: 4px;
  margin-bottom: 8px;
  float: left;
}

.oneFile {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

.expression {
  position: absolute;
  right: -2px;
  top: 0;

  /* border-right: 2px solid red;
  border-top: 2px solid red; */
  /* border-left: none; */
  /* border-bottom: none; */
  border: 18px solid transparent;

  border-top: 18px solid #f7f7f7;
  border-right: 19px solid #f7f7f7;
  border-radius: 6px;
}

.expressionImg {
  width: 10px;
  height: 10px;
  position: absolute;
  right: -13px;
  top: -13px;
}

.share {
  position: absolute;
  right: -2px;
  /* top: 0; */
  bottom: 0px;

  /* border-right: 2px solid red;
  border-top: 2px solid red; */
  /* border-left: none; */
  /* border-bottom: none; */
  /* border: 12px solid transparent; */
  width: 25px;
  height: 25px;

  background: #0560fd;
  border-radius: 8px 0 8px 0;
}

.shareImg {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 5px;
  top: 5px;
}

.lockMask {
  /* width: 45px; */

  position: absolute;
  z-index: 999;

  background: rgba(0, 0, 0, 0.65);
  /* opacity: 0.75; */

  line-height: 60px;
  text-align: center;
  border-radius: 8px;
}

.lockMask1 {
  top: 12px;
  left: 5px;
  width: 25%;

  height: 70%;
}

.lockMask2 {
  /* width: 43px;
  height: 43px; */
  width: 24%;

  height: 60%;

  top: 19px;
  left: 14px;
}

.lockMask img {
  width: 25px;
  /* height: 25px; */
  /* opacity: 1; */
}

.documents_img {
  width: 25%;
  display: inline-block;
  box-sizing: border-box;
  padding: 2px;
  /* padding-top: 5px; */
  /* padding: 10px; */
  /* height: 20%; */
  /* margin: 0 auto; */
  /* float: left; */
}

.title_text {
  width: 70%;
  box-sizing: border-box;
  display: inline-block;
  margin-left: -2px;
  margin-bottom: 4px;
  margin-top: 11px;
  padding: 1px;
  /* height: 80px; */
}

.title_text2 {
  width: 70%;
  box-sizing: border-box;
  display: inline-block;
  margin-left: -2px;
  margin-bottom: 4px;
  margin-top: 11px;
  padding: 1px;
  /* line-height: 14px; */
  /* height: 80px; */
}

.userDocumentsImg {
  width: 24%;
  display: inline-block;
  /* padding: 4px; */
  border-radius: 6px;
  box-sizing: border-box;
  margin-left: 10px;
  margin-top: 10px;
  /* padding-left: 2px;
  padding-left: 10px; */
}

.optUserDocumentsImg {
  /* box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1); */
}

.sceneChecked {
  padding: 5px;
}

.noCheckedScene {
  width: 95%;
  height: 100%;
  margin: 10px auto;
  /* border: 2px solid black; */
  /* margin-left: 20px;
  margin-top: 20px; */
  border-radius: 8px;
  /* text-align:center; */
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);

  /* background: #cddfff; */
  padding-top: 10px;
}

.checkedScene {
  /* width: 100%; */
  /* border: 2px solid black; */
  /* margin-left: 20px;
  margin-top: 20px; */
  border-radius: 8px;

  box-shadow: 0px 0px 4px 0px rgba(5, 96, 253, 0.5);
  color: rgba(5, 96, 253, 0.5);
}

.documents_title {
  position: relative;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, -35%);
  font-size: 14px;
  /* margin: 0 auto; */

  display: inline-block;
  /* width: 50px; */
  width: 88%;
  height: 14px;
  /* margin-left: 25px; */
  /* margin-top: 10px; */
  /* margin-left: 9px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  line-height: 16px;
  color: #000;
}

.checkedDocuments_title {
  font-size: 14px;
  /* margin-top: 10px; */
  /* margin: 0 auto; */

  display: inline-block;
  /* width: 50px; */
  width: 88%;
  /* margin-left: 25px; */
  /* margin-top: 10px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  color: RGBA(5, 96, 253, 1) !important;
  text-align: left;
}

.documents_title_name {
  color: #000;
}

.time {
  color: #999;
  margin-bottom: 1px;
  font-size: 12px;
}

.title {
  width: 90%;
  height: 25px;
  /* line-height: 10px; */
  color: #000;
  font-size: 10px;
  /* border-bottom: 2px solid #ccc; */
  text-align: left;
  margin: auto;
}

/* 图标 */
.icon {
  width: 19px;
  height: 17px;
  margin: 0 2px;
}

/* 菜单图标 */
.menuIcon {
  position: relative;
  width: 19px;
  height: 19px;
  margin-right: 20px;
  top: -1px;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
}

.myMenu /deep/ .menu {
  border-radius: 10px;
  padding: 0px 0;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
}

.myMenu /deep/.el-menu-item {
  height: 40px;
  line-height: 40px;
  position: relative;

  white-space: nowrap;
  list-style: none;
  text-align: left;
}

.myMent /deep/ .el-menu-item.is-active {
  color: #000;
}

.empty {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
  position: relative;
  left: 50%;
  top: 166px;
  transform: translate(-50%, -50%);

  /* margin: 100px auto; */
  /* position: absolute; */

  /* background:red; */
  /* background-position: 50%,50%; */
  background-size: 100px 100px;
  padding: 10px;

  /* background: url(../../assets/Resource/empty.png) no-repeat ; */
}

.empty_img {
  width: 50px;
  height: 50px;
}

/* js编辑器 */
.codeEditor {
  width: 1300px;
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #282c34;
  border-radius: 12px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.close {
  color: #ccc;
  position: absolute;
  z-index: 100;
  right: 15px;
  top: 10px;
  cursor: pointer;
}

.close img {
  width: 10px;
  height: 10px;
}

.masking {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* background: red; */
  opacity: 0;
}

/* 弹框 */

.delete {
  width: 350px;
  height: 180px;
  position: fixed;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  z-index: 9999999;
}

.delete .close {
  position: absolute;
  right: 20px;
  top: 0px;
  cursor: pointer;
}

.delete .close img {
  width: 10px;
  height: 10px;
}

.deletNameDiv {
  margin-top: 25px;
}

.deleteName {
  width: 290px;
  height: 100px;
  text-align: center;

  /* font-size: 14px; */
  margin: 0 auto;
  /* margin-top: 40px; */
}

.hint {
  margin-right: 2px;
  width: 14px;
  height: 14px;
  float: left;
  margin-right: 4px;
}

.nameText {
  width: 250px;
  text-align: left;
  line-height: 15px;
  font-weight: bolder;
  margin-top: -3px;
  /* float: left; */
}

.enter {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  font-size: 12px;
  display: flex;
}

.enter .set {
  width: 220px;
  height: 35px;
  line-height: 35px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.verify {
  background-color: #0560fd;
  color: #fff;
  margin-left: 20px;
  margin-top: 15px;
  margin-left: 35px;
}

.cancel {
  color: #999;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  margin-top: 15px;
  margin-left: 30px;
}

.deleteOK {
  background-color: #0560fd;
  color: #fff;
  margin-left: 20px;
  margin-top: 15px;
  margin-left: 30px;
}

.deleteCancel {
  color: #999;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 30px;
}

.delete .ipt_box {
  width: 250px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-left: 10px;
}

.delete .ipt_box .input {
  width: 250px;
  height: 35px;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 10px;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 15px;
}
</style>