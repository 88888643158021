import * as THREE from 'three';
import { log } from 'three-nebula/build/cjs/debug';
import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';
import { SceneFactory } from './SceneFactory';

class CollisionsFactory {

    static createBoxShape(physics, object, mesh, size, offset, mass = 80, real = false) {
        object.userData.m_collisionsMesh = mesh;
        if(real)
        {
            CollisionsFactory.destory(physics, object);
            physics.add.existing(mesh, {
                shape: 'box',
                ignoreScale: true,
                width: size.x,
                height: size.y,
                depth: size.z,
                mass : mass
            });
            object.userData.m_collisionsMesh.body.setCollisionFlags(0);
        }
        else
        {
            object.userData.m_collisionsMesh.userData = new GameObject();
            object.userData.m_collisionsMesh.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
            object.userData.m_collisionsMesh.renderOrder = 100;
            if(!object.userData.m_collisionsMesh.parent)
            {
                object.attach(object.userData.m_collisionsMesh)
            }
        }
        object.userData.m_mass = mass;
        object.userData.m_collisionFlags = 0;
        object.userData.m_gravity = -9.81;
        object.userData.m_size.copy(size);
        object.userData.m_offset.copy(offset);
    }

    static createMeshShape(physics, object, mesh, real = false)
    {
        object.userData.m_collisionsMesh = mesh;
        if(real)
        {
            CollisionsFactory.destory(physics, object);
            physics.add.existing(mesh, {
                shape: 'concaveMesh',
                ignoreScale: true,
                mass : 0
            });
            object.userData.m_collisionsMesh.body.setCollisionFlags(1);
        }
        else
        {
            object.userData.m_collisionsMesh.userData = new GameObject();
            object.userData.m_collisionsMesh.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
            object.userData.m_collisionsMesh.renderOrder = 100;
            if(!object.userData.m_collisionsMesh.parent)
            {
                object.attach(object.userData.m_collisionsMesh)
            }
        }
    
        object.userData.m_mass = 0;
        object.userData.m_collisionFlags = 1;
        object.userData.m_gravity = -9.81;
    }

    static createCapsuleShape(physics, object, mesh, radius = 0.5, height = 2, offset, mass = 80, real = false) {
        object.userData.m_collisionsMesh = mesh;
        if(real)
        {
            CollisionsFactory.destory(physics, object);

            physics.add.existing(mesh, {
                shape: 'capsule',
                ignoreScale: true,
                height: height,
                radius: radius,
                mass: mass,
            });
            object.userData.m_collisionsMesh.body.setAngularFactor(0, 0, 0);
            object.userData.m_collisionsMesh.body.setFriction(0);
            object.userData.m_collisionsMesh.body.setCollisionFlags(0);
            object.userData.m_collisionsMesh.body.setGravity(0, -9.81, 0);
        }
        else{
            object.userData.m_collisionsMesh.userData = new GameObject();
            object.userData.m_collisionsMesh.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
            object.userData.m_collisionsMesh.renderOrder = 100;
            if(!object.userData.m_collisionsMesh.parent)
            {
                object.attach(object.userData.m_collisionsMesh)
            }
    
        }
    
        object.userData.m_mass = mass;
        object.userData.m_collisionFlags = 0;
        object.userData.m_gravity = -9.81;
        object.userData.m_size.set(radius, height, 0);
        object.userData.m_offset.copy(offset);
    }

    static updateCollisions(object)
    {
        if(object.userData.m_collisionsMesh != null)
        {
            if(object.userData.m_collisionFlags == 0)
            {
                var pos = new THREE.Vector3();
                var vec = new THREE.Vector3();
                vec.copy(object.userData.m_offset);
                vec.applyQuaternion(object.userData.m_collisionsMesh.quaternion);
                pos.copy(object.userData.m_collisionsMesh.position)
                pos.add(vec);

                object.position.copy(pos);
                object.quaternion.copy(object.userData.m_collisionsMesh.quaternion);
                MainObjects.Blueprint.m_selectListenerBp.updateObject(object);
            } 
        }
    }

    static destory(physics, object)
    {
        if(object.userData.m_collisionsMesh != null)
        {
            if(object.userData.m_collisionsMesh)
            {
                if(object.userData.m_collisionsMesh.parent)
                {
                    object.userData.m_collisionsMesh.removeFromParent();
                }
                if(physics)
                {
                    physics.destroy(object.userData.m_collisionsMesh);
                }
   
            }
            if(object.userData.m_collisionsMesh.isMesh)
            {
                object.userData.m_collisionsMesh.geometry.dispose();
            }
            object.userData.m_collisionsMesh = null;
        }
    }
}

export { CollisionsFactory };