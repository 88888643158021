import Vue from 'vue'
import ElementUI from 'element-ui';
import './plugins/axios'
import App from './App.vue'
import router from './router'
import autoRouter from './router/routes'
import store from './store'
import './plugins/element.js'
import "./util/rem"

//引入腾讯云im
import "./util/tim"

import qs from 'qs'
import VueClipboard from 'vue-clipboard2'

// 视频插件
import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

//引入拖拽指令
import drag from './util/drag';
import dragFixed from './util/dragFixed';

//引入axios
import axios from 'axios'
/*请求方式*/
import './axios/index'

let Base64 = require('js-base64').Base64; //解码base64

import {
    server
} from './axios/api'

Vue.config.productionTip = false
//注册拖拽指令
Vue.directive('drag', drag);
Vue.directive('dragFixed', dragFixed);


Vue.prototype.$qs = qs
Vue.prototype.$https = server

Vue.prototype.ROUTER = autoRouter;
Vue.use(ElementUI);
Vue.use(VueClipboard);
// 视频插件
Vue.use(VideoPlayer)




//动态路由
Vue.prototype.$setRouter = function () {
    if (localStorage.getItem('token')) {
        let routerlist = []
        Vue.prototype.ROUTER.getRouterList(function (data) {
            routerlist = data
        });

        router.addRoutes(routerlist);
        // router.addRoute(routerlist)

        for (var i in routerlist[0].children) {


            if (routerlist[0].children[i].meta.hidden == false) {

                this.$router.push({
                    path: routerlist[0].children[i].redirect
                })

                break
            }
        }
        // if(routerlist[0].){}
        //   this.$router.push({path: '/index/user'})
    }
}


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
