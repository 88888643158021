import { HistoryRepos } from "../factory/HistoryRepos"
import { HistoryData } from "../object/HistoryData"
import { MainObjects } from "../common/MainObjects"
import { Saver } from "../loader/Saver"
import { IScene } from "../interface/IScene";
import { ITransform } from "../interface/ITransform";
import { ISelector } from "../interface/ISelector";
import { IUnity } from "../interface/IUnity";
import { IExport } from "../interface/IExport";

class HistoryListenerBp {
    constructor() {
        this.m_historyRepos = new HistoryRepos();
        this.m_tempCopyName = 'tempCopy';
        this.m_haveCopy = false;
    }

    undo() {
        const data = this.m_historyRepos.getUndo();
        if (data != null) {
            data.m_dataFunction(data.m_undoData);
        }
    }

    redo() {
        const data = this.m_historyRepos.getRedo();
        if (data != null) {
            data.m_dataFunction(data.m_redoData);
        }
    }

    addUndoRedo(undoData, redoData, datafunction, id = null) {
        var historyData = new HistoryData();
        historyData.m_undoData = undoData;
        historyData.m_redoData = redoData;
        historyData.m_dataFunction = datafunction;
        if(id != null)
        {
            historyData.m_id = id;
        }
        this.m_historyRepos.addUndoRedo(historyData);
    }

    onKeyDown(even) {

    }

    onKeyUp(even) {
        
        if (MainObjects.Blueprint.m_eventListenerBp.m_bCtrl == true || MainObjects.Blueprint.m_eventListenerBp.m_bShift == true) {
            if (even.keyCode == 90) {
                this.undo();
            }
            else if (even.keyCode == 67) //copy
            {
                if(MainObjects.Blueprint.m_sceneManagerBp && MainObjects.Blueprint.m_sceneManagerBp.m_db)
                {
                    if(MainObjects.Blueprint.m_sceneManagerBp.m_db.copyData)
                    {
                        MainObjects.Blueprint.m_sceneManagerBp.m_db.copyData.clear();
                    }      
                }
               
                if(MainObjects.Blueprint.m_sceneManagerBp.m_db.copyData)
                {
                    var _this = this;
                    this.m_haveCopy = false;
                    var copyData = [];
                    const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
                    for (let index = 0; index < length; index++) {
                        const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                        copyData.push(MainObjects.Blueprint.m_sceneManagerBp.getObjectJsonInfo(obj));
                    }

                    let json = JSON.stringify(copyData);
                    MainObjects.Blueprint.m_sceneManagerBp.m_db.copyData.add({id: _this.m_tempCopyName, value: json}).then(function() {
                    });
                }
            }
            else if (even.keyCode == 86) //pase
            { 
                var _this = this;
                if (!ITransform.m_draging) {   
                    _this.getCopyInDb(function(array){
                        ITransform.m_copying = true;
                        var copyData = JSON.parse(array.value);

                        var objectlist = new Array();
                        for (let index = 0; index < copyData.length; index++) {
                          
                            MainObjects.Blueprint.m_sceneManagerBp.getObjectFromJson(copyData[index], function (object) {
                                objectlist.push(object);
                                if(objectlist.length == copyData.length)
                                {
                                    MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd(objectlist);  
                                    MainObjects.Blueprint.m_collisionsManagerBp.initCollisions();
                                    if(IScene.f_sceneTreeCallback != null)
                                    {
                                       
                                        IScene.f_sceneTreeCallback();
                                    }   


                                }
                            }, true);
                        }

                        ITransform.m_copying = false;
                    });
                }
            }
        }
        if (MainObjects.Blueprint.m_eventListenerBp.m_bShift == true) {
            console.log(even.keyCode, ":even.keyCode")
            if (even.keyCode == 90) {
                this.redo();
            }
            else if (even.keyCode == 83) //save
            {
                Saver.toSave();
            }
            else if(even.keyCode == 48)
            {
                IUnity.copyToScene();
            }
            else if(even.keyCode == 85)
            {
                IExport.exportSceneGLTF();
            }
        }
    }

    async getCopyInDb(callback)
    {
       var temp = await MainObjects.Blueprint.m_sceneManagerBp.m_db.copyData.where('id').equals(this.m_tempCopyName).first();
       callback(temp)
    }

}
export { HistoryListenerBp };