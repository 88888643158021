import { OssManager } from "../network/OssManager"
import { MainObjects } from "../common/MainObjects"
import { Language } from "../common/Language";
import { ILoader } from "../interface/ILoader"
import { IImage } from "./IRes"
import { Saver } from "../loader/Saver";
import { IAnimation } from "./IAnimation";
import { IBehavior } from "./IBehavior";

class INetwork {

    static f_startSocketCallback = null;
    static f_loadSceneFinishCallback = null;

    static uploadFilesToPreview(files, processCallback, finishCallback) {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_progressCallback = processCallback;
        oss.f_finishCallback = finishCallback;
        const storeAs = oss.getPreviewFloder();
        oss.uploadFileList(files, storeAs);
    }
    static copyFilesToSql(files, sqlId, ext, finishCallback) {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = finishCallback;
        let srcUrlList = new Array();
        let dstUrlList = new Array();
        for (let index = 0; index < files.length; index++) {
            var srcStoreAs = oss.getPreviewFloder() + files[index].name;
            var dstStoreAs = oss.getSqlFloder(sqlId) + files[index].name;
            var file = dstStoreAs;
            var filename = file.split('?')[0];
            const extension = filename.split('.').pop().toLowerCase();
            if (ext.indexOf(extension) != -1 || ext == extension) {
                dstStoreAs = oss.getSqlFloder(sqlId) + sqlId + "." + extension;
            }
            srcUrlList.push(srcStoreAs);
            dstUrlList.push(dstStoreAs);
        }
        oss.copyFileList(srcUrlList, dstUrlList);
    }

    static copyPaseFilesToSql(srcStoreList, sqlId, finishCallback) {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = finishCallback;
        let srcUrlList = new Array();
        let dstUrlList = new Array();
        for (let index = 0; index < srcStoreList.length; index++) {

            var srcStoreAs = srcStoreList[index];
            var file = srcStoreAs;
            var filename = file.split('?')[0].split('.');
            const extension = filename.pop().toLowerCase();
            const name = filename.pop();
            var dstStoreAs = oss.getSqlFloder(sqlId) + name + "." + extension;
            
            srcUrlList.push(srcStoreAs);
            dstUrlList.push(dstStoreAs);
        }
        oss.copyFileList(srcUrlList, dstUrlList);
        return dstUrlList;
    }

    static copyBackUp(srcStoreAs, dstStoreAs, finishCallback)
    {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = finishCallback;
        let srcUrlList = new Array();
        let dstUrlList = new Array();
        srcUrlList.push(srcStoreAs);
        dstUrlList.push(dstStoreAs);
        oss.copyFileList(srcUrlList, dstUrlList);
    }

    static getFilesNameToSql(files, sqlId, ext) {
        var data = {
            name: Language.LOCAL_TEXT("resouse", "资源"),
            mainName: "",
            imageName: "",
            otherNameList: new Array()
        }
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        var imageName = oss.getSqlFloder(sqlId) + "image_" + sqlId + ".png";
        data.imageName = imageName;
        oss.uploadImageBuffer(imageName, MainObjects.MainRender.m_previewResouse.blob, function () {

        });
        for (let index = 0; index < files.length; index++) {
            var dstStoreAs = oss.getSqlFloder(sqlId) + files[index].name;

            var file = dstStoreAs;
            var filename = file.split('?')[0];
            const extension = filename.split('.').pop().toLowerCase();
            if (ext.indexOf(extension) != -1 || ext == extension) {
                dstStoreAs = oss.getSqlFloder(sqlId) + sqlId + "." + extension;
                data.mainName = dstStoreAs;
                data.name = ILoader.getBaseName(extension);
            }
            data.otherNameList.push(dstStoreAs);
        }
        var url = INetwork.getUrl(data.mainName);
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        gameScene.removeRepos(url);

        return data;
    }

    static getAudioFilesNameToSql(files, sqlId, ext){
        var data = {
            name: Language.LOCAL_TEXT("resouse", "资源"),
            mainName: "",
            imageName: IImage.audioImage,
            otherNameList: new Array()
        }
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        for (let index = 0; index < files.length; index++) {
            var dstStoreAs = oss.getSqlFloder(sqlId) + files[index].name;

            var file = dstStoreAs;
            var filename = file.split('?')[0];
            const extension = filename.split('.').pop().toLowerCase();
            if (ext.indexOf(extension) != -1 || ext == extension) {
                dstStoreAs = oss.getSqlFloder(sqlId) + sqlId + "." + extension;
                data.mainName = dstStoreAs;
                data.name = ILoader.getBaseName(extension);
            }
            data.otherNameList.push(dstStoreAs);
        }
        var url = INetwork.getUrl(data.mainName);
        return data;
    }

    static getParticleFilesNameToSql(files, sqlId, ext){
        var data = {
            name: Language.LOCAL_TEXT("resouse", "资源"),
            mainName: "",
            imageName: IImage.particleImage,
            otherNameList: new Array()
        }
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        for (let index = 0; index < files.length; index++) {
            var dstStoreAs = oss.getSqlFloder(sqlId) + files[index].name;

            var file = dstStoreAs;
            var filename = file.split('?')[0];
            const extension = filename.split('.').pop().toLowerCase();
            if (ext.indexOf(extension) != -1 || ext == extension) {
                dstStoreAs = oss.getSqlFloder(sqlId) + sqlId + "." + extension;
                data.mainName = dstStoreAs;
                data.name = ILoader.getBaseName(extension);
            }
            data.otherNameList.push(dstStoreAs);
        }
        var url = INetwork.getUrl(data.mainName);
        return data;
    }

    static getFileList(dir, finishCallback)
    {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = finishCallback;
        oss.getListFile(dir);
    }

    //create group
    static getGroupJsonToSql(sqlId, finishCallback)
    {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        var imageName = oss.getSqlFloder(sqlId) + "image_" + sqlId + ".png";
        oss.uploadImageBuffer(imageName, MainObjects.MainRender.m_previewResouse.blob, function () {

        });
        let json = JSON.stringify(MainObjects.MainRender.m_previewResouse.object_data);
        var data = INetwork.uploadJsonToSql(sqlId, "group", json, finishCallback);
        data.imageName = imageName;
        return data;
    }

    //material
    static getMaterialJsonToSql(sqlId, finishCallback){
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        var imageName = oss.getSqlFloder(sqlId) + "image_" + sqlId + ".png"; 
        oss.uploadImageBuffer(imageName, MainObjects.MainRender.m_previewResouse.blob, function () {
        });
        var json = MainObjects.Blueprint.m_materialEditorBp.getMaterialJson(MainObjects.MainRender.m_previewResouse.sphere);
        var jsonString = JSON.stringify(json);
        var data = INetwork.uploadJsonToSql(sqlId, "material", jsonString, function(){
            var srcStoreAs = oss.getSqlFloder(sqlId) + "material" + ".json";
            MainObjects.Blueprint.m_materialEditorBp.updateSceneMaterial(INetwork.getUrl(srcStoreAs), srcStoreAs, sqlId, json);
            finishCallback();
        });
        data.imageName = imageName;
       
        return data;
    }

    //animationBehavior
    static getAnimationBehaviorJsonToSql(sqlId, finishCallback){

        var json = IAnimation.getAnimationBehaviorJson();
        var jsonString = JSON.stringify(json);
        var data = INetwork.uploadJsonToSql(sqlId, "animationBehavior", jsonString, function(){
            finishCallback();
        });
        data.imageName = IImage.animationBehaviorImage;
        return data
    }

    //Behavior
    static getBehaviorJsonToSql(sqlId, key, finishCallback){
        var json = IBehavior.getBehaviorJson(key);
        var jsonString = JSON.stringify(json);
        var data = INetwork.uploadJsonToSql(sqlId, key, jsonString, function(){
            finishCallback();
        });
        data.imageName = IImage.animationBehaviorImage;
        return data
    }

    // scene
    static uploadJsonToSql(sqlId, name, json, finishCallback) {
        var oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = finishCallback;
        var srcStoreAs = oss.getSqlFloder(sqlId) + name + ".json";
        var dir = oss.getSqlFloder(sqlId);
        var max = 20;
        INetwork.getFileList(dir, function(objects){
            var timestamp = 0;
            if(objects.length == 0)
            {
                oss.uploadJson(srcStoreAs, json);
            }
            else{   
                
                if(objects.length >= max)
                {  
                    timestamp = 0;
                }
                timestamp = objects.length;
                var copyStoreAs = oss.getSqlFloder(sqlId) + name + timestamp + ".json";
                INetwork.copyBackUp(srcStoreAs, copyStoreAs, function(){
                    oss.uploadJson(srcStoreAs, json);
                });
            }

        });
        var data = {
            name: Language.LOCAL_TEXT("resouse", "资源"),
            mainName: "",
            imageName: IImage.sceneImage,
            otherNameList: new Array()
        }
        data.mainName = srcStoreAs;
        data.name = ILoader.getBaseName(name);
        data.otherNameList.push(data.mainName);
        return data;
        
    }

    static uploadJsTSql(sqlId, str, finishCallback){
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = finishCallback;
        var srcStoreAs = oss.getSqlFloder(sqlId) + "Behavior.js";
        oss.uploadJs(srcStoreAs, str);
        var data = {
            name: Language.LOCAL_TEXT("js", "脚本"),
            mainName: "",
            imageName: IImage.behaviorImage,
            otherNameList: new Array()
        }
        data.mainName = srcStoreAs;
        data.name = ILoader.getBaseName("js");
        data.otherNameList.push(data.mainName);
        return data;
    }

    static getDefultJs(callback){
        MainObjects.Blueprint.m_runtimeJsManagerBp.getBehavior(function(data){
            callback(data);
        });
    }

    static openSceneJson(name, callback) {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = function (data) {
            Saver.saveToLocal(data, function(){
                callback()
            });
       
        };
        localStorage.setItem("RuntimeScene", name);
        oss.downloadJson(name);
    }

    static openAnimationBehaviorJson(name, callback)
    {
        INetwork.downloadJson(name, function(json){
            IAnimation.createAnimationBehavior();
            IAnimation.setAnimationBehaviorJson(json);
            callback(json);
        });
    }

    static openBehaviorJson(name, key, callback)
    {
        INetwork.downloadJson(name, function(json){
            IBehavior.createBehavior(key);
            IBehavior.setBehaviorJson(json,key);
            callback(json);
        });
    }

    static downloadJson(name, callback){
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = function (data) {
            callback(data)
        };
        oss.downloadJson(name);
    }

    static downloadJs(name, callback){
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.f_finishCallback = function (data) {
            callback(data)
        };
        oss.downloadJs(name)
    }

    static getUrl(name) {
        var url = MainObjects.Network.m_http + name;
        return url;
    }

    static deleteFiles(nameList) {
        let oss = new OssManager(MainObjects.Network.m_bucketName);
        oss.deleteFiles(nameList);
    }

    static startSocket(b){
        MainObjects.Blueprint.m_renderManagerBp.createSocket(b);
    }

}

export { INetwork };