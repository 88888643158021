import { MainObjects } from "../common/MainObjects"
import { IScene } from "../interface/IScene"

class ICamera {
    static CameraMode =
        {
            e_PERSPECTIVE: 0,
            e_ORTHOGRAPHIC: 1,
        }

    static f_windowsResize = null;
    static f_cameraModeCallback = null;

    static setCameraMode() {
        MainObjects.Blueprint.m_cameraListenerBp.changeCameraMode();
    }

    static resize() {
        MainObjects.Blueprint.m_eventListenerBp.onCameraResize();
    }

    static setMainCamera(id) {

        var nonelist = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.setMainCamera(id);
        if(MainObjects.Scene.m_cameraViewHelper.visible)
        {
            MainObjects.MainRender.m_previewCamera.setSceneCamera();
        }
        return nonelist;
    }

    static setWH(w, h) {
        var option = {
            w: w,
            h: h
        }
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if(obj.isCamera)
            {
                obj.userData.m_w = option.w;
                obj.userData.m_h = option.h;
                if(MainObjects.MainRender.m_previewCamera.camera != null)
                {
                    if(obj.id == MainObjects.MainRender.m_previewCamera.camera.id)
                    {
                        MainObjects.MainRender.m_previewCamera.resize(option);
                    }
                }
            }
        });
    }

    static openPreviewCamera(turnOff = true)
    {
        if(turnOff)
        {
            MainObjects.Scene.m_cameraViewHelper.setVisible(true);
            return MainObjects.MainRender.m_previewCamera.setSceneCamera();
        }
        else
        {
            MainObjects.Scene.m_cameraViewHelper.setVisible(false);
            MainObjects.MainRender.m_previewCamera.close();
            return true;
        }
    }

    static setPerspCameraProperty(cameraProp) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if(obj.isCamera)
            {
                obj.far = cameraProp.far;
                obj.near = cameraProp.near;
                obj.fov = cameraProp.fov;
                obj.updateProjectionMatrix();
                obj.userData.m_gameObjectHelper.update();
            }
        });
    }

    static setOrthoCameraProperty(cameraProp) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if(obj.isCamera)
            {
                obj.far = cameraProp.far;
                obj.near = cameraProp.near;
                obj.zoom = cameraProp.zoom;
                obj.updateProjectionMatrix();
                obj.userData.m_gameObjectHelper.update();
            }
        });
    }
}

export { ICamera };