<template>
    <div class="container">
        <div class="caption">名称</div>
        <div class="Name">
            <!-- <div class="title">名称</div> -->
            <div class="ipt_box">
                <input v-model="name" @change="nameChange($event)" @focus="Focus" @blur="Blur" placeholder="" />
                <span class="txt">NAME</span>
            </div>
        </div>
        <div class="caption">排放</div>
        <div class="emisions">
            <div class="emisionsList">
                <div class="ipt_box">
                    <input v-model="total" placeholder="" @change="nameChange($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">TOTAL</span>
                </div>
                <el-switch
                    v-model="totalState">
                </el-switch>
            </div>
            <div class="emisionsList">
                <div class="ipt_box">
                    <input v-model="life" placeholder="" @change="nameChange($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">LIFE</span>
                </div>
                <el-switch
                    v-model="lifeState">
                </el-switch>
            </div>
        </div>
        <div class="caption">速度</div>
        <div class="rate">
            <div class="particles">
                <div class="title">粒子</div>
                <div class="txt_box">
                    <input v-model="life" placeholder="" @change="nameChange($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MIN</span>
                </div>
                <div class="txt_box">
                    <input v-model="life" placeholder="" @change="nameChange($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MAX</span>
                </div>
            </div>
            <div class="particles">
                <div class="title">秒钟</div>
                <div class="txt_box">
                    <input v-model="life" placeholder="" @change="nameChange($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MIN</span>
                </div>
                <div class="txt_box">
                    <input v-model="life" placeholder="" @change="nameChange($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MAX</span>
                </div>
            </div>
        </div>
        <div class="caption">位置</div>
        <div class="loction1">
            <div class="x-axis axis">
                <p>X</p>
                <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
            </div>
            <div class="y-axis axis">
                <p>Y</p>
                <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
            </div>
            <div class="z-axis axis">
                <p>Z</p>
                <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
            </div>
        </div>
        <div class="caption">旋转</div>
        <div class="loction1">
            <div class="x-axis axis">
                <p>X</p>
                <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
            </div>
            <div class="y-axis axis">
                <p>Y</p>
                <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
            </div>
            <div class="z-axis axis">
                <p>Z</p>
                <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
            </div>
        </div>
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
export default {
    data(){
        return{
            name:"",
            totalState:false,
            lifeState:false,
            total:0,
            life:0,
            positionX:0,
            positionY:0,
            positionZ:0,
        }
    },
    mounted(){

    },
    methods:{
        nameChange(){

        },
        changeNum(){

        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },
    }
    
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 30px;
}
.container::-webkit-scrollbar{
  display: none;
}
.Name{
    width: 100%;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: #fff;
    overflow: hidden;
    margin-top: 10px;
    padding-left: 22px;
}
.Name .title{
    width: 70px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.ipt_box{
    width: 240px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ipt_box .txt{
    display: inline-block;
    width: 50px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 12px;
    font-size: 12px;
    color: #808080;
}
.ipt_box input{
    width: 190px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.caption{
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #F7F7F7;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.emisions{
    width: 100%;
    margin-top: 10px;
}
.emisions .emisionsList{
    display: flex;
    width: 100%;
    height: 35px;
    align-items: center;
    justify-content: space-evenly;
}
.loction1{
    width: 100%;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    margin-top: 15px;
}
.axis{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;
}
.axis p{
    width: 22px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
.rate{
    width: 100%;
}
.rate .particles {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
}
.particles .title{
    width: 70px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.particles .txt_box{
    width: 100px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.particles .txt_box .txt{
    display: inline-block;
    width: 40px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    color: #808080;
}
.particles .txt_box input{
    width: 60px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
</style>