import { MainObjects } from "../common/MainObjects";

class ICollisions {
    static CollisionsType = {
        e_NONE : 0,
        e_MESH : 1,
        e_CAPSULE : 2,
        e_BOUNDING_BOX : 3,
        e_GROUP_BOUNDING_BOX : 4,
    }

    static setCollisionsType(type)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_collisionsManagerBp.setType(type, obj);
        });
    }

    static getCollisions()
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            var data = MainObjects.Blueprint.m_collisionsManagerBp.getCollisionsData(firstObj);
            return data;
        }
        var data = {
            type: ICollisions.CollisionsType.e_NONE,
            center: [0, 0, 0],    //偏移 
            size: [1, 1, 1],      //尺寸
            height: 1.8,          //高度
            radius: 0.35,         //半径
            mass : 4,             //质量
            collisionFlags : 0,   //类型
            gravity: -0.981,      //重力
            angularFactor: [0, 0, 0],  //角度摩擦
            friction: 0           //摩擦
        }
        return data;
    }

    //type
    static setCollisionsType(type)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            MainObjects.Blueprint.m_collisionsManagerBp.setCollisionsType(firstObj, type);
        }
    }

    //类型collisionFlags //重力gravity //角度摩擦angularFactor //摩擦friction
    static setCollisionsPhyData(data)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            MainObjects.Blueprint.m_collisionsManagerBp.setCollisionsPhyData(firstObj, data);
        }
    }

    //尺寸size //高度height //半径radius //质量mass //偏移center
    static setCollisionsBodyProp(data)
    {
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            MainObjects.Blueprint.m_collisionsManagerBp.setCollisionsBodyProp(firstObj, data);
        }
    }

    static setPhysicsTurnOff(b)
    {
        MainObjects.Blueprint.m_collisionsManagerBp.turnPhysics(b);
    }
}
export { ICollisions };