<template>
  <div class="wrap">
    <div class="toolbar">
      <a name="跳转项目页面">
        <div class="tool1" @click="projectChange()">
          <img class="save" src="../../assets/ToolBar/project.png" alt="" />
        </div>
      </a>



      <a name="保存-快捷键Shift+S">
        <div class="tool1" @click="saveChange()">
          <img class="save" src="../../assets/ToolBar/save.png" alt="" />
        </div>
      </a>


      <a name="上一步-快捷键Ctrl+Z">
        <div class="tool1" @click="lastChange()">
          <img class="back" src="../../assets/ToolBar/last.png" alt="" />
        </div>
      </a>
      <a name="下一步-快捷键Shift+Z">
        <div class="tool1" @click="nextChange()">
          <img class="back" src="../../assets/ToolBar/next.png" alt="" />
        </div>
      </a>
      <a name="切换子选择-快捷键G" id="group">
        <div class="tool1" @click="groupChange()">
          <img class="box" src="../../assets/ToolBar/groupSelect.png" alt v-show="groupState" />
          <img class="box" src="../../assets/ToolBar/son.png" alt v-show="!groupState" />
        </div>
      </a>
      <a name="框选-快捷键S">
        <div class="tool1" @click="selectChange()">
          <img class="box" src="../../assets/ToolBar/box.png" alt v-show="boxState" />
          <img class="box" src="../../assets/ToolBar/checkedBox.png" alt v-show="!boxState" />
        </div>
      </a>
      <a name="切换本地-快捷键Q" id="Space">
        <div class="tool1" @click="TransformChange()">
          <img class="mine" src="../../assets/ToolBar/world.png" alt v-show="downIcon" />
          <img class="mine" src="../../assets/ToolBar/local.png" v-show="!downIcon" alt />
        </div>
      </a>
      <div style="display: flex" v-for="(item, index) in ToolBarImg" :key="index">
        <a :name="item.title">
          <div class="tool1" @click="transLastChange(index)">
            <img class="move" :src="item.img" alt="" />
          </div>
        </a>
      </div>
      <a name="尺寸">
        <div class="tool1" @click="sizeChange()">
          <img class="box" src="../../assets/ToolBar/checkedSize.png" alt v-show="sizeState" />
          <img class="box" src="../../assets/ToolBar/size.png" alt v-show="!sizeState" />
        </div>
      </a>
      <a name="切换正交-快捷键P" id="camera">
        <div class="tool1" @click="cameraChange()">
          <img src="../../assets/ToolBar/perspectivity.png" alt v-show="cameraState" />
          <img src="../../assets/ToolBar/quadrature.png" alt v-show="!cameraState" />
        </div>
      </a>
      <a name="切换网格-快捷键O" class="render">
        <div class="tool1" @click="renderChange">
          <img src="../../assets/ToolBar/checkedRender.png" alt v-show="renderState" />
          <img src="../../assets/ToolBar/render.png" alt v-show="!renderState" />
        </div>
      </a>
      <a name="预览窗口-开启" id="preview">
        <div class="tool1" @click="previewChange()">
          <img src="../../assets/ToolBar/previewCamera.png" alt v-show="previewIconState" />
          <img src="../../assets/ToolBar/PreviewCameraChecked.png" alt v-show="!previewIconState" />
        </div>
      </a>
      <!-- <a name="物理模拟" class="simulation">
        <div class="tool1" @click="simulationChange()">
          <img src="../../assets/ToolBar/simulation.png" alt="" v-show="simulationState">
          <img src="../../assets/ToolBar/simulationChecked.png" alt="" v-show="!simulationState">
        </div>
      </a> -->

      <!-- <a class="simulation" v-if="$store.state.cloudsScene"> -->
      <el-tooltip placement="top" effect="light">

        <div slot="content">
          <div style="cursor: pointer;" class="" @click="download()">粒子插件</div>

          <div style="cursor: pointer; margin-top: 0.65104vw;">
            <div class="" v-show="$store.state.cloudsScene" @click="createCloudStore()">
              创建云店
            </div>
          </div>
          <div style="cursor: pointer; margin-top: 0.65104vw;">
            <div class="" v-show="$store.state.cloudsScene" @click="createRealisticCloudStore()">
              创建实景云店
            </div>
          </div>
        </div>

        <div @mouseenter="changeplugShow()" class="tool1">
          <img src="../../assets/PlugIn/cloudStore/plugInIcon.png" alt="">
        </div>

      </el-tooltip>

      <el-tooltip placement="top" effect="light">
        <div slot="content">
          <div style="cursor: pointer;" class="" @click="Down()">导出GLTF</div>
        </div>

        <div @mouseenter="exportShow()" class="tool1">
          <img src="../../assets/ToolBar/export.png" alt="">
        </div>
      </el-tooltip>





      <!-- </a> -->


      <!-- 
      <a name="下载粒子编辑器" class="simulation" >
        <div class="tool1" @click="download()">
          <img src="../../assets/PlugIn/cloudStore/download.png" alt="" >

        </div>
      </a> -->


      <!-- <div class="tool1" @click="addChange">
        <img
          class="add"
          src="../../assets/ToolBar/add.png"
          alt=""
          v-show="addImgState"
        />
        <img
          class="add"
          src="../../assets/ToolBar/checkedAdd.png"
          alt=""
          v-show="!addImgState"
        />
      </div>-->
    </div>
    <!-- <div class="addition" v-show="addHide">
      <AddiTion />
    </div>-->
    <!-- <RenderCamera v-show="readerC" class="RenderCamera" /> -->
    <el-dialog :visible="selectScene" width="23%" :modal="true" title="请选择场景" append-to-body
      :before-close="selectHistoryClose" @open="openSelectSceneShow" top="23vh">
      <el-table ref="singleTable" :data="dataList" highlight-current-row @current-change="handleCurrentChange"
        max-height="400" style="width: 100%">
        <el-table-column type="index" width="50">
        </el-table-column>

        <el-table-column property="folderName" label="文件夹目录" width="220">
        </el-table-column>
        <el-table-column property="name" label="场景名" width="120">
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChangePage" :current-page="currentPage"
        :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totals"
        align="right">
      </el-pagination>

      <el-button @click="confirmSelection()" round>确定</el-button>
    </el-dialog>




  </div>
</template>
<script>
import Move from "../../assets/ToolBar/move.png";
import Rotate from "../../assets/ToolBar/rotate.png";
import Zoom from "../../assets/ToolBar/zoom.png";
import Flex from "../../assets/ToolBar/flex.png";
import Adsorb from "../../assets/ToolBar/adsorb.png";
import checkedMove from "../../assets/ToolBar/checkedMove.png";
import checkedRotate from "../../assets/ToolBar/checkedRotate.png";
import checkedZoom from "../../assets/ToolBar/checkedZoom.png";
import checkedFlex from "../../assets/ToolBar/checkedFlex.png";
import checkedAdsorb from "../../assets/ToolBar/checkedAdsorb.png";
import Align from "../../assets/ToolBar/align.png";
import checkedAlign from "../../assets/ToolBar/checkedAlign.png";
import groupSelect from "../../assets/ToolBar/groupSelect.png";
import son from "../../assets/ToolBar/son.png";
import RenderCamera from "../property/RenderCamera";

import { ISelector } from "../../threejs/interface/ISelector";
import { ICamera } from "../../threejs/interface/ICamera";
import { IScene } from "../../threejs/interface/IScene";
import { IHistory } from "../../threejs/interface/IHistory";
import AddiTion from "../property/AddObject";
import { ISetting } from "../../threejs/interface/ISetting";
import { IRender } from "../../threejs/interface/IRender";
import { ICollisions } from "../../threejs/interface/ICollisions"
import { IParameter } from "../../threejs/interface/IParameter";
import { IExport } from "../../threejs/interface/IExport";

import { OssManager } from "../../threejs/network/OssManager";

import { MainObjects } from "../../threejs/common/MainObjects";


import { GameObject } from "../../threejs/object/GameObject"

import { CubeData } from "../PlugIn/CloudStores/cloudSoresThree/factory/CubeData"
import * as THREE from 'three';


export default {
  data() {
    return {
      value: 50,
      value1: 50,
      hide: false,
      addHide: false,
      ToolBarImg: [
        { img: checkedMove, title: "移动-快捷键W" },
        { img: Rotate, title: "旋转-快捷键E" },
        { img: Flex, title: "伸缩-快捷键T" },
        { img: Adsorb, title: "吸附-快捷键Y" },
        { img: Align, title: "对齐-快捷键U" }
      ],
      downIcon: true,
      cameraState: true,
      boxState: true,
      renderState: true,
      addImgState: true,
      flexState: true,
      adsorbState: true,
      AlignState: true,
      sizeState: true,
      groupState: true,
      previewIconState: true,
      readerC: false,
      simulationState: true,
      exportState: null,
      // 场景选择展示
      selectScene: false,
      currentRow: null,
      dataList: [],
      currentPage: 1,
      totals: 10,
      pageSize: 10,
      page: 1,
      count: 10,

      plugShow: false
    };
  },
  mounted() {
    ISelector.f_transformModeCallback = this.transformKeyChange.bind(this);
    ISelector.f_transformSpaceCallback = this.transformSpaceChange.bind(this);
    ISelector.f_selectModeCallback = this.selectModeChange.bind(this);
    ISelector.f_selectLayerCallback = this.groupModeChange.bind(this);
    ICamera.f_cameraModeCallback = this.cameraModeChange.bind(this);
    IRender.f_renderModeCallback = this.renderModeChange.bind(this);

    let projectId = localStorage.getItem("projectId");

    if (!projectId) {
      this.projectChange()
    }
  },
  components: {
    AddiTion,
    RenderCamera
  },
  methods: {


    changeplugShow() {
      this.plugShow = !this.plugShow
    },
    exportShow() {
      this.exportState = !this.exportState;
    },
    handleSizeChange(val) {
      this.count = val

      this.getData(1, val)
    },
    handleCurrentChangePage(val) {
      this.page = val

      this.getData(this.page, this.count)
    },

    // 确认选中
    confirmSelection() {
      if (this.currentRow) {
        this.$store.commit('changeCloudsScene', this.currentRow)
        let routeUrl = this.$router.resolve({
          path: "/PlugIn/CloudStores/compileDirectory",
        })
        window.open(routeUrl.href, '_blank')
      } else {
        this.$message({
          // type: "info",
          message: "请至少选择一个场景"
        });
      }
    },
    selectHistoryClose() {
      this.selectScene = false
    },

    openSelectSceneShow() {
      this.getData(this.page, this.count)
    },
    async getData(page, count) {
      let data = {
        page: page,
        count: count,
        projectId: localStorage.getItem("projectId"),
        name: "",
      }

      const res = await this.$https.getAllScene(data)
      if (res.success == true) {


        this.totals = res.data.total
        this.dataList = res.data.sceneCOS
      }

    },

    handleCurrentChange(val) {
      this.currentRow = val;
    },


    // addChange() {
    //   this.addHide = !this.addHide;
    //   this.addImgState = !this.addImgState;
    // },

    transLastChange(index) {
      ISelector.setTransformMode(index);
      if (index == 0) {
        this.ToolBarImg = [
          { img: checkedMove, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-快捷键Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (index == 1) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: checkedRotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-快捷键Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (index == 2) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: checkedFlex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-快捷键Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (index == 3) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: checkedAdsorb, title: "吸附-快捷键Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (index == 4) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-快捷键Y" },
          { img: checkedAlign, title: "对齐-快捷键U" }
        ];
      }
    },
    transformKeyChange(type) {
      if (type == 0) {
        this.ToolBarImg = [
          { img: checkedMove, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (type == 1) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: checkedRotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (type == 2) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: checkedFlex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-快捷键Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (type == 3) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: checkedAdsorb, title: "吸附-快捷键Y" },
          { img: Align, title: "对齐-快捷键U" }
        ];
      } else if (type == 4) {
        this.ToolBarImg = [
          { img: Move, title: "移动-快捷键W" },
          { img: Rotate, title: "旋转-快捷键E" },
          { img: Flex, title: "伸缩-快捷键T" },
          { img: Adsorb, title: "吸附-快捷键Y" },
          { img: checkedAlign, title: "对齐-快捷键U" }
        ];
      }
    },
    TransformChange() {
      this.downIcon = !this.downIcon;
      let Space = document.getElementById("Space");
      if (this.downIcon) {
        ISelector.setTransformSpace(ISelector.TransformSpace.e_WORLD);
        Space.name = "切换本地-快捷键Q";
      } else {
        ISelector.setTransformSpace(ISelector.TransformSpace.e_LOCAL);
        Space.name = "切换世界-快捷键Q";
      }
    },
    transformSpaceChange(type) {
      let Space = document.getElementById("Space");
      if (type == 0) {
        this.downIcon = true;
        Space.name = "切换本地-快捷键Q";
      } else if (type == 1) {
        this.downIcon = false;
        Space.name = "切换世界-快捷键Q";
      }
    },
    cameraChange() {
      this.cameraState = !this.cameraState;
      let title = document.getElementById("camera");
      if (this.cameraState) {
        ICamera.setCameraMode();
        title.name = "切换正交-快捷键P";
      } else {
        ICamera.setCameraMode();
        title.name = "切换透视-快捷键P";
      }
    },
    //预览窗口
    previewChange() {
      let title = document.getElementById("preview");
      this.previewIconState = !this.previewIconState;
      if (this.previewIconState) {
        this.$store.state.previewState = false;
        title.name = "预览窗口-开启";
        this.readerC = false;
        ICamera.openPreviewCamera(false)
      } else {
        this.$store.state.previewState = true;
        title.name = "预览窗口-关闭"
        this.readerC = true;
        ICamera.openPreviewCamera(true);
      }
    },
    alignChange() {
      this.AlignState = !this.AlignState;
    },
    sizeChange() {
      this.sizeState = !this.sizeState;
      if (this.sizeState) {
        ISelector.setSizeEnable();
      } else {
        ISelector.setSizeEnable();
      }
    },
    flexChange() {
      this.flexState = !this.flexState;
    },
    adsorbChange() {
      this.adsorbState = !this.adsorbState;
    },
    cameraModeChange(type) {
      let title = document.getElementById("camera");
      if (type == 0) {
        this.cameraState = true;
        title.name = "切换正交-快捷键P";
      } else if (type == 1) {
        this.cameraState = false;
        title.name = "切换透视-快捷键P";
      }
    },
    //切换组选择
    groupChange() {
      this.groupState = !this.groupState;
      let title = document.getElementById("group");
      if (this.groupState) {
        ISelector.setSelectLayer(0);
        title.name = "切换子选择-快捷键G"
      } else {
        ISelector.setSelectLayer(1);
        title.name = "切换组选择-快捷键G"
      }
    },
    groupModeChange(type) {
      let title = document.getElementById("group");
      if (type == 0) {
        this.groupState = true;
        title.name = "切换子选择-快捷键G"
      } else if (type == 1) {
        this.groupState = false;
        title.name = "切换组选择-快捷键G"
      }
    },
    //款选
    selectChange() {
      this.boxState = !this.boxState;
      if (this.boxState) {
        ISelector.setSelectMode();
      } else {
        ISelector.setSelectMode();
      }
    },
    selectModeChange(type) {
      if (type == 0) {
        this.boxState = true;
      } else if (type == 1) {
        this.boxState = false;
      }
    },
    renderChange() {
      this.renderState = !this.renderState;
      let render = document.querySelector(".render");
      if (this.renderState) {
        IRender.setRenderMode(false);
        render.name = "切换网格-快捷键O"
      } else {
        IRender.setRenderMode(true);
        render.name = "切换实体-快捷键O"
      }
    },
    simulationChange() {
      this.simulationState = !this.simulationState;
      let simulation = document.querySelector('.simulation');
      if (this.simulationState) {
        ICollisions.setPhysicsTurnOff(false)
      } else {
        ICollisions.setPhysicsTurnOff(true)
      }
    },
    // 创建实景云店
    createRealisticCloudStore() {

      let cameraList3D = IScene.getTypeObjects(GameObject.GameObjectType.e_CAMERA_EQUIRECT)

      let modelLis3D = IScene.getTypeObjects(GameObject.GameObjectType.e_GLTF)

      let cameraList = []

      let modelLis = {}


      let CubeArr = []

      let groupId= localStorage.getItem("teamId")

      console.log(groupId,"groupId");

      // console.log(cameraList3D,"cameraList3D");

      for (let data of cameraList3D) {
        let obj = {
          id: data.uuid,
          name: data.userData.m_name,
          FOV: data.fov,
          vertical: 0,
          horizontal: 0,
          img: "",
          initialCamera: false,

          position: data.position,
          groupId:groupId

        }
        cameraList.push(obj)
      }
      // console.log(modelLis3D,"modelLis3D")

      for (let data of modelLis3D) {
        let obj = {
          id: data.uuid,
          name: data.userData.m_name,
          url: data.userData.m_url,
          number: 1
        }
        let Cube = new CubeData()

        const material = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          fog: false,
          toneMapped: false,
        });
        Cube.createCube(data, material)

        let cubeData = {
          size: Cube.size,
          pos: Cube.pos,
          m_id: Cube.m_id,
          rot: Cube.rot

        }

        CubeArr.push(cubeData)


        if (modelLis[data.userData.m_name]) {

          modelLis[data.userData.m_name].number = modelLis[data.userData.m_name].number + 1
        } else {
          modelLis[data.userData.m_name] = obj
        }
      }


      // console.log(CubeArr,"CubeArr");
      let modelDataList = []
      for (var key in modelLis) {

        let oss = new OssManager(MainObjects.Network.m_bucketName);
        var imageName = oss.getSqlFloder(modelLis[key].id) + "image_" + modelLis[key].id + ".png";

        var modelData = {};
        let furnitureData = {
          explicitName: "",
          len: "",
          breadth: "",
          altitude: "",
          describe: "",
          showImg: ""
        }
        let commodityData = {
          showName: "",
          guidingPrice: "",
          describe: "",
          showImg: "",
          goToUrl: "",
        }

        modelData.id = modelLis[key].id;
        modelData.name = modelLis[key].name;
        modelData.number = modelLis[key].number;

        modelData.type = 0;
        modelData.img = imageName;
        modelData.furnitureData = furnitureData;
        modelData.commodityData = commodityData;


        modelDataList.push(modelData);
      }





      // console.log(modelDataList, "modelDataList")




      // console.log(cameraList3D, modelLis3D, "modelLis3D")
      cameraList = JSON.stringify(cameraList)
      modelDataList = JSON.stringify(modelDataList)

      this.plugShow = false

      let cloudsScene = JSON.stringify(this.$store.state.cloudsScene)
      CubeArr = JSON.stringify(CubeArr)


      let affiliation = localStorage.getItem("projectName")
      let projectId = localStorage.getItem("projectId")
      // let groupId= localStorage.getItem("teamId")

      let routeUrl = this.$router.resolve({

        path: "/PlugIn/CealisticCloudStores/compileDirectory",
        query: {
          groupId:groupId,
                    //要传的参数
                    cloudsScene: cloudsScene,
          affiliation: affiliation,
          // 項目id
          projectId: projectId,
          cameraList: cameraList,
          modelLis: modelDataList,
          CubeArr: CubeArr,
          // teamId: teamId
        }

      })
      window.open(routeUrl.href, '_blank')
    },
    //创建云店
    createCloudStore() {

      let cameraList3D = IScene.getTypeObjects(GameObject.GameObjectType.e_CAMERA_EQUIRECT)

      let modelLis3D = IScene.getTypeObjects(GameObject.GameObjectType.e_GLTF)

      let cameraList = []

      let modelLis = {}


      let CubeArr = []

      let groupId= localStorage.getItem("teamId")

      console.log(groupId,"groupId");

      // console.log(cameraList3D,"cameraList3D");

      for (let data of cameraList3D) {
        let obj = {
          id: data.uuid,
          name: data.userData.m_name,
          FOV: data.fov,
          vertical: 0,
          horizontal: 0,
          img: "",
          initialCamera: false,

          position: data.position,
          groupId:groupId

        }
        cameraList.push(obj)
      }
      // console.log(modelLis3D,"modelLis3D")

      for (let data of modelLis3D) {
        let obj = {
          id: data.uuid,
          name: data.userData.m_name,
          url: data.userData.m_url,
          number: 1
        }
        let Cube = new CubeData()

        const material = new THREE.MeshBasicMaterial({
          color: 0x00ff00,
          fog: false,
          toneMapped: false,
        });
        Cube.createCube(data, material)

        let cubeData = {
          size: Cube.size,
          pos: Cube.pos,
          m_id: Cube.m_id,
          rot: Cube.rot

        }

        CubeArr.push(cubeData)


        if (modelLis[data.userData.m_name]) {

          modelLis[data.userData.m_name].number = modelLis[data.userData.m_name].number + 1
        } else {
          modelLis[data.userData.m_name] = obj
        }
      }


      // console.log(CubeArr,"CubeArr");
      let modelDataList = []
      for (var key in modelLis) {

        let oss = new OssManager(MainObjects.Network.m_bucketName);
        var imageName = oss.getSqlFloder(modelLis[key].id) + "image_" + modelLis[key].id + ".png";

        var modelData = {};
        let furnitureData = {
          explicitName: "",
          len: "",
          breadth: "",
          altitude: "",
          describe: "",
          showImg: ""
        }
        let commodityData = {
          showName: "",
          guidingPrice: "",
          describe: "",
          showImg: "",
          goToUrl: "",
        }

        modelData.id = modelLis[key].id;
        modelData.name = modelLis[key].name;
        modelData.number = modelLis[key].number;

        modelData.type = 0;
        modelData.img = imageName;
        modelData.furnitureData = furnitureData;
        modelData.commodityData = commodityData;


        modelDataList.push(modelData);
      }





      // console.log(modelDataList, "modelDataList")




      // console.log(cameraList3D, modelLis3D, "modelLis3D")
      cameraList = JSON.stringify(cameraList)
      modelDataList = JSON.stringify(modelDataList)

      this.plugShow = false

      let cloudsScene = JSON.stringify(this.$store.state.cloudsScene)
      CubeArr = JSON.stringify(CubeArr)


      let affiliation = localStorage.getItem("projectName")
      let projectId = localStorage.getItem("projectId")
      let teamId = localStorage.getItem("teamId")

      let routeUrl = this.$router.resolve({

        path: "/PlugIn/CloudStores/compileDirectory",
        query: {
          //要传的参数
          cloudsScene: cloudsScene,
          affiliation: affiliation,
          // 項目id
          projectId: projectId,
          cameraList: cameraList,
          modelLis: modelDataList,
          CubeArr: CubeArr,
          teamId: teamId
        }

      })
      window.open(routeUrl.href, '_blank')




    },
    //导出OBJ
    Down() {
      IExport.exportSceneGLTF();
    },

    //下载粒子编辑器
    download() {
      this.plugShow = false
      window.location.href = "https://file.yicreator.com/res/plugIn/nebula.zip"
    },
    renderModeChange(type) {
      let render = document.querySelector(".render");
      if (type == 0) {
        this.renderState = true;
        render.name = "切换网格-快捷键O"
      } else if (type == 1) {
        this.renderState = false;
        render.name = "切换实体-快捷键O"
      }
    },
    //撤销
    lastChange() {
      IHistory.undo();
    },
    nextChange() {
      IHistory.redo();
    },
    //保存
    saveChange() {
      IScene.save();
    },
    projectChange() {
      // var projectId= localStorage.getItem("projectId")
      // this.projectLocal(projectId).then(()=>{
      ISetting.exit();
      // let projectId =   localStorage.getItem('projectId');
      // projectId = null;
      //清除localStorage 里的场景id
      //项目id
      localStorage.removeItem("projectId");

      //更新的文件id
      localStorage.removeItem("updateFileId");
      //当前场景id
      localStorage.removeItem("presentSceneId");
      //展开列表
      localStorage.removeItem("unfoldIdList");
      //右侧 文件夹id
      localStorage.removeItem("FileDirectoryId");
      //当前右侧类型
      localStorage.removeItem("ResourceType");
      // 路径
      localStorage.removeItem("temppath");
      //场景名字
      localStorage.removeItem("sceneName");

      localStorage.removeItem("shear");
      var oldId = localStorage.getItem("openSceneId");
      this.setSceneLocal(oldId, 0);

      localStorage.removeItem("openSceneId");

      // })

      //模型路径
      localStorage.removeItem("modelUrl");
      localStorage.removeItem("leftClickNode");
      this.$store.commit('changeCloudsScene', null)


      this.$router.replace({ path: "/index/user/User" });

    },

    //设置场景锁状态
    async setSceneLocal(_id, _local) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });

      const res = await this.$https.sceneLocal({
        id: _id,
        local: _local
      });
      if (res.success == true) {
        // loading.close();
      } else {
        // loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },

    //项目上锁解锁接口
    async projectLocal(id) {
      const res = await this.$https.projectLocal({
        id: id,
        local: 0
      });
      if (res.errCode == null) {
      } else {
        this.$message({
          message: res.errMessage
          // type: "warning",
        });
      }
    }
  }
};
</script>
<style scoped>
.toolbar {
  display: flex;
  width: 1020px;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}

.toolbar .tool1 {
  width: 50px;
  height: 30px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar .tool1:hover {
  background: #fafafa;
}

.toolbar .tool1 img {
  width: 15px;
  height: 15px;
}

.camera {
  width: 280px;
  height: 85px;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  left: 295px;
  top: 50px;
}

.metallicity {
  display: flex;
  width: 90%;
  height: 20px;
  align-items: center;
  margin-top: 15px;
}

.metallicity .title {
  width: 100px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}

.metallicity .ipt {
  width: 40px;
  height: 20px;
  outline: none;
  margin-left: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #ccc;
}

.slider {
  width: 150px;
}

.RenderCamera {
  position: absolute;
  left: -1000px;
}

a::after {
  content: attr(name);
  display: none;
}

a:hover::after {
  display: block;
  position: absolute;
  min-width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  top: 40px;
  color: #000;
  font-size: 12px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  z-index: 99999999999;
}

.content {
  position: absolute;
  top: 40px;
  background-color: #fff;
  padding: 5px;
  border-radius: 8px;
  cursor: pointer;
}
</style>