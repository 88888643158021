<template>
    <div>
         <div id="qrcode" ref="qrcode"></div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
    data(){
        return{
            data:"111",
        }
    },
    mounted(){
        this.qrcode()
    },
    methods:{
        qrcode () {
            let qrcode = new QRCode("qrcode", {

                width: 150,

                height: 150,

                text: ""

            });

        },

    }
}
</script>
<style scoped>
#qrcode{
    width: 150px;
    height: 150px;
    padding: 5px;
    box-shadow: 0px 0px 5px 0px rgba(33, 35, 49, 0.1);
    background-color: #fff;
}
</style>