import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { MaterialObject } from "../object/MaterialObject"
import { GameObject } from '../object/GameObject'
import { ITransform } from "./ITransform"
import { MaterialFactory } from '../factory/MaterialFactory';
import { IMaterialPreview } from './IMaterialPreview';

class IMaterial {
    static getSelectMeshMaterial() {

        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            if(!IMaterial.canMaterial()) 
            {
                return null;
            }
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            if (firstObj.isMesh)
            {
                if(firstObj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_BASIC)
                {
                    return IMaterial.getBaseMatData(firstObj);
                }
                else if(firstObj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_STANDARD)
                {
                    return IMaterial.getStandMatData(firstObj);
                }
                else if(firstObj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_PHONE)
                {
                    return IMaterial.getPhoneMatData(firstObj);
                }
            }
            else
            {
                return null;
            }
        }   
        else
        {
            return null;
        }
        return null;
    }

    static getPhoneMatData(firstObj)
    {
        var material = {};
        material.type = firstObj.material.userData.m_materialObjectType;
        material.resId = firstObj.material.userData.m_id;
        material.edType = firstObj.material.userData.m_materialEditorType;

        material.fog = firstObj.material.fog;
        material.opacity = firstObj.material.opacity;
        material.side = firstObj.material.side;
        material.transparent = firstObj.material.transparent;
  
        material.reflectivity  = firstObj.material.reflectivity;
        material.refractionRatio  = firstObj.material.refractionRatio;
        material.shininess  = firstObj.material.shininess;

        material.emissive = firstObj.material.emissive.getHexString();
        material.emissiveMap = firstObj.material.emissiveMap;
        material.emissiveIntensity = firstObj.material.emissiveIntensity;
        if (material.emissiveMap != null && material.emissiveMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.emissiveMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.emissiveMap = data;
        }
        material.emissive = "#" + material.emissive;

        material.specular = firstObj.material.specular.getHexString();
        material.specularMap = firstObj.material.specularMap;
        if (material.specularMap != null && material.specularMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.specularMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.specularMap = data;
        }
        material.specular = "#" + material.specular;

        material.normalMap = firstObj.material.normalMap;
        if (material.normalMap != null && material.normalMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.normalMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.normalMap = data;
        }

        material.aoMap = firstObj.material.aoMap;
        material.aoMapIntensity = firstObj.material.aoMapIntensity;
        if (material.aoMap != null && material.aoMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.aoMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.aoMap = data;
        }

        material.envMap = firstObj.material.envMap;
        if (material.envMap != null && material.envMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.envMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.envMap = data;
        }

        material.lightMap = firstObj.material.lightMap;
        material.lightMapIntensity = firstObj.material.lightMapIntensity;
        if (material.lightMap != null && material.lightMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.lightMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.lightMap = data;
        }

        material.map = firstObj.material.map;
        if (material.map != null && material.map != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.map);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.map = data;
        }

        material.color = firstObj.material.color.getHexString();
        material.color = "#" + material.color;
        return material;
    }

    static getStandMatData(firstObj)
    {
        var material = {};
        material.type = firstObj.material.userData.m_materialObjectType;
        material.resId = firstObj.material.userData.m_id;
        material.edType = firstObj.material.userData.m_materialEditorType;
        material.matType = firstObj.material.userData.m_materialMatType;
        material.materialMat = firstObj.material.userData.getMaterialMat();

        material.fog = firstObj.material.fog;
        material.opacity = firstObj.material.opacity;
        material.side = firstObj.material.side;
        material.transparent = firstObj.material.transparent;
        
        material.emissive = firstObj.material.emissive.getHexString();
        material.emissiveMap = firstObj.material.emissiveMap;
        material.emissiveIntensity = firstObj.material.emissiveIntensity;
        if (material.emissiveMap != null && material.emissiveMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.emissiveMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.emissiveMap = data;
        }
        material.emissive = "#" + material.emissive;

        material.metalness = firstObj.material.metalness;
        material.metalnessMap = firstObj.material.metalnessMap;
        if (material.metalnessMap != null && material.metalnessMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.metalnessMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.metalnessMap = data;
        }

        material.normalMap = firstObj.material.normalMap;
        if (material.normalMap != null && material.normalMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.normalMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.normalMap = data;
        }

        material.roughness = firstObj.material.roughness;
        material.roughnessMap = firstObj.material.roughnessMap;
        if (material.roughnessMap != null && material.roughnessMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.roughnessMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.roughnessMap = data;
        }

        material.aoMap = firstObj.material.aoMap;
        material.aoMapIntensity = firstObj.material.aoMapIntensity;
        if (material.aoMap != null && material.aoMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.aoMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.aoMap = data;
        }

        material.envMap = firstObj.material.envMap;
        material.envMapIntensity = firstObj.material.envMapIntensity;
        if (material.envMap != null && material.envMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.envMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.envMap = data;
        }

        material.lightMap = firstObj.material.lightMap;
        material.lightMapIntensity = firstObj.material.lightMapIntensity;
        if (material.lightMap != null && material.lightMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.lightMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.lightMap = data;
        }

        material.map = firstObj.material.map;
        if (material.map != null && material.map != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.map);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.map = data;
        }

        material.color = firstObj.material.color.getHexString();
        material.color = "#" + material.color;
     
        return material;
    }

    static getBaseMatData(firstObj)
    {
        var material = {};
        material.type = firstObj.material.userData.m_materialObjectType;
        material.resId = firstObj.material.userData.m_id;
        material.edType = firstObj.material.userData.m_materialEditorType;

        material.fog = firstObj.material.fog;
        material.opacity = firstObj.material.opacity;
        material.side = firstObj.material.side;
        material.transparent = firstObj.material.transparent;
        material.refractionRatio = firstObj.material.refractionRatio;

        material.specularMap = firstObj.material.specularMap;
        if (material.specularMap != null && material.specularMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.specularMap);
            data.repeat  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.specularMap = data;
        }
        
        material.aoMap = firstObj.material.aoMap;
        material.aoMapIntensity = firstObj.material.aoMapIntensity;
        if (material.aoMap != null && material.aoMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.aoMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.aoMap = data;
        }
        
        material.envMap = firstObj.material.envMap;
        if (material.envMap != null && material.envMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.envMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.envMap = data;
        }
        
        material.lightMap = firstObj.material.lightMap;
        material.lightMapIntensity = firstObj.material.lightMapIntensity;
        if (material.lightMap != null && material.lightMap != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.lightMap);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.lightMap = data;
        }

        material.map = firstObj.material.map;
        if (material.map != null && material.map != "-") {
            var data = MainObjects.Blueprint.m_materialEditorBp.getTextureJson(material.map);
            data.offset  = ITransform.mTommArray(data.offset);
            data.rotation = ITransform.radToAngle(data.rotation);
            material.map = data;
        }

        material.color = firstObj.material.color.getHexString();
        material.color = "#" + material.color;

        return material;
    }

    static selectorMaterialType(mode) {
        if (MainObjects.Blueprint.m_selectorPropertyBp.isSelectorSameType()) {
            if (MainObjects.Blueprint.m_selectorPropertyBp.isSelectorSameMaterialType()) {
                MainObjects.Blueprint.m_materialEditorBp.setSelectMeshMaterial(mode);
            }
        }
    }

    static setBasicMaterial(data) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            IMaterialPreview.setBasicMaterialData(data, obj);
        });
    }

    static setStandardMaterial(data) {

        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            IMaterialPreview.setStandardMaterial(data, obj);
        });
    }

    static setPhoneMaterial(data){
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            IMaterialPreview.setPhoneMaterialData(data, obj);
        });
    }

    static setMap(texture, type) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if(obj.isMesh)
            {
                IMaterialPreview.setMapData(texture, type, obj);
                if (ITransform.f_selectTransformCallback != null) {
                    ITransform.f_selectTransformCallback(true);
                }
            }
        });
    }

    static setTextureUv(data, type, mode)
    {
        // var data = {
        //     offset: [0, 0],
        //     repeat: [1, 1],
        //     rotation: 0
        // }
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            IMaterialPreview.setTextureUvData(data, type, mode, obj);
            if (ITransform.f_selectTransformCallback != null) {
                ITransform.f_selectTransformCallback(true);
            }
        });
    }

    static canMaterial()
    {
        if(MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType == GameObject.GameObjectType.e_GLTF_MESH)
        {
            return true;
        }
        else
        {
            return false;
        }
    } 
}

export { IMaterial };