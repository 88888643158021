import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage, // 默认位置是 localStorage
    reducer: (state) => {
      return {
        // 默认是全部缓存，在这里可以设置需要缓存的状态
        userPermissionData: state.userPermissionData,
        //用户在团队的身份
        UserRank: state.UserRank,
        cloudsScene: state.cloudsScene,
      }
    }
  })],
  state: {
    //更新粒子数据
    partiClecompileData:{},
    //粒子编辑
    partiClecompile:false,
    //状态机
    macState:false,
    
    //显示新建隐藏粒子
    nweParticleShow:false,
    textureBallMasking:false,
    
    //资源树 被点击的节点
    resourceNode:{},
    
    //被点击的场景节点
    sceneNode:{},


    //材质球显示
    textureBallShow:false,
    
    //3D场景数类型
    threeSceneTreeType:0,
    
    previewState:false,
    previewStateReaderC:false,

    //创建材质球类型/粒子
    creatingMaterialTypes:null,

    resId:"",  

    // 是否存在未读消息
    unread:false,

    
    //资源里被选中的文件
    selectNode:{},


    //权限
    
    userPermissionData:{
  
    }, 
    UserRank:'',

    //选择要上传的共享库

      //选择要上传的共享库 显示
      historyShow:false,
      //要上传的数据
      historyData:{},
      //上传文件/文件夹 父文件夹类型
      uploadingParentType:"",



    // 选择要同步的文件夹
      
      //当前所处的库
      chosenWarehouse:{
        createTime:null,
        id:null,
        type:null,
        name:null,
      },
      //下载类型
      synchronization:"",

      //同步到本地显示隐藏
      downloadShow:false,
      //文件夹树类型
      folderType:null,
      //需要下载的文件
      downloadData:{

      },
      // 选择文件夹id
      selectFolderId:"",


      //云店

      //基础
      basics:{
        //名字
        NameShop:"",
        // 简介
        briefIntroduction:"",
        // 互动
        interaction:false,
        // 地图展示
        map:false,
        // 店铺统计
        StoreStatistics:false,
        NumberShops:false,


      },
      //媒体
      media:{
        // 背景音乐
        backgroundMusic:[],
        // 导购音乐
        MusicGuide:[],





      },
      //地图
        mapList:[]
      ,
      //商品
      merchandiseList:[],

      //家具
      furniture:[],
      //店铺
      store:{

      },
      //外部
      external:{

      },
      //效果
      result:{

      },
      // 复制移动文件树
      cloudTree:false,
      // 移动或复制
      cloudTreeOperationType:"",
      // 选择的文件信息
      cloudFileData:{},

      // 云店场景
      cloudsScene:null,

      //新建云店数据
      cloudData:{},
      moveList:[],

      //新建动作播放器文件夹id
      motionPlayerParentId:"",


      motionPlayerData:{},
      // 1是新建2是編輯
      motionPlayerType:0,

      motionPlayerSqlData:"",


      // 材质加载到地形
      TerrainMaterial:{
        type:"",
        subTexIndex:""
      },
      //判断加载类型
      terrainType:"",

      // 脚本应用脚本
      scriptData:"",
  },
  
  mutations: { 

    // 脚本修改
    ScriptDataChange(state,scriptData){
      state.scriptData=scriptData
    },

    // 材质加载到地形
    TerrainMaterialChange(state,TerrainMaterial){
      state.TerrainMaterial=TerrainMaterial
    },
    terrainTypeChange(state,terrainType){
      state.terrainType=terrainType;
    },


    changeMotionPlayerSqlData(state,motionPlayerSqlData){
      state.motionPlayerSqlData=motionPlayerSqlData

    },

    changeMotionPlayerType(state,motionPlayerType){

      state.motionPlayerType=motionPlayerType
    },

    changeMotionPlayerData(state,motionPlayerData){

      state.motionPlayerData=motionPlayerData
    },


    changeMacState(state,macState){
      state.macState=macState
    },

    changeMotionPlayerParentId(state,motionPlayerParentId){
      state.motionPlayerParentId=motionPlayerParentId
    },

    changeMoveList(state,moveList){
      state.moveList=moveList
      
    },
    changeCloudData(state,cloudData){

      state.cloudData=cloudData
    },
    // 修改云店场景
    changeCloudsScene(state,cloudsScene){

      state.cloudsScene=cloudsScene
    },

    // 复制黏贴类型
    changeCloudTreeOperationType(state,cloudTreeOperationType){
      state.cloudTreeOperationType=cloudTreeOperationType

    },
    //设置要复制或移动的文件
    changeCloudFileData(state,cloudFileData){
      state.cloudFileData=cloudFileData

    },

    // 云店复制树
    changeCloudTree(state,cloudTree){
      state.cloudTree=cloudTree

    },

    //设置更改粒子的数据
    changePartiClecompileData(state,partiClecompileData){
      state.partiClecompileData=partiClecompileData

    },
    //让编辑粒子显示隐藏 
    changePartiClecompile(state,partiClecompile){
      state.partiClecompile=partiClecompile


    },

    setTemppathList(state,temppathLis){
      state.temppathLis=temppathLis

    },
    //显示隐藏粒子改变
    
    changeNweParticleShow(state,nweParticleShow){
      state.nweParticleShow=nweParticleShow

    },

    // 选择文件夹id
    changeSelectFolderId(state,selectFolderId){
      state.selectFolderId=selectFolderId

    },

    //设置下载类型
    changeSynchronization(state,synchronization){

      state.synchronization=synchronization
    },

    //设置需要同步到本地的数据
    changeDownloadData(state,downloadData){
      state.downloadData=downloadData
    },

    //设置文件夹树类型
    changeFolderType(state,folderType){
      state.folderType=folderType
    },

    //显示 同步到本地
    changeDownloadShow(state,downloadShow){
      state.downloadShow=downloadShow
    },




    //设置当前所处库
    changeChosenWarehouse(state,chosenWarehouse){
      state.chosenWarehouse=chosenWarehouse
    },







    //要被上传的文件/文件夹节点
    changeHistoryData(state,historyData){
      state.historyData=historyData
    },

    //要被上传的文件/文件夹节点的父文件类型
    changeUploadingParentType(state,uploadingParentType){
      state.uploadingParentType=uploadingParentType
    },


    


    //显示上传库选择
    changeHistoryShow(state,historyShow){
      state.historyShow=historyShow
    },



    //设置用户所在权限
    changeUserRank(state,UserRank){
      state.UserRank=UserRank
    },




    //设置用户权限
    changeUserPermissionData(state,userPermissionData){
      state.userPermissionData=userPermissionData
    },


    //改变消息已读未读
      changeUnread(state,unread){
      state.unread=unread
      },

    //控制蒙版显示隐藏
    showOrHide(state,textureBallMasking){
      state.textureBallMasking=textureBallMasking
    },
    //设置资源树 被点击的节点
    setResourceNode(state,resourceNode){
      state.resourceNode=resourceNode
    },
    //设置被点击的场景节点
    setsceneNode(state,sceneNode){
      state.sceneNode=sceneNode
    },
    setTextureBallShow(state,textureBallShow){
      state.textureBallShow=textureBallShow

    },
    // 设置场景数类型
    setThreeSceneTreeType(state,threeSceneTreeType){
      
      state.threeSceneTreeType=threeSceneTreeType



    },
    //改变创建材质类型
    setCreatingMaterialTypes(state,creatingMaterialTypes){
      
      state.creatingMaterialTypes=creatingMaterialTypes

     
      

    },
    //改变选中的文件
    setSelectNode(state,selectNode){
      state.selectNode=selectNode

    }

  },
  actions: {
    
  },
  modules: {
  }
})
