<template   >
  <div>
    <!-- <div class="box1"></div> -->
    <div
      :class="{
      EditorBox:upgrading==2,
      createEditorBox: upgrading==1
      
      }"
 
    v-dragFixed
    @click="myClick"
    >
    <div class="controlDrag" id="controlDrag">

    </div>


      <div :class="{
      left:upgrading==2,
      createLeft: upgrading==1
      }">
        <PreviewEditor
          :files="files"
          :fileUrl="fileUrl"
          :ext="ext"
          @transfer="getPreviewShow"
          :upDataId="upDataId"
          :upgrading="upgrading"
          @setFileDirectoryId="setFileDirectoryId"
          :FileDirectoryId="FileDirectoryId"
          :ResourceType="ResourceType"
          :upDataNode="upDataNode"
          :sid="childSia"
        />
      </div>
      <div
        v-if="upgrading==2"
        :class="{
                right: true,
                redactRight: upgrading==2,
               
              }"
      >
        <PreviewMateria @passVaule="passValue" />
      </div>
      <div class="close" @click="closeChange">
        <img src="../../assets//Project/close.png" alt />
      </div>
    </div>
  </div>
</template>
<script>
import PreviewEditor from "./PreviewEditor";

import PreviewMateria from "./PreviewMaterial";
import { ISetting } from "../../threejs/interface/ISetting";
import { IResousePreview } from "../../threejs/interface/IResousePreview";

export default {
  data() {
    return {
      childSia: "",
      //父节点id
      fatherNodeid: "",

      //上传文件列表
      fileUelList: [],
      //进度条
      progressBar: 0,
      //主url
      mainUrl: "",
      //所有url
      url_list: "",

      //加载进度条显示隐藏
      progressBarShow: false,

      totalSize: 0,

      //对话框
      dialogFormVisible: true,

      name_list: [],
      //修改的文件id
      fileId: "",
      //进度条文字提示
      progressText: "正在上传",

      fileName: "",
      //点击多次只触发一次
      state: false,

      title: "上传模型",

      loadingProgressBar: 0
    };
  },

  props: [
    //拿到文件url现在没用到
    "fileUrl",
    //拿到上传的文件
    "files",
    //模型还是材质现在没用到
    "ext",
    "upDataId",
    //更改还是上传 调用不同的接口
    "upgrading",
    //父文件id
    "FileDirectoryId",
    // 传过来的右侧树的类型
    "ResourceType",
    //传过来的更新node
    "upDataNode"
  ],

  components: {
    PreviewEditor,
    PreviewMateria
  },
  mounted() {
    this.initialized();
  },
  methods: {
    myClick(){

      
    },
    //获取文件
    getSubCatalog(msg) {

      this.$parent.getSubCatalog(msg);
    },

    //禁用快捷键
    initialized() {
      ISetting.isInput(true);
      this.$store.commit("setTextureBallShow", true);
    },
    passValue(data) {
      this.childSia = data;
      this.$emit("passVaule", this.childSia);

    },
    //传选中的id
    setFileDirectoryId(msg) {
      

      this.FileDirectoryId = msg;

      //调用获取方法
      this.getSubCatalog(msg);
    },

    getSubCatalog(upDataId) {


      this.$parent.getSubCatalog(upDataId);
    },

    getPreviewShow(msg) {
      this.$parent.getPreviewShow(msg);
    },

    closeChange() {
      this.$parent.titleChange();
      localStorage.removeItem("updateFileId");
      this.$store.commit("setTextureBallShow", false);

      ISetting.isInput(false);

      IResousePreview.close();

      //清空选中的加载材质
      this.passValue("");
    }
  }
};
</script>
<style scoped>
.box1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.1;
}
.EditorBox {
  width: 60%;
  height: 560px;
  background: #fff;
  border-radius: 10px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -75%);

  display: flex;
  justify-content: space-between;
  z-index: 999;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
}

.createEditorBox {
  height: 610px;
  width: 26%;
  background: #fff;
  border-radius: 10px;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -65%);
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
}

.left {
  width: 50%;
  height: 94%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 10px;
}
.createLeft {
  width: 100%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 10px;
}

.right {
  width: 50%;
  height: 98%;
  overflow: auto;
  background: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  /* margin-top: 10px; */
}
.redactRight {
  height: 97%;
}
.right::-webkit-scrollbar {
  display: none;
}
.right {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.close {
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}
.close img {
  width: 13px;
  height: 13px;
}
.controlDrag{
  /* background: red; */
  opacity:0;
  width: 80%;
  height: 50px;
  position: absolute;
  top: 0;
  z-index: 9999;
}
</style>