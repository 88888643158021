import { Vector3 } from "three";
import { Object3D } from "three";
import { LineSegments } from "three";
import { LineBasicMaterial } from "three";
import { Float32BufferAttribute } from "three";
import { BufferGeometry } from "three";
import { MainObjects } from "../common/MainObjects";
import { GameObject } from "../object/GameObject";
import { GameScene } from "../object/GameScene";

const _vector = /*@__PURE__*/ new Vector3();

class SpotLightHelper extends Object3D {

	constructor( light, color ) {

		super();
		this.light = light;
		this.light.updateMatrixWorld();
		this.m_target_obj = new Object3D();
		MainObjects.Scene.m_helperGroup.add(this.m_target_obj);
		this.layers.set(11);

		this.matrix = light.matrixWorld;
		this.matrixAutoUpdate = false;

		this.color = color;
		var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
		
		var geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_LINE_CONE);
		var material = gameScene.m_matRepos.get(GameScene.MatKey.e_LINE);

		this.cone = new LineSegments( geometry, material );
		this.cone.renderOrder = 100;
		this.add( this.cone );

		this.update();

		_vector.setFromMatrixPosition( this.light.target.matrixWorld );
		this.cone.lookAt( _vector );
	}

	dispose() {

		// this.cone.geometry.dispose();
		// this.cone.material.dispose();
		this.m_target_obj.removeFromParent();
	}

	update() {

		var position = new Vector3();
        var target = new Vector3();
        this.light.getWorldPosition(position);
        this.light.getWorldDirection(target);
		position.add(target);
		this.m_target_obj.position.copy(position);
		this.light.target = this.m_target_obj;
		this.light.target.updateMatrixWorld(true);

		const coneLength = 0.1;
		const coneWidth = coneLength * Math.tan( this.light.angle );

		this.cone.scale.set( coneWidth, coneWidth, coneLength );
        this.updateMatrixWorld(true);
	}

}


export { SpotLightHelper };