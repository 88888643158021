<template>
    <div>
        <div class="box" :class="isActive?'anmDiv':'aaa'">
           <div class="header">
               <p>参数设置</p>
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
           </div>
           <div class="projection">
               <div class="title">选择类型</div>
               <select class="select">
                   <option value="0">透视</option>
                   <option value="0">正交</option>
               </select>
           </div>
          <div class="metallicity">
               <div class="title">FOV轴</div>
               <div class="slider">
                    <el-slider v-model="value"></el-slider>
                </div>
                <input class="ipt" type="text" :value="value" />
           </div>
           <div class="shadow">
               <div class="title">遮挡剔除</div>
               <input class="input" type="checkbox" />
           </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            isActive:true,
            value:50,
        }
    },
    methods:{
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        }
    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 150px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 25px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 25px;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.projection{
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin: 15px auto;
    font-size: 12px;
}
.projection .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
}
.projection .select{
    width: 270px;
    height:30px;
    outline: none;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius:4px ;
}
.metallicity{
    display: flex;
    width: 90%;
    height: 20px;
    align-items: center;
    margin-top: 25px;
}
.metallicity .title{
    width: 80px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
}
.metallicity .ipt{
    width: 62px;
    height: 28px;
    border-radius: 6px;
    outline: none;
    margin-left: 20px;
    border: 1px solid #ccc;
    color: #929292;
}
.slider{
    width: 240px;
}

.shadow{
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin: 15px auto;
}
.shadow .title{
    width: 80px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
}
.shadow .input{
    width: 20px;
    height: 20px;
}
</style>