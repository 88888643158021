import { UIPanel, UIElement } from '../object/UIElement';
import { ViewHelper as ViewHelperBase } from './ViewHelperBase';

class ViewHelper extends ViewHelperBase {

	constructor(editorCamera, containerDom, control) {

		super(editorCamera, containerDom);
		const container = new UIElement(containerDom);
		this.controls = control;
		const panel = new UIPanel();
		panel.setId('viewHelper');
		panel.setPosition('absolute');
		panel.setRight('0px');
		panel.setBottom('0px');
		panel.setHeight('128px');
		panel.setWidth('128px');


		panel.dom.addEventListener('pointerup', (event) => {

			event.stopPropagation();

			this.handleClick(event);

		});

		panel.dom.addEventListener('pointerdown', function (event) {

			event.stopPropagation();

		});

		container.add(panel);

	}

	
}

export { ViewHelper };