import { UIPanel, UIElement } from '../object/UIElement';

class CameraViewHelper{

	constructor(containerDom) {

		this.container = new UIElement(containerDom);
		this.panel = new UIPanel();
		this.panel.setId('camera_render_view');
		this.panel.setPosition('absolute');
		this.panel.setLeft('0px');
		this.panel.setTop('0px');
		this.panel.setHeight('108px');
		this.panel.setWidth('198px');
        this.panel.setBorder('ridge');
        this.panel.setBackground('black');
        this.panel.setDisplay('none');
		this.container.add(this.panel);
        this.visible = false;
        this.containerDom = containerDom;
	}

    getDom()
    {
        return this.panel.dom;
    }

    setVisible(b)
    {
        if(b)
        {
            this.panel.setDisplay('block');
            this.visible = true;
        }
        else
        {
            this.panel.setDisplay('none');
            this.visible = false;
        }
    }

    setWH(w, h)
    {
        const maxW = this.containerDom.clientWidth * 0.5;
        const maxH = this.containerDom.clientHeight * 0.5;
        if(w > h)
        {
            var width = maxW;
            var height = h * width / w;
            this.panel.setWidth(width + 'px');
            this.panel.setHeight(height + 'px');
        }
        else
        {
            var height = maxH;
            var width = w * height / h;
            this.panel.setWidth(width + 'px');
            this.panel.setHeight(height + 'px');
        }
    }

	
}

export { CameraViewHelper };