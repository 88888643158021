<template>
    <div class="box" v-dragFixed>
        <div class="header" id="controlDrag"> {{this.$store.state.partiClecompileData.name}}</div>
        <div class="content">
            <div class="left">
                <div class="layers">
                    <div class="firstHalf">
                        <p>BodySprite</p>
                        <el-switch v-model="BodySprite" @change="BodySpriteSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>Life</p>
                        <el-switch v-model="Life" @change="lifeSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>半径</p>
                        <el-switch v-model="Radius" @change="RadiusSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>质量</p>
                        <el-switch v-model="Mass" @change="MassSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>径向速度</p>
                        <el-switch v-model="RadialVelocity" @change="RadialVelocitySwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>颜色</p>
                        <el-switch v-model="Color" @change="ColorSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>透明度</p>
                        <el-switch v-model="Alpha" @change="AlphaSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>缩放</p>
                        <el-switch v-model="Scale" @change="ScaleSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>Spring</p>
                        <el-switch v-model="Spring" @change="SpringSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>Force</p>
                        <el-switch v-model="Force" @change="ForceSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>随机</p>
                        <el-switch v-model="RandomDrift" @change="RandomDriftSwitch()">
                        </el-switch>
                    </div>
                    <div class="firstHalf">
                        <p>旋转</p>
                        <el-switch v-model="Rotate" @change="RotateSwitch()">
                        </el-switch>
                    </div>
                </div>
            </div>
            <div class="main">
                <div class="container" id="preview-container"></div>
                <div class="bottom">
                    <div class="save" @click="saveParticle">保存</div>
                </div>
            </div>
            <div class="right">
                <div class="tabTitle">
                    <a :title="item.title" v-for="(item, index) in tabList" :key="index">
                        <div class="ImgList" @click="propertyChange(index)" :class="{ active: currentType == index }">
                            <img v-if="currentType == index" :src="item.onImg" alt="">
                            <img v-else :src="item.img" alt="">
                        </div>
                    </a>
                </div>
                <div class="tabContent">
                    <div class="EmissionPage">
                        <EmissionPage v-show="currentType == 0" />
                        <ConverterPage v-show="currentType == 1" />
                        <BehaviorPage v-show="currentType == 2" />
                        <EmitterPage v-show="currentType == 3" />
                    </div>
                </div>
            </div>
        </div>
        <div class="close" @click="closeChange">
            <img src="../../assets//Project/close.png" alt />
        </div>
    </div>
</template>
<script>
import emission from "../../assets/Particle/emission.png";
import onEmission from "../../assets/Particle/onEmission.png";
import converter from "../../assets/Particle/converter.png";
import onConverter from "../../assets/Particle/onConverter.png";
import behavior from "../../assets/Particle/behavior.png";
import onBehavior from "../../assets/Particle/onBehavior.png";
import emitter from "../../assets/Particle/emitter.png";
import onEmitter from "../../assets/Particle/onEmitter.png";
import EmissionPage from "./Emission.vue";
import ConverterPage from "./Converter.vue";
import BehaviorPage from "./Behavior.vue";
import EmitterPage from "./Emitter.vue";


import { PreviewResouse } from "../../threejs/common/PreviewResouse";
import { MainObjects } from "../../threejs/common/MainObjects";
import { INetwork } from "../../threejs/interface/INetwork";
import { ISetting } from "../../threejs/interface/ISetting";
import { IResousePreview } from "../../threejs/interface/IResousePreview";

export default {
    data() {
        return {
            currentType: 0,
            tabList: [
                { img: emission, onImg: onEmission, title: "发射" },
                { img: converter, onImg: onConverter, title: "粒子转化器" },
                { img: behavior, onImg: onBehavior, title: "粒子行为" },
                { img: emitter, onImg: onEmitter, title: "发射器行为" },
            ],
            //粒子图层属性
            BodySprite: false,
            Life: false,
            Radius: false,
            Mass: false,
            RadialVelocity: false,
            Color: false,
            Alpha: false,
            Scale: false,
            Spring: false,
            Force: false,
            RandomDrift: false,
            Rotate: false,
        }
    },
    mounted() {
        //显示3d
        var option = {};
        option.container = document.getElementById("preview-container");

        if (MainObjects.MainRender.m_previewResouse == null) {
            MainObjects.MainRender.m_previewResouse = new PreviewResouse(option);

        } else {
            MainObjects.MainRender.m_previewResouse.addRenderDom(option);
        }
        this.uploading();
    },
    methods: {

        //回显
        uploading() {
            INetwork.downloadJson(this.$store.state.partiClecompileData.fileUrl, data => {
                var json = data;
                IResousePreview.loadMaterial(json, () => { });
            });

        },
        closeChange() {
            // this.$parent.titleChange();
            
            this.$store.commit("changePartiClecompile", false)
        },
        saveParticle() {
            this.$store.commit("changePartiClecompile", false)
            this.updateFile()

        },
        //保存粒子
        //调用后台接口 更改文件
        async updateFile() {
            var fileId = this.$store.state.partiClecompileData.id;
            var data = INetwork.getMaterialJsonToSql(fileId, () => {});

            console.log('data', data);.00

            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });

            var time = new Date().getTime();

            const res = await this.$https.updateFile({

                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                id: fileId,
                // name: name,
                fileUrl: data.mainName,
                imageUrl: data.imageName + "?" + "time=" + time,
                deputyUrl: data.otherNameList.toString(),
                fileSize: this.totalSize
            });

            if (res.errCode == null) {
                // IResousePreview.close();
                loading.close();
                // this.fileName = "";
                // this.$store.commit("changeNweParticleShow", false)

              

    
                this.$parent.$refs.Resource.getSubCatalog(this.$store.state.resourceNode.id)
            } else {
                loading.close();
            }
        },
        SceneChange() {

        },
        BodySpriteSwitch(){

        },
        lifeSwitch(){
            // console.log(this.Life)
        },
        RadiusSwitch(){

        },
        MassSwitch(){

        },
        RadialVelocitySwitch(){

        },
        ColorSwitch(){

        },
        AlphaSwitch(){

        },
        ScaleSwitch(){

        },
        SpringSwitch(){

        },
        ForceSwitch(){

        },
        RandomDriftSwitch(){

        },
        RotateSwitch(){

        },
        propertyChange(index) {
            this.currentType = index;
        },
    },
    components: {
        EmissionPage,
        ConverterPage,
        BehaviorPage,
        EmitterPage
    }
}
</script>
<style scoped>
.box {
    width: 1400px;
    height: 650px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
}

.header {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
}

.content {
    width: 100%;
    height: 609px;
    display: flex;
    justify-content: space-around;
}

.content .left {
    width: 15%;
    height: 100%;
    border-right: 1px solid #eee;
}

.content .main {
    width: 60%;
    height: 100%;
    border-right: 1px solid #eee;
}

.content .container {
    width: 100%;
    height: 559px;
}

.content .bottom {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content .save {
    width: 140px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background: #0560fd;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.content .right {
    width: 25%;
    height: 100%;
}

.close {
    position: absolute;
    top: 13px;
    right: 15px;
    cursor: pointer;
}

.close img {
    width: 13px;
    height: 13px;
}

.firstHalf {
    display: flex;
    width: 100%;
    height: 30px;
    margin: 8px auto;
    align-items: center;
    /* color: #000; */
    font-weight: bold;
    font-size: 12px;
    margin-top: 18px;
    justify-content: space-evenly;
}

.firstHalf p {
    width: 100px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}

a {
    text-decoration: none;
}

.right .tabTitle {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: rgba(247, 247, 247, 1);
}

.right .tabTitle .ImgList {
    width: 60px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.right .tabTitle .ImgList img {
    width: 15px;
    height: 15px;
}

.active {
    background: #fff;
}

.right .tabContent {
    width: 100%;
    height: calc(100% - 40px);
}

.tabContent .EmissionPage {
    width: 100%;
    height: 100%;
}
</style>
