import * as THREE from 'three';
import System, {
    SpriteRenderer
} from 'three-nebula';
import { Language } from '../common/Language';
import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';
import { GameScene } from '../object/GameScene';

class ParticleFactory {
    static createParticleSystem(json, callback)
    {
        var object = new THREE.Object3D();
        object.name = object.uuid;
        object.userData = new GameObject();
        object.userData.m_gameObjectType = GameObject.GameObjectType.e_PARTICLE_SYS;
        object.userData.m_name = Language.LOCAL_TEXT("Particle System", "粒子");

        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "particle_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
       
        System.fromJSONAsync(json.particleSystemState, THREE).then(loaded => {
            const renderer = new SpriteRenderer(object, THREE);
            object.userData.m_particleSystem = loaded.addRenderer(renderer);
            object.add(cube);
            callback(object);
        });
    }

    static destory(object)
    {
        if(object.userData.m_particleSystem)
        {
            object.userData.m_particleSystem.destroy();
        }
    }

    static update(object)
    {
        if(object.userData.m_particleSystem)
        {
            object.userData.m_particleSystem.update();
            object.updateMatrixWorld(true, true);
        }
    }
}

export { ParticleFactory };