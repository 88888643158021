import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { GeometryFactory } from '../factory/GeometryFactory';

class CurveEditorBp
{
    constructor()
    {
        this.m_point = new THREE.Vector3();
    }

    initCurve(object)
    {
        const spline = object.userData.m_curve;
        const position = object.geometry.attributes.position;

        for ( let i = 0; i < object.userData.m_arcSegments; i ++ ) {

            const t = i / ( object.userData.m_arcSegments - 1 );
            spline.getPoint( t, this.m_point );
            position.setXYZ( i, this.m_point.x, this.m_point.y, this.m_point.z );

        }
        position.needsUpdate = true;
    }

    updateCurve(object)
    {
        this.initCurve(object);
    }

    addCurve(object)
    {
        var sp = GeometryFactory.createCurvePoint(object.userData.m_positionList[0])
        object.userData.m_positionList.push( sp.position );
        object.userData.m_gltf_meshList.push(sp);
        object.attach(sp);
        sp.userData.m_parent = object;
        this.updateCurve(object);
        MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(sp);
    }

    removeCurve(object)
    {
        if ( object.userData.m_gltf_meshList.length <= 4 ) {
            return;
        }
        object.userData.m_positionList.pop();
        var sp = object.userData.m_gltf_meshList.pop();
        object.remove(sp);
        sp.material.dispose();
        this.updateCurve(object)
        MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.removeAllSelectObjects(sp);
    }
}

export { CurveEditorBp };