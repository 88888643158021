import * as THREE from 'three';
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js"

class DymaticBehavior {
    constructor() {
        this.m_pos_start = [0, 0, 0];             //移动
        this.m_pos_end = [0, 0, 0];               //移动End
        this.m_rot_start = [0, 0, 0];             //旋转
        this.m_rot_end = [0, 0, 0];               //旋转End
        this.m_scale_start = [1, 1, 1];           //缩放
        this.m_scale_end = [1, 1, 1];             //缩放End
        this.m_time = 1000;                       //速度
        this.m_tween = null;
        this.m_yoyo = false;
    }

    getJson()
    {
        var data = {
            m_pos_start: this.m_pos_start,
            m_pos_end: this.m_pos_end,
            m_rot_start: this.m_rot_start,
            m_rot_end: this.m_rot_end,
            m_scale_start: this.m_scale_start,
            m_scale_end: this.m_scale_end,
            m_time: this.m_time,
            m_yoyo: this.m_yoyo
        }
        return data;
    }

    setJson(json)
    {
        this.m_pos_start = json.m_pos_start;
        this.m_pos_end = json.m_pos_end;

        this.m_rot_start = json.m_rot_start;
        this.m_rot_end = json.m_rot_end;

        this.m_scale_start = json.m_scale_start;
        this.m_scale_end = json.m_scale_end;

        this.m_time = json.m_time;
        this.m_yoyo = json.m_yoyo;
    }

    init(object){
        this.destory();
        var coords = {
            px: this.m_pos_start[0],
            py: this.m_pos_start[1],
            pz: this.m_pos_start[2],
            rx: this.m_rot_start[0],
            ry: this.m_rot_start[1],
            rz: this.m_rot_start[2],
            sx: this.m_scale_start[0],
            sy: this.m_scale_start[1],
            sz: this.m_scale_start[2]
        }
        this.m_tween = new TWEEN.Tween(coords);
        this.m_tween.yoyo(this.m_yoyo);
        this.m_tween.repeat(Infinity);
        this.m_tween.to({
            px: this.m_pos_end[0],
            py: this.m_pos_end[1],
            pz: this.m_pos_end[2],
            rx: this.m_rot_end[0],
            ry: this.m_rot_end[1],
            rz: this.m_rot_end[2],
            sx: this.m_scale_end[0],
            sy: this.m_scale_end[1],
            sz: this.m_scale_end[2]
        }, this.m_time);
        this.m_tween.onUpdate(() => {
            object.position.set(coords.px, coords.py, coords.pz);
            object.rotation.set(coords.rx, coords.ry, coords.rz);
            object.scale.set(coords.sx, coords.sy, coords.sz);
            object.updateMatrixWorld(true);
        })
        this.m_tween.start();
    }

    destory()
    {
        if(this.m_tween !=  null){
            this.m_tween.stop();
        }
    }
}

export { DymaticBehavior };