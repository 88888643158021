import axios from 'axios';
import QS from 'qs';
import router from '../router'
import { Message } from 'element-ui';
import Vue from 'vue'

Vue.prototype.$message = Message;



var service = axios.create({
    //测试


    baseURL: "https://metaverseapitest.yicreator.com/",
    //正式
    // baseURL: "https://metaverseapi.yicreator.com/",
    timeout: 100000
})
var service2 = axios.create({
    baseURL: "http://metaverseapitest.eyearts.cn/",
    timeout: 100000
})
// var service3 = axios.create({//登录专用
//  baseURL: "http://172.17.0.35:18004",
//  timeout: 10000
// })
/*登录*/
var service3 = axios.create({
    baseURL: "",
    headers: { "Authorization": 'Basic Y2xpZW50MTpXb1lpSG9tZTEyMzQ1Ng==' },
    timeout: 100000
})


// post请求头
service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//对外接口
// export function request({ method, url, params }) {
//     if (method == 'Get') {
//         return GET(url, params);
//     } else if (method == 'Post') {
//         return GET(url, params);
//     }
// }

// 封装get方法
export function GET(host, url, params) {
    return new Promise((resolve, reject) => {
        service.get(host + url + '?' + QS.stringify(params)).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    });
}

// 封装get方法
export function GET2(host, url, params) {
    return new Promise((resolve, reject) => {
        service2.get(host + url + '?' + QS.stringify(params)).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    });
}


// 封装post方法//字符串
export function POST(host, url, params) {
    // console.log('请求地址',host + url, QS.stringify(params))
    return new Promise((resolve, reject) => {
        service.post(host + url, QS.stringify(params)).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    });
}

// 封装post方法//JSON
export function POST2(host, url, params) {
    return new Promise((resolve, reject) => {
        service.post(host + url, params).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    });
}

// 封装post登录方法
export function POST3(host, url, params) {
    return new Promise((resolve, reject) => {
        service3.post(host + url, QS.stringify(params)).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        })
    });
}

//请求拦截(请求发出前处理请求)
service.interceptors.request.use(
    // config => {
    //     //将token放入header, 这里用Vuex把token放在store中，取出
    //     // const token = "e2ee95c0f62d8b0e412ed4941b7719b2";
    // const token = localStorage.getItem('token');
    //     // const userId ="7a8d2cbe-bf54-47af-ab9f-4203ae468e31";
    //     const nickName = localStorage.getItem('nickName');
    //     // token && (config.headers.Authorization = 'bearer' + ' ' + token);
    //     token && (config.headers.Authorization = token);

    //     // userId && (config.headers.userId = userId);
    //     //登录判断
    //     return config;
    // },



    // error => {
    //     return Promise.error(error);
    // }

    function (config) {
        undefined
        const token = localStorage.getItem('token');


        //设置 token
        config.headers.token = token
        return config
    }



)
// 响应拦截器（处理响应数据）
service.interceptors.response.use(response => {
    if (response.status == 409) {


        root.$message({
            message: e.message,
            type: 'error'
        })



        localStorage.clear();

        router.replace({
            path: '/OfficialWebsite/HomePage/HomePageContent',
        });



        location.reload();

        // console.error('其他用户在登录该账号，您已被退出登录！');





    } else if (response.status == 200) {
        if (response.data.errCode == '411') {
            router.replace({
                path: '/OfficialWebsite/HomePage/HomePageContent',
            });


        } else if (response.data.errCode == '409') {
            // this.$message({
            //     message:    "其他用户在登录该账号，您已被退出登录!"
            //     // type: "warning"
            //   });


            localStorage.clear();

            router.replace({
                path: '/OfficialWebsite/HomePage/HomePageContent',
            }, () => {


            }, () => {



            });


            // console.error('其他用户在登录该账号，您已被退出登录！');

        }
        return Promise.resolve(response);

    } else {
        //这个地方可以由后台编辑状态码区分不同情况，做不同的逻辑处理
        console.error('请求失败,请检查网络!')
        return Promise.reject(response);
    }
},
    error => {
        // //请求失败，这个地方可以根据error.response.status统一处理一些界面逻辑，比如status为401未登录,可以进行重定向
        // router.replace({
        //     path: '/login',
        //     query: { redirect: router.currentRoute.fullPath }
        //    });
        return Promise.reject(error.response);

    }
)
//请求拦截(请求发出前处理请求)
service2.interceptors.request.use(
    // config => {

    //     //将token放入header, 这里用Vuex把token放在store中，取出
    //     // const token = "e2ee95c0f62d8b0e412ed4941b7719b2";
    //     const token = localStorage.getItem('token');
    //     // const userId ="7a8d2cbe-bf54-47af-ab9f-4203ae468e31";
    //     // const userId = localStorage.getItem('userId');
    //     // token && (config.headers.token = token);
    //     token && (config.headers.Authorization = 'bearer' + ' ' + token);
    //     // userId && (config.headers.userId = userId);
    //     return config;
    // },
    // error => {
    //     return Promise.error(error);
    // }

    function (config) {
        undefined
        const token = localStorage.getItem('token');


        //设置 token
        config.headers.token = token
        return config
    }



)

// 响应拦截器（处理响应数据）
service2.interceptors.response.use(response => {
    if (response.status == 409) {


        root.$message({
            message: e.message,
            type: 'error'
        })
        localStorage.clear()

        router.replace({
            path: '/OfficialWebsite/HomePage/HomePageContent',
        });

        location.reload();

        // console.error('token失效,请重新登录!');   
    } else if (response.status == 200) {

        if (response.data.errCode == '411') {
            router.replace({
                path: '/OfficialWebsite/HomePage/HomePageContent',
            });


        } else if (response.data.errCode == '409') {
            localStorage.clear();

            router.replace({
                path: '/OfficialWebsite/HomePage/HomePageContent',

            });


            // 状态保持清除后刷新页面
            window.location.reload()
        }
        return Promise.resolve(response);
    } else {
        //这个地方可以由后台编辑状态码区分不同情况，做不同的逻辑处理
        console.error('请求失败,请检查网络!');
        return Promise.reject(response);
    }
}, error => {
    // //请求失败，这个地方可以根据error.response.status统一处理一些界面逻辑，比如status为401未登录,可以进行重定向
    // router.replace({
    //     path: '/login',
    //     query: { redirect: router.currentRoute.fullPath }
    //    });
    return Promise.reject(error.response);

})


//请求拦截(请求发出前处理请求)
service3.interceptors.request.use(
    config => {
        /*//将token放入header, 这里用Vuex把token放在store中，取出
         // const token = "e2ee95c0f62d8b0e412ed4941b7719b2";
         const token = localStorage.getItem('token');
         // const userId ="7a8d2cbe-bf54-47af-ab9f-4203ae468e31";
         const nickName = localStorage.getItem('nickName');
         token && (config.headers.Authorization = 'bearer' + ' ' + token);*/
        // userId && (config.headers.userId = userId);
        //登录判断
        return config;
    },
    error => {
        return Promise.error(error);
    }
)

// 响应拦截器（处理响应数据）
service3.interceptors.response.use(
    response => {
        if (response.status == 200) {
            return Promise.resolve(response);
        } else {
            //这个地方可以由后台编辑状态码区分不同情况，做不同的逻辑处理
            console.error('请求失败,请检查网络!')
            return Promise.reject(response);
        }
    },
    error => {
        // //请求失败，这个地方可以根据error.response.status统一处理一些界面逻辑，比如status为401未登录,可以进行重定向
        // router.replace({
        //     path: '/login',
        //     query: { redirect: router.currentRoute.fullPath }
        //    });
        return Promise.reject(error.response);

    }
)

// 方法暴露
export default {
    POST,
    POST2,
    POST3,
    GET,
    GET2
}
