import * as THREE from 'three';
import { MainObjects } from '../common/MainObjects';
import { TextureObject } from "./TextureObject"

class MaterialObject {
    static MaterialType =
        {
            e_BASIC: 0,
            e_STANDARD: 1,
            e_PHONE: 2
        }

    static MaterialEditorType =
        {
            e_DEFLUT: 0,
            e_NET: 1,
        }

    static MaterialMatType =
        {
            e_DEFLUT: 0, //默认
            e_Glass: 1,  //玻璃
            e_Rough_Glass: 2, //磨砂玻璃
            e_Mirror: 3,//镜子
            e_Velvet: 4,//绒布
            e_Cloth: 5//布
        }
    //玻璃
    static MaterialGlass =
        {
            m_interiorior: 1.5,//折射率内
            m_exteriorior: 1,  //折射率外
            m_cauchyb: 0       //色散
        }
    //磨砂玻璃
    static MaterialRoughGlass =
        {
            m_interiorior: 1.5,//折射率内
            m_exteriorior: 1,  //折射率外
        }
    //镜子（无参数）
    static MaterialMirror =
        {
        }
    //绒布
    static MaterialVelvet =
        {
            m_thickness: 0.1 //绒毛高度
        }
    //布
    static MaterialCloth =
        {
            m_preset: "denim", // denim, silk_charmeuse, silk_shantung, cotton_twill, wool_garbardine, polyester_lining_cloth
            m_weft_kd: 0.5,        
            m_weft_ks: 0.5,    
            m_warp_kd: 0.5,
            m_warp_ks: 0.5,
            m_repeat_u: 100000,
            m_repeat_v: 100000,
        }
    //option = {
    //   mat : null,
    //   type : null,
    //   ed_type : null 
    //   url : null,
    //   name : null,
    //   id : null
    //}
    constructor(option) {
        this.m_materialMat = {};
        this.m_materialMatType = MaterialObject.MaterialMatType.e_DEFLUT;
        this.m_materialObjectType = option.type;
        this.m_materialEditorType = option.ed_type;
        this.m_url = option.url;
        this.m_name = option.name;
        this.m_id = option.id;
        if (option.mat == null) {
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            if (this.m_materialObjectType == MaterialObject.MaterialType.e_STANDARD) {
                if (this.m_url != undefined) {
                    var matCopy = gameScene.m_matRepos.get(this.m_url);
                    if (matCopy == undefined || matCopy == null) {
                        this.m_material = new THREE.MeshStandardMaterial();
                        gameScene.m_matRepos.add(this.m_url, this.m_material);
                    }
                    else {
                        this.m_material = matCopy;
                    }
                }
            }
            else if (this.m_materialObjectType == MaterialObject.MaterialType.e_BASIC) {
                if (this.m_url != undefined) {
                    var matCopy = gameScene.m_matRepos.get(this.m_url);
                    if (matCopy == undefined || matCopy == null) {
                        this.m_material = new THREE.MeshBasicMaterial();
                        gameScene.m_matRepos.add(this.m_url, this.m_material);
                    }
                    else {
                        this.m_material = matCopy;
                    }
                }

            }
            else if (this.m_materialObjectType == MaterialObject.MaterialType.e_PHONE) {
                if (this.m_url != undefined) {
                    var matCopy = gameScene.m_matRepos.get(this.m_url);
                    if (matCopy == undefined || matCopy == null) {
                        this.m_material = new THREE.MeshPhongMaterial();
                        gameScene.m_matRepos.add(this.m_url, this.m_material);
                    }
                    else {
                        this.m_material = matCopy;
                    }
                }
            }
        }
        else {
            this.m_material = option.mat;
        }
        this.m_material.userData = this;

    }

    getMaterial() {
        return this.m_material;
    }

    setDefultTextureUserData() {
        if (this.m_material.aoMap != null) {
            this.m_material.aoMap.userData = new TextureObject();
        }
        if (this.m_material.lightMap != null) {
            this.m_material.lightMap.userData = new TextureObject();
        }
        if (this.m_material.emissiveMap != null) {
            this.m_material.emissiveMap.userData = new TextureObject();
        }

        if (this.m_material.metalnessMap != null) {
            this.m_material.metalnessMap.userData = new TextureObject();
        }

        if (this.m_material.normalMap != null) {
            this.m_material.normalMap.userData = new TextureObject();
        }

        if (this.m_material.roughnessMap != null) {
            this.m_material.roughnessMap.userData = new TextureObject();
        }

        if (this.m_material.envMap != null) {
            this.m_material.envMap.userData = new TextureObject();
        }
        if (this.m_material.map != null) {
            this.m_material.map.userData = new TextureObject();
            this.m_material.map.userData.m_gamma = 2.2;
        }
        if (this.m_material.specularMap != null) {
            this.m_material.specularMap.userData = new TextureObject();
        }
    }

    setMaterialMat(data) {
        if (data) {
            if (this.m_materialMatType == MaterialObject.MaterialMatType.e_Glass) {
                this.m_materialMat.m_interiorior = data.m_interiorior;
                this.m_materialMat.m_exteriorior = data.m_exteriorior;
                this.m_materialMat.m_cauchyb = data.m_cauchyb;
            }
            else if(this.m_materialMatType == MaterialObject.MaterialMatType.e_Rough_Glass)
            {
                this.m_materialMat.m_interiorior = data.m_interiorior;
                this.m_materialMat.m_exteriorior = data.m_exteriorior;
            }
            else if(this.m_materialMatType == MaterialObject.MaterialMatType.e_Mirror)
            {
                ;
            }
            else if(this.m_materialMatType == MaterialObject.MaterialMatType.e_Velvet)
            {
                this.m_materialMat.m_thickness = data.m_thickness;
            }
            else if(this.m_materialEditorType == MaterialObject.MaterialMatType.e_Cloth)
            {
                this.m_materialMat.m_preset  = data.m_preset;
                this.m_materialMat.m_weft_kd = data.m_weft_kd;     
                this.m_materialMat.m_weft_ks = data.m_weft_ks;
                this.m_materialMat.m_warp_kd = data.m_warp_kd;
                this.m_materialMat.m_warp_ks = data.m_warp_ks;
                this.m_materialMat.m_repeat_u= data.m_repeat_u;
                this.m_materialMat.m_repeat_v= data.m_repeat_v;
            }
        }
    }

    setMaterialMatType(type) {
        this.m_materialMatType = type;
        if (this.m_materialMatType == MaterialObject.MaterialMatType.e_Glass) {
            this.m_materialMat = MaterialObject.MaterialGlass;
        }
        else if(this.m_materialMatType == MaterialObject.MaterialMatType.e_Rough_Glass)
        {
            this.m_materialMat = MaterialObject.MaterialRoughGlass;
        }
        else if(this.m_materialMatType == MaterialObject.MaterialMatType.e_Mirror)
        {
            this.m_materialMat = MaterialObject.MaterialMirror;
        }
        else if(this.m_materialMatType == MaterialObject.MaterialMatType.e_Velvet)
        {
            this.m_materialMat = MaterialObject.MaterialVelvet;
        }
        else if(this.m_materialMatType == MaterialObject.MaterialMatType.e_Cloth)
        {
            this.m_materialMat = MaterialObject.MaterialCloth;
        }
    }

    getMaterialMat() {
        return this.m_materialMat;
    }
}

export { MaterialObject };