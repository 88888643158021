import { MainObjects } from "../common/MainObjects"
import { GameObject } from "../object/GameObject"
import { AnimationBehavior } from "../behavior/AnimationBehavior"

class IAnimation {

    static m_animationBehavior = null;

    static getSelectAnimation() {
        var animation = {
            bHelper: false,
            type: GameObject.GameObjectType.e_DEFULT,
            bPlay: false,
            currentAnimation: "",
            timeScale : 1,
            animationList: new Array(),
            skeletonList: new Array(),
            currentSkeleton: "",
        }
        const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
        if (firstObj != null && firstObj.userData.m_animationMixer != null) {
            animation.bHelper = firstObj.userData.m_gameObjectHelper.visible;
            animation.type = firstObj.userData.m_gameObjectType;
            animation.bPlay = firstObj.userData.m_bPlay;
            animation.currentAnimation = firstObj.userData.m_currentAnimationName;
            animation.animationList = MainObjects.Blueprint.m_animationManagerBp.getAnimationList(firstObj);
            animation.timeScale = firstObj.userData.m_animationMixer.timeScale;
        
            var tempcurrentSkeleton = MainObjects.Scene.m_userGroup.getObjectByName(firstObj.userData.m_skeleton_name);
            if(tempcurrentSkeleton != null)
            {
                animation.currentSkeleton = tempcurrentSkeleton.userData.m_name;
            }
        }
        else
        {
            return null;
        }
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
            if (obj.userData.m_animationMixer != null) {
                animation.bHelper = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(animation.bHelper, obj.userData.m_gameObjectHelper.visible);
                animation.bPlay = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(animation.bPlay, obj.userData.m_bPlay);
                animation.currentAnimation = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(animation.currentAnimation, obj.userData.m_currentAnimationName);
                var animationList = MainObjects.Blueprint.m_animationManagerBp.getAnimationList(obj);
                animation.animationList = MainObjects.Blueprint.m_selectorPropertyBp.getSameArrayValue(transform.bvisible, animationList);
                animation.timeScale = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(animation.timeScale, obj.userData.m_animationMixer.timeScale);

                var tempcurrent = MainObjects.Scene.m_userGroup.getObjectByName(obj.userData.m_skeleton_name);
                if(tempcurrent != null)
                {
                    var tempcurrentSkeleton = tempcurrent.userData.m_name;
                    animation.currentSkeleton = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(animation.currentSkeleton, tempcurrentSkeleton);
                }
             
            }
        });

        MainObjects.Blueprint.m_animationManagerBp.m_skeletonList = [];
        MainObjects.Blueprint.m_animationManagerBp.getSkinMeshList(MainObjects.Scene.m_userGroup);
        for (let index = 0; index < MainObjects.Blueprint.m_animationManagerBp.m_skeletonList.length; index++) {
            const element = MainObjects.Blueprint.m_animationManagerBp.m_skeletonList[index];
            var data = {
                id : element.id,
                key : element.name,
                name : element.userData.m_name
            }
            animation.skeletonList.push(data);
        }
        return animation;
    }

    static getSelectSkeleton()
    {
        var animation = {
            type: GameObject.GameObjectType.e_DEFULT,
            skeletonList: new Array(),
            currentSkeleton: "",
        }
        const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
        if (firstObj != null && firstObj.userData.m_skeleton != null) {
            animation.type = firstObj.userData.m_gameObjectType;
            var object = MainObjects.Scene.m_userGroup.getObjectByName(firstObj.userData.m_skeleton_name);
            if(object != null)
            {
                animation.currentSkeleton = object.userData.m_name;
            }
            else
            {
                //
            }
        }
        else
        {
            return null;
        }
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
            if (obj.userData.m_skeleton != null) {
                var tempcurrent = MainObjects.Scene.m_userGroup.getObjectByName(obj.userData.m_skeleton_name)
                var tempcurrentSkeleton = tempcurrent.userData.m_name;
                animation.currentSkeleton = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(animation.currentSkeleton, tempcurrentSkeleton);
            }
        });

        MainObjects.Blueprint.m_animationManagerBp.m_skeletonList = [];
        MainObjects.Blueprint.m_animationManagerBp.getSkinMeshList(MainObjects.Scene.m_userGroup);
        for (let index = 0; index < MainObjects.Blueprint.m_animationManagerBp.m_skeletonList.length; index++) {
            const element = MainObjects.Blueprint.m_animationManagerBp.m_skeletonList[index];
            var data = {
                id : element.id,
                key : element.name,
                name : element.userData.m_name
            }
            animation.skeletonList.push(data);
        }
        return animation;
    }

    static getSelectMorph(){
        var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if(length == 1)
        {
            var animation = {
                type: GameObject.GameObjectType.e_DEFULT,
                morphNameValueList: new Array(),
            }
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            if (firstObj != null) {
                animation.type = firstObj.userData.m_gameObjectType;
                if(firstObj.isMesh)
                {
                    if(firstObj.morphTargetInfluences == undefined)
                    {
                        return null;
                    }
                    else
                    {
                        const expressions = Object.keys(firstObj.morphTargetDictionary );
                        for ( let i = 0; i < expressions.length; i ++ ) {
    
                            var morph = {
                                name : expressions[ i ],
                                value: firstObj.morphTargetInfluences[i] 
                            }
                            animation.morphNameValueList.push(morph);
                        }
                        return animation;
                    }
                }
            }
            else
            {
                return null;
            }
        }
        else 
        {
            return null;
        }
    }

    static setSelectMorph(name, value){
        const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
        if (firstObj != null) {
            if(firstObj.isMesh)
            {
                const expressions = Object.keys(firstObj.morphTargetDictionary);
                var index = -1;
                for ( let i = 0; i < expressions.length; i ++ ) {
                    if(expressions[ i ] == name)
                    {
                        index = i;
                        break;
                    }
                }
                firstObj.morphTargetInfluences[index] = value;
            }
        }
    }

    static setHelper(b) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.userData.m_gameObjectHelper.visible = b;
        });
    }

    static setPlay(b) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if (b) {
                MainObjects.Blueprint.m_animationManagerBp.play(obj);
            }
            else {
                MainObjects.Blueprint.m_animationManagerBp.stop(obj);
            }
        });
    }

    static setTimeScale(timeScale) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_animationManagerBp.modifyTimeScale(obj, timeScale)
        });
    }

    static setCurrentAnimation(name) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_animationManagerBp.changeAnimation(obj, name);
        });
    }

    static setCurrentSkeleton(name) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_animationManagerBp.setSkeleton(obj, name);
        });
        
    }

    static createAnimationBehavior()
    {
        if(!IAnimation.m_animationBehavior)
        {
            IAnimation.m_animationBehavior = new AnimationBehavior();
        }
    }
    
    static setAnimationBehaviorJson(json)
    {
        // array
        // var json = [{
        //     id : 
        //     actionStart : 
        //     actionEnd : 
        //     bLoop : 
        //     bEnd : 
        //     time : 
        // }]

        IAnimation.m_animationBehavior.setJson(json);
        
    }

    static getAnimationBehaviorJson()
    {
        return IAnimation.m_animationBehavior.getJson();
    }
}

export { IAnimation };