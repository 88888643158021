import * as THREE from "three"
import { MainObjects } from "../common/MainObjects";
import * as T from '../object/Terrain';
import tgrass1 from "../../assets/ThreeTexture/terrain/grass1.jpg"
import tsand1 from "../../assets/ThreeTexture/terrain/sand1.jpg"
import heightmap from "../../assets/ThreeTexture/terrain/heightmap.png"
import { GameObject } from "../object/GameObject";
import { GeometryFactory } from "../factory/GeometryFactory";
import { SceneFactory } from "../factory/SceneFactory";
import { Loader } from "../loader/Loader";
import { INetwork } from "../interface/INetwork";
import { IScene } from "../interface/IScene";
import { ObjectFactory } from "../factory/ObjectFactory";
import { ITransform } from "../interface/ITransform";
import { ISelector } from "../interface/ISelector";

class TerrainBp {
    constructor() {

    }

    setProperty(obj, prop) {
        if (obj.userData.m_gameObjectType == GameObject.GameObjectType.e_TERRAIN_SYS) {
            var pos = new THREE.Vector3();
            var rot = new THREE.Euler();
            var scale = new THREE.Vector3();

            pos.copy(obj.position);
            rot.copy(obj.rotation);
            scale.copy(obj.scale);

            var heightmapLoad = true;
            var texturesLoad = true;
            var grassmapLoad = true;

            if(!(prop.heightmap instanceof Image))
            {
                prop.heightmap = T.THREE.Terrain.DiamondSquare;
                heightmapLoad = false;
            }

            if (prop.textures.length == 0) {
                var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
                var tgrass1Texture = gameScene.m_textureRepos.get(tgrass1);
                prop.textures = [
                    // The first texture is the base; other textures are blended in on top.
                    { texture: tgrass1Texture }
                ];
                texturesLoad = false;
            }

            if(!(prop.grassmap instanceof Image))
            {
                grassmapLoad = false;
            }

            const object = GeometryFactory.createTerrain(prop);

            if(heightmapLoad)
            {
                object.userData.m_terrain_data.m_heightmap_id = obj.userData.m_terrain_data.m_heightmap_id;
                object.userData.m_terrain_data.m_heightmap_name = obj.userData.m_terrain_data.m_heightmap_name;
                object.userData.m_terrain_data.m_heightmap_image = obj.userData.m_terrain_data.m_heightmap_image;
            }

            if(texturesLoad)
            {
                object.userData.m_terrain_data.m_texture_list = obj.userData.m_terrain_data.m_texture_list;
            }

            if(grassmapLoad)
            {
                object.userData.m_terrain_data.m_grassmap_id = obj.userData.m_terrain_data.m_grassmap_id;
                object.userData.m_terrain_data.m_grassmap_name = obj.userData.m_terrain_data.m_grassmap_name;
                object.userData.m_terrain_data.m_grassmap_image = obj.userData.m_terrain_data.m_grassmap_image;
            }

            object.userData.m_terrain_data.m_grass_num =  obj.userData.m_terrain_data.m_grass_num;
            object.userData.m_terrain_data.m_grass_height = obj.userData.m_terrain_data.m_grass_height;

          
            object.position.copy(pos);
            object.rotation.copy(rot);
            object.scale.copy(scale);
            object.updateMatrixWorld(true, true);
            ObjectFactory.deleteObject(obj);
            SceneFactory.addToUserGroup(object);
            ISelector.selectObjectByTree(object, false);
            if (IScene.f_sceneTreeCallback != null) {
                IScene.f_sceneTreeCallback();
            }

            return object;
        }
    }

    setTexture(name, id, type, subTexIndex, processCallback, finishCallback) {
        var url = INetwork.getUrl(name);
        var _this = this;

        if (type == "height_map") {
            // 初始化一个加载器
            const loader = new THREE.ImageLoader();
            // 加载一个图片资源
            loader.load(
                // 资源URL
                url,

                // onLoad回调
                function (image) {
                    MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
                        var json = obj.userData.m_terrain_data.getData();
                        json.heightmap = image;
                        var object = _this.setProperty(obj, json);
                        object.userData.m_terrain_data.m_heightmap_id = id;
                        object.userData.m_terrain_data.m_heightmap_name = name;
                        object.userData.m_terrain_data.m_heightmap_image = image;
                        processCallback(1);
                        finishCallback();
                    });
                   
                },

                // 目前暂不支持onProgress的回调
                undefined,

                // onError回调
                function () {
                    console.error('An error happened.');
                }
            );
        }
        else if(type == "grass_map")
        {
             // 初始化一个加载器
             const loader = new THREE.ImageLoader();
             // 加载一个图片资源
             loader.load(
                 // 资源URL
                 url,
 
                 // onLoad回调
                 function (image) {
                     MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
                         var json = obj.userData.m_terrain_data.getData();
                         json.grassmap = image;
                         obj.userData.m_terrain_data.m_grassmap_id = id;
                         obj.userData.m_terrain_data.m_grassmap_name = name;
                         obj.userData.m_terrain_data.m_grassmap_image = image;
                         processCallback(1);
                         finishCallback();
                     });
                    
                 },
 
                 // 目前暂不支持onProgress的回调
                 undefined,
 
                 // onError回调
                 function () {
                     console.error('An error happened.');
                 }
             );
        }
        else{
           

            Loader.loadFileToScene(url, name, id, processCallback, function (res) {
                res.flipY = true;
                res.encoding = THREE.sRGBEncoding;
                MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
                    var json = obj.userData.m_terrain_data.getData();
                    if (type == "base_texture") {
                        var array = [];
                        array.push({
                            texture: res
                        });
                        for (let index = 1; index < json.textures.length; index++) {
                            const element = json.textures[index];
                            array.push(element);
                        }
                        json.textures = array;
                        var object = _this.setProperty(obj, json);
                        object.userData.m_terrain_data.m_texture_list = array;
                        console.log(object.userData.m_terrain_data.m_texture_list, "sssssss");
                        processCallback(1);
                        finishCallback();
                    }
                    else if (type == "blend_texture") {
                        var array = [];
                        for (let index = 1; index < json.textures.length; index++) {
                            const element = json.textures[index];
                            if (index == subTexIndex) {
                                var tex = {
                                    texture: res,
                                    levels: element.levels
                                }
                                array.push(tex);
                            }
                            else {
                                array.push(element);
                            }
    
                        }
                        json.textures = array;
                        var object = _this.setProperty(obj, json);
                        object.userData.m_terrain_data.m_texture_list = array;
                        processCallback(1);
                        finishCallback();
                    }
                });
                
            });
        }
    }

    setTerrainTextureLeves(obj, subTexIndex, level) {
        var json = obj.userData.m_terrain_data.getData();
        var array = [];
        for (let index = 1; index < json.textures.length; index++) {
            const element = json.textures[index];
            if (index == subTexIndex) {
                var tex = {
                    texture: element.texture,
                    levels: level
                }
                array.push(tex);
            }
            else {
                array.push(element);
            }
        }
        json.textures = array;
        this.setProperty(obj, json);
    }

    createTerrain(data, processCallback, finishCallback) {
        var manager = new THREE.LoadingManager();
        var map = new Map();
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        var tgrass1Texture = gameScene.m_textureRepos.get(tgrass1);
        var option = {
            textures: [
                // The first texture is the base; other textures are blended in on top.
                { texture: tgrass1Texture },
            ],
            xS: data.m_xSegments,
            yS: data.m_ySegments,
            easing: data.m_easing,
            frequency: data.m_frequency,
            heightmap: T.THREE.Terrain.DiamondSquare,
            maxHeight: data.m_maxHeight,
            minHeight: data.m_minHeight,
            steps: data.m_steps,
            xSize: data.m_xSize,
            ySize: data.m_ySize,
        }

        manager.onProgress = function (url, itemsLoaded, itemsTotal) {
            processCallback(itemsLoaded / itemsTotal);
        };

        manager.onError = function (url) {
            console.log('There was an error loading ' + url);
        };

        manager.onLoad = function () {
            var array = []
            for (let index = 0; index < data.m_texture_list.length; index++) {
                var textureData = data.m_texture_list[index].texture;
                var texture = map.get(textureData.url);
                var t = {};
                if(index == 0)
                {
                    t.texture = texture;
                }
                else
                {
                    t.texture = texture;
                    t.levels = data.m_texture_list[index].levels;
                }
                array.push(t);
            }
            if(array.length > 0)
            {
                option.textures = array;
            }
            const object = GeometryFactory.createTerrain(option);

            if(option.heightmap instanceof Image)
            {
                object.userData.m_terrain_data.m_heightmap_id = data.m_heightmap_id;
                object.userData.m_terrain_data.m_heightmap_name = data.m_heightmap_name;
                object.userData.m_terrain_data.m_heightmap_image = option.heightmap;
            }

            if(data.m_texture_list.length > 0)
            {
                object.userData.m_terrain_data.m_texture_list = array;
            }

            if(data.grassmap)
            {
                object.userData.m_terrain_data.m_grassmap_id = data.m_grassmap_id;
                object.userData.m_terrain_data.m_grassmap_name = data.m_grassmap_name;
                object.userData.m_terrain_data.m_grassmap_image = data.m_grassmap_image;
            }
  
            object.userData.m_terrain_data.m_grass_num =  data.m_grass_num;
            object.userData.m_terrain_data.m_grass_height = data.m_grass_height;

            processCallback(1);
            finishCallback(object);
        }

        if (!data.m_heightmap_name && data.m_texture_list.length == 0) {
            manager.onLoad();
        }
        if(data.m_heightmap_name){
            var url = INetwork.getUrl(data.m_heightmap_name);
            // 初始化一个加载器
            const loader = new THREE.ImageLoader(manager);
            // 加载一个图片资源
            loader.load(
                // 资源URL
                url,
                // onLoad回调
                function (image) {
                    option.heightmap = image;
                },

                // 目前暂不支持onProgress的回调
                undefined,

                // onError回调
                function () {
                    console.error('An error happened.');
                }
            );
        }
        if(data.m_grassmap_name){
            var url = INetwork.getUrl(data.m_grassmap_name);
            // 初始化一个加载器
            const loader = new THREE.ImageLoader(manager);
            // 加载一个图片资源
            loader.load(
                // 资源URL
                url,
                // onLoad回调
                function (image) {
                    option.grassmap = image;
                },

                // 目前暂不支持onProgress的回调
                undefined,

                // onError回调
                function () {
                    console.error('An error happened.');
                }
            );
        }
        if(data.m_texture_list.length > 0)
        {
            for (let index = 0; index < data.m_texture_list.length; index++) {
                var textureData = data.m_texture_list[index].texture;
                var url = INetwork.getUrl(textureData.url);
         
                Loader.loadFileToScene(url, textureData.url, textureData.id, function (p) { }, function (res) {
                    res.flipY = true;
                    res.encoding = THREE.sRGBEncoding;
                    map.set(textureData.url, res);
                }, manager);
            }
        }
    }

    setGrass(obj, prop)
    {
        obj.userData.m_terrain_data.m_grass_num = prop.grass_num;
        obj.userData.m_terrain_data.m_grass_height = prop.grass_height;
    }

}

export { TerrainBp };