import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { ObjectsRepos } from "../factory/ObjectsRepos"
import { ObjectFactory } from '../factory/ObjectFactory';
import { SceneFactory } from "../factory/SceneFactory"
import { ISelector } from "../interface/ISelector"
import { SelectionBox } from '../object/SelectionBox';
import { SelectionHelper } from '../helper/SelectionHelper';
import { ITransform } from "../interface/ITransform"
import { IScene } from '../interface/IScene';
import { GameObject } from '../object/GameObject';
import { IRender } from '../interface/IRender';
import { ICollisions } from '../interface/ICollisions';
import { CollisionsFactory } from '../factory/CollisionsFactory';

class SelectListenerBp {
    static SelectLayer =
        {
            e_GROUP: 0,
            e_CHIRD: 1
        }
    static SelectMode =
        {
            e_S_CLICK: 0,
            e_S_BOX: 1
        }
    // Mouse buttons
    static MouseButtons =
        {
            e_LEFT: 0,
            e_MIDDLE: 1,
            e_RIGHT: 2
        };
    constructor() {
        this.m_selectLayer = SelectListenerBp.SelectLayer.e_GROUP;
        this.m_selectMode = SelectListenerBp.SelectMode.e_S_CLICK;
        this.m_selectMap = new ObjectsRepos();
        this.m_currentGroup = new THREE.Group();
        this.m_currentGroupVector = new THREE.Vector3();
        SceneFactory.addToHelperGroup(this.m_currentGroup);
        //add base control even
        MainObjects.Control.m_transformControl.addEventListener('dragging-changed', this.draggingChanged.bind(this));
        MainObjects.Control.m_transformControl.addEventListener('objectChange', this.objectChange.bind(this));
        //ray cast
        this.m_raycaster = new THREE.Raycaster();
        this.m_pointer = new THREE.Vector2();
        this.m_startPointer = new THREE.Vector2();
        this.m_bUpClickMove = false;
        this.m_bMouseDown = false;
        this.m_bDragUp = false;
        //select box
        this.m_selectionBox = new SelectionBox();
        this.m_selectBoxHelper = new SelectionHelper(this.m_selectionBox, MainObjects.Render.m_renderer);
        //set OutlinePass Params
        // this.setOutlinePassParams();
        this.undoTransformData;
    }

    changeSelectMode() {
        if (this.m_selectMode == SelectListenerBp.SelectMode.e_S_CLICK) {
            MainObjects.Control.m_orbitControl.enableRotate = false;
            this.m_selectMode = SelectListenerBp.SelectMode.e_S_BOX;
            if(MainObjects.Camera.m_renderCamera.isPerspectiveCamera)
            {
                this.m_selectBoxHelper.enable = true;
            }
        }
        else {
            MainObjects.Control.m_orbitControl.enableRotate = true;
            this.m_selectMode = SelectListenerBp.SelectMode.e_S_CLICK;
            this.m_selectBoxHelper.enable = false;
        }
    }

    setOutlinePassParams() {
        MainObjects.Render.m_outlinePass.edgeStrength = 3.0;
        MainObjects.Render.m_outlinePass.edgeGlow = 0.0;
        MainObjects.Render.m_outlinePass.edgeThickness = 1.0;
        MainObjects.Render.m_outlinePass.pulsePeriod = 0.0;
        MainObjects.Render.m_outlinePass.downSampleRatio = 1.0;
        MainObjects.Render.m_outlinePass.visibleEdgeColor.set(new THREE.Color(1, 0, 0));
        MainObjects.Render.m_outlinePass.hiddenEdgeColor.set(new THREE.Color(1, 0, 0));
        MainObjects.Render.m_outlinePass.overlayMaterial.blending = THREE.CustomBlending;
    }

    clearSelectObjects() {
        this.m_selectMap.clear();
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        for (let index = 0; index < length; index++) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
            MainObjects.Blueprint.m_scaleBoxBp.setSizeSprite(obj, false);
            ObjectFactory.changeSpriteSelectColor(obj, false);
        }
        if(length == 1)
        {
            MainObjects.Blueprint.m_boundingBoxBp.setMagneticSprite(MainObjects.Render.m_outlinePass.selectedObjects[0], false);
        }
        MainObjects.Blueprint.m_boundingBoxBp.disposeBoundingBox(MainObjects.Render.m_outlinePass.selectedObjects);
        MainObjects.Blueprint.m_scaleBoxBp.setHideLine();
        MainObjects.Control.m_transformControl.detach();
        MainObjects.Render.m_outlinePass.selectedObjects = [];
        if (this.m_selectMode == SelectListenerBp.SelectMode.e_S_BOX) {
            this.m_selectBoxHelper.enable = true;
        }
        if (ITransform.f_selectTransformCallback != null) {
            ITransform.f_selectTransformCallback(false);
        }
        ISelector.sceneTreeSelectCallback();
    }

    setAxis() {
        if (ISelector.m_transformMode == ISelector.TransformMode.e_TRANSLAST
            || ISelector.m_transformMode == ISelector.TransformMode.e_ROTATE) {
            if (MainObjects.Render.m_outlinePass.selectedObjects.length > 0) {
                this.m_currentGroupVector.set(0, 0, 0);
                var count = 0;
                var bAttach = true;
                const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
                for (let index = 0; index < length; index++) {
                    const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                    if (!obj.userData.m_block) {
                        var wp = new THREE.Vector3();
                        obj.getWorldPosition(wp);
                        this.m_currentGroupVector.add(wp);
                        count++;
                    }
                    if(obj.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE 
                        || obj.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY
                        || obj.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY 
                        || obj.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY_DIR_LIGHT)
                    {
                        bAttach = false;
                    }
                }
                if (count != 0 && bAttach) {
                    this.m_currentGroupVector.multiplyScalar(1 / count);
                    this.m_currentGroup.position.copy(this.m_currentGroupVector);
                    if(count == 1)
                    {
                        const obj = MainObjects.Render.m_outlinePass.selectedObjects[0];
                        this.m_currentGroup.rotation.copy(obj.rotation);
                        this.m_currentGroup.scale.copy(obj.scale);
                    }
                    else
                    {
                        this.m_currentGroup.rotation.set(0, 0, 0);
                        this.m_currentGroup.scale.set(1, 1, 1);
                    }
                    this.m_currentGroup.updateMatrixWorld(true);
                    MainObjects.Control.m_transformControl.attach(this.m_currentGroup);
                }
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[0];
                MainObjects.Blueprint.m_scaleBoxBp.setSizeSprite(obj, false);
                MainObjects.Blueprint.m_boundingBoxBp.setMagneticSprite(obj, false);
                MainObjects.Blueprint.m_boundingBoxBp.selectBoundingBoxVisible(MainObjects.Render.m_outlinePass.selectedObjects);
            }
        }
        else if (ISelector.m_transformMode == ISelector.TransformMode.e_STRECH) {
            MainObjects.Control.m_transformControl.detach();
            if (MainObjects.Render.m_outlinePass.selectedObjects.length > 0) {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[0];
                MainObjects.Blueprint.m_boundingBoxBp.setMagneticSprite(obj, false);
                MainObjects.Blueprint.m_scaleBoxBp.setSizeSprite(obj, true);
            }
        }
        else if (ISelector.m_transformMode == ISelector.TransformMode.e_MAGNETIC || ISelector.m_transformMode == ISelector.TransformMode.e_ALIGN) {
            MainObjects.Control.m_transformControl.detach();
            const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
            for (let index = 0; index < length; index++) {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                MainObjects.Blueprint.m_scaleBoxBp.setSizeSprite(obj, false);
            }
            if(length > 0)
            {
                MainObjects.Blueprint.m_boundingBoxBp.setMagneticSprite(MainObjects.Render.m_outlinePass.selectedObjects[0], true);
            }
        }
        if (MainObjects.Render.m_outlinePass.selectedObjects.length > 0) {
            if(MainObjects.Render.m_outlinePass.selectedObjects.length == 1)
            {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[0];
                MainObjects.Blueprint.m_scaleBoxBp.setMagneticLine(obj);
                var breset = false;
                if(obj.userData.m_block)
                {
                    breset = true;
                }
                if(obj.isLight)
                {
                    if(!obj.userData.m_gameObjectHelper.visible)
                    {
                        breset = true;
                    }
                }
                else
                {
                    if(!obj.visible)
                    {
                        breset = true;
                    }
                }
                if(breset)
                {
                    MainObjects.Blueprint.m_boundingBoxBp.close();
                    MainObjects.Blueprint.m_scaleBoxBp.setHideLine();
                    MainObjects.Control.m_transformControl.detach();
                }
            }
            else
            {
                MainObjects.Blueprint.m_scaleBoxBp.setHideLine();
            }
            if (ITransform.f_selectTransformCallback != null) {
                ITransform.f_selectTransformCallback(true);
            }
            ISelector.sceneTreeSelectCallback();
        }
        else
        {
            MainObjects.Blueprint.m_boundingBoxBp.close();
            MainObjects.Blueprint.m_scaleBoxBp.setHideLine();
            MainObjects.Control.m_transformControl.detach();
        }
    }

    objectChange(event) {
        if (MainObjects.Render.m_outlinePass.selectedObjects.length > 0) {
            const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
            //close lay
            for (let index = 0; index < length; index++) {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                obj.traverse(function (child) {
                    if (child.isMesh) {
                        child.layers.set(12);
                    }
                });
            }
            if (event.mode == "translate" && event.axis == 'XYZ') {
                this.m_raycaster.setFromCamera(event.pointer, MainObjects.Camera.m_renderCamera);
                const intersects = this.m_raycaster.intersectObjects(MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_selectAllMeshList);
                if (intersects.length > 0) {
                    this.m_currentGroup.position.copy(intersects[0].point);
                    this.m_currentGroup.updateMatrixWorld(true);
                }
            }
            //open lay
            for (let index = 0; index < length; index++) {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                obj.traverse(function (child) {
                    if (child.isMesh) {
                        child.layers.set(0);
                    }
                });
                if (!obj.userData.m_block) {
                    this.updateObject(obj);
                    MainObjects.Blueprint.m_boundingBoxBp.selectBoundingBoxVisible(MainObjects.Render.m_outlinePass.selectedObjects);
                    if (obj.userData.m_gameObjectType == GameObject.GameObjectType.e_GEOMETRY_CURVE) {
                        obj.position.copy(this.m_currentGroup.position);
                        obj.updateMatrixWorld(true);
                        MainObjects.Blueprint.m_curveEditorBp.updateCurve(obj.userData.m_parent);
                    }
                }
            }
            if (length == 1) {
                MainObjects.Blueprint.m_scaleBoxBp.updateRayCast();
            }

        }
        if (this.m_selectMode == SelectListenerBp.SelectMode.e_S_BOX) {
            this.m_selectBoxHelper.enable = false;
        }
        if (ITransform.f_selectTransformNeedUpdateCallback != null) {
            ITransform.f_selectTransformNeedUpdateCallback();
        }
    }

    updateObject(obj)
    {
        obj.updateMatrixWorld(true);
        if(obj.userData == null)
        {
            return;
        }
        MainObjects.Blueprint.m_collisionsManagerBp.move(obj);
        if(obj.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP)
        {
            for(var i = 0; i < obj.children.length; i++)
            {
                this.updateObject(obj.children[i]);
            }
        }
        else
        {
            if (obj.isLight) {
                obj.userData.m_gameObjectHelper.updateMatrixWorld(true);
                obj.userData.m_gameObjectHelper.update();
                if( obj.userData.m_gameLightCameraHelper != null)
                {
                    var posTemp = new THREE.Vector3(); 
                    obj.getWorldPosition(posTemp);

                    obj.shadow.camera.position.copy(posTemp);
                    if(obj.target != undefined)
                    {
                        obj.shadow.camera.lookAt(obj.target.position);
                    }
                    obj.shadow.camera.updateMatrixWorld(true);
                    obj.userData.m_gameLightCameraHelper.update();
                }
            }
            else if(obj.isCamera)
            {
                obj.userData.m_gameObjectHelper.updateMatrixWorld(true);
                obj.userData.m_gameObjectHelper.update();
            }
            else{
                if(obj.userData.m_gameObjectType != undefined && obj.userData.m_gameObjectType == GameObject.GameObjectType.e_AUDIO)
                {
                    var posTemp = new THREE.Vector3();
                    obj.getWorldPosition(posTemp)
                    obj.userData.m_gameObjectHelper.position.copy(posTemp);
                    obj.userData.m_gameObjectHelper.updateMatrixWorld(true);
                }
            }
        }
    }

    draggingChanged(event) {
        if(ITransform.m_copying)
        {
            return;
        }
        var bRest = false;
        if (this.m_selectMode == SelectListenerBp.SelectMode.e_S_CLICK) {
            MainObjects.Control.m_orbitControl.enabled = !event.value;
            bRest = MainObjects.Control.m_orbitControl.enabled;
        }
        else if (this.m_selectMode == SelectListenerBp.SelectMode.e_S_BOX) {
            this.m_selectBoxHelper.enable = !event.value;
            bRest = this.m_selectBoxHelper.enable;
        }
        if (bRest) {
            if (MainObjects.Blueprint.m_eventListenerBp.m_bShift) {
                MainObjects.Control.m_transformControl.setTranslationSnap(null);
                MainObjects.Control.m_transformControl.setRotationSnap(null);
                MainObjects.Control.m_transformControl.setScaleSnap(null);
            }
            var b = true;
            const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
            for (let index = 0; index < length; index++) {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                b = obj.userData.m_block & b;
                if (!obj.userData.m_block) {
                    if (obj.userData.m_gameObjectType != GameObject.GameObjectType.e_GEOMETRY_CURVE) {
                        if(obj.userData.m_oldParent.id != obj.parent.id)
                        {
                            obj.userData.m_oldParent.attach(obj);
                            obj.userData.m_oldParent.updateMatrixWorld(true);
                        }    
                    }
                }
                //update physic
            }
            if (b) {
                this.setAxis();
            }
            //
            this.m_bDragUp = true;
            ITransform.m_draging = false;
            if (ITransform.f_selectTransformNeedUpdateCallback != null) {
                ITransform.f_selectTransformNeedUpdateCallback();
            }
            this.addUndoRedoTransform(MainObjects.Render.m_outlinePass.selectedObjects, this.undoTransformData, true);
        }
        else {
            if (MainObjects.Blueprint.m_eventListenerBp.m_bShift) {
                MainObjects.Control.m_transformControl.setTranslationSnap(0.1);
                MainObjects.Control.m_transformControl.setRotationSnap(THREE.MathUtils.degToRad(5));
                MainObjects.Control.m_transformControl.setScaleSnap(0.5);
            }
            ITransform.m_draging = true;
            this.undoTransformData = this.addUndoRedoTransform(MainObjects.Render.m_outlinePass.selectedObjects);
            const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
            for (let index = 0; index < length; index++) {
                const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
                if (!obj.userData.m_block) {
                    if (obj.userData.m_gameObjectType != GameObject.GameObjectType.e_GEOMETRY_CURVE) {
                        obj.userData.m_oldParent = obj.parent;
                        if(this.m_currentGroup.id != obj.parent.id)
                        {
                            this.m_currentGroup.attach(obj);
                            this.m_currentGroup.updateMatrixWorld(true);
                        }
                    }
                }
            }
        }
    }

    selectObject(object) {
        var obj = object;
        obj = this.getRootObject(object);
        let b = this.m_selectMap.add(obj.id, obj);
        if (b) {
            ObjectFactory.changeSpriteSelectColor(obj, true);
            MainObjects.Render.m_outlinePass.selectedObjects.push(obj);
        }
    }

    getRootObject(object) {
    

        if(this.m_selectLayer == SelectListenerBp.SelectLayer.e_GROUP)
        {
            if (object.parent.uuid == MainObjects.Scene.m_userGroup.uuid) {
                return object;
            }
            else {
                return this.getRootObject(object.parent);
            }
        }
        else
        {
            if(object.userData.m_gameObjectType == GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {
                return this.getRootObject(object.parent);
            }
            return object;
        }
    }

    
    getAlwaysGltfObject(object) {
        if (object.parent &&object.parent.uuid == MainObjects.Scene.m_userGroup.uuid) {
            return object;
        }
        else {
            if(object.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF)
            {
                return object;
            }
            else
            {
                return this.getAlwaysGltfObject(object.parent);
            } 
        }
    }

    getMouseAction(even) {
        var mouseAction;
        switch (even.button) {
            case 0:
                mouseAction = SelectListenerBp.MouseButtons.e_LEFT;
                break;
            case 1:
                mouseAction = SelectListenerBp.MouseButtons.e_MIDDLE;
                break;
            case 2:
                mouseAction = SelectListenerBp.MouseButtons.e_RIGHT;
                break;
            default:
                mouseAction = - 1;
        }
        return mouseAction;
    }

    onPointerDown(even) {
        const width = MainObjects.Render.m_container.clientWidth;
        const height = MainObjects.Render.m_container.clientHeight;
        const offsetLeft = MainObjects.Render.m_container.offsetLeft;
        const offsetTop = MainObjects.Render.m_container.offsetTop;
        this.m_startPointer.x = even.x;
        this.m_startPointer.y = even.y;
        this.m_bUpClickMove = false;
        this.m_bMouseDown = true;
        if (this.m_selectMode == SelectListenerBp.SelectMode.e_S_BOX) {

            this.m_selectionBox.startPoint.set(((even.clientX - offsetLeft) / width) * 2 - 1, - ((even.clientY - offsetTop) / height) * 2 + 1, 0.5);
        }
    }

    onPointerUp(even) {
        this.m_bMouseDown = false;
        if (this.m_bDragUp) {
            this.m_bDragUp = false;
            return;
        }
        if (this.m_bUpClickMove && this.m_selectMode == SelectListenerBp.SelectMode.e_S_CLICK) {
            return;
        }
        var needClear = false;
        if (this.getMouseAction(even) == SelectListenerBp.MouseButtons.e_LEFT) {
            const width = MainObjects.Render.m_container.clientWidth;
            const height = MainObjects.Render.m_container.clientHeight;
            const offsetLeft = MainObjects.Render.m_container.offsetLeft;
            const offsetTop = MainObjects.Render.m_container.offsetTop;

            this.m_pointer.x = ((even.clientX - offsetLeft) / width) * 2 - 1;
            this.m_pointer.y = - ((even.clientY - offsetTop) / height) * 2 + 1;

            this.m_raycaster.setFromCamera(this.m_pointer, MainObjects.Camera.m_renderCamera);
            this.m_raycaster.layers.disable(10);
            this.m_raycaster.layers.enable(11);
            const intersects = this.m_raycaster.intersectObjects(MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_selectAllMeshList);
            if (intersects.length > 0) {
                if (!MainObjects.Blueprint.m_eventListenerBp.m_bCtrl) {
                    this.clearSelectObjects();
                }
                var object = intersects[0].object;
                if(MainObjects.Setting.m_renderMode == IRender.RenderMode.e_WIRE)
                {
                    var max = Number.MAX_VALUE;
                    for(var ins of intersects)
                    {
                        if(ins.object.isMesh)
                        {
                            if(ins.object.name == "light_sprite")
                            {
                                object = ins.object;
                                break;
                            }
                            const meshPosition = ins.object.geometry.attributes.position;
                            var a = new THREE.Vector3(meshPosition.getX(ins.face.a),  meshPosition.getY(ins.face.a), meshPosition.getZ(ins.face.a));
                            var b = new THREE.Vector3(meshPosition.getX(ins.face.b),  meshPosition.getY(ins.face.b), meshPosition.getZ(ins.face.b));
                            var c = new THREE.Vector3(meshPosition.getX(ins.face.c),  meshPosition.getY(ins.face.c), meshPosition.getZ(ins.face.c));
                            a = ins.object.localToWorld(a);
                            b = ins.object.localToWorld(b);
                            c = ins.object.localToWorld(c);

                            var l1 = new THREE.Line3(a, b);
                            var l2 = new THREE.Line3(b, c);
                            var l3 = new THREE.Line3(a, c);

                            var target = new THREE.Vector3();
                            l1.closestPointToPoint ( ins.point, true, target );
                            var length = ins.point.distanceTo(target);
                            if( length < max)
                            {
                                max = length;
                                object = ins.object;
                            }

                            l2.closestPointToPoint ( ins.point, true, target );
                            length = ins.point.distanceTo(target);
                            if( length < max)
                            {
                                max = length;
                                object = ins.object;
                            }

                            l3.closestPointToPoint ( ins.point, true, target );
                            length = ins.point.distanceTo(target);
                            if( length < max)
                            {
                                max = length;
                                object = ins.object;
                            }
                        }
                        else if(ins.object.isSprite)
                        {
                            object = ins.object;
                            break;
                        }
                    }
                }
                this.selectObject(object);
            }
            else {
                needClear = true;
                this.clearSelectObjects();
            }
        }
        if (this.m_selectMode == SelectListenerBp.SelectMode.e_S_BOX && this.getMouseAction(even) == SelectListenerBp.MouseButtons.e_LEFT) {
            const width = MainObjects.Render.m_container.clientWidth;
            const height = MainObjects.Render.m_container.clientHeight;
            const offsetLeft = MainObjects.Render.m_container.offsetLeft;
            const offsetTop = MainObjects.Render.m_container.offsetTop;

            this.m_selectionBox.endPoint.set(((even.clientX - offsetLeft) / width) * 2 - 1, - ((even.clientY - offsetTop) / height) * 2 + 1, 0.5);
            const allSelected = this.m_selectionBox.select();

            if (allSelected.length > 0) {
                for (let i = 0; i < allSelected.length; i++) {
                    const object = allSelected[i];
                    this.selectObject(object);
                }
            }
            else {
                if (needClear) {
                    this.clearSelectObjects();
                }
            }
        }
        this.setAxis();
    }

    onPointerMove(even) {

        if (this.m_bMouseDown) {
            if (Math.abs(this.m_startPointer.x - even.x) > 10 ||
                Math.abs(this.m_startPointer.y - even.y) > 10) {
                this.m_bUpClickMove = true;
            }
        }
    }

    onKeyDown(even) {
    }

    deleteObjects() {
        const length = MainObjects.Render.m_outlinePass.selectedObjects.length;
        MainObjects.Blueprint.m_scaleBoxBp.setHideLine();
        const data = this.addUndoRedoDelete(MainObjects.Render.m_outlinePass.selectedObjects);
        for (let index = 0; index < length; index++) {
            const obj = MainObjects.Render.m_outlinePass.selectedObjects[index];
            MainObjects.Blueprint.m_scaleBoxBp.setSizeSprite(obj, false);
            ObjectFactory.changeSpriteSelectColor(obj, false);
            if(index == 0)
            {
                MainObjects.Blueprint.m_boundingBoxBp.setMagneticSprite(MainObjects.Render.m_outlinePass.selectedObjects[0], false);
            }  
            ObjectFactory.deleteObject(obj);
        }
        this.addUndoRedoDelete(MainObjects.Render.m_outlinePass.selectedObjects, data, true);
        if(IScene.f_sceneTreeCallback != null)
        {
            IScene.f_sceneTreeCallback();
        }
        if (length > 0) {
            this.clearSelectObjects();
        }
    }

    onKeyUp(even) {
        // console.log(even.keyCode)
        switch (even.keyCode) {
            case 46: //del
                {
                    this.deleteObjects();
                }
                break;
            case 71:
                {
                    if(this.m_selectLayer == SelectListenerBp.SelectLayer.e_GROUP)
                    {
                        this.m_selectLayer = SelectListenerBp.SelectLayer.e_CHIRD;
                    }
                    else
                    {
                        this.m_selectLayer = SelectListenerBp.SelectLayer.e_GROUP;
                    }
                    if(ISelector.f_selectLayerCallback != null)
                    {
                        ISelector.f_selectLayerCallback(this.m_selectLayer);
                    }
                }
                break;
            case 72: //H
                {
                    if (MainObjects.Blueprint.m_eventListenerBp.m_bShift) {
                        MainObjects.Blueprint.m_selectorPropertyBp.changeAllObjectVisible(MainObjects.Scene.m_userGroup);
                    }
                    else if(MainObjects.Blueprint.m_eventListenerBp.m_bAlt)
                    {
                        MainObjects.Blueprint.m_selectorPropertyBp.changeAllObjectVisible(MainObjects.Scene.m_userGroup, false);
                        MainObjects.Blueprint.m_selectorPropertyBp.changeVisible();
                    }
                    else {
                        MainObjects.Blueprint.m_selectorPropertyBp.changeVisible();
                    }
                    if(IScene.f_sceneTreeCallback != null)
                    {
                        IScene.f_sceneTreeCallback();
                    }
                }
                break;
            case 76: //L
                {
                    MainObjects.Blueprint.m_selectorPropertyBp.changeLock();
                    if (ITransform.f_selectTransformCallback != null) {
                        ITransform.f_selectTransformCallback(true);
                    }
                }
                break;
            case 83: // S
                {
                    if (!MainObjects.Blueprint.m_eventListenerBp.m_bShift && !MainObjects.Blueprint.m_eventListenerBp.m_bCtrl) {
                        this.changeSelectMode();
                        if (ISelector.f_selectModeCallback != null) {
                            ISelector.f_selectModeCallback(this.m_selectMode);
                        }
                    }
                }
                break;
            case 81: // Q
                {
                    MainObjects.Control.m_transformControl.setSpace(MainObjects.Control.m_transformControl.space === 'local' ? 'world' : 'local');
                    if (MainObjects.Control.m_transformControl.space == 'local') {
                        if (ISelector.f_transformSpaceCallback != null) {
                            if (ISelector.f_transformSpaceCallback != null) {
                                ISelector.f_transformSpaceCallback(ISelector.TransformSpace.e_LOCAL);
                            }
                        }
                    }
                    else {
                        if (ISelector.f_transformSpaceCallback != null) {
                            if (ISelector.f_transformSpaceCallback != null) {
                                ISelector.f_transformSpaceCallback(ISelector.TransformSpace.e_WORLD);
                            }
                        }
                    }
                }
                break;
            case 87: // W
                {
                    ISelector.setTransformMode(ISelector.TransformMode.e_TRANSLAST);
                    if (ISelector.f_transformModeCallback != null) {
                        ISelector.f_transformModeCallback(ISelector.TransformMode.e_TRANSLAST);
                    }
                }
                break;
            case 69: // E
                {
                    ISelector.setTransformMode(ISelector.TransformMode.e_ROTATE);
                    if (ISelector.f_transformModeCallback != null) {
                        ISelector.f_transformModeCallback(ISelector.TransformMode.e_ROTATE);
                    }
                }
                break;
            case 84: // T
                {
                    ISelector.setTransformMode(ISelector.TransformMode.e_STRECH);
                    if (ISelector.f_transformModeCallback != null) {
                        ISelector.f_transformModeCallback(ISelector.TransformMode.e_STRECH);
                    }
                }
                break;
            case 89: // Y
                {
                    ISelector.setTransformMode(ISelector.TransformMode.e_MAGNETIC);
                    if (ISelector.f_transformModeCallback != null) {
                        ISelector.f_transformModeCallback(ISelector.TransformMode.e_MAGNETIC);
                    }
                }
                break;
            case 85: // U
                {
                    ISelector.setTransformMode(ISelector.TransformMode.e_ALIGN);
                    if (ISelector.f_transformModeCallback != null) {
                        ISelector.f_transformModeCallback(ISelector.TransformMode.e_ALIGN);
                    }
                }
                break;
            case 187:
            case 107: // +, =, num+
                {
                    if (MainObjects.Render.m_outlinePass.selectedObjects.length > 0) {
                        MainObjects.Control.m_transformControl.setSize(MainObjects.Control.m_transformControl.size + 0.1);
                    }
                }
                break;
            case 189:
            case 109: // -, _, num-
                {
                    if (MainObjects.Render.m_outlinePass.selectedObjects.length > 0) {
                        MainObjects.Control.m_transformControl.setSize(Math.max(MainObjects.Control.m_transformControl.size - 0.1, 0.1));
                    }
                }
                break;
            case 27: // Esc
                if (MainObjects.Render.m_outlinePass.selectedObjects.length > 0) {
                    MainObjects.Control.m_transformControl.reset();
                    MainObjects.Blueprint.m_boundingBoxBp.close();
                    MainObjects.Blueprint.m_eventListenerBp.m_bShift = false;
                    MainObjects.Blueprint.m_eventListenerBp.m_bCtrl = false;
                    this.clearSelectObjects();
                }
                break;
        }
    }

    addUndoRedoDelete(objects, undoData = null, end = false) {
        var _this = this;
        var _data = new Array();
        var _id = "addUndoRedoDelete";
        if (!end) {
            for (let index = 0; index < objects.length; index++) {
                const objd = MainObjects.Blueprint.m_sceneManagerBp.getObjectJsonInfo(objects[index]);
                _data.push(objd);
            }
        }
        else {
            for (let index = 0; index < objects.length; index++) {
                var objd = {
                    delete: true,
                    name: objects[index].name,
                    id: objects[index].id
                };
                _id = _id + objd.name;
                _data.push(objd);
            }
        }

        if (end) {
            MainObjects.Blueprint.m_historyListenerBp.addUndoRedo(undoData, _data, function (data) {
                var list = new Array();
                var b = false;
                for (let index = 0; index < data.length; index++) {
                    if (data[index].delete != undefined) {
                        const object = MainObjects.Scene.m_userGroup.getObjectByName(data[index].name);
                        ObjectFactory.deleteObject(object);
                        b = true;
                    }
                    else {
                       

                        MainObjects.Blueprint.m_sceneManagerBp.getObjectFromJson(data[index]);
                        const object = MainObjects.Scene.m_userGroup.getObjectByName(data[index].name);
                        list.push(object);
                        b = true;
                    }
                }
             
                if(b)
                {
                    if(IScene.f_sceneTreeCallback != null)
                    {
                        IScene.f_sceneTreeCallback();
                    }
                    _this.clearSelectObjects();
                }

            }, _id);

        }

        return _data;
    }

    addUndoRedoAdd(objects) {
        var _this = this;
        var _id = "addUndoRedoAdd";
        var undoData = {
            delete: true,
            name: new Array(),
            ids : new Array()
        };
        var redoData = new Array();
        for (let index = 0; index < objects.length; index++) {
            const element = objects[index];
            undoData.name.push(element.name);
            undoData.ids.push(element.id);
            redoData.push(MainObjects.Blueprint.m_sceneManagerBp.getObjectJsonInfo(element));
        }

        MainObjects.Blueprint.m_historyListenerBp.addUndoRedo(undoData, redoData, function (data) {
            var b = false;
            if (data.delete != undefined) {
                for (let index = 0; index < data.name.length; index++) {
                    const object = MainObjects.Scene.m_userGroup.getObjectByName(data.name[index]);
                    ObjectFactory.deleteObject(object);
                }
                b = true;
            }
            else {
                for (let index = 0; index < data.length; index++) {
                   

                    MainObjects.Blueprint.m_sceneManagerBp.getObjectFromJson(data[index]);
                }
                b = true;
            }
            if(b)
            {
                if(IScene.f_sceneTreeCallback != null)
                {
                    IScene.f_sceneTreeCallback();
                }
                _this.clearSelectObjects();
            }
        }, _id);
    }

    addUndoRedoTransform(objects, undoData = null, end = false) {
        var _this = this;
        var _data = new Array();
        var b = false;
        for (let index = 0; index < objects.length; index++) {
            var objd = {
                id:objects[index].id,
                name: objects[index].name,
                position: new THREE.Vector3(),
                rotation: new THREE.Euler(),
                scale: new THREE.Vector3(),
            };
            objd.position.copy(objects[index].position);
            objd.rotation.copy(objects[index].rotation);
            objd.scale.copy(objects[index].scale);
            if (undoData != null) {
                if (undoData[index].name != objd.name || !undoData[index].position.equals(objd.position) ||
                    !undoData[index].scale.equals(objd.scale) || !undoData[index].rotation.equals(objd.rotation)) {
                    b = true;
                }
            }
            _data.push(objd);
        }
        if (end && b) {
            MainObjects.Blueprint.m_historyListenerBp.addUndoRedo(undoData, _data, function (data) {
                for (let index = 0; index < data.length; index++) {
                    //getObjectByName
                    var object = MainObjects.Scene.m_userGroup.getObjectById(data[index].id);
                    if(object == undefined)
                    {
                        object = MainObjects.Scene.m_userGroup.getObjectByName(data[index].name);
                    }
                    object.position.copy(data[index].position);
                    object.rotation.copy(data[index].rotation);
                    object.scale.copy(data[index].scale);
                    MainObjects.Blueprint.m_selectListenerBp.updateObject(object);
                    if (object.userData.m_gameObjectType == GameObject.GameObjectType.e_GEOMETRY_CURVE) {
                        MainObjects.Blueprint.m_curveEditorBp.updateCurve(object.userData.m_parent);
                    }
                }
                _this.setAxis();
            });
        }
        return _data;
    }

}
export { SelectListenerBp };