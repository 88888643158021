import {
	BackSide,
	BufferGeometry,
	DoubleSide,
	Float32BufferAttribute,
	Line,
	LineBasicMaterial,
	Mesh,
	MeshBasicMaterial,
	Matrix4
} from 'three';
import { MainObjects } from '../common/MainObjects';
import { GameScene } from '../object/GameScene';

/**
 *  This helper must be added as a child of the light
 */

class RectAreaLightHelper extends Line {

	constructor( light, color ) {

		var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
		var geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_LINE_PLAN_1);
		var material = gameScene.m_matRepos.get(GameScene.MatKey.e_LINE);

		super( geometry, material );

		this.light = light;
		this.color = color; // optional hardwired color for the helper
		this.type = 'RectAreaLightHelper';
		this.layers.set(11);

		const geometry3 = gameScene.m_geoRepos.get(GameScene.GeoKey.e_LINE_0_3);
		this.add(new Line( geometry3, material ));

		this.children[ 0 ].renderOrder = 100;
		this.renderOrder = 100;
		this.children[ 0 ].name = "light_sprite";
	}

    update()
    {
		this.updateMatrixWorld();
    }

	updateMatrixWorld() {

		this.scale.set( 0.5 * this.light.width, 0.5 * this.light.height, 1 );
		// ignore world scale on light
		this.matrixWorld.extractRotation( this.light.matrixWorld ).scale( this.scale ).copyPosition( this.light.matrixWorld );

		this.children[ 0 ].matrixWorld.copy( this.matrixWorld );

		var mat = new Matrix4();
		mat.extractRotation( this.light.matrixWorld ).scale( this.children[ 1 ].scale  ).copyPosition( this.light.matrixWorld );

		this.children[ 1 ].matrixWorld.copy( mat );
	}

	dispose() {

		// this.geometry.dispose();
		// this.material.dispose();
		// this.children[ 0 ].geometry.dispose();
		// this.children[ 0 ].material.dispose();
		// this.children[ 1 ].geometry.dispose();

	}

}

export { RectAreaLightHelper };