import * as THREE from 'three';
import { MainObjects } from '../common/MainObjects';
import { SceneFactory } from "../factory/SceneFactory";
import { GameObject } from '../object/GameObject';

class AnimationManagerBp {
    constructor() {
        this.m_skeletonList = new Array();
        this.m_skinList = new Array();
    }

    play(object) {
        if (object.userData != undefined || object.userData != null) {
            for(var [name, action] of object.userData.m_animationAction)
            {
                action.paused = false;
            }
            object.userData.m_bPlay = true;
        }
    }

    stop(object) {
        if (object.userData != undefined || object.userData != null) {
            for(var [name, action] of object.userData.m_animationAction)
            {
                action.paused = true;
            }
            object.userData.m_bPlay = false;
        }
    }

    modifyTimeScale(object, speed) {
        if (object.userData != undefined || object.userData != null) {
            object.userData.m_animationMixer.timeScale = speed;
        }
    }

    getAnimationList(object)
    { 
        var animationList = new Array();
        for(var [key, value] of object.userData.m_animationAction)
        {
            animationList.push(key);
        }
        return animationList;
    }

    changeAnimation(object, endName) {
        if (object.userData != undefined || object.userData != null) {
            var startAction = object.userData.m_animationAction.get(object.userData.m_currentAnimationName);
            var endAction = object.userData.m_animationAction.get(endName);
            this.synchronizeCrossFade(startAction, endAction, 0);
            object.userData.m_currentAnimationName = endName;
        }
    }

    synchronizeCrossFade(startAction, endAction, duration) {
        this.executeCrossFade(startAction, endAction, duration);
    }

    executeCrossFade(startAction, endAction, duration) {

        // Not only the start action, but also the end action must get a weight of 1 before fading
        // (concerning the start action this is already guaranteed in this place)
        if (endAction) {
            this.setWeight(endAction, 1);
            endAction.time = 0;
            if (startAction) {
                // Crossfade with warping
                startAction.crossFadeTo(endAction, duration, true);
            } else {
                // Fade in
                endAction.fadeIn(duration);
            }
        } else {
            // Fade out
            startAction.fadeOut(duration);
        }
    }

    setWeight(action, weight) {
        action.enabled = true;
        action.setEffectiveTimeScale(1);
        action.setEffectiveWeight(weight);
    }

    initAnimation(skinnedMesh) {
        if (skinnedMesh.userData.m_gltf == null) {
            return;
        }
        if (skinnedMesh.userData.m_gltf.animations.length > 0) {
            skinnedMesh.userData.m_animationMixer = new THREE.AnimationMixer(skinnedMesh);
            var i = 0;
            for (var anima of skinnedMesh.userData.m_gltf.animations) {
               
                var action = skinnedMesh.userData.m_animationMixer.clipAction(anima);
                skinnedMesh.userData.m_animationAction.set(anima.name, action);
               
                if(i == 0)
                {
                    skinnedMesh.userData.m_currentAnimationName = anima.name;
                    this.activateAction(action, 1);
                }
                else{
                    this.activateAction(action, 0);
                }
                i++;
            }
            skinnedMesh.userData.m_gameObjectHelper = new THREE.SkeletonHelper(skinnedMesh);
            skinnedMesh.userData.m_gameObjectHelper.visible = false;
            SceneFactory.addToHelperGroup(skinnedMesh.userData.m_gameObjectHelper);
        }
    }

    computeBoundingBox(object, childList) {
        var boundingBox = new THREE.Box3();
        for (let i = 0; i < childList.length; i++) {
            childList[i].geometry.computeBoundingBox();
            var box = new THREE.Box3();
            box.copy(childList[i].geometry.boundingBox);
            boundingBox.union( box.applyMatrix4(childList[i].matrixWorld));
        }
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new THREE.Mesh(geometry, material);

        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        boundingBox.getCenter(cube.position);
        boundingBox.getSize(cube.scale);
        cube.visible = false;
        object.add(cube);
    }

    activateAction(action, weight) {
        this.setWeight(action, weight);
        action.play();
    }

    getSkinMeshList(object) {
        for (var i = 0; i < object.children.length; i++) {
            var iterator =  object.children[i];
            if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {   
                if(iterator.userData.m_skeleton != null)
                {
                    this.m_skeletonList.push(iterator);
                }
                if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
                    this.getSkinMeshList(iterator);
                }
            }
        }
    }

    setSkeleton(object, name)
    {
        this.m_skeletonList = [];
        this.getSkinMeshList(MainObjects.Scene.m_userGroup);
        var dirObj = null;
        for (let index = 0; index < this.m_skeletonList.length; index++) {
            const element = this.m_skeletonList[index];
            if(element.userData.m_name == name)
            {
                dirObj = element;
                break;
            }
        }
        if(dirObj != null)
        {
            object.userData.m_skeleton_name = dirObj.name;
            object.traverse(function (child) {
                if (child.isMesh || child.isSkinnedMesh) {
                    child.skeleton = dirObj.userData.m_skeleton;
                }
            });
            object.position.copy(dirObj.position);
            object.updateMatrixWorld (true); 
        }
    }

    initSkeleton()
    {
        this.m_skeletonList = [];
        var map = new Map();
        this.getSkinMeshList(MainObjects.Scene.m_userGroup);

        for (let index = 0; index < this.m_skeletonList.length; index++) {
            const element = this.m_skeletonList[index];
            map.set(element.name, element);
        }
  
        var animationMixList = MainObjects.Blueprint.m_animationManagerBp.m_skinList;
        for(var mix of animationMixList)
        {
            if(mix.userData.m_skeleton_name != mix.name)
            {

                if(map.get(mix.userData.m_skeleton_name) != null)
                {
            
                   var object = map.get(mix.userData.m_skeleton_name);
                   mix.traverse(function (child) {
                    if (child.isMesh || child.isSkinnedMesh) {
                        child.skeleton = object.userData.m_skeleton;
                    }});
                    mix.position.copy(object.position);
                    mix.updateMatrixWorld (true); 
                }
            }
        }
    }

}

export { AnimationManagerBp };