<template>
  <div id="box" ref="box">
    <!-- 模型加载 -->
    <div class="masking" v-show="progressBar != 100">
      <!-- progressBar != 100 -->
      <div v-show="progressBar != 100" class="uploading_process">
        <el-progress
          :percentage="progressBar"
          color=" #0560FD"
          :stroke-width="18"
          class="progressBar"
        ></el-progress>

        <div class="uploading_text">正在加载中...</div>
      </div>
    </div>
    <div
      id="container"
      ref="left"
      :style="leftWidth ? `width:${leftWidth}px;height:${leftHeight}px;` : ''"
    ></div>
    <div class="header">
      <div class="toolbar">
        <ToolBar />
      </div>
      <div class="run" @click="playChange" @mouseenter="enter()" @mouseleave="leave()">
        <a title="点击运行">
          <img src="../assets/ToolBar/play.png" alt />
        </a>
      </div>
      <div class="userId" >
        <div class="user">用户：</div>
        <div>{{ phone }}</div>
        <div class="progress_box">
          <el-progress
            :text-inside="true"
            :stroke-width="16"
            :percentage="percentage"
            style="color: #333"
          ></el-progress>
        </div>
        <div class="memory">{{ usedspace | formatbytes }}/{{ storage | formatbytes }}</div>
      </div>
    </div>
    <div class="qrcode" v-show="qrcodeState">
      <div id="qrcode" ref="qrcode"></div>
    </div>
    <div class="drag" @mousedown="dragLine" :style="`left:${moveLineLeft}px;`" v-show="dragShow"></div>
    <div
      class="right"
      :class="isActive ? 'anmDiv' : 'aaa'"
      ref="right"
      :style="rightWidth ? `width:${rightWidth}px;` : ''"
    >
      <div class="box1" v-show="$store.state.textureBallMasking"></div>
      <div class="addition" v-show="addHide">
        <AddiTion />
      </div>     

      <!--  @mouseleave="mouseLeave" -->
      <div v-show="rightState">
        <div class="right_top">
          <div class="topTitle">
            <p class="ScenarioName_title">场景名称:</p>
            <span class="ScenarioName" v-show="rightState">{{ sceneName ? sceneName : "场景未保存" }}</span>
          </div>
        </div>
        <div
          class="SceneTree"
          :style="SceneHeight ? `height:${SceneHeight - 82}px;` : ''"
          @contextmenu.prevent="rightClick({}, {})"

            :SceneHeight="Number(SceneHeight)"
        >
          <div class="SceneBal">
            <a title="所有节点">
            <div
              :class="{ SceneBalOne: true, SceneBalOneSelect: hierarchy == 1 }"
              @click="SwitchingHierarchy"
            >
              <img
                v-if="hierarchy == 1"
                class="SceneBalOneimg"
                src="../assets/Resource/pitch_hierarchical.png"
                alt
              />
              <img v-else class="SceneBalOneimg" src="../assets/Resource/icon_tier.png" alt />
            </div>

            </a>
            <a title="灯光">
            
            <div
              :class="{ SceneBalOne: true, SceneBalOneSelect: hierarchy == 2 }"
              @click="SwitchTheLight"
            >
              <img
                v-if="hierarchy == 2"
                class="SceneBalOneimg"
                src="../assets/Resource/icon_light.png"
                alt
              />
              <img v-else class="SceneBalOneimg" src="../assets/Resource/icon_lamplight.png" alt />
            </div>
            </a>
            <a title="相机">

            <div
              :class="{ SceneBalOne: true, SceneBalOneSelect: hierarchy == 3 }"
              @click="SwitchCamera"
            >
              <img
                v-if="hierarchy == 3"
                class="SceneBalOneimg"
                src="../assets/Resource/SelectCamera.png"
                alt
              />
              <img v-else class="SceneBalOneimg" src="../assets/Resource/camera.png" alt />
            </div>
            </a>
            <a title="选中">

            <div
              :class="{ SceneBalOne: true, SceneBalOneSelect: hierarchy == 4 }"
              @click="SwitchReveal"
            >
              <img
                v-if="hierarchy == 4"
                class="SceneBalOneimg"
                src="../assets/Resource/selectSelected.png"
                alt
              />
              <img v-else class="SceneBalOneimg" src="../assets/Resource/select.png" alt />
            </div>
            </a>
            <a title="碰撞器">

            <div
              :class="{ SceneBalOne: true, SceneBalOneSelect: hierarchy == 5 }"
              @click="SwitchBoundingBox"
            >
              <img
                v-if="hierarchy == 5"
                class="SceneBalOneimg"
                src="../assets/Property/checkedCollision.png"
                alt
              />
              <img v-else class="SceneBalOneimg" src="../assets/Property/Collision.png" alt />
            </div>
            </a>
            <a title="粒子">

            <div
              :class="{ SceneBalOne: true, SceneBalOneSelect: hierarchy == 6 }"
              @click="SwitchParticleBox"
            >
              <img
                v-if="hierarchy == 5"
                class="SceneBalOneimg"
                src="../assets/Resource/particleIcon.png"
                alt
              />
              <img v-else class="SceneBalOneimg" src="../assets/Resource/particleIcon.png" alt />
            </div>

            </a>


          </div>
          <div
            :class="{
              SceneContent: true,
              unfoldSceneContent: !isunfold,
            }"
          >
            <div class="SceneContentTop">
              <div class="packUp fl">
                <div class="packUpIcon" @click="setIsunfold">
                  <img
                    :class="{
                      packUpImg: isunfold,

                      unfoldPackUpImg: !isunfold,
                    }"
                    src="../assets/Resource/arrows.png"
                    alt
                  />
                </div>
              </div>
              <div class="topLeft">
                <p class="packUpText fl">层级</p>
                <div class="tool fl" @click.stop="addHide = !addHide">
                  <img class="add" src="../assets/ToolBar/add.png" alt v-show="!addHide" />
                  <img class="add" src="../assets/ToolBar/checkedAdd.png" alt v-show="addHide" />
                </div>
              </div>
              <!-- <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input> -->
              <div class="seek">
                <el-input
                  v-model="filterText"
                  placeholder="输入关键字进行过滤"
                  prefix-icon="el-icon-search"
                  clearable
                  value
                  @focus="Focus"
                  @blur="Blur"
                  @change="folderByName"
                  @keyup.enter.native="folderByName"
                >
                  <template slot="prefix">
                    <!-- <select name="" id="" class="indistinctOrExact">
                      <option value="1">模糊</option>
                      <option value="2">精准</option>

                    </select>-->

                    <div class="select">
                      <el-select
                        v-model="searchType"
                        @change="searchTypeChange"
                        placeholder="请选择"
                        class="indistinctOrExact"
                      >
                        <el-option label="模糊" value="1"></el-option>
                        <el-option label="精准" value="2"></el-option>
                      </el-select>
                    </div>
                  </template>

                  <i class="el-icon-search" slot="suffix"></i>

                  <!--  -->
                </el-input>
              </div>
            </div>

            <div class="leftToptree">
              <SceneTree ref="threeTree"  
              
              
              
              
              :SceneHeight="Number(SceneHeight)" @getUser="getUser" :recycle="false" :isThree="1" />
            </div>
          </div>
        </div>
        <div class="myMenu">
          <div v-show="menu">
            <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">
              <el-menu-item v-show="true" index="1" class="menuItem">
                <span slot="title">
                  <img src="../assets/Resource/group.png" alt class="menuIcon" />
                  新建群组
                </span>
              </el-menu-item>
            </el-menu>
          </div>
        </div>

        <div
          class="line"
          @mousedown="LineChange"
          :style="
            lineWidth ? `width:${lineWidth}px;bottom:${moduleLine}px;` : ''
          "
        ></div>
        <div class="Property" :style="PropertyHeight ? `height:${PropertyHeight}px;` : ''">
          <Property @passVaule="passValue" :PropertyHeight="Number(PropertyHeight)" />
        </div>
      </div>
    </div>

    <div
      v-show="dragShow"
      class="resize"
      @mousedown="BottomDragLine"
      :style="drawWidth ? `width:${drawWidth}px;top:${moveLineBottom}px;` : ''"
    ></div>
    <div
      class="bottom"
      :style="
        bottomWidth ? `width:${bottomWidth}px;height:${bottomHeight}px;` : ''
      "
    >
      <div class="resource" v-show="bottomState">
        
        <div class="sharedFile">

          <SharedLibrary>

          </SharedLibrary>
        </div>

        <Resource
          :bottomHeight="Number(bottomHeight)"
          :sid="childSia"
          @setModelProcess="setModelProcess"
          ref="Resource"
        />





      </div>
    </div>

    <!-- <div class="runTime" v-show="runTime">
      <runTime />
    </div> -->
    <div class="preview" v-if="previewShow">
      <PreviewGroup
        v-if="previewShow"
        ref="previewSave"
        :revampResource="revampResource"
        :files="files"
        :fileUrl="url"
        :ext="ext"
        @transfer="getPreviewShow"
        :upDataId="upDataId"
        :upgrading="upgrading"
        @setFileDirectoryId="setFileDirectoryId"
        :FileDirectoryId="FileDirectoryId"
        :ResourceType="ResourceType"
        :upDataNode="upDataNode"
        type="1"
        :Node="groupNode"
      ></PreviewGroup>
    </div>
    <Particle class="particle" v-if="$store.state.partiClecompile" />
    <!-- 状态机 -->
    <StateMac class="StateMac" v-if="$store.state.macState" />
    <div v-if="$store.state.nweParticleShow" class="NweParticle"      v-dragFixed  >
      <NweParticle  id="controlDrag" />
      

    </div>

    

  </div>


</template>

<script>
import PreviewGroup from "./layout/PreviewGroup";

import setEditor from "./layout/SetMaterialEditor";
import Particle from "./ParticleSystem/Particle";
import StateMac from "./layout/StateMachineFinite";
import SceneTree from "./layout/SceneTree";
import Property from "./layout/Property";
import Resource from "./layout/Resource";
import ToolBar from "./layout/ToolBar";
import runTime from "./layout/Runtime.vue";
import SharedLibrary from "./layout/SharedLibrary.vue";
import NweParticle from "./ParticleSystem/NweParticle"

import { MainRender } from "../threejs/common/MainRender";
import { MainObjects } from "../threejs/common/MainObjects";
import { ICamera } from "../threejs/interface/ICamera";
import { ITween } from "../threejs/interface/ITween";
import { ISetting } from "../threejs/interface/ISetting";
import { IFactory } from "../threejs/interface/IFactory";
import AddiTion from "./property/AddObject";
import Name from "./property/Name";
import QRCode from "qrcodejs2";
export default {
  name: "Home",
  // 自定义指令
  directives: {},
  data() {
    return {
      qrcodeState: false,
      //群组node
      groupNode: {},
      //上传
      previewShow: false,
      files: [],
      ext: "",
      url: "",
      upDataId: "",
      //更改还是上传
      upgrading: "",

      //拿到的文件id
      FileDirectoryId: "",
      // 拿到左侧文件树类型
      ResourceType: 1,
      upDataNode: "",

      //保存组
      PreviewGroup: false,
      value: "",
      childSia: "", //接收值
      isActive: true,
      rightState: true,
      hide: true,
      bottomState: true,
      tabBar: true,
      phone: "",
      addHide: false,
      runTime: false,
      addImgState: true,
      //结构组件需要用到的参数
      structureData: {
        name: "home传过来的数据"
      },
      storage: 0, //存储空间
      usedspace: 0, //使用空间
      percentage: 0, //计算百分比
      moveLineLeft: localStorage.getItem("moveLineLeft"),
      rightWidth: localStorage.getItem("rightWidth"),
      leftWidth: localStorage.getItem("leftWidth"),
      leftHeight: localStorage.getItem("leftHeight"),
      bottomWidth: localStorage.getItem("bottomWidth"),
      bottomHeight: localStorage.getItem("bottomHeight"),
      drawWidth: localStorage.getItem("drawWidth"),
      moveLineBottom: localStorage.getItem("moveLineBottom"),
      lineWidth: localStorage.getItem("lineWidth"),
      moduleLine: localStorage.getItem("moduleLine"),
      PropertyHeight: localStorage.getItem("PropertyHeight"),

      SceneHeight: localStorage.getItem("SceneHeight"),

      //场景名字
      sceneName: localStorage.getItem("sceneName"),

      //模型加载进度
      progressBar: 100,

      //菜单显示隐藏
      menu: false,
      dragShow: true,
      //控制顶部展开
      isunfold: true,
      //层级类型
      hierarchy: 1,
      //保持用户状态定时器
      times: null,
      //搜索
      filterText: "",
      //搜索类型
      searchType: "1",
      //团队id
      teamId:"",
      //在团队的等级
      teamLevel:"",
      //保存到修改资源
      revampResource:false,
    };
  },
  components: {
    SceneTree,
    Property,
    Resource,
    ToolBar,
    AddiTion,
    setEditor,
    PreviewGroup,
    Name,
    SharedLibrary,
    Particle,
    NweParticle,
    StateMac
  },
  created() {

      
      //团队id
      let teamId =localStorage.getItem("teamId")
      //成员在该团队的等级

      let teamLevel=localStorage.getItem("teamLevel")
    
      if (teamId && teamLevel) {
        this.$store.commit("changeUserRank",teamLevel)
        this.teamId=teamId
        this.teamLevel=teamLevel

      }
      let SceneHeight = localStorage.getItem("SceneHeight")
      
      if (SceneHeight==null) {
        localStorage.setItem("SceneHeight", 371);
        
      }
    var projectId = localStorage.getItem("projectId");
    this.openProject(projectId);
    this.times = setInterval(() => {
      var projectId = localStorage.getItem("projectId");

      this.openProject(projectId);
    }, 1000 * 60 * 4);
  },
  destroyed() {
    //销毁
    clearInterval(this.times);
  },
  beforeMount() {
    window.parentMounted = this._isMounted; // _isMounted是当前实例mouned()是否执行 此时为false
  },

  mounted() {
    document.title ="艺搭"
    window.addEventListener("click", this.addChange);
    window.addEventListener("click", e => {
      if (this.menu == true) {
        this.menu = false;
        this.setDragShow(true);
      }
    });

    if (MainObjects.MainRender.m_this != null) {

      MainObjects.MainRender.m_this = null;
      location.reload();
    }
    var option = {};
    option.container = document.getElementById("container");
    MainObjects.MainRender.m_this = new MainRender(option);
    ISetting.getUserBaseProjectInfo();
    // let data = IScene.getSceneTree();
    // this.data = data;
    window.parentMounted = this._isMounted; // _isMounted是当前实例mouned()是否执行 此时为true
    this.useMemory();
    this.phone = localStorage.getItem("phone");
    ICamera.f_windowsResize = this.resizeChange.bind(this);
    this.resizeChange();
    this.createwidth();

    // 监听回退事件
    window.addEventListener(
      "popstate",
      function(e) {
        // 这里写你想做的事：如关闭我关闭弹层
        //项目id

        localStorage.removeItem("projectId");

        //更新的文件id
        localStorage.removeItem("updateFileId");
        //当前场景id
        localStorage.removeItem("presentSceneId");
        //展开列表
        localStorage.removeItem("unfoldIdList");
        //右侧 文件夹id
        localStorage.removeItem("FileDirectoryId");
        //当前右侧类型
        localStorage.removeItem("ResourceType");
        // 路径
        localStorage.removeItem("temppath");
        //场景名
        localStorage.removeItem("sceneName");
        //复制或剪切
        localStorage.removeItem("shear");
        //模型url
        localStorage.removeItem("modelUrl");

        // localStorage.removeItem("openSceneId");

        //点击的节点值
        localStorage.removeItem("leftClickNode");
        this.$store.commit('changeCloudsScene', null)


        ISetting.exit();
      },
      false
    );
    //监听页面刷新
    window.addEventListener("load", () => {
      //清除模块url
      localStorage.removeItem("modelUrl")

    });
    this.getUserSig()
    this.qrcode();
  },


  watch: {
    filterText(val) {
      // this.$refs.tree.filter(val);
      this.folderByName();
    }
  },
  methods: {
    //获取 userSing
   async getUserSig(){
      const res = await this.$https.getUserSig({
     
      })


      if(res.success == true){
        
         localStorage.setItem('userSig',res.data)
          
          let userID=localStorage.getItem("phone")

          let test= localStorage.getItem("userSig")
      
          let _this=this
          // this.$tim.logout()
          let promise = this.$tim.logout();
            promise.then(function(imResponse) {


              // 登出成功
              _this.$tim.login({userID: userID, userSig: res.data});
              
            }).catch(function(imError) {
              // console.warn('logout error:', imError);
            });

 
      }
    },



    //定位
      orientation(parameter){
        this.$refs.Resource.orientation(parameter)
      },




    //切换搜索类型
    searchTypeChange() {
      this.$refs.threeTree.searchTypeChange(this.searchType);
      this.filterText = "";

      this.$refs.threeTree.setFilterText("");
    },

    //设置搜索
    folderByName() {
      this.$refs.threeTree.setFilterText(this.filterText);
    },

    //搜索获取焦点
    Focus() {
      ISetting.isInput(true);
    },
    //搜索失去焦点
    Blur() {
      ISetting.isInput(false);
    },

    //设置场景名称
    setSceneName(smg) {
   
      this.sceneName = smg;
      localStorage.setItem("sceneName", smg);
    },

    //切换层级
    SwitchingHierarchy() {
      this.$store.commit("setThreeSceneTreeType", "0");
      this.hierarchy = 1;
      this.$refs.threeTree.getDataTree({});
    },
    //切换灯光
    SwitchTheLight() {
      this.$store.commit("setThreeSceneTreeType", "1");

      this.hierarchy = 2;
      this.$refs.threeTree.getDataTree({});
    },

    //切换相机
    SwitchCamera() {
      this.$store.commit("setThreeSceneTreeType", "2");

      this.hierarchy = 3;
      this.$refs.threeTree.getDataTree({});
    },
    //切换相机
    SwitchReveal() {
      this.$store.commit("setThreeSceneTreeType", "3");

      this.hierarchy = 4;
      this.$refs.threeTree.getDataTree({});
    },

    //切换碰撞盒
    SwitchBoundingBox() {
      this.$store.commit("setThreeSceneTreeType", "4");

      this.hierarchy = 5;
      this.$refs.threeTree.getDataTree({});
    },



    //粒子
    SwitchParticleBox() {
      this.$store.commit("setThreeSceneTreeType", "5");

      this.hierarchy = 6;
      this.$refs.threeTree.getDataTree({});
    },




    setIsunfold() {
      this.isunfold = !this.isunfold;
    },

    setNode(msg) {
      this.groupNode = msg;
    },

    //传选中的id
    setFileDirectoryId(msg) {
    

      this.FileDirectoryId = msg;

      //调用获取方法
      // this.getSubCatalog(msg);
    },
    //获取子节点数据
    getSubCatalog(msg) {
      this.$refs.Resource.getSubCatalog(msg);
    },

    //子组件传参子组件调用这个函数来让 弹框显示隐藏
    getPreviewShow(msg) {
      this.previewShow = msg;
      ISetting.isInput(false);
    },

    //保存组
    showPreviewGroup(revampResource) {

      this.revampResource=revampResource
      this.previewShow = true;
      ISetting.isInput(true);
   
    },

    closePreviewGroup() {
     
      this.PreviewGroup = false;
    },

    closeChange() {
      this.PreviewGroup = false;
    },



    //控制菜单隐藏
    commandMenuShow() {
      this.$refs.Resource.commandMenuShow();
      this.$refs.threeTree.showMenu();
      this.menu = false;
    },

    mouseLeave() {
      this.$refs.threeTree.mouseLeave();
    },
    passValue(data) {
      this.childSia = data;
    },
    getUser(msg) {

    },
    playChange() {
      // this.runTime = !this.runTime;
      // this.$router.push({ path: "/Runtime" });
      // window.open("home#" + "/Runtime");
      var json = localStorage.getItem("RuntimeScene");
      let routerURL = this.$router.resolve({
        path:"/Runtime?scene="+ json,
      });
      window.open(routerURL.href,'_blank')
    },
    createwidth() {
      let height = localStorage.getItem("bottomHeight");
      if (height == null) {
        this.bottomHeight = 300;
      } else {
        this.bottomHeight = Number(localStorage.getItem("bottomHeight"));
      }
    },
    resizeChange() {
      let width = document.body.clientWidth;
      let height = document.body.clientHeight;
      if (this.rightWidth == null) {
        this.rightWidth = 580;
      }
      if (this.bottomHeight == null) {
        this.bottomHeight = 300;
      }
      if (this.PropertyHeight == null) {
        this.PropertyHeight = 900;
      }
      this.bottomWidth = width - this.rightWidth;
      this.moveLineLeft = width - this.rightWidth;
      this.drawWidth = width - this.rightWidth;
      this.lineWidth = width - this.bottomWidth;
      this.moveLineBottom = height - this.bottomHeight;
      this.leftWidth = width - this.rightWidth;
      this.leftHeight = height - 50 - this.bottomHeight;
      ITween.tweenUpdate(1200, function(x) {
        ICamera.resize();
      });
    },
    dragLine() {
      let width = this.$refs.box.clientWidth; //获取盒子的宽 //绑定onmousemove
      document.onselectstart = function() {
        return false;
      };
      document.onmousemove = e => {
        //鼠标点击拖动时
        this.moveLineLeft = e.clientX;
        if (this.moveLineLeft < 1) {
          //设置一个最小值
          this.leftWidth = 1;
          this.bottomWidth = 1;
          this.drawWidth = 1;
          this.moveLineLeft = 1;
          this.rightWidth = width - 1;
          this, (this.lineWidth = width - 1);
        } else if (this.moveLineLeft > width + 0 - 67) {
          //设置一个最大值
          this.rightWidth = 67;
          this.lineWidth = 67;
          this.leftWidth = width - 67;
          this.bottomWidth = width - 67;
          this.drawWidth = width - 67;
          this.moveLineLeft = width - 67;
        } else {
          this.leftWidth = this.moveLineLeft - 0;
          this.bottomWidth = this.moveLineLeft - 0;
          this.drawWidth = this.moveLineLeft - 0;
          this.rightWidth = width - this.moveLineLeft - 0;
          this.lineWidth = width - this.moveLineLeft - 0;
        }
        ITween.tweenUpdate(1200, function(x) {
          ICamera.resize();
        });
      }; //绑定onmouseup
      document.onmouseup = () => {
        //松开鼠标时
        document.onmousedown = null;
        document.onmousemove = null;
        document.onmouseup = null;
        localStorage.setItem("moveLineLeft", this.moveLineLeft);
        localStorage.setItem("rightWidth", this.rightWidth);
        localStorage.setItem("leftWidth", this.leftWidth);
        localStorage.setItem("bottomWidth", this.bottomWidth);
        localStorage.setItem("drawWidth", this.drawWidth);
        localStorage.setItem("lineWidth", this.lineWidth);
      };
    },

    BottomDragLine() {
      let height = this.$refs.box.clientHeight; //获取盒子的高
      document.onselectstart = function() {
        return false;
      };
      document.onmousemove = e => {
        //鼠标点击拖动时
        this.moveLineBottom = e.clientY;
        if (this.moveLineBottom < 50) {
          //设置一个最小值
          this.leftHeight = 50;
          this.moveLineBottom = 50;
          this.bottomHeight = height - 50;
        } else if (this.moveLineBottom > height + 0 - 30) {
          //设置一个最大值
          this.leftHeight = height - 90;
          this.bottomHeight = height - this.leftHeight - 50;
          // this.bottomHeight = height - 40;
          this.moveLineBottom = height - 40;
        } else {
          this.leftHeight = this.moveLineBottom - 40;
          this.bottomHeight = height - this.moveLineBottom - 0;
        }
        ITween.tweenUpdate(1200, function(x) {
          ICamera.resize();
        });
      };
      document.onmouseup = () => {
        //松开鼠标时
        document.onmousedown = null;
        document.onmousemove = null;
        document.onmouseup = null;
        localStorage.setItem("moveLineBottom", this.moveLineBottom);
        localStorage.setItem("leftHeight", this.leftHeight);
        localStorage.setItem("bottomHeight", this.bottomHeight);
      };
    },
    LineChange() {
      let height = this.$refs.right.clientHeight; //获取盒子的高
      document.onselectstart = function() {
        return false;
      };
      document.onmousemove = e => {

        //鼠标点击拖动时
        this.moduleLine = e.clientY;
        if (this.moduleLine < 50) {
          //设置一个最小值
          this.SceneHeight = 50;
          this.moduleLine = 50;
          this.PropertyHeight = height - 50;
        } else if (this.moduleLine > height + 0 - 40) {
          //设置一个最大值
          this.SceneHeight = height - 40;
          this.PropertyHeight = height - 40;
          this.moduleLine = height - 40;
        } else {
          this.SceneHeight = this.moduleLine - 10;
          this.PropertyHeight = height - this.moduleLine - 0;
        }

        
      };
      document.onmouseup = () => {
        //松开鼠标时
        document.onmousedown = null;
        document.onmousemove = null;
        document.onmouseup = null;
        localStorage.setItem("SceneHeight", this.SceneHeight);
        localStorage.setItem("PropertyHeight", this.PropertyHeight);
        localStorage.setItem("moduleLine", this.moduleLine);
      };
    },
    async useMemory(_type) {
      const res = await this.$https.useMemory({
        groupId:this.teamId
      });
      if (res.errCode == null) {
        this.storage = res.data.totalMemory;
        this.usedspace = res.data.memory;
        this.percentage = Math.ceil(res.data.memory / res.data.totalMemory);

        
      } else {
        this.$message({
          message: res.errMessage
          // type: "warning",
        });
      }
    },
    //设置模型加载进度
    setModelProcess(msg) {
      this.progressBar = msg;

    },
    //层级空白右击
    rightClick(node, data) {
      this.setDragShow(false);

      this.commandMenuShow();
      this.$refs.threeTree.showMenu();
      //获取到菜单
      let menu = this.$refs.menu;
      let x = event.clientX;
      let y = event.clientY;
      if (y > 700) {
        menu.$el.style.top = y - 150 + "px";
      } else {
        menu.$el.style.top = y + "px";
      }
      if (x > 1280) {
        menu.$el.style.left = x - 100 + "px";
      } else {
        menu.$el.style.left = x + "px";
      }
      this.menu = true;
    },

    //选择菜单
    handleSelect(key) {
      if (key == 1) {
        //创建组
        // ISelector.selectObjectByTree(this.Node.object);
        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\——)(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        );

        ISetting.isInput(true);
        this.$prompt(``, "请输入群组名称", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // center: true,
          closeOnClickModal: false,
          inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",

          inputValidator: value => {
            // 点击按钮时，对文本框里面的值进行验证
            if (!value) {
              return "输入不能为空";
            } else if (containSpecial.test(value)) {
              return "输入不能为特殊字符";
            } else if (value.length > 20) {
              return "输入文字不能超过20个字符";
            }
          }
          // inputErrorMessage: "输入不能为空",
          // inputValidator: value => {
          //   // 点击按钮时，对文本框里面的值进行验证
          //   if (!value) {
          //     return "输入不能为空";
          //   }
          // }
        })
          .then(({ value }) => {
            ISetting.isInput(false);
            IFactory.createGroup(value);
          })
          .catch(() => {
            ISetting.isInput(false);

            this.$message({
              // type: "info",
              message: "取消输入"
            });
          });

      
      }
    },

    showMenu() {
      this.menu = false;
    },

    setDragShow(msg) {
      //调用到了拖拽

      this.dragShow = msg;
    },

    //项目上锁解锁接口
    async projectLocal(id) {
      const res = await this.$https.projectLocal({
        id: id,
        local: 0
      });
      if (res.errCode == null) {
      } else {
        this.$message({
          message: res.errMessage
          // type: "warning",
        });
      }
    },
    addChange() {
      this.addHide = false;
      this.addImgState = !this.addImgState;

      if (this.menu == true) {
        this.menu = false;
        this.setDragShow(true);
      }

    },
    enter() {
      this.qrcodeState = true;
    },
    leave() {
      this.qrcodeState = false;
    },
    //刷新在线状态
    async openProject(id) {
      const res = await this.$https.openProject({
        id: id
      });
      if (res.err == null) {
     
      }
    },
    qrcode () {
        var json = localStorage.getItem("RuntimeScene");
        let qrcode = new QRCode("qrcode", {

            width: 150,

            height: 150,

            text: "https://www.yicreator.com/editor/dev/index.html#/Runtime?scene="+ json

        });
        
    },

  },
  filters: {
    formatbytes: function(bytes) {
      if (bytes === 0) return "0 B";
      var k = 1024, // or 1024
        sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.round(Math.log(bytes) / Math.log(k));

      return (bytes / Math.pow(k, i)).toPrecision(3) + " " + sizes[i];
    },

    calculatePercentage: function(data) {
      let data1 = (data * 100).toFixed(4) + "%";
      return data1;
    }
  }
};
</script>
<style scoped>

#container {
  width: calc(100% - 423px);
  height: calc(100% - 357px);
  margin: 0;
  padding: 0;
  position: absolute;
  top: 53px;
  left: 0;
  -webkit-font-smoothing: antialiased;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

#box {
  width: 100vw;
  height: 100vh;
}

#container >>> canvas {
  width: 100% !important;
  height: 100% !important;
}

.SceneTree {
  height: 271px;
  width: 100%;
  margin-top: 15px;
  /* margin: 0 auto; */
  /* margin-left: 20px; */
  /* padding-right: 4%; */
  background: #ffffff;
  /* box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1); */
  border-radius: 6px;
  overflow: hidden;
  /* margin-bottom: 20px; */
}

.Property {
  height: calc(100% - 255px);
  /* position: fixed;
  right: 0;
  top: 405px; */
}

.right {
  width: 530px;
  height: 100%;
  background: #ffffff;
  position: fixed;
  right: 0;
  top: 52px;
}

.right::-webkit-scrollbar {
  display: none;
}

.right {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* .anmDiv{
transition: 1S;
}
.aaa{
width: 55px;
transition: 1S;
} */
.right_top {
  width: 90%;
  margin: 10px auto;

  height: 13px;
  font-size: 12px;
  /* position: relative; */
}

.tier_text {
  width: 31px;
  height: 18px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  margin-left: 8px;
  /* float: left; */

  color: #000;
  font-weight: bold;
  font-size: 10px;
  /* margin-top: -2px; */
}

.contentTxt {
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  position: fixed;
  top: 56px;
  right: 10px;
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentTxt img {
  width: 11px;
  height: 14px;
}

.bottom {
  width: calc(100% - 423px);
  height: 300px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #fff;
  border-radius: 5px;
  /* z-index: 2; */
}

.resize {
  width: calc(100% - 423px);
  height: 5px;
  position: absolute;
  left: 0;
  bottom: 300px;
  background-color: #d6d6d6;
  cursor: row-resize;
}

.resource {
  width: 100%;
  height: 100%;
}

/* .anmDiv1{
transition: 1S;
}
.aaa1{
height: 55px;
box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
transition: 1S;
} */
.bottomTxt {
  width: 32px;
  height: 32px;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottomTxt img {
  width: 11px;
  height: 14px;
}

.title {
  width: 90%;
  height: 30px;
  line-height: 30px;
  color: #000000;
  font-size: 16px;
  border-bottom: 2px solid #fff;
  text-align: left;
  margin: auto;
}

.header {
  width: 100%;
  height: 50px;
  /* position: fixed;
  top: 0;
  left: 0; */
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .run {
  width: 25px;
  height: 25px;
  line-height: 30px;
  background: #fff;
  box-shadow: 0px 2px 9px 0px rgba(33, 35, 49, 0.1);
  border-radius: 50%;
  font-size: 8px;
  position: absolute;
  left: 72%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.run img {
  width: 10px;
  height: 10px;
}

.run:hover {
  background: #cddfff;
}

.userId {
  height: 100%;
  width: 25%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(153, 158, 161, 1);
  overflow: hidden;
}

.userId .user {
  width: 45px;
  height: 25px;
  line-height: 25px;
  overflow: hidden;
}

.progress_box {
  width: 180px;
  height: 20px;
  /* background:url(../assets/Property/frame.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  /* border: 2px solid #1673ff; */
  border-radius: 15px;
  margin-left: 10px;
}

.el-progress {
  width: 175px;
  margin-left: 2px;
}

.progress_box >>> .el-progress-bar__inner {
  background: #1673ff;
}

.progress_box >>> .el-progress-bar__innerText {
  color: #333;
}

.progress_box >>> .el-progress-bar__outer {
  border: 2px solid #1673ff;
}

.progressBar /deep/.el-progress-bar {
  padding-right: 0px;
  width: 100%;
  margin-right: 0px;
  box-sizing: border-box;
}

.userId .memory {
  margin-left: 20px;
  color: rgba(153, 158, 161, 1);
}

/* 拖动 */
.drag {
  width: 5px;
  height: calc(100% - 53px);
  background-color: #d6d6d6;
  cursor: col-resize;
  position: absolute;
  top: 53px;
  right: 480px;
  z-index: 2;
}

.resize {
  width: calc(100% - 423px);
  height: 5px;
  background-color: #d6d6d6;
  cursor: row-resize;
  position: absolute;
  left: 0;
  bottom: 300px;
  z-index: 2;
}

.line {
  width: 480px;
  height: 5px;
  background-color: #d6d6d6;
  cursor: row-resize;
  z-index: 2;
}

.icon {
  width: 15px;
  height: 14px;
  margin: 0 2px;
}

.iconGradation {
  display: block;
  /* width: 15px; */
  height: 15px;
  /* margin: 0 2px; */
  /* margin-top: 10px; ;   */
  float: left;
}

.topTitle {
  width: 100%;
  height: 25px;
  color: #000;
  font-weight: bold;
  font-size: 10px;
  /* margin:  auto; */
  display: flex;
  align-items: center;
  /* justify-content:space-around ; */
}

.topTitle .icon {
  width: 15px;
  height: 14px;
  margin-right: 10px;
  margin-left: 8px;
}

.topTitle .icon img {
  width: 15px;
  height: 14px;
}

.ScenarioName_title {
  width: 60px;
  height: 30px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 44px;
  /* margin-left: 20px; */
  text-align: left;
  margin-top: -9px;
  color: #000;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;

  text-overflow: ellipsis;

  margin-left: 6px;

  /* font-weight: bold; */
}

.ScenarioName {
  width: 80%;
  height: 20px;
  margin-right: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 26px;
  /* margin-left: 20px; */
  text-align: left;
  margin-top: -9px;
  color: #000;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;

  text-overflow: ellipsis;
}

.codeEditor {
  width: 600px;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #282c34;
  border-radius: 12px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 99;
}

.close {
  color: #fff;
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

/* 蒙版 */
.masking {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

/* 上传进度 */
.uploading_process {
  width: 1039px;
  height: 169px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 0px 17px 1px rgba(33, 35, 49, 0.1);
  border-radius: 20px;
  z-index: 9999999999999;
}

.progressBar {
  width: 971px;
  /* height: 57px; */
  margin: 0 auto;
  margin-top: 50px;

  font-family: PingFang SC;
  font-weight: 400;
}

.progressBar /deep/.el-progress__text {
  position: absolute;
  top: -130%;
  left: 50%;
  transform: translate(-100%, -50%);
  width: 58px;
  height: 15px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}

/* /deep/.el-dialog__body{
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
} */
.uploading_text {
  width: 120px;
  height: 23px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
  margin: 0 auto;
  margin-top: 40px;
}

/**设置右击 */

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
}

.menuItem {
  /* text-align:center; */
  line-height: 60px;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
}

.menuItem {
  /* text-align:center; */
  line-height: 60px;
}

.menuIcon {
  width: 19px;
  height: 17px;

  margin-right: 20px;
}

.myMenu /deep/ .menu {
  border-radius: 8px;
  padding: 10px 0;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
}
.myMenu /deep/.el-menu-item {
  height: 40px;
  line-height: 40px;
  position: relative;

  white-space: nowrap;
  list-style: none;
}
.myMent /deep/ .el-menu-item.is-active {
  color: #000;
}

/* 图标 */
.icon {
  width: 19px;
  height: 17px;
  margin: 0 2px;
}

.topLeft {
  /* width: 23%; */
  float: left;
}
/* 搜索 */

.seek {
  /* width: 220px; */
  width: 76%;
  float: left;
  margin-left: 0px;
  /* margin: 0 auto; */
  /* margin-bottom: 10px; */
  /* margin-top: 1px; */
  line-height: 40px;
}

.seek .select /deep/ .el-input {
  width: 75px !important;
  height: 35px !important;
  outline: none !important;
  line-height: 38px !important;
  text-align: center;
  border: none;
}
.seek .select /deep/ .el-input__inner {
  border-radius: 7px;
  border: none;
  background-color: #ffffff;
}

.seek .select /deep/ .el-input--suffix .el-input__inner {
  padding: 0px;
  padding-left: 10px;
}

.seek .select /deep/.el-input__inner {
  height: 24px;
  margin-top: 0px;
}

.seek .select /deep/.el-input__icon {
  line-height: 35px;
}

.seek .select /deep/.el-select .el-input .el-select__caret {
  font-size: 10px;
}

.seek /deep/ .el-input--suffix .el-input__inner {
  padding-right: 50px;
}

.seek /deep/ .el-input--prefix .el-input__inner {
  padding-left: 100px;
}

.seek /deep/.el-input__inner {
  /* width: 300px; */
  height: 35px;
  background: #f7f7f7;
  border-radius: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #929292;
  box-shadow: none;
}
.seek /deep/.el-input__inner .handle_conter {
  height: 20px;
  float: left;
  width: 30px;
  margin-right: 20px;
  margin-left: 20px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}
.seek /deep/.el-input__inner {
  /* width: 300px; */
  height: 35px;
  background: #f7f7f7;
  border-radius: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #929292;
  box-shadow: none;
}
.seek /deep/.el-input__inner .handle_conter {
  height: 20px;
  float: left;
  width: 30px;
  margin-right: 20px;
  margin-left: 20px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}
.seek
  /deep/.el-input__suffix
  .el-input__suffix-inner
  .el-icon-circle-close:before {
  content: "\e79d" !important;
  font-size: 18px;
}
</style>
<style>
.myClass {
  border-radius: 10px;
}

.tool {
  width: 50px;
  height: 40px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  /* margin-left: 15px; */
}

.tool:hover {
  background: #fafafa;
}

.tool img {
  width: 10px;
  height: 10px;
  margin-top: 0px;
}

.runTime {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 300px;
  z-index: 99999;
  background: #fff;
  border-radius: 10px;
  z-index: 999999;
}

.addition {
  position: absolute;
  left: 75px;
  top: 50px;
  z-index: 9999999;
}

/* 右边蒙版 */
.box1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.1;
  z-index: 9999;
}

.SceneBal {
  width: 39px;
  height:100%;
  background: rgba(247, 247, 247, 1);
  float: left;
  margin-left: 15px;
}

.SceneBalOne {
  width: 95%;
  height: 40px;
  background: rgba(247, 247, 247, 1);
  position: relative;
  margin-top: 2px;
  margin-left: 2px;
  cursor: pointer;
}
.SceneBalOneSelect {
  background: #ffffff;
}

/* .SceneBalOne:hover{
    background: #1673ff;
    color: #fff;
} */

.SceneBalOneimg {
  width: 15px;
  height: 15px;

  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.SceneContent {
  box-sizing: border-box;
  width: 80%;
  height: 100%;
  /* float: right; */
  /* background-color: #1673ff; */
  /* margin-top: 10px; */
  padding: 16px;
  box-shadow: 0px 0px 3px 0px rgba(33, 35, 49, 0.1);

  overflow: hidden;
  /* margin: 0 auto; */
  margin-top: 1px;
  margin-left: 70px;
}
.unfoldSceneContent {
  height: 70px;
  overflow: hidden;
}

.SceneContentTop {
  width: 100%;

  height: 40px;
  margin-bottom: 30px;
  overflow: hidden;
}

.leftToptree {
  width: 105%;
  height: 80%;
  /* overflow: auto; */
  margin-left: -10px;
  margin-top: -25px;
}

/* 滚动条样式 */
/* .leftToptree::-webkit-scrollbar {
  margin-top: 5px;
  
  width: 10px;
  
  height: 1px;
}

.leftToptree::-webkit-scrollbar-thumb {

  border-radius: 10px;
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
}

.leftToptree::-webkit-scrollbar-track {
  
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #e9f1fe;
} */


.packUp {
  width: 15px;
  height: 30px;
  position: relative;
}
.packUpIcon {
  width: 15px;
  height: 15px;
  display: inline-block;
  position: absolute;
  left: 75%;
  top: 53%;
  transform: translate(-50%, -50%);
}

.packUpImg {
  width: 10px;
  height: 10px;
  transform: rotate(90deg);
}
.unfoldPackUpImg {
  width: 10px;
  height: 10px;
  transform: rotate(0deg);
}
.packUpText {
  line-height: 40px;
  margin: 0;
  margin-left: 10px;

  color: #000;
  font-weight: bold;
  font-size: 0.75rem;
}
.qrcode {
  position: fixed;
  left: 68%;
  top: 50px;
  z-index: 999;
}
#qrcode{
    width: 150px;
    height: 150px;
    padding: 5px;
    box-shadow: 0px 0px 5px 0px rgba(33, 35, 49, 0.1);
    background-color: #fff;
}

.sharedFile{
  width: 100%;
  height: 40px;
  /* background: #000; */
}
.particle{
  position: fixed;
  z-index: 100;
  top: 42%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.StateMac{
  position: fixed;
  z-index: 100;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.NweParticle{
  position: fixed;
  z-index: 9999;

  background: #ffffff;
  top: 42%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 10px;
}
/* a::after {
  content: attr(name);
  display: none;
}

a:hover::after {
  display: block;
  position: absolute;
  min-width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;

  color: #000;
  font-size: 12px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  z-index: 99999999999;
} */
</style>