<template>
  <div class="box">
    <div id="runtime_container" class="runtime_container"></div>
    <div class="sidebar">
      <div class="column">
        <div class="title">模型总数:</div>
        <div class="number">{{number}}</div>
      </div>
      <div class="column">
        <div class="title">总面数:</div>
        <div class="number">{{TotalFaces}}</div>
      </div>
      <div class="resourceTabel">
        <el-table :data="actionList" border height="39.0625vw" :row-style="tableRowStyle"
            :header-cell-style="tableHeaderColor" style="width: 19.53125vw" :resizable="false"  :default-sort = "{prop: 'date', order: 'descending'}">
            <el-table-column prop="actionStart" label="模型名称" align="center" width="144">
                <template slot-scope="scope">
                  <!-- <el-input style="width:8.46354vw;text-align: center;" v-model="scope.row.key" @change="mixtureNum(scope.row)" placeholder=""
                    ></el-input> -->
                    <span>{{ scope.row.key }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="actionEnd" label="数量" align="center" width="115" sortable :sort-method="handlesortChange">
                <template slot-scope="scope">
                    <!-- <el-input style="width:2.60417vw" v-model="scope.row.value" @change="mixtureNum(scope.row)" placeholder=""
                        ></el-input> -->
                        <span>{{ scope.row.countNum }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="actionEnd" label="面数" align="center" width="115" sortable :sort-method="handlesortChange1">
                <template slot-scope="scope">
                    <!-- <el-input style="width:2.60417vw" v-model="scope.row.value" @change="mixtureNum(scope.row)" placeholder=""
                        ></el-input> -->
                        <span>{{ scope.row.triNum }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
    </div>
    <div class="progress" v-show="progressBar != 99">
          <el-progress
            :percentage="progressBar"
            color=" #0560FD"
            :stroke-width="10"
            class="progressBar"
            :format="formatData"
          ></el-progress>
      </div>
  </div>
</template>
<script>
import { IRender, ILoader, ICameraControls, ISetting } from "yi_creator_runtime";

export default {
  data() {
    return {
      progressBar:0,
      number:"",
      TotalFaces:"",
      actionList:[
    
      ],
    };
  },

  mounted() {
    var _this = this;
    let url = window.location.href;
    var indexs = url.indexOf("=");
    var json = url.substring(indexs + 1, url.length);
    // console.log(json)
    if (json != null) {
      var option = {};
      option.container = document.getElementById("runtime_container");
      IRender.initRender(option);
      ISetting.turnOffStatistics(true)
      ISetting.turnOffPhysicsDebug(false);
      _this.progressBar = 100;
      ILoader.loadScene(
        json,
        function (p) {
          _this.progressBar = parseInt(p * 100);
        },
        function () {
          ICameraControls.createCameraOrbite(true);
          _this.getData()
        }
      );
      return;
    }
  },
  methods: {
    getData(){
      this.number =ISetting.getDebugInfo().instanceMeshMap.size;
      // console.log("11",ISetting.getDebugInfo().instanceMeshMap)
      // let arr = ISetting.getDebugInfo().instanceMeshMap;
      let sum = 0;
      for(var [key, value] of ISetting.getDebugInfo().instanceMeshMap){
        // console.log("输出",key, value)
        let obj = {};
        obj.key = key;
        obj.countNum = value.countNum;
        obj.triNum = value.triNum;
        this.actionList.push(obj);
        sum+= value.triNum;
      }
      this.TotalFaces = sum;
    },
    handlesortChange(a,b){
      return a.countNum - b.countNum;
    },
    handlesortChange1(a,b){
      return a.triNum - b.triNum;
    },
    formatData(percentage) {
      return `${percentage}%`;
    },
    tableRowStyle({ row, rowIndex }) {
        return "background-color:#F7F7F7;font-size:8px; color: #000000;height:30px; ";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
        return "background-color:#F7F7F7;height:30px;font-size: 8px;color: #000000;";
    },
  },
};
</script>
<style scoped>
.box{
  width: 100%;
  height: 100%;
  display: flex;
}
.runtime_container {
  width: 80%;
  height: 100%;
  background: #fff;
}
canvas{
  width: 100% !important;
  height: 100% !important;
}
.sidebar{
  width: 20%;
  height: 100%;
  border-left: 1px solid #ccc;
  background: #fff;
}
.column{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.column .title{
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: #fff;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}
.column .number{
  width: 50px;
  height: 30px;
  line-height: 30px;
}
.resourceTabel{
    font-size: 10px;
    margin-top: 10px;
}
.resourceTabel .chartlet_box1{
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
}
.progress{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.progressBar {
  width: 800px;
  /* height: 57px; */
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;
}
.el-progress__text{
  position: absolute !important;
}
.resourceTabel /deep/ .el-input__inner{
  text-align: center !important; 
}
</style>