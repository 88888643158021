import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { ObjectFactory } from "./ObjectFactory"
import camera_icon from "../../assets/ThreeTexture/camera.png"
import camera_equirect_icon from "../../assets/ThreeTexture/camera_equirect_icon.png"
import camera_project_equirect_icon from "../../assets/ThreeTexture/camera_project_equirect_icon.png"
import camera_project_icon from "../../assets/ThreeTexture/camera_project_icon.png"
import light_icon from "../../assets/ThreeTexture/light.png"
import audio_icon from "../../assets/ThreeTexture/audio.png"
import point_icon from "../../assets/ThreeTexture/point.png"
import little_point_icon from "../../assets/ThreeTexture/lttlepoint.png"
import curve_icon from "../../assets/ThreeTexture/curve.png"
import lensflare0 from "../../assets/ThreeTexture/lensflare/lensflare0.png"
import lensflare3 from "../../assets/ThreeTexture/lensflare/lensflare3.png"
import grassDiffuse from "../../assets/ThreeTexture/grass/blade_diffuse.jpg"
import grassAlpha from "../../assets/ThreeTexture/grass/blade_alpha.jpg"
import perlinFbm from "../../assets/ThreeTexture/grass/perlinFbm.jpg"

import tgrass1 from "../../assets/ThreeTexture/terrain/grass1.jpg"
import tsand1 from "../../assets/ThreeTexture/terrain/sand1.jpg"

import wnormalMap0 from "../../assets/ThreeTexture/water/Water_1_M_Normal.jpg"
import wnormalMap1 from "../../assets/ThreeTexture/water/Water_2_M_Normal.jpg"

import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import { Saver } from "../loader/Saver"
import { INetwork } from "../interface/INetwork"
import { Loader } from "../loader/Loader"
import { GameScene } from '../object/GameScene';
import { ISetting } from '../interface/ISetting';

class SceneFactory {
    static loadBaseRepos() {
        RectAreaLightUniformsLib.init();
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const textureLoader = new THREE.TextureLoader();
        const imageLoader = new THREE.ImageLoader();
        const cameraTexture = textureLoader.load(camera_icon);
        const cameraEquirectTexture = textureLoader.load(camera_equirect_icon);
        const cameraProjectEquirectTexture = textureLoader.load(camera_project_equirect_icon);
        const cameraProjectTexture = textureLoader.load(camera_project_icon);
        const lightTexture = textureLoader.load(light_icon);
        const audioTexture = textureLoader.load(audio_icon);
        const pointTexture = textureLoader.load(point_icon);
        const litPointTexture = textureLoader.load(little_point_icon);
        const curveTexture = textureLoader.load(curve_icon);
        const textureFlare0 = textureLoader.load(lensflare0);
        const textureFlare3 = textureLoader.load(lensflare3);

        const grassTexture = textureLoader.load(grassDiffuse);
        grassTexture.encoding = THREE.sRGBEncoding;
        const grassAlphaMap = textureLoader.load(grassAlpha);
        var noiseTexture = textureLoader.load(perlinFbm);
        noiseTexture.wrapS = THREE.RepeatWrapping;
        noiseTexture.wrapT = THREE.RepeatWrapping;

        const tgrass1Texture = textureLoader.load(tgrass1);
        const tsand1Texture= textureLoader.load(tsand1);

        tgrass1Texture.encoding = THREE.sRGBEncoding;
        tsand1Texture.encoding = THREE.sRGBEncoding;

        const wnormalMap0Texture = textureLoader.load(wnormalMap0);
        const wnormalMap1Texture= textureLoader.load(wnormalMap1);

        gameScene.m_textureRepos.add(camera_icon, cameraTexture);
        gameScene.m_textureRepos.add(camera_equirect_icon, cameraEquirectTexture);
        gameScene.m_textureRepos.add(camera_project_equirect_icon, cameraProjectEquirectTexture);
        gameScene.m_textureRepos.add(camera_project_icon, cameraProjectTexture);
        gameScene.m_textureRepos.add(light_icon, lightTexture);
        gameScene.m_textureRepos.add(audio_icon, audioTexture);
        gameScene.m_textureRepos.add(point_icon, pointTexture);
        gameScene.m_textureRepos.add(little_point_icon, litPointTexture);
        gameScene.m_textureRepos.add(curve_icon, curveTexture);

        gameScene.m_textureRepos.add(lensflare0, textureFlare0);
        gameScene.m_textureRepos.add(lensflare3, textureFlare3);

        gameScene.m_textureRepos.add(grassDiffuse, grassTexture);
        gameScene.m_textureRepos.add(grassAlpha, grassAlphaMap);
        gameScene.m_textureRepos.add(perlinFbm, noiseTexture);

        gameScene.m_textureRepos.add(tgrass1, tgrass1Texture);
        gameScene.m_textureRepos.add(tsand1, tsand1Texture);

        gameScene.m_textureRepos.add(wnormalMap0, wnormalMap0Texture);
        gameScene.m_textureRepos.add(wnormalMap1, wnormalMap1Texture);

        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_CAMERA_ICON, cameraTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_CAMERA_EQUIRECT_ICON, cameraEquirectTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_CAMERA_PROJECT_EQUIRECT_ICON, cameraProjectEquirectTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_CAMERA_PROJECT_ICON, cameraProjectTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_LIGHT_ICON, lightTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_AUDIO_ICON, audioTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_POINT_ICON, pointTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_LIT_POINT_ICON, litPointTexture);
        SceneFactory.loadBaseIconMat(GameScene.MatKey.e_CURVE_ICON, curveTexture);

        SceneFactory.loadBaseLineMat(GameScene.MatKey.e_LINE, 0xffff00);
        SceneFactory.loadBaseMat(GameScene.MatKey.e_BASE_MAT);
        SceneFactory.loadBaseWireframeMat();
        SceneFactory.loadCollisionWireframeMat();
        SceneFactory.loadDoubleBaseMat();

        SceneFactory.loadBaseCubeGeo(GameScene.GeoKey.e_CUBE_0_1, 0.1);
        SceneFactory.loadBaseLineGeo();
        SceneFactory.loadBaseOnePlaneLineGeo();
        SceneFactory.loadBasePlaneLineGeo(GameScene.GeoKey.e_LINE_PLAN_0_1, 0.1);
        SceneFactory.loadBaseSphereGeometry(GameScene.GeoKey.e_SPHERE, 0.1)
        SceneFactory.loadBaseLine03Geo();
        SceneFactory.loadBaseLineConeGeo();
    }

    static loadBaseSphereGeometry(key, sphereSize)
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const geometry = new THREE.SphereGeometry( sphereSize, 4, 2 );
        gameScene.m_geoRepos.add(key, geometry);
    }

    static loadBaseOnePlaneLineGeo()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;

		const positions = [ 1, 1, 0, - 1, 1, 0, - 1, - 1, 0, 1, - 1, 0, 1, 1, 0 ];

		const geometry = new THREE.BufferGeometry();
		geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );
		geometry.computeBoundingSphere();
        gameScene.m_geoRepos.add(GameScene.GeoKey.e_LINE_PLAN_1, geometry);
    }

    static loadBasePlaneLineGeo(key, size)
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;

        let geometry = new THREE.BufferGeometry();
		geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( [
			- size, size, 0,
			size, size, 0,
			size, - size, 0,
			- size, - size, 0,
			- size, size, 0
		], 3 ) );

        gameScene.m_geoRepos.add(key, geometry);
    }

    static loadBaseLine03Geo()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const geometry = new THREE.BufferGeometry();
		geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( [ 0, 0, 0, 0, 0, -0.3 ], 3 ) );
        gameScene.m_geoRepos.add(GameScene.GeoKey.e_LINE_0_3, geometry);
    }

    static loadBaseLineGeo()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const points = [];
        points.push( new THREE.Vector3( 0, 0, 0 ) );
        points.push( new THREE.Vector3( 0, 0, 1 ) );
        const geometry = new THREE.BufferGeometry().setFromPoints( points ); 
        gameScene.m_geoRepos.add(GameScene.GeoKey.e_LINE, geometry);
    }

    static loadBaseLineConeGeo()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const geometry = new THREE.BufferGeometry();

		const positions = [
			0, 0, 0, 	0, 0, 1,
			0, 0, 0, 	1, 0, 1,
			0, 0, 0,	- 1, 0, 1,
			0, 0, 0, 	0, 1, 1,
			0, 0, 0, 	0, - 1, 1
		];

		for ( let i = 0, j = 1, l = 32; i < l; i ++, j ++ ) {

			const p1 = ( i / l ) * Math.PI * 2;
			const p2 = ( j / l ) * Math.PI * 2;

			positions.push(
				Math.cos( p1 ), Math.sin( p1 ), 1,
				Math.cos( p2 ), Math.sin( p2 ), 1
			);

		}

		geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( positions, 3 ) );

        gameScene.m_geoRepos.add(GameScene.GeoKey.e_LINE_CONE, geometry);
    }

    static loadBaseCubeGeo(key, size)
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const cubeGeometry = new THREE.BoxGeometry( size, size, size );
        gameScene.m_geoRepos.add(key, cubeGeometry);
    }

    static loadCollisionWireframeMat()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const material = new THREE.MeshBasicMaterial( { 
            color: 0x00ff00,
            wireframe: true, 
            fog: false, 
            toneMapped: false, 		
        });
        gameScene.m_matRepos.add(GameScene.MatKey.e_COLLISION_WIREFRAME_MAT, material);
    }

    static loadBaseWireframeMat()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const material = new THREE.MeshBasicMaterial( { 
            color: 0xffff00,
            wireframe: true, 
            fog: false, 
            toneMapped: false, 		
			depthTest: false,
			depthWrite: false,
        });
        gameScene.m_matRepos.add(GameScene.MatKey.e_BASE_WIREFRAME_MAT, material);
    }

    static loadBaseMat(key)
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const meshMat = new THREE.MeshBasicMaterial({
            transparent : true,
            opacity : 0
        });
        gameScene.m_matRepos.add(key, meshMat);
    }

    static loadDoubleBaseMat()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const meshMat = new THREE.MeshBasicMaterial( { 
            side: THREE.DoubleSide, 
            fog: false, 
            transparent : true, 
            opacity : 0.3,
            depthTest: false,
            depthWrite: false,
            fog: false,
            toneMapped: false 
        } );
        gameScene.m_matRepos.add(GameScene.MatKey.e_BASE_DOUBLE_MAT, meshMat);
    }

    static loadBaseLineMat(key, color)
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const lineMaterial = new THREE.LineBasicMaterial( {
            color: color,
            depthTest: false,
            depthWrite: false,
            fog: false,
            toneMapped: false,
        } );
        gameScene.m_matRepos.add(key, lineMaterial);
    }

    static loadBaseIconMat(key, texture)
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const mat = new THREE.SpriteMaterial({ 
            map: texture,
            depthTest: false,
            depthWrite: false,
            fog: false,
            toneMapped: false,
            transparent: true,
        });

        gameScene.m_matRepos.add(key, mat);
    }

    static initDefultHdrInScene()
    {
        var hdr = INetwork.getUrl(MainObjects.Setting.m_envMap);
        const manager = new THREE.LoadingManager();
        Loader.loadFile(hdr, "hdr", manager, function (texture, extension) {
            if(MainObjects.Setting.m_use_envMap || MainObjects.Setting.m_use_envMap == undefined)
            {
                texture.mapping = THREE.EquirectangularReflectionMapping;
                MainObjects.Scene.m_renderScene.environment = texture;
            }
            else
            {
                MainObjects.Scene.m_renderScene.environment = null;
            }
            if(ISetting.f_updateCallback != null)
            {
                ISetting.f_updateCallback();
            }
        });
    }

    static createSceneObjects() {

        Saver.isHavaSave(function(b){
            if (b) {
                Saver.autoLoadSave();
            }
            else {
                SceneFactory.addToUserGroup(ObjectFactory.createDirLight());
                if(INetwork.f_loadSceneFinishCallback != null)
                {
                    INetwork.f_loadSceneFinishCallback();
                }
            }
        });

 
        SceneFactory.initDefultHdrInScene();
    }

    static createScene() {
        var scene = new THREE.Scene();
        scene.autoUpdate = false;
        scene.background = new THREE.Color(new THREE.Color(0.2745, 0.2745, 0.2745));
        MainObjects.Scene.m_fogExp = new THREE.FogExp2( scene.background, 0.0025 );
        MainObjects.Scene.m_userGroup = new THREE.Group();
        MainObjects.Scene.m_userGroup.name = "editor_id_userGroup";
        MainObjects.Scene.m_helperGroup = new THREE.Group();
        MainObjects.Scene.m_helperGroup.name = "editor_id_helperGroup";
        scene.add(MainObjects.Scene.m_userGroup);
        scene.add(MainObjects.Scene.m_helperGroup);
        MainObjects.Scene.m_helperGride = ObjectFactory.createGridHelper();
        MainObjects.Scene.m_helperGroup.add(MainObjects.Scene.m_helperGride);
        return scene;
    }

    static setRenderScene(scene) {
        MainObjects.Scene.m_renderScene = scene;
    }

    static addToUserGroup(object) {
        MainObjects.Scene.m_userGroup.add(object);
        MainObjects.Blueprint.m_selectListenerBp.updateObject(object);
        MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(object);
    }
    static addToHelperGroup(object) {
        MainObjects.Scene.m_helperGroup.add(object);
    }
}

export { SceneFactory };