import { MainObjects } from "../common/MainObjects"
import { SceneFactory } from "../factory/SceneFactory";
import { Saver } from "../loader/Saver";
import { INetwork } from "./INetwork";
import { ITransform } from "./ITransform";
import * as THREE from 'three';

class ISetting {

    static f_updateCallback = null;

    static EnvLightMode =
    {
        e_AMBIENT: 0,
        e_HEMISPHERE: 1,
        e_NONE: 2
    }

    static getEnvLightMode(){
        return MainObjects.Setting.m_envLightMode;
    }
    
    static setEnvLightMode(mode) {
        MainObjects.Blueprint.m_sceneManagerBp.setEnvLightMode(mode);
    }

    static getSceneEnvMap()
    {
        var data = {
            use_envMap: MainObjects.Setting.m_use_envMap,
            envMapImage: INetwork.getUrl(MainObjects.Setting.m_envMapImage),
        }
        return data;
    }

    static setSceneEnvMap(b)
    {
        MainObjects.Setting.m_use_envMap = b;
        SceneFactory.initDefultHdrInScene();
    }

    static getAmbientSetting(bsave = false) {
        var prop = {
            type : MainObjects.Setting.m_envLightMode,
            color: "",
            intensity: 1,
        }
        prop.color = "#" + MainObjects.Scene.m_ambientLight.color.getHexString();
        prop.intensity = MainObjects.Scene.m_ambientLight.intensity;
        if(bsave)
        {
            prop.color = [1, 1, 1];
            MainObjects.Scene.m_ambientLight.color.toArray(prop.color);
        }
        return prop;
    }

    static setAmbientSetting(prop) {
        var color = [
            ITransform.canSetNumValue(MainObjects.Scene.m_ambientLight.color.r, prop.color[0]),
            ITransform.canSetNumValue(MainObjects.Scene.m_ambientLight.color.g, prop.color[1]),
            ITransform.canSetNumValue(MainObjects.Scene.m_ambientLight.color.b, prop.color[2])
        ];
        MainObjects.Scene.m_ambientLight.color.fromArray(color);
        MainObjects.Scene.m_ambientLight.intensity = ITransform.canSetNumValue(MainObjects.Scene.m_ambientLight.intensity, prop.intensity);
    }

    static getHemisphereSetting(bsave = false) {
        var prop = {
            type : MainObjects.Setting.m_envLightMode,
            color: "",
            groundColor: "",
            intensity: 1,
        }
        prop.color = "#" + MainObjects.Scene.m_hemisphereLight.color.getHexString();
        prop.groundColor = "#" + MainObjects.Scene.m_hemisphereLight.groundColor.getHexString();
        prop.intensity = MainObjects.Scene.m_hemisphereLight.intensity;
        if(bsave)
        {
            prop.color = [1, 1, 1];
            prop.groundColor = [1, 1, 1];
            MainObjects.Scene.m_hemisphereLight.color.toArray(prop.color);
            MainObjects.Scene.m_hemisphereLight.groundColor.toArray(prop.groundColor);
        }
        return prop;
    }

    static setHemisphereSetting(prop) {

        var color = [
            ITransform.canSetNumValue(MainObjects.Scene.m_hemisphereLight.color.r, prop.color[0]),
            ITransform.canSetNumValue(MainObjects.Scene.m_hemisphereLight.color.g, prop.color[1]),
            ITransform.canSetNumValue(MainObjects.Scene.m_hemisphereLight.color.b, prop.color[2])
        ];

        var color_g = [
            ITransform.canSetNumValue(MainObjects.Scene.m_hemisphereLight.groundColor.r, prop.groundColor[0]),
            ITransform.canSetNumValue(MainObjects.Scene.m_hemisphereLight.groundColor.g, prop.groundColor[1]),
            ITransform.canSetNumValue(MainObjects.Scene.m_hemisphereLight.groundColor.b, prop.groundColor[2])
        ];

        MainObjects.Scene.m_hemisphereLight.color.fromArray(color);
        MainObjects.Scene.m_hemisphereLight.groundColor.fromArray(color_g);
        MainObjects.Scene.m_hemisphereLight.intensity = ITransform.canSetNumValue(MainObjects.Scene.m_hemisphereLight.intensity, prop.intensity);
    }
    
    static getUserBaseProjectInfo() {
        var pid = localStorage.getItem("projectId");
        if (pid != undefined && pid != null) {
            MainObjects.Network.m_projectId = pid;
        }
        var phone = localStorage.getItem("phone");
        if (phone != undefined && phone != null) {
            MainObjects.Network.m_userPhone = phone;
        }
    }

    static exit() {
        Saver.removeSave();

        if(MainObjects.Blueprint.m_audioListenerBp){
            MainObjects.Blueprint.m_audioListenerBp.closeAllAudio();
        }
    }

    static isInput(b)
    {
        MainObjects.Control.m_isInput = b;
    }

    static getGrideVisible()
    {
        return MainObjects.Setting.m_grideVisible;
    }

    static setGrideVisible(visible)
    { 
        MainObjects.Scene.m_helperGride.visible = visible;
        MainObjects.Setting.m_grideVisible = visible;
    }

    static setExposure(value)
    {
        MainObjects.Setting.m_exposure = value;
        MainObjects.Render.m_renderer.toneMappingExposure = value;
    }

    static getExposure()
    {
        return MainObjects.Render.m_renderer.toneMappingExposure;
    }

    static setTurnPhysicsHelper(value)
    {
        MainObjects.Setting.m_turnPhysicsHelper = value;
        MainObjects.Blueprint.m_collisionsManagerBp.turnOffPhysicsHelper(value);
    }

    static getTurnPhysicsHelper()
    {
        return MainObjects.Setting.m_turnPhysicsHelper;
    }

    static getFogValue()
    {
        var json = {
            color : [MainObjects.Scene.m_fogExp.color.r, MainObjects.Scene.m_fogExp.color.g, MainObjects.Scene.m_fogExp.color.b],
            colorHex :  "#" + MainObjects.Scene.m_fogExp.color.getHexString(),
            density : MainObjects.Scene.m_fogExp.density,
            turnFog : MainObjects.Setting.m_turnFog
        }
        return json;
    }

    static setTurnFog(value)
    {
        MainObjects.Setting.m_turnFog = value;
        if(value)
        {
            MainObjects.Scene.m_renderScene.fog = MainObjects.Scene.m_fogExp;
        }
        else
        {
            MainObjects.Scene.m_renderScene.fog = null;
        }
    }

    static setFogValue(data)
    {
        MainObjects.Scene.m_fogExp.color = new THREE.Color(data.color[0],data.color[1],data.color[2]);
        MainObjects.Scene.m_fogExp.density = data.density;
    }
    
}

export { ISetting };