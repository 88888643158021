import * as THREE from 'three';
import { GameObject } from "../object/GameObject"
import { Language } from "../common/Language"
import { MainObjects } from "../common/MainObjects"
import { GameScene } from '../object/GameScene';
import { SkySample } from '../object/SkySample';
import { Lensflare, LensflareElement } from 'three/examples/jsm/objects/Lensflare.js';
import lensflare0 from "../../assets/ThreeTexture/lensflare/lensflare0.png"
import lensflare3 from "../../assets/ThreeTexture/lensflare/lensflare3.png"
import { Sky } from 'three/examples/jsm/objects/Sky';
import { ObjectFactory } from './ObjectFactory';
import { SceneFactory } from './SceneFactory';
import * as T from '../object/Terrain';
import { Water } from "../object/Water2"
import wnormalMap0 from "../../assets/ThreeTexture/water/Water_1_M_Normal.jpg"
import wnormalMap1 from "../../assets/ThreeTexture/water/Water_2_M_Normal.jpg"


class GeometryFactory {

    static createCurve(count, smooth = 200) {
        var userData = new GameObject();
        userData.m_gameObjectType = GameObject.GameObjectType.e_CURVE;
        userData.m_name = Language.LOCAL_TEXT("curve", "曲线");
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const material = gameScene.m_matRepos.get(GameScene.MatKey.e_CURVE_ICON);
        const lineMaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_LINE);
        for (let i = 0; i < count; i++) {
            const sprite = new THREE.Sprite(material);
            sprite.layers.set(11);
            sprite.scale.set(0.1, 0.1, 0.1);
            sprite.position.set(Math.random(), Math.random(), Math.random());
            userData.m_positionList.push(sprite.position);
            userData.m_gltf_meshList.push(sprite);

            var userDataP = new GameObject();
            userDataP.m_name = Language.LOCAL_TEXT("curvePoint", "节点");
            userDataP.m_gameObjectType = GameObject.GameObjectType.e_GEOMETRY_CURVE;
            sprite.name = sprite.uuid;
            sprite.userData = userDataP;
        }

        var curve = new THREE.CatmullRomCurve3(userData.m_positionList);
        curve.curveType = 'chordal';
        const geometry = new THREE.BufferGeometry();
        geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(smooth * 3), 3));

        curve.mesh = new THREE.Line(geometry, lineMaterial);
        curve.mesh.renderOrder = 100;
        curve.mesh.frustumCulled = false;

        for (let i = 0; i < userData.m_gltf_meshList.length; i++) {
            curve.mesh.attach(userData.m_gltf_meshList[i]);
            userData.m_gltf_meshList[i].userData.m_parent = curve.mesh;
        }
        curve.mesh.updateWorldMatrix(true);
        curve.mesh.name = curve.mesh.uuid;
        userData.m_curve = curve;
        curve.mesh.userData = userData;
        MainObjects.Blueprint.m_curveEditorBp.initCurve(curve.mesh);
        return curve.mesh
    }

    static createCurvePoint(pos) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const material = gameScene.m_matRepos.get(GameScene.MatKey.e_CURVE_ICON);
        const sprite = new THREE.Sprite(material);
        sprite.layers.set(11);
        sprite.scale.set(0.1, 0.1, 0.1);
        sprite.position.set(pos.x + Math.random(), pos.y + Math.random(), pos.z + Math.random());
        sprite.updateWorldMatrix(true);
        var userDataP = new GameObject();
        userDataP.m_name = Language.LOCAL_TEXT("curvePoint", "节点");
        userDataP.m_gameObjectType = GameObject.GameObjectType.e_GEOMETRY_CURVE;
        sprite.name = sprite.uuid;
        sprite.userData = userDataP;
        return sprite;
    }

    static createSky() {

        // Add Sky
        var sky = new SkySample();
        sky.userData = new GameObject();
        sky.userData.m_name = Language.LOCAL_TEXT("PureSky", "纯色天空");
        sky.userData.m_gameObjectType = GameObject.GameObjectType.e_SKY;
        sky.name = sky.uuid;
        return sky;
    }

    static createRealTimeSky() {
        // Add Sky
        const sky = new Sky();
        sky.scale.setScalar(10000);
        sky.userData = new GameObject();
        sky.userData.m_name = Language.LOCAL_TEXT("RealTimeSky", "实时天空");
        sky.userData.m_gameObjectType = GameObject.GameObjectType.e_REAL_TIME_SKY;
        sky.name = sky.uuid;

        const skyUniforms = sky.material.uniforms;
        skyUniforms['turbidity'].value = 10;
        skyUniforms['rayleigh'].value = 3;
        skyUniforms['mieCoefficient'].value = 0.005;
        skyUniforms['mieDirectionalG'].value = 0.8;

        sky.userData.m_gameObjectHelper = ObjectFactory.createDirLight();
        sky.userData.m_gameObjectHelper.userData.m_name = Language.LOCAL_TEXT("Sky Directional Light", "天空平行光");
        sky.userData.m_gameObjectHelper.userData.m_gameObjectType = GameObject.GameObjectType.e_SKY_DIR_LIGHT;
        sky.add(sky.userData.m_gameObjectHelper);

        return sky;
    }

    static createLensFlare() {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const lensflare = new Lensflare();
        lensflare.userData = new GameObject();
        lensflare.userData.m_name = Language.LOCAL_TEXT("Lensflare", "光晕");
        lensflare.userData.m_gameObjectType = GameObject.GameObjectType.e_LENS_FLARE;
        lensflare.name = lensflare.uuid;

        var textureFlare0 = gameScene.m_textureRepos.get(lensflare0);
        var textureFlare3 = gameScene.m_textureRepos.get(lensflare3);

        lensflare.userData.m_dom_List.push(new LensflareElement(textureFlare0, 700, 0));
        lensflare.userData.m_dom_List.push(new LensflareElement(textureFlare3, 60, 0.6));
        lensflare.userData.m_dom_List.push(new LensflareElement(textureFlare3, 70, 0.7));
        lensflare.userData.m_dom_List.push(new LensflareElement(textureFlare3, 120, 0.9));
        lensflare.userData.m_dom_List.push(new LensflareElement(textureFlare3, 70, 1));

        lensflare.addElement(lensflare.userData.m_dom_List[0]);
        lensflare.addElement(lensflare.userData.m_dom_List[1]);
        lensflare.addElement(lensflare.userData.m_dom_List[2]);
        lensflare.addElement(lensflare.userData.m_dom_List[3]);
        lensflare.addElement(lensflare.userData.m_dom_List[4]);

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = false;
        cube.name = "lensflare_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        cube.scale.set(10, 10, 10);
        lensflare.add(cube);

        return lensflare;
    }

    static createTerrain(option) {
        var material = T.THREE.Terrain.generateBlendedMaterial(option.textures);
        var data = option;
        // Generate a terrain
        var xS = option.xS, yS = option.yS;
        var terrainScene = T.THREE.Terrain({
            easing: option.easing,
            frequency: option.frequency,
            heightmap: option.heightmap,
            material: material,
            maxHeight: option.maxHeight,
            minHeight: option.minHeight,
            steps: option.steps,
            xSegments: xS,
            xSize: option.xSize,
            ySegments: yS,
            ySize: option.ySize,
        });
        terrainScene.userData = new GameObject();
        terrainScene.userData.m_name = Language.LOCAL_TEXT("Terrain", "地形");
        terrainScene.userData.m_gameObjectType = GameObject.GameObjectType.e_TERRAIN_SYS;
        terrainScene.name = terrainScene.uuid;

        terrainScene.userData.m_terrain_data.m_easing = data.easing;
        terrainScene.userData.m_terrain_data.m_frequency = data.frequency;
        terrainScene.userData.m_terrain_data.m_maxHeight = data.maxHeight;
        terrainScene.userData.m_terrain_data.m_minHeight = data.minHeight;
        terrainScene.userData.m_terrain_data.m_steps = data.steps;
        terrainScene.userData.m_terrain_data.m_xSegments = data.xS;
        terrainScene.userData.m_terrain_data.m_xSize = data.xSize;
        terrainScene.userData.m_terrain_data.m_ySegments = data.yS;
        terrainScene.userData.m_terrain_data.m_ySize = data.ySize;
        return terrainScene;
    }

    static createBaseWater()
    {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        var wnormalMap0Texture = gameScene.m_textureRepos.get(wnormalMap0);
        var wnormalMap0Texture = gameScene.m_textureRepos.get(wnormalMap1);
        const waterGeometry = new THREE.PlaneGeometry( 20, 20 );
        var water = new Water( waterGeometry, {
            color: new THREE.Color(1, 1, 1),
            scale: 4,
            flowDirection: new THREE.Vector2( 1, 1 ),
            textureWidth: 1024,
            textureHeight: 1024,
            normalMap0: wnormalMap0Texture,
            normalMap1: wnormalMap0Texture
        } );

        water.position.y = 1;
        water.rotation.x = Math.PI * - 0.5;

        water.userData = new GameObject();
        water.userData.m_name = Language.LOCAL_TEXT("Water Base", "基础水");
        water.userData.m_gameObjectType = GameObject.GameObjectType.e_WATER_BASE;
        water.name = water.uuid;

        var value = water.userData.m_water_data.getJsonForUI();
        water.material.uniforms[ 'color' ].value.set( value.m_color );
        water.material.uniforms[ 'config' ].value.w = value.m_scale;
        water.material.uniforms[ 'flowDirection' ].value.x = value.m_flowX;
        water.material.uniforms[ 'flowDirection' ].value.y = value.m_flowY;
        water.material.uniforms[ 'flowDirection' ].value.normalize();

        return water;
    }
}

export { GeometryFactory };