import * as THREE from 'three';
import { MainObjects } from '../common/MainObjects';
import { GameObject } from '../object/GameObject';

class LensFlareBp {
    constructor() {

    }

    getLensFlareProp(obj)
    {
        var prop =
        {
            type : obj.userData.m_gameObjectType,
            size : 700,
            dis : 0.6,
            color : "#ffffff",
            colorArray : [1, 1, 1] 
        }
        prop.size = obj.userData.m_dom_List[0].size;
        prop.dis = obj.userData.m_dom_List[1].distance;
        prop.color = "#" + obj.userData.m_dom_List[0].color.getHexString();
        prop.colorArray[0] = obj.userData.m_dom_List[0].color.r;
        prop.colorArray[1] = obj.userData.m_dom_List[0].color.g;
        prop.colorArray[2] = obj.userData.m_dom_List[0].color.b;
        return prop;
    }

    setLensFlareProp(obj, prop) {
        if(prop != undefined)
        {
            obj.userData.m_dom_List[0].size = prop.size;
            obj.userData.m_dom_List[1].size = prop.size / 10 - 10;
            obj.userData.m_dom_List[2].size = prop.size / 10;
            obj.userData.m_dom_List[3].size = prop.size / 5;
            obj.userData.m_dom_List[4].size = prop.size / 10;
    
            obj.userData.m_dom_List[1].distance = prop.dis;
            obj.userData.m_dom_List[2].distance = Math.min(prop.dis + 0.1, 1);
            obj.userData.m_dom_List[3].distance = Math.min(prop.dis + 0.2, 1);
            obj.userData.m_dom_List[4].distance = 1;
    
            obj.userData.m_dom_List[0].color.fromArray(prop.colorArray);
            obj.userData.m_dom_List[1].color.fromArray(prop.colorArray);
            obj.userData.m_dom_List[2].color.fromArray(prop.colorArray);
            obj.userData.m_dom_List[3].color.fromArray(prop.colorArray);
            obj.userData.m_dom_List[4].color.fromArray(prop.colorArray);
        }

    }

}

export { LensFlareBp };