<template>
    <div>
        <div v-show="readerC">
            <RenderCamera v-show="readerC" ref="myChild" />
        </div>
        <div class="box" :class="isActive?'anmDiv':'aaa'">
           <div class="header" v-show="headerState">
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
               <p>参数设置</p>
           </div>
           <div v-show="AreaSate">
                <div class="color">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="color" @change="AreaColorChange()" @active-change="AreaActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="AreaColorImport()">
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">强度</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" oninput="if(value>999999)value=999999;if(value<0)value=''" v-model="intensity" placeholder="0" @focus="Focus" @blur="Blur"/>
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">高度</div>
                    <div class="txt_box">
                        <input type="number" oninput="if(value>999999)value=999999;if(value<=1)value=1" @change="changeNum($event)" v-model="Height" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">宽度</div>
                    <div class="txt_box">
                        <input type="number" oninput="if(value>999999)value=999999;if(value<=1)value=1" @change="changeNum($event)" v-model="Width" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <!-- <div class="projection">
                    <div class="title">阴影</div>
                    <div class="ipt_box">
                        <input class="input" @change="AreaChange($event)" v-model="castShadow" type="checkbox" />
                    </div>
                </div> -->
                <div class="projection">
                    <div class="title">实时</div>
                    <div class="ipt_box">
                        <input class="input" @change="AreaisRealtimeChange($event)" v-model="isRealtime" type="checkbox" />
                    </div>
                </div>
           </div>
           <div v-show="DirState">
               <div class="color">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="color" @change="DirColorChange()" @active-change="DirActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="DirColorImport()">
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">强度</div>
                    <div class="txt_box">
                        <input type="number"  @change="changeNum($event)" oninput="if(value>999999)value=999999;if(value<0)value=''" min="0" v-model="intensity" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">阴影</div>
                    <div class="ipt_box">
                        <input class="input" @change="DirCastShadowChange($event)" v-model="castShadow" type="checkbox" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">实时</div>
                    <div class="ipt_box">
                        <input class="input" @change="DirIsRealtimeChange($event)" v-model="isRealtime" type="checkbox" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title" style="width:3.58073vw">尺寸</div>
                    <div class="txt_box">
                        <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="mapSize_size" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏差</div>
                    <div class="slider">
                        <el-slider v-model="bias" @input="dirSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">模糊</div>
                    <div class="slider">
                        <el-slider v-model="blurSamples" @input="dirSlider()" show-input :min="1" :max="10" :step="1" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏移</div>
                    <div class="slider">
                        <el-slider v-model="normalBias" @input="dirSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">半径</div>
                    <div class="slider">
                        <el-slider v-model="radius" @input="dirSlider()" show-input :min="1" :max="5" :step="0.001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="projection">
                    <div class="title" style="width:3.58073vw">阴影相机</div>
                    <div class="ipt_box" style="margin-left:1.43229vw">
                        <input class="input" @change="dirShadowOff($event)" v-model="camera_visible" type="checkbox" />
                    </div>
                </div>
                <div class="shadown">
                    <div class="title">阴影相机</div>
                    <div class="shaBox">
                        <div class="sha">
                            <div class="txt">近平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_near" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">远平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_far" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">边平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_side" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                    </div>
                </div>
           </div>
           <div v-show="PointState">
               <div class="color">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="color" @change="PointColorChange()" @active-change="PointActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="PointColorImport()">
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">强度</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" oninput="if(value>999999)value=999999;if(value<0)value=''" min="0" v-model="intensity" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                 <div class="parameter">
                    <div class="title">距离</div>
                    <div class="txt_box">
                         <input type="number" @change="changeNum($event)" v-model="distance" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">阴影</div>
                    <div class="ipt_box">
                        <input class="input" @change="PointCastChange($event)" v-model="castShadow" type="checkbox" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">实时</div>
                    <div class="ipt_box">
                        <input class="input" @change="PointIsRealtimeChange($event)" v-model="isRealtime" type="checkbox" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title" style="width:3.90625vw">尺寸</div>
                    <div class="txt_box">
                        <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" v-model="mapSize_size" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏差</div>
                    <div class="slider">
                        <el-slider v-model="bias" @input="pointSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">模糊</div>
                    <div class="slider">
                        <el-slider v-model="blurSamples" @input="pointSlider()" show-input :min="1" :max="10" :step="1" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏移</div>
                    <div class="slider">
                        <el-slider v-model="normalBias" @input="pointSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">半径</div>
                    <div class="slider">
                        <el-slider v-model="radius" @input="pointSlider()" show-input :min="1" :max="5" :step="0.001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <!-- <div class="projection">
                    <div class="title" style="width:3.58073vw">阴影相机</div>
                    <div class="ipt_box" style="margin-left:1.43229vw">
                        <input class="input" @change="pointShadowOff($event)" v-model="camera_visible" type="checkbox" />
                    </div>
                </div> -->
                <!-- <div class="shadown">
                    <div class="title">阴影相机</div>
                    <div class="shaBox" >
                        <div class="sha">
                            <div class="txt">近平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_near" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">远平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_far" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">边平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_side" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                    </div>
                </div> -->
           </div>
           <div v-show="SpotState">
               <div class="color">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="color" @change="SpotColorChange()" @active-change="SpotActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="SpotColorImport()">
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">强度</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" oninput="if(value>999999)value=999999;if(value<0)value=''" min="0" v-model="intensity" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                 <div class="parameter">
                    <div class="title">距离</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" min="0" v-model="distance" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">散射角度</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" v-model="angle" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">阴影</div>
                    <div class="ipt_box">
                        <input class="input" @change="SpotCastChange($event)" v-model="castShadow" type="checkbox" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">实时</div>
                    <div class="ipt_box">
                        <input class="input" @change="SpotIsRealtimeChange($event)" v-model="isRealtime" type="checkbox" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title" style="width:3.90625vw">尺寸</div>
                    <div class="txt_box">
                        <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="mapSize_size" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏差</div>
                    <div class="slider">
                        <el-slider v-model="bias" @input="spotSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">模糊</div>
                    <div class="slider">
                        <el-slider v-model="blurSamples" @input="spotSlider()" show-input :min="1" :max="10" :step="1" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏移</div>
                    <div class="slider">
                        <el-slider v-model="normalBias" @input="spotSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">半径</div>
                    <div class="slider">
                        <el-slider v-model="radius" @input="spotSlider()" show-input :min="1" :max="5" :step="0.001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">聚焦</div>
                    <div class="slider">
                        <el-slider v-model="focus" @input="spotSlider()" show-input :min="0" :max="1" :step="0.01" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <!-- <div class="projection">
                    <div class="title" style="width:3.58073vw">阴影相机</div>
                    <div class="ipt_box" style="margin-left:1.43229vw">
                        <input class="input" @change="spotShadowOff($event)" v-model="camera_visible" type="checkbox" />
                    </div>
                </div> -->
                <!-- <div class="shadown">
                    <div class="title">阴影相机</div>
                    <div class="shaBox">
                        <div class="sha">
                            <div class="txt">近平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_near" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">远平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_far" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">边平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_side" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                    </div>
                </div> -->
           </div>
           <div v-show="OrthoState">
               <div class="parameter">
                    <div class="title">投影</div>
                    <div class="txt_box">
                        <input type="text" value="正交"  readonly="readonly" @focus="Focus" @blur="Blur"/>
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">远视角</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" v-model="far" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">近视角</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" v-model="near" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">焦距</div>
                    <div class="txt_box">
                         <input type="number" @change="changeNum($event)" v-model="zoom" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
           </div>
           <div v-show="PerspState">
               <div class="metallicity">
                    <div class="title">FOV轴</div>
                    <div class="slider">
                        <el-slider v-model="fov" @input="fovChange()" show-input  :show-tooltip="false"></el-slider>
                    </div>
                    <!-- <div class="fov_box">
                         <input class="ipt" type="text" readonly="readonly" v-model="fov" />
                    </div> -->
                </div>
               <div class="parameter">
                    <div class="title">投影</div>
                    <div class="txt_box">
                        <input type="text" value="透视"  readonly="readonly" @focus="Focus" @blur="Blur"/>
                    </div>
                </div>
               <div class="parameter">
                    <div class="title">远视角</div>
                    <div class="txt_box">
                         <input type="number" @change="changeNum($event)" v-model="far" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">近视角</div>
                    <div class="txt_box">
                         <input type="number" @change="changeNum($event)" v-model="near" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <!-- <div class="parameter">
                    <div class="title">焦距</div>
                    <div class="txt_box">
                         <input type="number" @change="changeNum($event)" v-model="zoom" @focus="Focus" @blur="Blur" />
                    </div>
                </div> -->
           </div>
           <div v-show="shadowState">
               <div class="render">
                    <div class="title">渲染顺序</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" min="0" name="" id="" v-model="renderOrder" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="projection shadow">
                    <div class="title">投射阴影</div>
                    <div class="ipt_box">
                        <input class="input" type="checkbox" @change="projectChange($event)" v-model="castShadow" />
                    </div>
                </div>
                <div class="projection shadow">
                    <div class="title">接受阴影</div>
                    <div class="ipt_box">
                        <input class="input" type="checkbox" @change="takeChange($event)" v-model="receiveShadow" />
                    </div>
                </div>
            </div>
            <div class="curve" v-show="curveStae">
                <div class="parameter">
                    <div class="title">编辑</div>
                    <div class="compile">
                        <div class="not" @click="addCurve">增加</div>
                        <div class="not" @click="removeCurve">减少</div>
                    </div>
                </div>
                <!-- <div class="parameter">
                    <div class="title">平滑度</div>
                    <div class="txt_box" style="margin-left: 1.10677vw;">
                        <input type="text" v-model="smoothCount" oninput="if(value>999999)value=999999;if(value<0)value=''" min="0"  placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div> -->
            </div>
            <div class="lod" v-show="lodState">
                <div class="txt">LOD属性:</div>
                <div class="illumination">
                    <div class="chartlet_box">
                      <div class="chartlet" id="1"><img alt="" /></div>
                      <input type="radio" name="1"   class="input"/>
                    </div>
                    <div class="ipt_box">
                      <input type="number" class="ipt" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="illumination">
                    <div class="chartlet_box">
                      <div class="chartlet" id="1"><img alt="" /></div>
                      <input type="radio" name="1"   class="input"/>
                    </div>
                    <div class="ipt_box">
                      <input type="number" class="ipt" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="illumination">
                    <div class="chartlet_box">
                      <div class="chartlet" id="1"><img alt="" /></div>
                      <input type="radio" name="1"   class="input"/>
                    </div>
                    <div class="ipt_box">
                      <input type="number" class="ipt" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="illumination">
                    <div class="chartlet_box">
                      <div class="chartlet" id="1"><img alt="" /></div>
                      <input type="radio" name="1"   class="input"/>
                    </div>
                    <div class="ipt_box">
                      <input type="number" class="ipt" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="illumination">
                    <div class="chartlet_box">
                      <div class="chartlet" id="1"><img alt="" /></div>
                      <input type="radio" name="1"   class="input"/>
                    </div>
                    <div class="ipt_box">
                      <input type="number" class="ipt" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
            </div>
            <div class="curve" v-show="centralState">
                <div class="selectBox">
                   <p>中心点位置</p>
                   <div class="ipt_box">
                        <!-- <select class="select" v-model="select" @change="SelectChange()">
                            <option v-for="(item,index) in selectList" :key="index">{{item}} </option>
                        </select> -->
                        <el-select class="select" @change="SelectChange()" v-model="select" placeholder="请选择">
                            <el-option
                                v-for="item in selectList"
                                :key="item"
                                :label="item"
                                :value="item">
                            </el-option>
                        </el-select>
                    </div>
               </div>
               <div class="render">
                    <div class="title">渲染顺序</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" min="0" name="" id="" v-model="renderOrder" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="projection shadow">
                    <div class="title">投射阴影</div>
                    <div class="ipt_box">
                        <input class="input" type="checkbox" @change="projectChange($event)" v-model="castShadow" />
                    </div>
                </div>
                <div class="projection shadow">
                    <div class="title">接受阴影</div>
                    <div class="ipt_box">
                        <input class="input" type="checkbox" @change="takeChange($event)" v-model="receiveShadow" />
                    </div>
                </div>
            </div>
            <div v-show="audioState">
                <div class="projection shadow">
                    <div class="title">展示范围</div>
                    <div class="ipt_box">
                        <input class="input" type="checkbox" @change="displayChange($event)" v-model="displayRange" />
                    </div>
                </div>
                <div class="play">
                    <div class="title">播放</div>
                    <div @click="startChange">
                        <div class="start" v-show="!play">{{textPlay}}</div>
                        <div class="stop" v-show="play">{{textStop}}</div>
                    </div>
                </div>
               <!-- <div class="render">
                    <div class="title">最大距离</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" min="0" name="" id="" v-model="maxinum" @focus="Focus" @blur="Blur">
                    </div>
                </div> -->
                <div class="render">
                    <div class="title">衰减度</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" min="0" name="" id="" v-model="degree" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="render">
                    <div class="title">衰减距离</div>
                    <div class="txt_box">
                        <input type="number" @change="changeNum($event)" min="0" name="" id="" v-model="degreeDistance" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="projection loop">
                    <div class="title">循环</div>
                    <div class="ipt_box">
                        <input class="input" @change="loopChange($event)" v-model="loop" type="checkbox" />
                    </div>
                </div>
            </div>
            <div v-show="skyState">
                <div class="color">
                    <div class="title">天空</div>
                    <el-color-picker v-model="skyColor" @change="skyColorChange()" @active-change="skyActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="skyColor" @focus="Focus" @blur="Blur" @input="skyColorImport()">
                    </div>
                </div>
                <div class="color">
                    <div class="title">地面</div>
                    <el-color-picker v-model="floorColor" @change="floorColorChange()" @active-change="floorActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="floorColor" @focus="Focus" @blur="Blur" @input="floorColorImport()">
                    </div>
                </div>
                <!-- <div class="selectBox selectBox1">
                   <p>平行光</p>
                   <div class="ipt_box">
                        <el-select class="select" @change="LightSelectChange()" v-model="light" placeholder="请选择">
                            <el-option
                                v-for="item in LightSelectList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
               </div>
               <div class="metallicity">
                    <div class="title">浊度</div>
                    <div class="slider">
                        <el-slider v-model="turbidity" @input="skyChange()" show-input :min="0.0" :max="20.0" :step="0.1" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">雷射强度</div>
                    <div class="slider">
                        <el-slider v-model="laser" @input="skyChange()" show-input :min="0.0" :max="4" :step="0.001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">影响因子</div>
                    <div class="slider">
                        <el-slider v-model="factor" @input="skyChange()" show-input :min="0" :max="0.1" :step="0.00001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">全局照明</div>
                    <div class="slider">
                        <el-slider v-model="lightMass" @input="skyChange()" :min="0.0" :max="1" :step="0.001" show-input :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">垂直角</div>
                    <div class="slider">
                        <el-slider v-model="vertical" @input="skyChange()" :min="0" :max="90" :step="0.1" show-input :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">方位角</div>
                    <div class="slider">
                        <el-slider v-model="azimuth" @input="skyChange()" :min="-180" :max="180" :step="0.1" show-input :show-tooltip="false"></el-slider>
                    </div>
                </div> -->
           </div>
           <div v-show="RealTimeSky">
            <div class="render">
                    <div class="title">尺寸倍数</div>
                    <div class="txt_box">
                        <input type="number" @change="RealTimeNum($event)" min="0" name="" id="" v-model="skySize" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="render">
                    <div class="title">太阳半径</div>
                    <div class="txt_box">
                        <input type="number" @change="RealTimeNum($event)" min="0" name="" id="" v-model="skyLightRadius" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="render">
                    <div class="title">当前时间</div>
                    <div class="txt_box">
                        <input type="number" @change="RealTimeNum($event)" min="0" name="" id="" v-model="currentSkyTime" @input="changeValue" @focus="Focus" @blur="Blur" oninput="if(value>24)value=24;if(value<0)value=0">
                    </div>
                </div>
                <div class="render">
                    <div class="title" style="width:7.8125vw;">太阳升起落下时间</div>
                    <div class="time_box">
                        <div class="txt_box">
                            <input type="number" @change="RealTimeNum($event)" @input="changeValue1" min="0" name="" id="" v-model="startTime" @focus="Focus" @blur="Blur" oninput="if(value>24)value=24;if(value<0)value=0">
                        </div>
                        <div class="txt_box">
                            <input type="number" @change="RealTimeNum($event)" @input="changeValue2" min="0" name="" id="" v-model="endTime" @focus="Focus" @blur="Blur" oninput="if(value>24)value=24;if(value<0)value=0">
                        </div>
                    </div>
                </div>
                <div class="metallicity" style="margin-top:1.30208vw">
                    <div class="title" >水平角度</div>
                    <div class="slider">
                        <el-slider v-model="m_azimuth" @input="skyChange()" :min="-180" :max="180" :step="0.1" show-input :show-tooltip="false"></el-slider>
                    </div>
                </div>
           </div>
           
           <div v-show="haloState">
                <div class="render">
                    <div class="title">尺寸</div>
                    <div class="txt_box">
                        <input type="number" @change="haloNum($event)" min="0" name="" id="" v-model="size" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="render">
                    <div class="title">距离</div>
                    <div class="txt_box">
                        <input type="number" @change="haloNum($event)" min="0" name="" id="" v-model="dis" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="color color1">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="haloColor" @change="haloColorChange()" @active-change="haloActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="haloColorImport()">
                    </div>
                </div>
           </div>
           <!-- 天空平行光 -->
           <div v-show="skyScalarState">
               <div class="color">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="color" @change="skyScalarColorChange()" @active-change="skyScalarActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="color" @focus="Focus" @blur="Blur" @input="skyScalarColorImport()">
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">强度</div>
                    <div class="txt_box">
                        <input type="number"  @change="changeNum($event)" oninput="if(value>999999)value=999999;if(value<0)value=''" min="0" v-model="intensity" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">阴影</div>
                    <div class="ipt_box">
                        <input class="input" @change="skyScalarCastShadowChange($event)" v-model="castShadow" type="checkbox" />
                    </div>
                </div>
                <div class="projection">
                    <div class="title">实时</div>
                    <div class="ipt_box">
                        <input class="input" @change="skyScalarIsRealtimeChange($event)" v-model="isRealtime" type="checkbox" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title" style="width:3.58073vw">尺寸</div>
                    <div class="txt_box">
                        <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="mapSize_size" placeholder="0" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏差</div>
                    <div class="slider">
                        <el-slider v-model="bias" @input="skyScalarSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">模糊</div>
                    <div class="slider">
                        <el-slider v-model="blurSamples" @input="skyScalarSlider()" show-input :min="1" :max="10" :step="1" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">偏移</div>
                    <div class="slider">
                        <el-slider v-model="normalBias" @input="skyScalarSlider()" show-input :min="-0.01" :max="0.01" :step="0.0001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="metallicity">
                    <div class="title">半径</div>
                    <div class="slider">
                        <el-slider v-model="radius" @input="skyScalarSlider()" show-input :min="1" :max="5" :step="0.001" :show-tooltip="false"></el-slider>
                    </div>
                </div>
                <div class="projection">
                    <div class="title" style="width:3.58073vw">阴影相机</div>
                    <div class="ipt_box" style="margin-left:1.43229vw">
                        <input class="input" @change="skyScalarShadowOff($event)" v-model="camera_visible" type="checkbox" />
                    </div>
                </div>
                <div class="shadown">
                    <div class="title">阴影相机</div>
                    <div class="shaBox">
                        <div class="sha">
                            <div class="txt">近平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_near" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">远平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_far" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                        <div class="sha">
                            <div class="txt">边平面</div>
                            <div class="txt_box">
                                <input type="number"  @change="shadowNum($event)" oninput="if(value>100000)value=100000;if(value<0)value=0" min="0" v-model="camera_side" placeholder="0" @focus="Focus" @blur="Blur" />
                            </div>
                        </div>
                    </div>
                </div>
           </div>
           <!-- 地形 -->
           <div v-show="TerrainState">
                <!-- <div class="render">
                    <div class="title">频率</div>
                    <div class="txt_box">
                        <input type="number" @change="terrainNum($event)" min="0" name="" id="" v-model="frequency" @focus="Focus" @blur="Blur">
                    </div>
                </div> -->
                <div class="render">
                    <div class="title">最高</div>
                    <div class="txt_box">
                        <input type="number" @change="terrainNum($event)" min="0" name="" id="" v-model="maxHeight" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="render">
                    <div class="title">最低</div>
                    <div class="txt_box">
                        <input type="number" @change="terrainNum($event)" min="0" name="" id="" v-model="minHeight" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="render">
                    <div class="title">步长</div>
                    <div class="txt_box">
                        <input type="number" @change="terrainNum($event)" min="0" name="" id="" v-model="stepSize" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="loction1">
                   <div class="title">分段</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="subsectionX" type="number" @change="terrainNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="subsectionY" type="number" @change="terrainNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
               </div>
               <div class="loction1">
                   <div class="title">尺寸</div>
                   <div class="x-axis axis">
                       <p>X</p>
                       <input v-model="sizeX"  type="number" @change="terrainNum($event)"  @focus="Focus" @blur="Blur" />
                   </div>
                   <div class="y-axis axis">
                       <p>Y</p>
                       <input v-model="sizeY" type="number" @change="terrainNum($event)"  @focus="Focus" @blur="Blur"/>
                   </div>
               </div>
               <div class="conColor">
                    <div class="title">纹理</div>
                    <div class="chartlet_box">
                        <div class="chartlet" id="2"><img :src="textureMap" alt="" /></div>
                        <input type="radio" name="1" @click="textureMapChange($event)" class="input" :checked="checked" />
                    </div>
                </div>
                <div class="conColor">
                    <div class="title">高度图</div>
                    <div class="chartlet_box">
                        <div class="chartlet" id="2"><img :src="heightMap" alt="" /></div>
                        <input type="radio" name="1" @click="heightMapChange($event)" class="input" :checked="checked"/>
                    </div>
                </div>
                <div class="render">
                    <div class="title">草地高度</div>
                    <div class="txt_box">
                        <input type="number" @change="grassNumChange($event)" min="0" name="" id="" v-model="grassHeight" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="render">
                    <div class="title">草地数量</div>
                    <div class="txt_box">
                        <input type="number" @change="grassNumChange($event)" min="0" name="" id="" v-model="grassNum" @focus="Focus" @blur="Blur">
                    </div>
                </div>
                <div class="conColor">
                    <div class="title">草地图</div>
                    <div class="chartlet_box">
                        <div class="chartlet" id="2"><img :src="grassMap" alt="" /></div>
                        <input type="radio" name="1" @click="grassMapChange($event)" class="input" :checked="checked"/>
                    </div>
                </div>
                <!-- <div class="conColor">
                    <div class="title">混合</div>
                    <div class="add" @click="addAction">
                        添加
                    </div>
                </div>
                <div class="resourceTabel">
                    <el-table :data="actionList" border height="24.08854vw" :row-style="tableRowStyle"
                        :header-cell-style="tableHeaderColor" style="width: 26.04167vw" :resizable="false">
                        <el-table-column prop="actionStart" label="图" align="center" width="75">
                            <template slot-scope="scope">
                                <div class="chartlet_box1">
                                    <div class="chartlet" id="2"><img :src="scope.img" alt="" /></div>
                                    <input type="radio" name="1" @click="mixtureMapChange(scope.row)" class="input" :disabled="disabled"/>
                                </div>
                                
                            </template>
                        </el-table-column>
                        <el-table-column prop="actionEnd" label="开始高度" align="center" width="75">
                            <template slot-scope="scope">
                                <el-input style="width:2.60417vw" v-model="scope.row.startHeight" @change="mixtureNum(scope.row)" placeholder="" @focus="Focus"
                                    @blur="Blur"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bLoop" label="融合开始" align="center" width="75">
                            <template slot-scope="scope">
                                <el-input style="width:2.60417vw" @change="mixtureNum(scope.row)" v-model="scope.row.fuseStart" placeholder="" @focus="Focus"
                                    @blur="Blur"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="bEnd" label="融合结束"  align="center" width="75">
                            <template slot-scope="scope">
                                <el-input style="width:2.60417vw" @change="mixtureNum(scope.row)" v-model="scope.row.fuseEnd" placeholder="" @focus="Focus"
                                    @blur="Blur"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="time" label="结束高度" align="center" width="75">
                            <template slot-scope="scope">
                                <el-input style="width:2.60417vw" @change="mixtureNum(scope.row)" v-model="scope.row.finishHeight" placeholder="" @focus="Focus"
                                    @blur="Blur"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="address" align="center" label="操作" width="50">
                            <template slot-scope="scope">
                                <button class="remove" @click="actionRemoval(scope.row)">-</button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div> -->
           </div>
           <!-- 水的属性 -->
           <div v-show="waterState">
                <div class="color">
                    <div class="title">颜色</div>
                    <el-color-picker v-model="waterColor" @change="waterColorChange()" @active-change="waterActiveColor" popper-class="picker"></el-color-picker>
                    <div class="import_color">
                        <input type="text" v-model="waterColor" @focus="Focus" @blur="Blur" @input="waterColorImport()">
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">波尺寸</div>
                    <div class="txt_box">
                        <input type="number" @change="waterNum($event)" oninput="if(value>999999)value=999999;if(value<0.01)value=0.01" :step="0.01" v-model="m_scale" placeholder="0" @focus="Focus" @blur="Blur"/>
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">x方向</div>
                    <div class="txt_box">
                        <input type="number" @change="waterNum($event)" v-model="m_flowX" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
                <div class="parameter">
                    <div class="title">y方向</div>
                    <div class="txt_box">
                        <input type="number" @change="waterNum($event)" v-model="m_flowY" @focus="Focus" @blur="Blur" />
                    </div>
                </div>
           </div>
        </div>
    </div>
</template>
<script>
import { IMaterial } from '../../threejs/interface/IMaterial';
import { IParameter } from "../../threejs/interface/IParameter";
import { ITransform } from "../../threejs/interface/ITransform";
import { ISetting } from "../../threejs/interface/ISetting";
import { ICamera } from "../../threejs/interface/ICamera"
import RenderCamera from "./RenderCamera";
export default {
    data(){
        return{
            headerState:false,
            DirState:false,//平行光
            PointState:false,//点光源
            SpotState:false,//聚光灯
            AreaSate:false,//区域光
            OrthoState:false,//摄像机参数设置
            PerspState:false,//透视参数设置
            shadowState:false,//网格
            audioState:false,//音频
            skyState:false,//天空属性
            RealTimeSky:false,//实时天空
            TerrainState:false,//地形
            waterState:false,//水
            haloState:false,
            readerC:false,
            isActive:true,
            curveStae:false,//曲线
            lodState:false,//lod
            centralState:false,//中心点
            color:'#FFFFFF',//颜色
            skyScalarState:false,//天空平行光
            Width:'',
            Height:'',
            angle:'',//角度
            distance:'',//距离
            castShadow:'',//阴影
            intensity:'',//强度
            type:'',//类型
            renderOrder:'',//渲染顺序
            castShadow:'',//投射阴影
            receiveShadow:'',//接受阴影
            displayRange:'',//展示范围
            isRealtime:'',//实时
            penumbra:0,//半影

            far : '',
            near : '',
            zoom : '',
            fov : 0,
            smoothCount:"",//曲线平滑度
            select:"",
            selectList:[],
            light:"",
            LightSelectList:[],
            play:false,//播放
            textPlay:'开始',
            textStop:'暂停',
            maxinum:0,//最大距离
            degree:0,//衰减度
            degreeDistance:0,//衰减距离
            turbidity:0,//浊度
            laser:0,//镭射强度
            factor:0,//影响因子
            lightMass:0,//全局光照
            vertical:0,//垂直角
            azimuth:0,//方位角
            exposure:0,//曝光
            size:"",//尺寸
            dis:"",//距离
            haloColor:"#FFFFFF",//光晕颜色
            loop:false,//循环
            typeId:"",
            bias:0,//偏差
            blurSamples:0,//模糊
            normalBias:0,//偏移
            radius:0,//半径，
            focus:0,//聚焦
            camera_bottom:0,//下平面
            camera_side:0,//上平面
            camera_left:0,//左平面
            camera_right:0,//右平面
            camera_far:0,//远平面
            camera_near:0,//近平面
            mapSize_width:0,//大小：宽
            mapSize_height:0,//大小：高
            mapSize_size:0,//尺寸
            camera_fov:0,//fov
            camera_visible:false,
            skyColor:"#FFFFFF",
            floorColor:"#FFFFFF",
            startTime: 0,
            endTime: 0,
            currentSkyTime:0,
            skySize:"",//天空尺寸倍数
            skyLightRadius:"",//太阳半径
            m_azimuth:0,//水平角度
            height_img:"",
            textureMap:"",//纹理图
            heightMap:"",//高度图
            grassMap:"",//草图
            checked:false,
            frequency:"",//频率
            maxHeight:"",//最高
            minHeight:"",//最低
            stepSize:"",//步长
            subsectionX:"",//分段X
            subsectionY:"",//分段Y
            sizeX:"",//尺寸X
            sizeY:"",//尺寸Y
            actionList: [
                {
                    img: "",
                    startHeight: "",
                    fuseStart: "",
                    fuseEnd: "",
                    finishHeight: "",
                    id: 1,
                },
            ],
            currentIndex: 1,
            waterColor:"#FFFFFF",//水的颜色
            m_scale:"",
            m_flowX:"",
            m_flowY:"",
            grassHeight:"",
            grassNum:"",
        }
    },
    components:{
        RenderCamera
    },
    mounted(){
        // ITransform.f_selectTransformCallback = this.IParameterChange.bind(this);
    },
    methods:{
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },
        IParameterChange(){
            let IParameterList =  IParameter.getProperty();
            if(IParameterList != null){
                this.headerState = true;
                let type = IParameterList.type;
                this.typeId = type;
                let toAngle = IParameterList.angle;
                this.Width=IParameterList.width;
                this.Height=IParameterList.height,
                this.distance=IParameterList.distance;//距离
                this.castShadow=IParameterList.castShadow;//阴影
                this.intensity=IParameterList.intensity;//强度
                this.isRealtime = IParameterList.isRealtime;
    
                if(IParameterList != null && type != 2 && type != 7 && type != 8){
                    this.color  = IParameterList.color;
                }
                // console.log("type",type,IParameterList)
                if(type==2 || type==11){
                    this.renderOrder = IParameterList.renderOrder;
                    this.castShadow = IParameterList.castShadow;
                    this.receiveShadow = IParameterList.receiveShadow;
                    this.shadowState = true;
                    this.readerC = false;
                }else{
                    this.shadowState = false;
                }
                if(type == 16){
                    this.readerC = true;
                }
                if(type == 17){
                    this.audioState = true;
                    this.play = IParameterList.bPlay;
                    this.displayRange = IParameterList.bShowRange;
                    this.maxinum = IParameterList.max;
                    this.degree = IParameterList.rolloffFactor;
                    this.degreeDistance = IParameterList.refDistance;
                    this.loop = IParameterList.bLoop;
                    
                }else{
                    this.audioState = false;
                }
                if(type == 18){
                    this.skyState = true;
                    this.skyColor = IParameterList.topColor;
                    this.floorColor = IParameterList.bottomColor;
                    // this.turbidity = IParameterList.turbidity;
                    // this.laser= IParameterList.rayleigh;
                    // this.factor = IParameterList.mieCoefficient;
                    // this.lightMass = IParameterList.mieDirectionalG;
                    // this.vertical = IParameterList.elevation;
                    // this.azimuth = IParameterList.azimuth;
                    // // console.log(IParameter.getDirLightList())
                    // this.LightSelectList = IParameter.getDirLightList();
                    // this.light = IParameterList.dirId;
                }else{
                    this.skyState = false;
                }
                if(type == 21){
                    this.RealTimeSky = true;
                    this.m_azimuth = IParameterList.azimuth;
                    this.currentSkyTime = (IParameterList.currentSkyTime).toFixed(2);
                    this.startTime = (IParameterList.dayTime[0]).toFixed(2);
                    this.endTime = (IParameterList.dayTime[1]).toFixed(2);
                    this.skyLightRadius = IParameterList.skyLightRadius;
                    this.skySize = IParameterList.skySize;
                }else{
                    this.RealTimeSky = false;
                }
                if(type == 22){
                    this.skyScalarState = true;
                    this.bias = IParameterList.shadow.bias;
                    this.blurSamples = IParameterList.shadow.blurSamples;
                    this.normalBias = IParameterList.shadow.normalBias;
                    this.radius = IParameterList.shadow.radius;
                    this.mapSize_size = IParameterList.shadow.mapSize;
                    this.camera_near = IParameterList.shadow.camera_near;
                    this.camera_far = IParameterList.shadow.camera_far;
                    this.camera_side = IParameterList.shadow.camera_side;
                    this.camera_visible = IParameterList.shadow.camera_visible;
                }else{
                    this.skyScalarState = false;
                }
                if(type == 24){
                    this.TerrainState = true;
                    this.frequency = IParameterList.m_frequency;
                    this.maxHeight = IParameterList.m_maxHeight;
                    this.minHeight = IParameterList.m_minHeight;
                    this.stepSize = IParameterList.m_steps;
                    this.sizeX = IParameterList.m_xSize;
                    this.sizeY = IParameterList.m_ySize;
                    this.subsectionX = IParameterList.m_xSegments;
                    this.subsectionY = IParameterList.m_ySegments;
                    if(IParameterList.m_heightmap_src == null){
                        this.heightMap = IParameterList.m_heightmap_src;
                    }else{
                        // this.checked = true;
                        this.heightMap = IParameterList.m_heightmap_src.currentSrc;
                    }
                    if(IParameterList.m_base_texture_image == null){
                        this.textureMap = IParameterList.m_base_texture_image;
                    }else{
                        // this.checked = true;
                        this.textureMap = IParameterList.m_base_texture_image.currentSrc;
                    }
                    this.grassNum = IParameterList.m_grass_num;
                    this.grassHeight = IParameterList.m_grass_height;
                    if(IParameterList.m_grassmap_src == null){
                        this.grassMap = IParameterList.m_grassmap_src;
                    }else{
                        this.grassMap = IParameterList.m_grassmap_src.currentSrc;
                    }
                }else{
                    this.TerrainState = false;
                }
                if(type == 25){
                    this.waterState = true;
                    this.waterColor = IParameterList.m_color;
                    this.m_flowX = IParameterList.m_flowX;
                    this.m_flowY = IParameterList.m_flowY;
                    this.m_scale = IParameterList.m_scale;
                }else{
                    this.waterState = false;
                }
                if(type == 19){
                    this.haloState = true;
                    this.size = IParameterList.size;
                    this.dis = IParameterList.dis;
                    this.haloColor = IParameterList.color;
                }else{
                    this.haloState = false;
                }
                if(type==3){
                    this.DirState = true;
                    this.readerC = false;
                    this.bias = IParameterList.shadow.bias;
                    this.blurSamples = IParameterList.shadow.blurSamples;
                    this.normalBias = IParameterList.shadow.normalBias;
                    this.radius = IParameterList.shadow.radius;
                    this.mapSize_size = IParameterList.shadow.mapSize;
                    // this.mapSize_height = IParameterList.shadow.mapSize_height;
                    this.camera_near = IParameterList.shadow.camera_near;
                    this.camera_far = IParameterList.shadow.camera_far;
                    this.camera_side = IParameterList.shadow.camera_side;
                    // this.camera_bottom = IParameterList.shadow.camera_bottom;
                    // this.camera_left = IParameterList.shadow.camera_left;
                    // this.camera_right = IParameterList.shadow.camera_right;
                    this.camera_visible = IParameterList.shadow.camera_visible;
                }else{
                    this.DirState = false;
                }
                if(type == 4){
                    this.PointState = true;
                    this.readerC = false;
                    this.blurSamples = IParameterList.shadow.blurSamples;
                    this.normalBias = IParameterList.shadow.normalBias;
                    this.radius = IParameterList.shadow.radius;
                    this.mapSize_size = IParameterList.shadow.mapSize;
                    // this.mapSize_height = IParameterList.shadow.mapSize_height;
                    this.camera_near = IParameterList.shadow.camera_near;
                    this.camera_far = IParameterList.shadow.camera_far;
                    this.camera_side = IParameterList.shadow.camera_side;
                    this.camera_visible = IParameterList.shadow.camera_visible;
                    
                    // let color = this.color.slice(1);
                    // let rgba = [
                    //     parseInt('0x'+color.slice(0, 2)),
                    //     parseInt('0x'+color.slice(2, 4)),
                    //     parseInt('0x'+color.slice(4, 6)),
                    // ];
                    // let color0 = rgba[0]/255;
                    // let color1 = rgba[1]/255;
                    // let color2 = rgba[2]/255;
                    // let lightProp = {
                    //     color:[color0,color1,color2],
                    //     intensity:Number(this.intensity),
                    //     castShadow:this.castShadow,
                    //     distance:Number(this.distance)
                    // }
                    // IParameter.setPointLightProperty(lightProp)
                }else{
                    this.PointState = false;
                }
                if(type == 5){
                    this.angle = toAngle.toFixed(2) ;//角度
                    this.SpotState = true;
                    this.readerC = false;
                    this.blurSamples = IParameterList.shadow.blurSamples;
                    this.normalBias = IParameterList.shadow.normalBias;
                    this.radius = IParameterList.shadow.radius;
                    this.mapSize_size = IParameterList.shadow.mapSize;
                    // this.mapSize_height = IParameterList.shadow.mapSize_height;
                    this.camera_near = IParameterList.shadow.camera_near;
                    this.camera_far = IParameterList.shadow.camera_far;
                    this.camera_side = IParameterList.shadow.camera_side;
                    this.focus = IParameterList.shadow.focus;
                    this.camera_visible = IParameterList.shadow.camera_visible;
                }else{
                    this.SpotState = false;
                }
                if(type == 6){
                    this.AreaSate = true;
                    this.readerC = false;
                }else{
                    this.AreaSate = false;
                }
                if(type == 7){
                    this.PerspState = true;
                    this.readerC = true;
                    this.far = IParameterList.far,
                    this.near= IParameterList.near,
                    // this.zoom=IParameterList.zoom,
                    this.fov=IParameterList.fov;
                    this.$refs.myChild.IParameterCamera();
                }else{
                    this.PerspState = false;
                    // this.readerC =false;
                }
                if(type == 8){
                    this.OrthoState = true;
                    this.readerC = true;
                    this.far = IParameterList.far,
                    this.near= IParameterList.near,
                    this.zoom=IParameterList.zoom
                    this.$refs.myChild.IParameterCamera();
                }else{
                    this.OrthoState = false
                    // this.readerC = false;
                }
                if(type == 1 || type == 10){
                    this.centralState = true;
                    this.readerC = false;
                    // let getGroupPropertyList = IParameter.getGroupProperty();
                    // console.log(getGroupPropertyList)
                    this.selectList = IParameterList.cneterList;
                    this.select = IParameterList.center;
                    this.renderOrder = IParameterList.renderOrder;
                    this.castShadow = IParameterList.castShadow;
                    this.receiveShadow = IParameterList.receiveShadow;
                }else{
                    this.centralState = false;
                }
                if(type == 12){
                    this.curveStae = true;
                    this.readerC = false;
                    this.smoothCount = IParameterList.smoothCount;
                }else{
                    this.curveStae = false;
                }
                if(type ==13){
                    this.lodState = true;
                    this.readerC = false;
                }else{
                    this.lodState = false;
                }
            }else if(IParameterList==null){
                this.headerState = false,
                this.DirState=false,//定向光
                this.PointState=false,//点光源
                this.SpotState=false,//聚光灯
                this.AreaSate=false,//区域光
                this.OrthoState=false,//摄像机参数设置
                this.PerspState=false;//透视参数设置
                this.shadowState = false;//网格
                this.curveStae = false;//曲线
                this.lodState = false;//lod
                this.readerC = false;
                this.centralState = false;
                this.audioState = false;//音频
                this.skyState = false;//天空
                this.haloState = false;//光晕
                this.RealTimeSky = false;//实时天空
                this.skyScalarState = false;//天空平行光
                this.TerrainState = false;//地形
                this.waterState = false;
            }
     
        },
        changeNum(){
            let IParameterList =  IParameter.getProperty();
            let type = IParameterList.type;
            if(type == 17){
                let audioProp = {
                    // max:Number(this.maxinum),
                    rolloffFactor:Number(this.degree),
                    refDistance:Number(this.degreeDistance),
                }
                // console.log(audioProp)
                IParameter.setAudioProperty(audioProp)
            }
            if(type==2 || type==11){
                let meshProp = {
                    renderOrder:Number(this.renderOrder),
                    castShadow:this.castShadow,
                    receiveShadow:this.receiveShadow,
                }
                IParameter.setMeshProperty(meshProp)
            }else if(type == 3){
                let color = this.color.slice(1);
                let rgba = [
                    parseInt('0x'+color.slice(0, 2)),
                    parseInt('0x'+color.slice(2, 4)),
                    parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let lightProp = {
                    color:[color0,color1,color2],
                    intensity:Number(this.intensity),
                    castShadow:this.castShadow,
                    isRealtime:this.isRealtime,
                }
                IParameter.setDirLightProperty(lightProp)
            }else if(type ==22){
                let color = this.color.slice(1);
                let rgba = [
                    parseInt('0x'+color.slice(0, 2)),
                    parseInt('0x'+color.slice(2, 4)),
                    parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let lightProp = {
                    color:[color0,color1,color2],
                    intensity:Number(this.intensity),
                    castShadow:this.castShadow,
                    isRealtime:this.isRealtime,
                }
                IParameter.setDirLightProperty(lightProp)
            }
            else if(type == 4){
                let color = this.color.slice(1);
                let rgba = [
                    parseInt('0x'+color.slice(0, 2)),
                    parseInt('0x'+color.slice(2, 4)),
                    parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let lightProp = {
                    color:[color0,color1,color2],
                    intensity:Number(this.intensity),
                    castShadow:this.castShadow,
                    distance:Number(this.distance),
                    isRealtime:this.isRealtime,
                }
                IParameter.setPointLightProperty(lightProp)
            }else if(type == 5){
                let color = this.color.slice(1);
                let rgba = [
                    parseInt('0x'+color.slice(0, 2)),
                    parseInt('0x'+color.slice(2, 4)),
                    parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let lightProp = {
                    color:[color0,color1,color2],
                    intensity:Number(this.intensity),
                    castShadow:this.castShadow,
                    distance:Number(this.distance),
                    angle:Number(this.angle),
                    isRealtime:this.isRealtime,
                }
                IParameter.setSpotLightProperty(lightProp)
            }else if(type == 6){
                let color = this.color.slice(1);
                let rgba = [
                    parseInt('0x'+color.slice(0, 2)),
                    parseInt('0x'+color.slice(2, 4)),
                    parseInt('0x'+color.slice(4, 6)),
                ];
                let color0 = rgba[0]/255;
                let color1 = rgba[1]/255;
                let color2 = rgba[2]/255;
                let lightProp = {
                    color:[color0,color1,color2],
                    intensity:Number(this.intensity),
                    width:Number(this.Width),
                    height:Number(this.Height),
                    isRealtime:this.isRealtime,
                }
                IParameter.setAreaLightProperty(lightProp)
            }else if(type == 7){
                let cameraProp = {
                    far:Number(this.far),
                    near:Number(this.near),
                    // zoom:Number(this.zoom),
                    fov:Number(this.fov),
                }
                ICamera.setPerspCameraProperty(cameraProp);
            }else if(type == 8){
                let cameraProp = {
                    far:Number(this.far),
                    near:Number(this.near),
                    zoom:Number(this.zoom),
                }
                ICamera.setOrthoCameraProperty(cameraProp);
            }else if(type == 1 || type == 10){
                let prop = {
                    receiveShadow:this.receiveShadow,
                    castShadow:this.castShadow,
                    renderOrder:Number(this.renderOrder)
                }
                // console.log(prop)
                IParameter.setGroupProperty(prop);
            }
        },
        shadowNum(){
            let IParameterList =  IParameter.getProperty();
            let type = IParameterList.type;
            if(type == 3){
                let data = {
                    bias:Number(this.bias),
                    blurSamples:Number(this.blurSamples),
                    camera_far:Number(this.camera_far),
                    camera_near:Number(this.camera_near),
                    camera_side:Number(this.camera_side),
                    mapSize:Number(this.mapSize_size),
                    normalBias:Number(this.normalBias),
                    radius:Number(this.radius),
                    camera_visible:this.camera_visible
                }
                // console.log(data)
                IParameter.setShadow(data)
            }else if(type == 22){
                let data = {
                    bias:Number(this.bias),
                    blurSamples:Number(this.blurSamples),
                    camera_far:Number(this.camera_far),
                    camera_near:Number(this.camera_near),
                    camera_side:Number(this.camera_side),
                    mapSize:Number(this.mapSize_size),
                    normalBias:Number(this.normalBias),
                    radius:Number(this.radius),
                    camera_visible:this.camera_visible
                }
                // console.log(data)
                IParameter.setShadow(data)
            }
            else if(type == 4){
                let data = {
                    bias:Number(this.bias),
                    blurSamples:Number(this.blurSamples),
                    camera_far:Number(this.camera_far),
                    camera_near:Number(this.camera_near),
                    mapSize:Number(this.mapSize_size),
                    normalBias:Number(this.normalBias),
                    radius:Number(this.radius),
                    camera_side:Number(this.camera_side),
                    camera_visible:this.camera_visible
                }
                // console.log(data)
                IParameter.setShadow(data)
            }else if(type == 5){
                let data = {
                    bias:Number(this.bias),
                    blurSamples:Number(this.blurSamples),
                    camera_far:Number(this.camera_far),
                    camera_near:Number(this.camera_near),
                    mapSize:Number(this.mapSize_size),
                    normalBias:Number(this.normalBias),
                    radius:Number(this.radius),
                    camera_side:Number(this.camera_side),
                    focus:Number(this.focus),
                    camera_visible:this.camera_visible
                }
                // console.log(data)
                IParameter.setShadow(data)
            }
        },
        fovChange(){
            let cameraProp = {
                far:Number(this.far),
                near:Number(this.near),
                zoom:Number(this.zoom),
                fov:Number(this.fov),
            }
            ICamera.setPerspCameraProperty(cameraProp);
        },
        skyChange(){
            const skyProp = {
                currentSkyTime: Number(this.currentSkyTime),
                dayTime:[Number(this.startTime),Number(this.endTime)],
                skyLightRadius: Number(this.skyLightRadius),
                skySize: Number(this.skySize),
                azimuth:Number(this.m_azimuth),
            };
            // console.log(skyProp)
            IParameter.setRealTimeSkyProperty(skyProp)
        },
        RealTimeNum(){
            const skyProp = {
                currentSkyTime: Number(this.currentSkyTime),
                dayTime:[Number(this.startTime),Number(this.endTime)],
                skyLightRadius: Number(this.skyLightRadius),
                skySize: Number(this.skySize),
                azimuth:Number(this.m_azimuth),
            };
            // console.log(skyProp)
            IParameter.setRealTimeSkyProperty(skyProp)
        },
        //限制输入
        changeValue(value) {
            this.currentSkyTime = this.currentSkyTime.replace(/[^\d.]/g,""); //清除"数字"和"."以外的字符
            this.currentSkyTime = this.currentSkyTime.replace(/^\./g,""); //验证第一个字符是数字而不是
            this.currentSkyTime = this.currentSkyTime.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
            this.currentSkyTime = this.currentSkyTime.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
            this.currentSkyTime = this.currentSkyTime.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //只能输入两个小数  
        },
        changeValue1(value) {
            this.startTime = this.startTime.replace(/[^\d.]/g,""); //清除"数字"和"."以外的字符
            this.startTime = this.startTime.replace(/^\./g,""); //验证第一个字符是数字而不是
            this.startTime = this.startTime.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
            this.startTime = this.startTime.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
            this.startTime = this.startTime.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //只能输入两个小数  
        },
        changeValue2(value) {
            this.endTime = this.endTime.replace(/[^\d.]/g,""); //清除"数字"和"."以外的字符
            this.endTime = this.endTime.replace(/^\./g,""); //验证第一个字符是数字而不是
            this.endTime = this.endTime.replace(/\.{2,}/g,"."); //只保留第一个. 清除多余的
            this.endTime = this.endTime.replace(".","$#$").replace(/\./g,"").replace("$#$",".");
            this.endTime = this.endTime.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3'); //只能输入两个小数  
        },
        skyColorChange(){
            let color = this.skyColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;

            let floorColor = this.floorColor.slice(1);
            let rgb = [
                parseInt('0x'+floorColor.slice(0, 2)),
                parseInt('0x'+floorColor.slice(2, 4)),
                parseInt('0x'+floorColor.slice(4, 6)),
            ];
            let floorColor0 = rgb[0]/255;
            let floorColor1 = rgb[1]/255;
            let floorColor2 = rgb[2]/255;

            const skyProp = {
                topColorArray:[color0,color1,color2],
                bottomColorArray:[floorColor0,floorColor1,floorColor2]
            };
            IParameter.setSkyProperty(skyProp)
        },
        skyColorImport(){
            let color = this.skyColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;

            let floorColor = this.floorColor.slice(1);
            let rgb = [
                parseInt('0x'+floorColor.slice(0, 2)),
                parseInt('0x'+floorColor.slice(2, 4)),
                parseInt('0x'+floorColor.slice(4, 6)),
            ];
            let floorColor0 = rgb[0]/255;
            let floorColor1 = rgb[1]/255;
            let floorColor2 = rgb[2]/255;

            const skyProp = {
                topColorArray:[color0,color1,color2],
                bottomColorArray:[floorColor0,floorColor1,floorColor2]
            };
            IParameter.setSkyProperty(skyProp)
        },
        skyActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.skyColor = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;

            let floorColor = this.floorColor.slice(1);
            let rgb = [
                parseInt('0x'+floorColor.slice(0, 2)),
                parseInt('0x'+floorColor.slice(2, 4)),
                parseInt('0x'+floorColor.slice(4, 6)),
            ];
            let floorColor0 = rgb[0]/255;
            let floorColor1 = rgb[1]/255;
            let floorColor2 = rgb[2]/255;

            const skyProp = {
                topColorArray:[color0,color1,color2],
                bottomColorArray:[floorColor0,floorColor1,floorColor2]
            };
            IParameter.setSkyProperty(skyProp)
        },
        floorColorChange(){
            let color = this.skyColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;

            let floorColor = this.floorColor.slice(1);
            let rgb = [
                parseInt('0x'+floorColor.slice(0, 2)),
                parseInt('0x'+floorColor.slice(2, 4)),
                parseInt('0x'+floorColor.slice(4, 6)),
            ];
            let floorColor0 = rgb[0]/255;
            let floorColor1 = rgb[1]/255;
            let floorColor2 = rgb[2]/255;

            const skyProp = {
                topColorArray:[color0,color1,color2],
                bottomColorArray:[floorColor0,floorColor1,floorColor2]
            };
            IParameter.setSkyProperty(skyProp)
        },
        floorColorImport(){
            let color = this.skyColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;

            let floorColor = this.floorColor.slice(1);
            let rgb = [
                parseInt('0x'+floorColor.slice(0, 2)),
                parseInt('0x'+floorColor.slice(2, 4)),
                parseInt('0x'+floorColor.slice(4, 6)),
            ];
            let floorColor0 = rgb[0]/255;
            let floorColor1 = rgb[1]/255;
            let floorColor2 = rgb[2]/255;

            const skyProp = {
                topColorArray:[color0,color1,color2],
                bottomColorArray:[floorColor0,floorColor1,floorColor2]
            };
            IParameter.setSkyProperty(skyProp)
        },
        floorActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.floorColor = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;

            let topColor = this.skyColor.slice(1);
            let rgb = [
                parseInt('0x'+topColor.slice(0, 2)),
                parseInt('0x'+topColor.slice(2, 4)),
                parseInt('0x'+topColor.slice(4, 6)),
            ];
            let topColor0 = rgb[0]/255;
            let topColor1 = rgb[1]/255;
            let topColor2 = rgb[2]/255;

            const skyProp = {
                topColorArray:[topColor0,topColor1,topColor2],
                bottomColorArray:[color0,color1,color2]
            };
            IParameter.setSkyProperty(skyProp)
        },
        PointActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.color = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:this.intensity,
                castShadow:this.castShadow,
                distance:this.distance
            }
            IParameter.setPointLightProperty(lightProp)
        },
        PointColorChange(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                isRealtime:this.isRealtime
            }
            IParameter.setPointLightProperty(lightProp)
        },
        PointColorImport(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                isRealtime:this.isRealtime
            }
            IParameter.setPointLightProperty(lightProp)
        },
        PointCastChange(event){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                isRealtime:this.isRealtime
            }
            IParameter.setPointLightProperty(lightProp)
        },
        PointIsRealtimeChange(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                isRealtime:this.isRealtime
            }
            IParameter.setPointLightProperty(lightProp)
        },
        pointSlider(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_side:Number(this.camera_side),
                camera_visible:this.camera_visible
            }
            // console.log(data)
            IParameter.setShadow(data)
        },
        pointShadowOff(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_side:Number(this.camera_side),
                camera_visible:this.camera_visible
            }
            // console.log(data)
            IParameter.setShadow(data)
        },
        SpotActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.color = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                angle:Number(this.angle),
                isRealtime:this.isRealtime
            }
            IParameter.setSpotLightProperty(lightProp)
        },
        SpotColorChange(){
            // console.log(this.color);
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                angle:Number(this.angle),
                isRealtime:this.isRealtime
            }
            IParameter.setSpotLightProperty(lightProp)
        },
        SpotColorImport(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                angle:Number(this.angle),
                isRealtime:this.isRealtime
            }
            IParameter.setSpotLightProperty(lightProp)
        },
        SpotCastChange(event){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                angle:Number(this.angle),
                isRealtime:this.isRealtime
            }
            IParameter.setSpotLightProperty(lightProp)
        },
        SpotIsRealtimeChange(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                distance:Number(this.distance),
                angle:Number(this.angle),
                isRealtime:this.isRealtime
            }
            IParameter.setSpotLightProperty(lightProp)
        },
        spotSlider(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_side:Number(this.camera_side),
                focus:Number(this.focus),
                camera_visible:this.camera_visible
            }
            // console.log(data)
            IParameter.setShadow(data)
        },
        spotShadowOff(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_side:Number(this.camera_side),
                focus:Number(this.focus),
                camera_visible:this.camera_visible
            }
            // console.log(data)
            IParameter.setShadow(data)
        },
        DirActiveColor(value){
            let value1 =  this.transitionColror(value)
            this.color = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:this.intensity,
                castShadow:this.castShadow,
            }
            IParameter.setDirLightProperty(lightProp)
        },
        DirColorChange(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            IParameter.setDirLightProperty(lightProp)
        },
        DirColorImport(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            IParameter.setDirLightProperty(lightProp)
        },
        DirCastShadowChange(event){
            let checked = event.target.checked;
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            IParameter.setDirLightProperty(lightProp)
        },
        DirIsRealtimeChange(){
            let checked = event.target.checked;
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            IParameter.setDirLightProperty(lightProp)
        },
        dirSlider(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                camera_side:Number(this.camera_side),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_visible:this.camera_visible
            }
            // console.log("pingx",data)
            IParameter.setShadow(data)
        },
        dirShadowOff(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                camera_side:Number(this.camera_side),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_visible:this.camera_visible
            }
            // console.log(data)
            IParameter.setShadow(data)
        },
        // 天空平行光
        skyScalarActiveColor(value){
            let value1 =  this.transitionColror(value)
            this.color = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:this.intensity,
                castShadow:this.castShadow,
            }
            IParameter.setDirLightProperty(lightProp)
        },
        skyScalarColorChange(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            // console.log(lightProp)
            IParameter.setDirLightProperty(lightProp)
        },
        skyScalarColorImport(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            IParameter.setDirLightProperty(lightProp)
        },
        skyScalarCastShadowChange(event){
            let checked = event.target.checked;
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            IParameter.setDirLightProperty(lightProp)
        },
        skyScalarIsRealtimeChange(){
            let checked = event.target.checked;
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime
            }
            IParameter.setDirLightProperty(lightProp)
        },
        skyScalarSlider(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                camera_side:Number(this.camera_side),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_visible:this.camera_visible
            }
            // console.log("pingx",data)
            IParameter.setShadow(data)
        },
        skyScalarShadowOff(){
            let data = {
                bias:Number(this.bias),
                blurSamples:Number(this.blurSamples),
                camera_far:Number(this.camera_far),
                camera_near:Number(this.camera_near),
                camera_side:Number(this.camera_side),
                mapSize:Number(this.mapSize_size),
                normalBias:Number(this.normalBias),
                radius:Number(this.radius),
                camera_visible:this.camera_visible
            }
            // console.log(data)
            IParameter.setShadow(data)
        },
        // 天空平行光
        AreaActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.color = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                width:Number(this.Width),
                height:Number(this.Height),
                isRealtime:this.isRealtime,
            }
            IParameter.setAreaLightProperty(lightProp)
        },
        AreaChange(event){
            let value1 =  this.transitionColror(value);
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime,
                width:Number(this.Width),
                height:Number(this.Height),
            }
            IParameter.setAreaLightProperty(lightProp)
        },
        AreaisRealtimeChange(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                isRealtime:this.isRealtime,
                width:Number(this.Width),
                height:Number(this.Height),
            }
            IParameter.setAreaLightProperty(lightProp)
        },
        AreaColorChange(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                width:Number(this.Width),
                height:Number(this.Height),
                isRealtime:this.isRealtime,

            }
            IParameter.setAreaLightProperty(lightProp)
        },
        AreaColorImport(){
            let color = this.color.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let lightProp = {
                color:[color0,color1,color2],
                intensity:Number(this.intensity),
                castShadow:this.castShadow,
                width:Number(this.Width),
                height:Number(this.Height),
                isRealtime:this.isRealtime,

            }
            IParameter.setAreaLightProperty(lightProp)
        },  
        
        //光晕颜色
        haloColorChange(){
            let color = this.haloColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                size:Number(this.size),
                dis:Number(this.dis),
                colorArray:[color0,color1,color2]
            }
            IParameter.setLensFlareProperty(prop)
        },
        haloColorImport(){
            let color = this.haloColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                size:Number(this.size),
                dis:Number(this.dis),
                colorArray:[color0,color1,color2]
            }
            IParameter.setLensFlareProperty(prop)
        },
        haloActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.haloColor = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                size:Number(this.size),
                dis:Number(this.dis),
                colorArray:[color0,color1,color2]
            }
            IParameter.setLensFlareProperty(prop)
        },
        haloNum(){
            let color = this.haloColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                size:Number(this.size),
                dis:Number(this.dis),
                colorArray:[color0,color1,color2],
            }
            IParameter.setLensFlareProperty(prop)
        },
        //投射阴影
        projectChange(){
            if(this.typeId==2 || this.typeId==11){
                let meshProp = {
                    receiveShadow:this.receiveShadow,
                    castShadow:this.castShadow,
                    renderOrder:Number(this.renderOrder)
                }
                IParameter.setMeshProperty(meshProp);
            }else if(this.typeId==1 || this.typeId == 10){
                let prop = {
                    receiveShadow:this.receiveShadow,
                    castShadow:this.castShadow,
                    renderOrder:Number(this.renderOrder)
                }
                // console.log(prop)
                IParameter.setGroupProperty(prop);
            }
        },
        //接受阴影
        takeChange(){
            if(this.typeId==2 || this.typeId==11){
                let meshProp = {
                    receiveShadow:this.receiveShadow,
                    castShadow:this.castShadow,
                    renderOrder:Number(this.renderOrder)
                }
                IParameter.setMeshProperty(meshProp);
            }else if(this.typeId==1 || this.typeId == 10){
                let prop = {
                    receiveShadow:this.receiveShadow,
                    castShadow:this.castShadow,
                    renderOrder:Number(this.renderOrder)
                }
                // console.log(prop)
                IParameter.setGroupProperty(prop);
            }
        },
        displayChange(){
            IParameter.setAudioHelper(this.displayRange)
            // console.log(this.displayRange)
        },
        //曲线编辑减少
        removeCurve(){ 
            IParameter.removeCurvePoint();
        },
        //曲线编辑增加
        addCurve(){
            IParameter.addCurvePoint();
        },
        SelectChange(){
            IParameter.setGroupCenter(this.select)
            // console.log(this.select)
        },
        LightSelectChange(){
            IParameter.setSkyDirLight(this.light)
        },
        startChange(){
            this.play = !this.play;
            IParameter.setPlayAudio(this.play)
            // console.log(this.play)
        },
        loopChange(){
            IParameter.setLoopAudio(this.loop)
        },
        // 地形模块
        //纹理选择
        textureMapChange(event){
            let checked = event.target.checked;
            if(checked == true){
                this.$store.commit("TerrainMaterialChange",{subTexIndex:0,type:"base_texture"})
                // this.IParameterChange();
                this.$store.commit("terrainTypeChange",'terrainType')
            }
        },
        //高度贴图
        heightMapChange(event){
            let checked = event.target.checked;
            if(checked == true){
                this.$store.commit("TerrainMaterialChange",{subTexIndex:0,type:"height_map"})
                this.$store.commit("terrainTypeChange",'terrainType')
            }
        },
        //草图
        grassMapChange(event){
            let checked = event.target.checked;
            if(checked == true){
                this.$store.commit("TerrainMaterialChange",{subTexIndex:0,type:"grass_map"})
                this.$store.commit("terrainTypeChange",'terrainType')
            }
        },
        mixtureMapChange(item){
            // console.log(item)
            this.$store.commit("TerrainMaterialChange",{subTexIndex:item.id,type:"blend_texture"})
            this.$store.commit("terrainTypeChange",'terrainType')
            // this.IParameterChange();
        },
        addAction() {
            if(this.actionList.length < 6){
                let id = this.getNum(20) + Date.now()
                let index = this.currentIndex++;
                let data = {
                    img: "",
                    startHeight: "",
                    fuseStart: "",
                    fuseEnd: "",
                    finishHeight: "",
                    id: index,
                }
                this.actionList.push(data)
            }
            
        },
        actionRemoval(item) {
            // console.log(item, "item");
            if (item.id != 1) {
                this.actionList.splice(this.actionList.indexOf(item), 1);
            } else {
                this.$message({
                    message: "初始动作不可删除"
                })
            }
        },
        terrainNum(){
            if(this.sizeX > 2048){
                this.sizeX = 2048
            }
            if(this.sizeX <10 ){
                this.sizeX = 10
            }
            if(this.sizeY > 2048){
                this.sizeY = 2048
            }
            if(this.sizeY <10 ){
                this.sizeY = 10
            }

            if(this.subsectionX >300){
                this.subsectionX =300;
            }
            if(this.subsectionX < 1){
                this.subsectionX = 1;
            }
            if(this.subsectionY >300){
                this.subsectionY =300;
            }
            if(this.subsectionY < 1){
                this.subsectionY = 1;
            }
            
            let prop = {
                frequency: Number(this.frequency),
                maxHeight:Number(this.maxHeight),
                minHeight:Number(this.minHeight),
                steps:Number(this.stepSize),
                xSize:Number(this.sizeX),
                ySize:Number(this.sizeY),
                xS:Number(this.subsectionX),
                yS:Number(this.subsectionY),
            }
            // console.log(prop)
            IParameter.setTerrainProperty(prop);
        },
        //草
        grassNumChange(){
            let prop = {
                grass_height:Number(this.grassHeight),
                grass_num:Number(this.grassNum)
            }
            // console.log(prop)
            IParameter.setGrassProperty(prop)
        },
        mixtureNum(item){
            // console.log(item)
            IParameter.setTerrainTextureLeves(item.id,[Number(item.startHeight),Number(item.fuseStart),Number(item.fuseEnd),Number(item.finishHeight)])
        },
        tableRowStyle({ row, rowIndex }) {
            return "background-color:#F7F7F7;font-size:8px; color: #000000;height:30px; ";
        },
        tableHeaderColor({ row, column, rowIndex, columnIndex }) {
            return "background-color:#F7F7F7;height:30px;font-size: 8px;color: #000000;";
        },

        // 水属性设置

        //颜色
        waterColorChange(){
            let color = this.waterColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                m_color:[color0,color1,color2],
                m_flowX:Number(this.m_flowX),
                m_flowY:Number(this.m_flowY),
                m_scale:Number(this.m_scale)
            }
            IParameter.setWaterProperty(prop)
        },
        waterActiveColor(value){
            let value1 =  this.transitionColror(value);
            this.waterColor = value1;
            let color = value1.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                m_color:[color0,color1,color2],
                m_flowX:Number(this.m_flowX),
                m_flowY:Number(this.m_flowY),
                m_scale:Number(this.m_scale)
            }
            IParameter.setWaterProperty(prop)
        },
        waterColorImport(){
            let color = this.waterColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                m_color:[color0,color1,color2],
                m_flowX:Number(this.m_flowX),
                m_flowY:Number(this.m_flowY),
                m_scale:Number(this.m_scale)
            }
            IParameter.setWaterProperty(prop)
        },
        waterNum(){
            let color = this.waterColor.slice(1);
            let rgba = [
                parseInt('0x'+color.slice(0, 2)),
                parseInt('0x'+color.slice(2, 4)),
                parseInt('0x'+color.slice(4, 6)),
            ];
            let color0 = rgba[0]/255;
            let color1 = rgba[1]/255;
            let color2 = rgba[2]/255;
            let prop = {
                m_color:[color0,color1,color2],
                m_flowX:Number(this.m_flowX),
                m_flowY:Number(this.m_flowY),
                m_scale:Number(this.m_scale)
            }
            // console.log(prop)
            IParameter.setWaterProperty(prop)
        },
        getNum(num) {
            var random = Math.floor(
                (Math.random() + Math.floor(Math.random() * 9 + 1)) *
                Math.pow(10, num - 1)
            );
            return random;
        },
         //rgb转十六进制
        transitionColror(color){
            var values = color
            .replace(/rgba?\(/, '')
            .replace(/\)/, '')
            .replace(/[\s+]/g, '')
            .split(',');
            var a = parseFloat(values[3] || 1),
                r = Math.round(a * parseInt(values[0]) + (1 - a) * 255),
                g = Math.round(a * parseInt(values[1]) + (1 - a) * 255),
                b = Math.round(a * parseInt(values[2]) + (1 - a) * 255);
            return "#" +
                ("0" + r.toString(16)).slice(-2) +
                ("0" + g.toString(16)).slice(-2) +
                ("0" + b.toString(16)).slice(-2);
        },
        
    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 1000px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 12px;
    background: #fff;
    overflow: hidden;
    margin-bottom: 50px;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header p{
    margin-left: 10px;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.projection{
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin: 25px auto;
    font-size: 12px;
}
.projection .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
    overflow: hidden;
}
.loop .title{
    width: 100px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
    overflow: hidden;
}
.projection .select{
    width: 245px;
    height: 30px;
    outline: none;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius:4px;
}
.color{
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin-top: 25px;
}
.color .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.color >>>.el-color-picker__trigger{
    height: 30px;
    width: 245px;
    margin-top: 5px;
}
.color1 .title{
    width: 100px;
}
.parameter{
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    margin-top: 25px;
}
.parameter .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.txt_box{
    width: 145px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.parameter input{
    width: 140px;
    height: 20px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px ;
}
.shadown{
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    margin-top: 25px;
}
.shadown .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.shadown .shaBox{
    width: 70%;
    min-height: 60px;
    /* display: flex; */
    /* justify-content:space-around; */
    word-break: break-word;
    margin-top:30px
}
.shadown .shaBox1{
    width: 70%;
    min-height: 20px;
    /* display: flex; */
    /* justify-content:space-around; */
    word-break: break-word;
    margin-top:0px
}
.shadown .shaBox .sha{
    display: flex;
    float: left;
    margin-left: 20px;
    margin-top: 15px;
}
.shadown .shaBox1 .sha{
    display: flex;
    float: left;
    /* margin-left: 20px; */
    margin-top: 0px;
}
.shadown .shaBox .sha .txt{
    width: 50px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
}
.shadown .shaBox .sha .txt_box{
    width: 75px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.shadown .shaBox .sha .txt_box input{
    width: 70px;
    height: 20px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px ;
}
.camera{
    display: flex;
    width: 100%;
    height: 20px;
    align-items: center;
    margin: 20px auto;
    font-size: 12px;
}
.camera .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
    overflow: hidden;
}
.camera .select{
    width: 240px;
    height: 30px;
    outline: none;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius:4px ;
}
.metallicity{
    display: flex;
    width: 95%;
    height: 20px;
    align-items: center;
    margin-top: 25px;
}
.metallicity .title{
    width: 120px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.fov_box{
    width: 62px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.metallicity .ipt{
    width: 60px;
    height: 28px;
    border-radius: 6px;
    outline: none;
    border: none;
    color: #929292;
}

.slider{
  width: 350px;
  /* margin-left: 20px; */
  margin-right: 60px;
}

.input:checked {
	background:#1673ff
}
.input {
	width:16px;
	height:16px;
    outline: none;
	background-color:rgba(125, 128, 133, 0.4);
	border:solid 1px #dddddd;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
	outline: none;
    color: #808080;
}
.input:checked::after {
	content:'';
	top:3px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:4px;
	width:6px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.ipt_box{
    width: 65px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.shadow .ipt_shadow{
    width: 60px;
    height: 20px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.render{
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    margin-top: 25px;
}
.render .title{
    width: 100px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.render .txt_box{
    width: 145px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: #808080;
}
.render input{
    width: 140px;
    height: 20px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px ;
}
.time_box{
    width:60%;
    display: flex;
    justify-content: space-around;
}
.shadow .title{
    width: 100px;
}
input{
    text-indent: 5px;
}
.curve .txt{
    width: 100px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    margin-top: 20px;
}
.lod .txt{
    width: 100px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
    margin-top: 20px;
}
.illumination {
  display: flex;
  width: 95%;
  height: 62px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px;
  align-items: center;
  margin: 20px auto;
  position: relative;
}
.illumination .chartlet {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #161a1e;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.illumination .chartlet img {
  width: 100%;
  height: 100%;
}
.illumination .ipt_box {
  width: 140px;
  height: 30px;
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.illumination .ipt {
  width: 135px;
  height: 28px;
  outline: none;
  border: none;
  color: #808080;
  border-radius: 5px;
}
.chartlet_box{
  display: flex;
  width: 80px;
  height: 35px;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  align-items: center;
  border-radius: 8px;
  justify-content: space-evenly;
  margin-left: 10px;
}
.compile{
    display: flex;
}
.not{
    width: 60px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    margin-left: 15px;
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    cursor: pointer;
    margin-left: 20px;
}
.not:active{
    background: #1673ff;
    color: #fff;
}
.selectBox{
    display: flex;
    width: 90%;
    height: 25px;
    /* margin: 8px auto; */
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
    margin-top: 20px;
}
.selectBox .ipt_box{
    width: 260px;
    height: 30px;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
}
.selectBox .select{
    width: 260px;
    height: 28px;
    outline: none;
    color: #808080;
    border: none;
    border-radius:4px;
    /* margin-left: 10px; */
}
.selectBox .select /deep/ .el-input{
   width: 260px !important;
   height: 35px !important;
   outline: none !important;
}
.selectBox .select /deep/ .el-input__inner{
   width: 260px !important;
   height: 28px !important;
   outline: none !important;
}
.selectBox .select /deep/ .el-select__caret{
  height: 28px !important;
  line-height: 28px !important;
}
.selectBox p{
    width: 80px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    margin-left: 20px;
    text-align: center;
}
.selectBox1 p {
    width: 70px;
    height: 30px;
    line-height: 30px;
    overflow: hidden;
    text-align: center;
}
.play{
    display: flex;
    width: 90%;
    height: 30px;
    align-items: center;
}
.play .title{
    width: 100px;
    height: 30px;
    line-height: 30px;
    background: #fff;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.play .start{
    width: 60px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    /* margin-left: 30px; */
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    background: #1673ff;
    color: #fff;
    cursor: pointer;
}
.play .stop{
    width: 60px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    /* margin-left: 30px; */
    border-radius: 6px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    cursor: pointer;
}
.import_color{
  width: 70px;
  height: 30px;
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  text-align: center;
  align-items: center;
  margin-left: 10px;
}
.import_color input{
  width: 65px;
  height: 28px;
  outline: none;
  border: none;
  color: #808080;
  border-radius: 5px;
  text-align: center;
}
.loction1{
    width: 100%;
    margin: 20px auto;
    display: flex;
    height: 25px;
    align-items: center;
    /* justify-content: space-between; */
    overflow: hidden;
}
.loction1 .title{
    width: 100px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-weight: bolder;
    overflow: hidden;
}
.axis{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;
    /* margin-left: 20px; */

}
.axis p{
    width: 22px;
    height: 18px;
    line-height: 20px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 18px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.y-axis{
    margin-left: 20px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
img[src=""],img:not([src]){
    opacity:0;
}
.conColor {
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  margin: auto;
  padding-top: 15px;
}
.conColor .title {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}
.conColor .chartlet {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  border: 1px solid #161a1e;
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.conColor .colorIpt{
  width: 18px;
  height: 18px;
  margin-left: 15px;
}
.conColor .chartlet img {
  width: 100%;
  height: 100%;
}
.conColor .add{
    width: 70px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    background: #1673ff;
    border-radius: 5px;
    color: #fff;
    font-size: 8px;
    margin-left: 15px;
    cursor: pointer;
}
.resourceTabel{
    font-size: 10px;
    margin-top: 10px;
}
.resourceTabel .chartlet_box1{
    width: 60px;
    height: 30px;
    display: flex;
    align-items: center;
}
.chartlet_box1 .chartlet{
    width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 1px solid #161a1e;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.remove{
    background: #1673ff;
    border: none;
    color: #fff;
    cursor: pointer;
}
</style>