import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { Vector3 } from "three";
import { ICamera } from "../interface/ICamera"

class CameraListenerBp
{
    constructor()
    {
        this.m_position = MainObjects.Camera.m_renderCamera.position.clone();
        this.m_length = 1;
        this.m_raycaster = new THREE.Raycaster();
    }

    getCameraJson()
    {
        var data = 
        {
            isPerspectiveCamera : MainObjects.Camera.m_renderCamera.isPerspectiveCamera,
            position : [MainObjects.Camera.m_renderCamera.position.x, MainObjects.Camera.m_renderCamera.position.y, MainObjects.Camera.m_renderCamera.position.z],
            rotation : [MainObjects.Camera.m_renderCamera.rotation.x, MainObjects.Camera.m_renderCamera.rotation.y, MainObjects.Camera.m_renderCamera.rotation.z],
            target : [MainObjects.Control.m_orbitControl.target.x, MainObjects.Control.m_orbitControl.target.y, MainObjects.Control.m_orbitControl.target.z],
            zoom : MainObjects.Camera.m_renderCamera.zoom
        }

        return data;
    }

    setCameraJson(json)
    {
        MainObjects.Control.m_orbitControl.target.set(json.target[0], json.target[1], json.target[2]);
        MainObjects.Camera.m_renderCamera.position.set(json.position[0], json.position[1], json.position[2]);
        MainObjects.Camera.m_renderCamera.rotation.set(json.rotation[0], json.rotation[1], json.rotation[2]);
        if(!json.isPerspectiveCamera)
        {
            this.changeCameraMode();
            if(json.zoom != undefined)
            {
                MainObjects.Control.m_orbitControl.scaleOrbit = MainObjects.Camera.m_mainOrthographicCamera.zoom = json.zoom;
            }  

        }
        MainObjects.Control.m_orbitControl.update();
        if(ICamera.f_cameraModeCallback != null)
        {
            if(MainObjects.Camera.m_renderCamera.isPerspectiveCamera)
            {
                ICamera.f_cameraModeCallback(ICamera.CameraMode.e_PERSPECTIVE);
            }
            else
            {
                ICamera.f_cameraModeCallback(ICamera.CameraMode.e_ORTHOGRAPHIC);
            }
        }
    }

    getCameraDirPosition(object)
    {
        var position = new THREE.Vector3();
        var target = new THREE.Vector3();
        MainObjects.Camera.m_renderCamera.getWorldPosition(position);
        MainObjects.Camera.m_renderCamera.getWorldDirection(target);
    
        // this.m_raycaster.set (position,target);
        // this.m_raycaster.layers.disable( 10 );
        // this.m_raycaster.layers.disable( 11 );
        // const intersects = this.m_raycaster.intersectObjects(MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_selectAllMeshList);
        // if (intersects.length > 0) {
        //     position.copy(intersects[0].point);
        // }
        // else
        // {
        //     position.add(target.multiplyScalar(1));
        // }

        if(object.userData.m_boudingBoxHelper != null)
        {
            var x = object.userData.m_boudingBoxHelper.m_xLen * object.scale.x;
            var y = object.userData.m_boudingBoxHelper.m_yLen * object.scale.y;
            var z = object.userData.m_boudingBoxHelper.m_zLen * object.scale.z;
            var size = Math.max(x, y, z) + 1;
            position.add(target.multiplyScalar(size));
        }
        else
        {
            position.add(target.multiplyScalar(1));
        }
        MainObjects.Control.m_orbitControl.target.copy(position);

        return position;
    }

    changeCameraMode()
    {
        if(MainObjects.Camera.m_renderCamera.isPerspectiveCamera)
        {
            this.m_position = MainObjects.Camera.m_renderCamera.position.clone();
            var newPosition = new Vector3();
            newPosition.copy(this.m_position);

            const normal = newPosition.sub(MainObjects.Control.m_orbitControl.target).normalize();
            const lengthNormal = normal.multiplyScalar(this.m_length);

            newPosition = this.m_position.add(lengthNormal);
            MainObjects.Camera.m_mainOrthographicCamera.position.copy( newPosition );
            MainObjects.Camera.m_mainOrthographicCamera.zoom = MainObjects.Control.m_orbitControl.scaleOrbit;  
            MainObjects.Control.m_orbitControl.scalePersp = 1;    
            MainObjects.Control.m_orbitControl.enableRotate = false;
            MainObjects.Camera.m_mainOrthographicCamera.updateMatrixWorld(true); 
        }
        else
        {
            this.m_position = MainObjects.Camera.m_renderCamera.position.clone();
            var newPosition = new Vector3();
            newPosition.copy(this.m_position);

            const normal = newPosition.sub(MainObjects.Control.m_orbitControl.target).normalize();
            const lengthNormal = normal.multiplyScalar(-this.m_length);
            newPosition = this.m_position.add(lengthNormal);
            MainObjects.Camera.m_mainPerspectiveCamera.position.copy( newPosition );
            MainObjects.Camera.m_mainPerspectiveCamera.updateMatrixWorld(true); 
            MainObjects.Control.m_orbitControl.setScale();
            MainObjects.Control.m_orbitControl.enableRotate = true;
        }
       
        MainObjects.Camera.m_renderCamera = MainObjects.Camera.m_renderCamera.isPerspectiveCamera ?  MainObjects.Camera.m_mainOrthographicCamera : MainObjects.Camera.m_mainPerspectiveCamera;
    
        // MainObjects.Render.m_renderPass.camera = MainObjects.Camera.m_renderCamera;
        // MainObjects.Render.m_outlinePass.renderCamera = MainObjects.Camera.m_renderCamera;
        
        MainObjects.Control.m_orbitControl.object = MainObjects.Camera.m_renderCamera;
        MainObjects.Control.m_transformControl.camera  = MainObjects.Camera.m_renderCamera;
        MainObjects.Control.m_dragControls.camera = MainObjects.Camera.m_renderCamera;
        MainObjects.Control.m_dragBoxControls.camera = MainObjects.Camera.m_renderCamera;

        MainObjects.Camera.m_renderCamera.lookAt(  MainObjects.Control.m_orbitControl.target.x,  MainObjects.Control.m_orbitControl.target.y,  MainObjects.Control.m_orbitControl.target.z );
        MainObjects.Blueprint.m_eventListenerBp.onCameraResize();
        MainObjects.Scene.m_viewHelper.m_editorCamera = MainObjects.Camera.m_renderCamera;
        MainObjects.Control.m_orbitControl.update();
        MainObjects.Blueprint.m_audioListenerBp.addToCamera();
    }

    zoomCameraPerspectiveToSelection(camera, controls, selection, fitRatio = 2, needUpdatOrthographic = false) 
    {
        const box = new THREE.Box3();

        if(selection.length == 0)
        {
            return;
        }
      
        for (const object of selection) box.expandByObject(object);
      
        var size = box.getSize(new THREE.Vector3());
        var center = box.getCenter(new THREE.Vector3());
      
        var maxSize = Math.max(size.x, size.y, size.z);
        if(maxSize < 0.1)
        {
            maxSize = 1.5;
            if(center.x == 0 && center.y == 0 && center.z == 0)
            {
                center = selection[0].position;
            }
        }
        const fitHeightDistance =
          maxSize / (2 * Math.atan((Math.PI * camera.fov) / 360));
        const fitWidthDistance = fitHeightDistance / camera.aspect;
        const distance = fitRatio * Math.max(fitHeightDistance, fitWidthDistance);
        var dv = new THREE.Vector3();
        camera.getWorldDirection(dv);
    
        const direction = dv.multiplyScalar(distance);
        controls.target.copy(center);
        camera.position.copy(controls.target).sub(direction);

        if(needUpdatOrthographic)
        {
            MainObjects.Control.m_orbitControl.scaleOrbit = MainObjects.Camera.m_mainOrthographicCamera.zoom =  MainObjects.Camera.m_frustumSize / (maxSize + fitRatio * 0.05);
            MainObjects.Camera.m_mainOrthographicCamera.updateProjectionMatrix();
            MainObjects.Camera.m_mainOrthographicCamera.getWorldDirection(dv);
            const directionO = dv.multiplyScalar(10);
            MainObjects.Camera.m_mainOrthographicCamera.position.copy(controls.target).sub(directionO);
        }
        MainObjects.Control.m_transformControl.updateMatrixWorld(true);
        controls.update();
    }

    onKeyUp(even)
    {
        switch ( even.keyCode ) 
        {
            case 80: // P
            {
                this.changeCameraMode();
                if(ICamera.f_cameraModeCallback != null)
                {
                    if(MainObjects.Camera.m_renderCamera.isPerspectiveCamera)
                    {
                        ICamera.f_cameraModeCallback(ICamera.CameraMode.e_PERSPECTIVE);
                    }
                    else
                    {
                        ICamera.f_cameraModeCallback(ICamera.CameraMode.e_ORTHOGRAPHIC);
                    }
                }
            }
            break;
            case 70: //F
            {
                if(MainObjects.Render.m_outlinePass.selectedObjects.length == 1 && MainObjects.Render.m_outlinePass.selectedObjects[0].isCamera)
                {
                    MainObjects.Render.m_outlinePass.selectedObjects[0].position.copy(MainObjects.Camera.m_renderCamera.position);
                    MainObjects.Render.m_outlinePass.selectedObjects[0].rotation.copy(MainObjects.Camera.m_renderCamera.rotation);
                    MainObjects.Blueprint.m_selectListenerBp.setAxis();
                    MainObjects.Render.m_outlinePass.selectedObjects[0].updateMatrixWorld(true);
                    MainObjects.Render.m_outlinePass.selectedObjects[0].userData.m_gameObjectHelper.update();
                }
                else
                {
                    this.zoomCameraPerspectiveToSelection(MainObjects.Camera.m_mainPerspectiveCamera, 
                        MainObjects.Control.m_orbitControl, MainObjects.Render.m_outlinePass.selectedObjects, 1, true);
                }
            }
            break;
            case 66: //B
            {
                //X
                if(MainObjects.Blueprint.m_eventListenerBp.m_bShift)
                {
                    //-
                    MainObjects.Scene.m_viewHelper.selectClick("negX", MainObjects.Control.m_orbitControl.target);
                }
                else
                {
                    MainObjects.Scene.m_viewHelper.selectClick("posX", MainObjects.Control.m_orbitControl.target);
                }
            }
            break;
            case 78: //N
            {
                //Y
                if(MainObjects.Blueprint.m_eventListenerBp.m_bShift)
                {
                    //-
                    MainObjects.Scene.m_viewHelper.selectClick("negY", MainObjects.Control.m_orbitControl.target);
                }
                else
                {
                    MainObjects.Scene.m_viewHelper.selectClick("posY", MainObjects.Control.m_orbitControl.target);
                }
            }
            break;
            case 77: //M
            {
                //Z
                if(MainObjects.Blueprint.m_eventListenerBp.m_bShift)
                {
                    //-
                    MainObjects.Scene.m_viewHelper.selectClick("negZ", MainObjects.Control.m_orbitControl.target);
                }
                else
                {
                    MainObjects.Scene.m_viewHelper.selectClick("posZ", MainObjects.Control.m_orbitControl.target);
                }
            }
            break;
            case 86: //v
            {
                if(MainObjects.Render.m_outlinePass.selectedObjects.length == 1 && MainObjects.Render.m_outlinePass.selectedObjects[0].isCamera 
                    && (MainObjects.Blueprint.m_eventListenerBp.m_bCtrl == false && MainObjects.Blueprint.m_eventListenerBp.m_bShift == false))
                {
                    MainObjects.Camera.m_renderCamera.position.copy(MainObjects.Render.m_outlinePass.selectedObjects[0].position);
                    MainObjects.Camera.m_renderCamera.rotation.copy(MainObjects.Render.m_outlinePass.selectedObjects[0].rotation);
                    var position = new THREE.Vector3();
                    var target = new THREE.Vector3();
                    MainObjects.Render.m_outlinePass.selectedObjects[0].getWorldPosition(position);
                    MainObjects.Render.m_outlinePass.selectedObjects[0].getWorldDirection(target);
                    position.add(target.multiplyScalar(1));
                    MainObjects.Control.m_orbitControl.target.copy(position);
                    MainObjects.Control.m_orbitControl.update();
                }
            }
            break;
        }
    }
}

export {CameraListenerBp};