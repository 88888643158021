class HistoryRepos {
    constructor() {
        //    !
        //[ o,o,o ]
        this.m_historyUndoArray = new Array();
        this.m_historRedoArray = new Array();
        this.m_maxLength = 200;
    }

    addUndoRedo(data) {
        if (this.m_historyUndoArray.length > this.m_maxLength) {
            this.m_historyUndoArray.splice(0, 100);
        }
        var b = true;
        if(this.m_historyUndoArray.length > 0)
        {
            if(this.m_historyUndoArray[this.m_historyUndoArray.length - 1].m_id == data.m_id)
            {
                b = false;
            }
        }
        if(b)
        {
            this.m_historyUndoArray.push(data);
            this.m_historRedoArray = [];
        }
    }

    getUndo() {
        if (this.m_historyUndoArray.length == 0) {
            return null;
        }
        const data = this.m_historyUndoArray[this.m_historyUndoArray.length - 1];
        this.m_historyUndoArray.splice(this.m_historyUndoArray.length - 1, 1);
        this.m_historRedoArray.push(data);
        return data;
    }

    getRedo() {
        if (this.m_historRedoArray.length == 0) {
            return null;
        }
        const data = this.m_historRedoArray[this.m_historRedoArray.length - 1];
        this.m_historRedoArray.splice(this.m_historRedoArray.length - 1, 1);
        this.m_historyUndoArray.push(data);
        return data;
    }
}

export { HistoryRepos };