<template>
    <div>
        <div class="box" :class="isActive?'anmDiv':'aaa'">
           <div class="header">
               <p>网格</p>
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
           </div>
           <div class="filter">
               <div class="title">过滤器</div>
               <select class="select">
                   <option value="0">模型名称</option>
               </select>
           </div>
           <div class="light">
               <div class="title">光照</div>
               <div class="light_box">
                   <p>投射阴影</p>
                   <input class="input" type="checkbox" />
               </div>
           </div>
           <div class="shadow">
               <div class="title">接受阴影</div>
               <input class="input" type="checkbox" />
           </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            isActive:true,
        }
    },
    methods:{
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        }
    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 155px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 25px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 25px;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.filter{
    display: flex;
    width: 90%;
    height: 20px;
    align-items: center;
    margin: 15px auto;
}
.filter .title{
    width: 70px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    font-weight: bolder;
}
.filter .select{
    width: 280px;
    height: 25px;
    outline: none;
    color: #ccc;
    border: 1px solid #ccc;
    border-radius:4px ;
}
.light{
    display: flex;
    width: 90%;
    height: 25px;
    align-items: center;
    margin: 15px auto;
}
.light .title{
    width: 70px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
}
.light .light_box{
    display: flex;
    width: 120px;
    height: 22px;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    border: 3px solid #F7F7F7;
}
.light p{
    width: 80px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: 400;
    color:  #7D8085;
    background-color: #fff;
    margin-left: 3px;
}
.light input{
    width: 18px;
    height: 18px;
}
.shadow{
    display: flex;
    width: 120px;
    height: 22px;
    align-items: center;
    background-color: #fff;
    margin-left: 90px;
    border-radius: 6px;
    border: 3px solid #F7F7F7;
}
.shadow .title{
    width: 80px;
    height: 20px;
    line-height: 20px;
    color:  #7D8085;
    text-align: center;
}
.shadow .input{
    width: 18px;
    height: 18px;
}
</style>