import * as THREE from 'three';
import { MainObjects } from './MainObjects';

class PreviewCamera {
    constructor() {
        window.createImageBitmap = undefined;
        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.toneMapping = THREE.CineonToneMapping;
        this.renderer.toneMappingExposure = 1;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.camera = null
        this.scene = null;
        this.userGroup = null;
        this.needUpdate = false;
        this.w = 0;
        this.h = 0;
        this.container = null;
        this.update();
    }

    setSceneCamera(){
        this.camera = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getMainCamera();
        if(this.camera != null)
        {
            this.scene = MainObjects.Scene.m_renderScene;
            this.resize();
            return true;
        }
        else
        {
            return false;
        }
    }

    addRender(option){
        this.container = option.container;
        this.container.appendChild(this.renderer.domElement);
        this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
    }
    
    resize() {
        MainObjects.Scene.m_cameraViewHelper.setWH(this.camera.userData.m_w, this.camera.userData.m_h);
        this.needUpdate = true;
    }

    close(){
        this.needUpdate = false;
    }

    update(){
        requestAnimationFrame(this.update.bind(this));
        if(this.needUpdate){
            if(this.container == null)
            {
                return;
            }
            if(this.w != this.container.clientWidth || this.h != this.container.clientHeight){
                var aspect = this.container.clientWidth / this.container.clientHeight;
                if(this.camera.isPerspectiveCamera)
                {
                    this.camera.aspect = aspect;
                }
                else
                {
                    this.camera.left = MainObjects.Camera.m_frustumSize * aspect / -2;
                    this.camera.right = MainObjects.Camera.m_frustumSize * aspect / 2;
                    this.camera.top = MainObjects.Camera.m_frustumSize / 2;
                    this.camera.bottom = MainObjects.Camera.m_frustumSize / -2;
                }
             
                this.camera.updateProjectionMatrix();
                this.renderer.setSize(this.container.clientWidth, this.container.clientHeight);
                this.w = this.container.clientWidth;
                this.h = this.container.clientHeight;
            }
            this.renderer.render(this.scene, this.camera);
        }
    }
}

export { PreviewCamera };