<template>
    <div class="container">
        <div class="caption">纹理</div>
        <div class="texture">
            <el-select class="select" @change="textureSelect()" popper-class="select_popper" :popper-append-to-body="false"  v-model="select" placeholder="请选择">
                <el-option
                    v-for="item in textureList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="caption">Life</div>
        <div class="rate">
            <div class="particles">
                <div class="txt_box">
                    <input v-model="life" placeholder="" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MIN</span>
                </div>
                <div class="txt_box">
                    <input v-model="life" placeholder="" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MAX</span>
                </div>
                <el-switch
                    v-model="lifeState">
                </el-switch>
            </div>
        </div>
        <div class="caption">半径</div>
        <div class="rate">
            <div class="particles">
                <div class="txt_box">
                    <input v-model="radius" placeholder="" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">W</span>
                </div>
                <div class="txt_box">
                    <input v-model="radius" placeholder="" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">H</span>
                </div>
                <el-switch
                    v-model="radiusState">
                </el-switch>
            </div>
        </div>
        <div class="caption">质量</div>
        <div class="rate">
            <div class="particles">
                <div class="txt_box">
                    <input v-model="mass" placeholder="" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MIN</span>
                </div>
                <div class="txt_box">
                    <input v-model="mass" placeholder="" @change="changeNum($event)" @focus="Focus" @blur="Blur" />
                    <span class="txt">MIN</span>
                </div>
                <el-switch
                    v-model="massState">
                </el-switch>
            </div>
        </div>
        <div class="caption">径向速度</div>
        <div class="radia">
            <div class="ipt_box">
                <input v-model="name" @change="changeNum($event)" @focus="Focus" @blur="Blur" placeholder="" />
                <span class="txt">RADIUS</span>
            </div>
            <div class="loction1">
                <div class="x-axis axis">
                    <p>X</p>
                    <input v-model="positionX" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur" />
                </div>
                <div class="y-axis axis">
                    <p>Y</p>
                    <input v-model="positionY" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
                <div class="z-axis axis">
                    <p>Z</p>
                    <input v-model="positionZ" oninput="if(value>999999)value=999999;if(value<-999999)value=-999999" type="number" @change="changeNum($event)"  @focus="Focus" @blur="Blur"/>
                </div>
            </div>
            <div class="ipt_box">
                <input v-model="name" @change="changeNum($event)" @focus="Focus" @blur="Blur" placeholder="" />
                <span class="txt">THETA</span>
            </div>
        </div>
    </div>
</template>
<script>
import { ISetting } from "../../threejs/interface/ISetting";
export default {
    data(){
        return{
            select:'',
            textureList:[
                {
                    value:1,
                    label:"动态纹理"
                },
                {
                    value:2,
                    label:"静态纹理"
                },
            ],
            lifeState:false,
            radiusState:false,
            massState:false,
            life:0,
            radius:0,
            mass:0,
            positionY:0,
            positionX:0,
            positionZ:0,
            name:'',
        }
    },
    mounted(){
    },
    methods:{
        textureSelect(){
            
        },
        changeNum(){

        },
        Focus(){
            ISetting.isInput(true);
        },
        Blur(){
            ISetting.isInput(false);
        },
    }
}
</script>
<style scoped>
.container{
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 30px;
}
.container::-webkit-scrollbar{
  display: none;
}
.caption{
    width: 100%;
    height: 30px;
    line-height: 30px;
    background: #F7F7F7;
    font-weight: bolder;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
}
.texture{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
}
.select{
    width: 300px;
    height: 28px;
    margin-left: 20px;
    position: relative;
}
.select /deep/ .el-input{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-input__inner{
   width: 100% !important;
   height: 28px !important;
   outline: none !important;
}
.select /deep/ .el-select__caret{
  height: 100% !important;
  line-height: 28px !important;
}
.el-select /deep/ .select_popper{
    z-index: 99999999 !important;
    top: auto !important;
    left: auto !important
}
.rate{
    width: 100%;
}
.rate .particles {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 10px;
}
.particles .title{
    width: 70px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.particles .txt_box{
    width: 100px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.particles .txt_box .txt{
    display: inline-block;
    width: 35px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    color: #808080;
}
.particles .txt_box input{
    width:65px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.radia{
    width: 100%;
    margin-top: 10px;
}
.ipt_box{
    width: 300px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
}
.ipt_box .txt{
    width: 70px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 12px;
    color: #808080;
}
.ipt_box input{
    width: 230px;
    height: 25px;
    border: none;
    border-radius: 5px;
    outline: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 0);
    color: #808080;
    text-indent: 5px;
}
.loction1{
    width: 95%;
    display: flex;
    height: 25px;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    margin:10px auto;
}
.axis{
    width: 25%;
    height: 100%;
    display: flex;
    align-items: center;
    background-color:#F7F7F7;
    border-radius: 5px;
}
.axis p{
    width: 25px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    color: #929292;
    background-color: #fff;
    margin-left: 2px;
    border-radius: 4px;
    float: left;
    color: #000000;
    font-size: 12px;
}
.axis input{
    width: 90%;
    height: 22px;
    text-align: center;
    outline: none;
    border: none;
    background-color: #F7F7F7;
    border-radius: 4px;
    font-weight: bold;
    font-size: 8px;
}
.x-axis p{
    background-color:#F20004;
}
.y-axis p{
    background-color: #43BD00;
}
.z-axis p {
    background-color: #5180E9;
}
</style>