import { MainObjects } from "../common/MainObjects"
import { PreviewCamera } from "../common/PreviewCamera";
import { SceneFactory } from "../factory/SceneFactory";
import { ThreeCameraHelper } from "../helper/ThreeCameraHelper";
import { GameObject } from "../object/GameObject"
import { ITransform } from "./ITransform";

class IParameter {
    static getProperty() {
        if (MainObjects.Blueprint.m_selectorPropertyBp.isSelectorSameType()) {
            if(!IParameter.canProperty())
            {
                return null;
            }
            const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
            
            if(firstObj.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getCurveProperty(firstObj);
                }
                else
                {
                    return null;
                }      
            }
            else if(firstObj.userData.m_gameObjectType == GameObject.GameObjectType.e_AUDIO)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getAudioProperty(firstObj);
                }
                else
                {
                    return null;
                }      
            }
            else if(firstObj.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getSkyProperty(firstObj);
                }
                else
                {
                    return null;
                }       
            }
            else if(firstObj.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getRealTimeSkyProperty(firstObj);
                }
                else
                {
                    return null;
                }       
            }
            else if(firstObj.userData.m_gameObjectType == GameObject.GameObjectType.e_LENS_FLARE)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getLensFlareProperty(firstObj);
                }
                else
                {
                    return null;
                }    
            }
            else if(MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType == GameObject.GameObjectType.e_GLTF ||
                MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType == GameObject.GameObjectType.e_GROUP)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getGroupProperty(firstObj);
                }
                else
                {
                    return null;
                }      
            }
            else if(firstObj.userData.m_gameObjectType == GameObject.GameObjectType.e_TERRAIN_SYS)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getTerrainProperty(firstObj);
                }
                else
                {
                    return null;
                }
            }
            else if(firstObj.userData.m_gameObjectType == GameObject.GameObjectType.e_WATER_BASE)
            {
                var length = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
                if(length == 1)
                {
                    return IParameter.getWaterProperty(firstObj);
                }
                else
                {
                    return null;
                }
            }
            else if (firstObj.isLight) {
                return IParameter.getLightProperty(firstObj);
            }
            else if (firstObj.isCamera) {
                return IParameter.getCameraProperty(firstObj);
            }
            else if (firstObj.isMesh || firstObj.isSkinnedMesh) {
                return IParameter.getMeshProperty(firstObj);
            }
        }

        return null;
    }

    static getAudioProperty(firstObj)
    {
        return MainObjects.Blueprint.m_audioListenerBp.getAudioProperty(firstObj);
    }

    static getLightProperty(firstObj)
    {
        var lightProp = {
            type: GameObject.GameObjectType.e_DEFULT,
            color: "",
            intensity: 1,
            castShadow: true,
            distance: 0,
            angle: Math.PI / 2,
            width: 10,
            height: 10,
            isRealtime : firstObj.visible
        }
        
        lightProp.color = firstObj.color.getHexString();
        lightProp.type = MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType;
        lightProp.intensity = firstObj.intensity;
        lightProp.castShadow = firstObj.castShadow;
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
            lightProp.color = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.color, obj.color.getHexString());
            lightProp.intensity = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.intensity, obj.intensity);
            lightProp.castShadow = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.castShadow, obj.castShadow);
            lightProp.isRealtime = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.isRealtime, obj.isRealtime);
        });
        switch (MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType) {
            case GameObject.GameObjectType.e_DIR_LIGHT:
                lightProp.shadow = IParameter.getShadow(firstObj.shadow, firstObj.userData.m_gameLightCameraHelper);
                break;
             case GameObject.GameObjectType.e_SKY_DIR_LIGHT:
                lightProp.shadow = IParameter.getShadow(firstObj.shadow, firstObj.userData.m_gameLightCameraHelper);
                break;
            case GameObject.GameObjectType.e_POINT_LIGHT:
                lightProp.shadow = IParameter.getShadow(firstObj.shadow, firstObj.userData.m_gameLightCameraHelper);
                lightProp.distance = firstObj.distance;
                MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
                    lightProp.distance = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.distance, obj.distance);
                });
                break;
            case GameObject.GameObjectType.e_SPOT_LIGHT:
                lightProp.shadow = IParameter.getShadow(firstObj.shadow, firstObj.userData.m_gameLightCameraHelper);
                lightProp.distance = firstObj.distance;
                lightProp.angle = firstObj.angle;
                MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
                    lightProp.distance = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.distance, obj.distance);
                    lightProp.angle = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.angle, obj.angle);
                });
                break;
            case GameObject.GameObjectType.e_AREA_LIGHT:
                lightProp.width = firstObj.width;
                lightProp.height = firstObj.height;
                MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
                    lightProp.width = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.width, obj.width);
                    lightProp.height = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(lightProp.height, obj.height);
                });
                break;
            default:
                break;
        }
        lightProp.color =  "#" + lightProp.color;
        lightProp.angle = ITransform.radToAngle(lightProp.angle);
        lightProp.distance = ITransform.mTomm(lightProp.distance);
        lightProp.width = ITransform.mTomm(lightProp.width);
        lightProp.height = ITransform.mTomm(lightProp.height);
        return lightProp;
    }

    static getShadow(shadow, gameLightCameraHelper)
    {
        var data = {};
        if(shadow != undefined)
        {
            data.mapSize = shadow.mapSize.width;

            data.camera_near  = shadow.camera.near;
            data.camera_far  = shadow.camera.far;
            data.camera_side  = shadow.camera.top;

            if(shadow.isSpotLightShadow)
            {
                //聚焦 0.01[0, 1]
                data.focus = shadow.focus;
            }
            //偏差step 0.0001[-0.01, 0.01]
            data.bias = shadow.bias;
            //偏移 0.0001[-0.01, 0.01]
            data.normalBias = shadow.normalBias;
            //半径 0.001[1, 5]
            data.radius = shadow.normalBias;
            //模糊 1[1, 10]
            data.blurSamples = shadow.blurSamples;

            if(gameLightCameraHelper != null)
            {
                data.camera_visible = gameLightCameraHelper.visible;
            }   

        }
        return data;
    }

    static getCameraProperty(firstObj){
        var cameraProp = {
            type: GameObject.GameObjectType.e_DEFULT,
            far: 1,
            near: 0.1,
            zoom: 1,

            fov: 50,

            w : firstObj.userData.m_w,
            h : firstObj.userData.m_h,
        }
        cameraProp.type = MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType;
        cameraProp.far = firstObj.far;
        cameraProp.near = firstObj.near;
        cameraProp.zoom = firstObj.zoom;

        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
            cameraProp.far = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(cameraProp.far, obj.far);
            cameraProp.near = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(cameraProp.near, obj.near);
            cameraProp.zoom = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(cameraProp.zoom, obj.zoom);

            cameraProp.w = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(cameraProp.w, obj.userData.m_w);
            cameraProp.h = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(cameraProp.h, obj.userData.m_w);
        });
        switch (MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType) {
            case GameObject.GameObjectType.e_CAMERA_PERSP:
                cameraProp.fov = firstObj.fov;
                MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
                    cameraProp.fov = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(cameraProp.fov, obj.fov);
                });
                break;
            case GameObject.GameObjectType.e_CAMERA_ORTHO:
                break;
            default:
                break;
        }
        return cameraProp;
    }

    static getMeshProperty(firstObj){
        var meshProp = {
            type: GameObject.GameObjectType.e_MESH,
            castShadow: false,
            receiveShadow: false,
            renderOrder: 0
        }
        meshProp.type = MainObjects.Blueprint.m_selectorPropertyBp.m_selectorType;
        meshProp.castShadow = firstObj.receiveShadow;
        meshProp.receiveShadow = firstObj.receiveShadow;
        meshProp.renderOrder = firstObj.renderOrder;

        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFromSecond(function (obj) {
            meshProp.castShadow = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(meshProp.castShadow, obj.castShadow);
            meshProp.receiveShadow = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(meshProp.receiveShadow, obj.receiveShadow);
            meshProp.renderOrder = MainObjects.Blueprint.m_selectorPropertyBp.getSameValue(meshProp.renderOrder, obj.renderOrder);
        });
        return meshProp;
    }

    static getCurveProperty(firstObj){
        var prop = {
            type: GameObject.GameObjectType.e_CURVE,
        }
        return prop;
    }

    static getGroupProperty(firstObj){
        var prop = {
            type: firstObj.userData.m_gameObjectType,
            center: firstObj.userData.m_group_center,
            cneterList: ["NoSet", "Center", "X+", "X-", "Y+", "Y-", "Z+", "Z-"],
            castShadow: firstObj.receiveShadow,
            receiveShadow: firstObj.receiveShadow,
            renderOrder: firstObj.renderOrder,
        }
        return prop;
    }

    static getSkyProperty(firstObj)
    {
        return MainObjects.Blueprint.m_skyBp.getSkyEffectController(firstObj);
    }

    static getRealTimeSkyProperty(firstObj)
    {
        return MainObjects.Blueprint.m_skyBp.getRealTimeSkyEffectController(firstObj);
    }

    static getLensFlareProperty(firstObj)
    {
        return MainObjects.Blueprint.m_lensFlareBp.getLensFlareProp(firstObj);
    }

    static getTerrainProperty(firstObj)
    {
        return firstObj.userData.m_terrain_data.getJsonForUI();
    }

    static getWaterProperty(firstObj)
    {
        return firstObj.userData.m_water_data.getJsonForUI();
    }

    static setGroupCenter(center)
    {
        const firstObj = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectFirst();
        if(firstObj.isGroup)
        {
            MainObjects.Blueprint.m_scaleBoxBp.setCenter(firstObj, center);
        }  
    }

    static setDirLightProperty(lightProp) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.color.fromArray(lightProp.color);
            obj.intensity = lightProp.intensity;
            obj.castShadow = lightProp.castShadow;
            if(lightProp.isRealtime != undefined)
            {
                obj.visible = lightProp.isRealtime;
            }
            if(obj.isLight)
            {
                obj.userData.m_gameObjectHelper.update();
            }
        });
    }

    static setPointLightProperty(lightProp) {
        lightProp.angle = ITransform.angleToRad(lightProp.angle);
        lightProp.distance = ITransform.mmTom(lightProp.distance);
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.color.fromArray(lightProp.color);
            obj.intensity = lightProp.intensity;
            obj.castShadow = lightProp.castShadow;
            obj.distance = lightProp.distance;
            if(lightProp.isRealtime != undefined)
            {
                obj.visible = lightProp.isRealtime;
            }
            if(obj.isLight)
            {
                obj.userData.m_gameObjectHelper.update();
            }
        });
    }

    static setSpotLightProperty(lightProp) {
        lightProp.angle = ITransform.angleToRad(lightProp.angle);
        lightProp.distance = ITransform.mmTom(lightProp.distance);
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.color.fromArray(lightProp.color);
            obj.intensity = lightProp.intensity;
            obj.castShadow = lightProp.castShadow;
            obj.distance = lightProp.distance;
            obj.angle = lightProp.angle;
            if(lightProp.isRealtime != undefined)
            {
                obj.visible = lightProp.isRealtime;
            }
            if(obj.isLight)
            {
                obj.userData.m_gameObjectHelper.update();
            }
        });
    }

    static setAreaLightProperty(lightProp) {
        lightProp.width = ITransform.mmTom(lightProp.width);
        lightProp.height = ITransform.mmTom(lightProp.height);
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.color.fromArray(lightProp.color);
            obj.intensity = lightProp.intensity;
            obj.width = lightProp.width;
            obj.height = lightProp.height;
            if(lightProp.isRealtime != undefined)
            {
                obj.visible = lightProp.isRealtime;
            }
            MainObjects.Blueprint.m_selectListenerBp.updateObject(obj);
        });
    }

    static setShadow(data)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if(obj.shadow != undefined)
            {
                obj.shadow.mapSize.width = data.mapSize;
                obj.shadow.mapSize.height = data.mapSize;

                if( obj.shadow.isSpotLightShadow)
                {
                    //聚焦 0.01[0, 1]
                    obj.shadow.focus = data.focus;
                }
                //偏差step 0.0001[-0.01, 0.01]
                obj.shadow.bias = data.bias;
                //偏移 0.0001[-0.01, 0.01]
                obj.shadow.normalBias = data.normalBias;
                //半径 0.001[1, 5]
                obj.shadow.normalBias = data.radius;
                //模糊 1[1, 10]
                obj.shadow.blurSamples = data.blurSamples;
                if(obj.userData.m_gameLightCameraHelper != null)
                {

                    obj.shadow.camera.near = data.camera_near;
                    obj.shadow.camera.far = data.camera_far;
        
                    obj.shadow.camera.left = -data.camera_side;
                    obj.shadow.camera.right = data.camera_side;
                    obj.shadow.camera.top = data.camera_side;
                    obj.shadow.camera.bottom = -data.camera_side;

                    obj.userData.m_gameLightCameraHelper.removeFromParent();
                    obj.userData.m_gameLightCameraHelper.dispose();
                    obj.userData.m_gameLightCameraHelper = null;

                    const camera_helper = new ThreeCameraHelper( obj.shadow.camera );
                    camera_helper.name = "light_camera"
                    SceneFactory.addToHelperGroup(camera_helper);
                    obj.userData.m_gameLightCameraHelper = camera_helper;
                    obj.userData.m_gameLightCameraHelper.visible = data.camera_visible;
                }   
                MainObjects.Blueprint.m_selectListenerBp.updateObject(obj); 
            }
        
        });
    }

    static setGroupProperty(prop)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.castShadow = prop.castShadow;
            obj.receiveShadow = prop.receiveShadow;
            obj.renderOrder = prop.renderOrder;
            obj.traverse(function (child) {
                if (child.isMesh && child.userData != null) {
                    if(child.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF_MESH)
                    {
                        child.castShadow = prop.castShadow;
                        child.receiveShadow = prop.receiveShadow;
                        child.renderOrder = prop.renderOrder;
                    }
                }
            });
        });
    }

    static setMeshProperty(meshProp) {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.castShadow = meshProp.castShadow;
            obj.receiveShadow = meshProp.receiveShadow;
            obj.renderOrder = meshProp.renderOrder;
        });
    }

    static addCurvePoint()
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_curveEditorBp.addCurve(obj)
        });
    }

    static removeCurvePoint()
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_curveEditorBp.removeCurve(obj)
        });
    }

    static setSkyProperty(skyProp)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_skyBp.skyChange(obj, skyProp);
        });
    }

    static setRealTimeSkyProperty(skyProp)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_skyBp.realTimeskyChange(obj, skyProp);
        });
    }

    static setLensFlareProperty(prop)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_lensFlareBp.setLensFlareProp(obj, prop);
        });
    }

    static canProperty()
    {
        return true;
    } 

    static setPlayAudio(bPlay)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if(bPlay)
            {
                obj.userData.m_sound.play();
            }
            else
            {
                obj.userData.m_sound.pause();
            }
        });
    }

    static setLoopAudio(bLoop)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            obj.userData.m_sound.setLoop(bLoop);
        });
    }

    static setAudioHelper(bHelper)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            if(bHelper)
            {
                obj.userData.m_gameObjectHelper.visible = true;
            }
            else
            {
                obj.userData.m_gameObjectHelper.visible = false;
            }
        });
    }

    static setAudioProperty(audioProp)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_audioListenerBp.setAudioProperty(obj, audioProp);
        });
    }

    static setTerrainProperty(prop)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            prop.heightmap = obj.userData.m_terrain_data.m_heightmap_image;
            prop.textures = obj.userData.m_terrain_data.m_texture_list;
            MainObjects.Blueprint.m_terrainBp.setProperty(obj, prop);
        });
    }

    static setTerrainTextureLeves(index, level)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_terrainBp.setTerrainTextureLeves(obj, index, level);
        });
    }

    static setWaterProperty(prop)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_waterBp.setProperty(obj, prop);
        });
    }

    static setGrassProperty(prop)
    {
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
            MainObjects.Blueprint.m_terrainBp.setGrass(obj, prop);
        });
    }
}

export { IParameter };