import * as THREE from 'three';
import { GameObject } from '../object/GameObject';
import { MainObjects } from "../common/MainObjects"
import { SceneFactory } from "./SceneFactory"
import { Language } from "../common/Language"
import { RectAreaLightHelper } from '../helper/RectAreaLightHelper.js';
import { DirectionalLightHelper } from "../helper/DirectionalLightHelper"
import { PointLightHelper } from "../helper/PointLightHelper"
import { SpotLightHelper } from "../helper/SpotLightHelper"
import { CameraHelper } from "../helper/CameraHelper"
import { ThreeCameraHelper } from "../helper/ThreeCameraHelper"
import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper';
import { GameScene } from '../object/GameScene';
import { CollisionsFactory } from './CollisionsFactory';

class ObjectFactory {
    static createGridHelper() {
        const helper = new THREE.GridHelper(800, 80, 0x808080, 0x808080);
        helper.name = "editor_id_gridHelper";
        helper.position.y = 0;
        helper.scale.set(0.05, 0.05, 0.05);
        helper.layers.set(11);
        helper.updateWorldMatrix(true);
        return helper;
    }

    static createDirLight(helper = null) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;

        var dirLight = new THREE.DirectionalLight(0xffffff, 1);

        var lightMaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_LIGHT_ICON);
        const sprite = new THREE.Sprite(lightMaterial);
        sprite.layers.set(11);
        sprite.name = "light_sprite"
        sprite.scale.set(0.1, 0.1, 0.1);
        sprite.userData = dirLight;

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "light_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        dirLight.add(cube);

        dirLight.name = dirLight.uuid;
        dirLight.userData = new GameObject();
        dirLight.userData.m_gameObjectType = GameObject.GameObjectType.e_DIR_LIGHT;
        dirLight.userData.m_name = Language.LOCAL_TEXT("Directional Light", "平行光");

        dirLight.castShadow = true;
        const d = 50;
        dirLight.shadow.mapSize.width = 2048;
        dirLight.shadow.mapSize.height = 2048;
        dirLight.shadow.camera.left = - d;
        dirLight.shadow.camera.right = d;
        dirLight.shadow.camera.top = d;
        dirLight.shadow.camera.bottom = - d;
        dirLight.shadow.camera.near = 0.01;
        dirLight.shadow.camera.far = 100;

        const dirLightHelper = new DirectionalLightHelper(dirLight, 0.1);
        dirLight.userData.m_gameObjectHelper = dirLightHelper;
        dirLightHelper.add(sprite)

        const camera_helper = new ThreeCameraHelper( dirLight.shadow.camera );
        camera_helper.name = "light_camera"
        dirLight.userData.m_gameLightCameraHelper = camera_helper;
        camera_helper.visible = false;

        if (helper == null) {
            SceneFactory.addToHelperGroup(dirLightHelper);
            SceneFactory.addToHelperGroup(camera_helper);
        }
        else {
            helper.add(dirLightHelper);
            helper.add(camera_helper);
        }
        dirLight.visible = false;
        dirLight.updateWorldMatrix(true);
        dirLightHelper.update();
        return dirLight;
    }

    static createPointLight(helper = null) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;

        var pointLight = new THREE.PointLight(0xffffff, 1, 100);

        var lightMaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_LIGHT_ICON);
        const sprite = new THREE.Sprite(lightMaterial);
        sprite.layers.set(11);
        sprite.name = "light_sprite"
        sprite.scale.set(0.1, 0.1, 0.1);

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "light_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        pointLight.add(cube);

        sprite.userData = pointLight;

        pointLight.name = pointLight.uuid;
        pointLight.castShadow = true;
        pointLight.shadow.mapSize.width = 1024;
        pointLight.shadow.mapSize.height = 1024;

        pointLight.userData = new GameObject();
        pointLight.userData.m_gameObjectType = GameObject.GameObjectType.e_POINT_LIGHT;
        pointLight.userData.m_name = Language.LOCAL_TEXT("Point Light", "点光");

        const pointLightHelper = new PointLightHelper(pointLight, 0.1);
        pointLight.userData.m_gameObjectHelper = pointLightHelper;
        pointLightHelper.add(sprite)

        if (helper == null) {
            SceneFactory.addToHelperGroup(pointLightHelper);
        }
        else {
            helper.add(pointLightHelper);
        }
        pointLight.updateWorldMatrix(true);
        pointLight.visible = false;
        return pointLight;
    }

    static createSpotLight(helper = null) {

        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        var spotLight = new THREE.SpotLight(0xffffff, 1);

        var lightMaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_LIGHT_ICON);
        const sprite = new THREE.Sprite(lightMaterial);
        sprite.layers.set(11);
        sprite.name = "light_sprite"
        sprite.scale.set(0.1, 0.1, 0.1);

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "light_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        spotLight.add(cube);

        sprite.userData = spotLight;

        spotLight.name = spotLight.uuid;
        spotLight.angle = Math.PI / 4;
        spotLight.penumbra = 0.1;
        spotLight.decay = 2;
        spotLight.distance = 50;

        spotLight.userData = new GameObject();
        spotLight.userData.m_gameObjectType = GameObject.GameObjectType.e_SPOT_LIGHT;
        spotLight.userData.m_name = Language.LOCAL_TEXT("Spot Light", "聚光");

        spotLight.castShadow = true;
        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;

        const spotLightHelper = new SpotLightHelper(spotLight);
        spotLightHelper.add(sprite)
        spotLight.userData.m_gameObjectHelper = spotLightHelper;

        if (helper == null) {
            SceneFactory.addToHelperGroup(spotLightHelper);
        }
        else {
            helper.add(spotLightHelper);
        }
        spotLight.updateWorldMatrix(true);
        spotLight.visible = false;
        return spotLight;
    }

    static createRectAreaLight(helper = null) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        var areaLight = new THREE.RectAreaLight(0xffffff, 1, 0.1, 0.1);

        var lightMaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_LIGHT_ICON);
        const sprite = new THREE.Sprite(lightMaterial);
        sprite.layers.set(11);
        sprite.name = "light_sprite"
        sprite.scale.set(0.1, 0.1, 0.1);

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "light_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        areaLight.add(cube);

        sprite.userData = areaLight;

        areaLight.name = areaLight.uuid;
        areaLight.userData = new GameObject();
        areaLight.userData.m_gameObjectType = GameObject.GameObjectType.e_AREA_LIGHT;
        areaLight.userData.m_name = Language.LOCAL_TEXT("Rect Area Light", "区域光");

        const areaLightHelper = new RectAreaLightHelper(areaLight);
        areaLightHelper.add(sprite);
        areaLight.userData.m_gameObjectHelper = areaLightHelper;
        if (helper == null) {
            SceneFactory.addToHelperGroup(areaLightHelper);
        }
        else {
            helper.add(areaLightHelper)
        }
        areaLight.visible = false;
        return areaLight;
    }

    static creatPerspectiveCamera(scenehelper = null) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;

        const aspect = 1920 / 1080;
        var camera = new THREE.PerspectiveCamera(40, aspect, 0.1, 100);

        var material = gameScene.m_matRepos.get(GameScene.MatKey.e_CAMERA_ICON);
        const sprite = new THREE.Sprite(material);
        sprite.name = "camera_sprite";
        sprite.layers.set(11);
        sprite.scale.set(0.1, 0.1, 0.1);
        sprite.userData = camera;

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "camera_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        camera.add(cube);

        camera.name = camera.uuid;
        camera.userData = new GameObject();
        camera.userData.m_gameObjectType = GameObject.GameObjectType.e_CAMERA_PERSP;
        camera.userData.m_name = Language.LOCAL_TEXT("Perspective Camera", "透视相机");
        const helper = new CameraHelper(camera);
        helper.add(sprite);
        camera.userData.m_gameObjectHelper = helper;
        if (scenehelper == null) {
            SceneFactory.addToHelperGroup(helper);
        }
        else {
            scenehelper.add(helper);
        }
        gameScene.m_cameraRepos.add(camera.id, camera);
        return camera;
    }

    static creatOrthographicCamera(scenehelper = null) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const aspect = 1920 / 1080;
        var frustumSize = 10;
        var camera = new THREE.OrthographicCamera(frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 0.1, 100);
        const material = gameScene.m_matRepos.get(GameScene.MatKey.e_CAMERA_ICON);
        const sprite = new THREE.Sprite(material);
        sprite.name = "camera_sprite";
        sprite.layers.set(11);
        sprite.scale.set(0.1, 0.1, 0.1);
        sprite.userData = camera;

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "camera_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        camera.add(cube);

        camera.name = camera.uuid;
        camera.userData = new GameObject();
        camera.userData.m_gameObjectType = GameObject.GameObjectType.e_CAMERA_ORTHO;
        camera.userData.m_name = Language.LOCAL_TEXT("Orthographic Camera", "正交相机");
        const helper = new CameraHelper(camera);
        camera.userData.m_gameObjectHelper = helper;
        helper.add(sprite);
        if (scenehelper == null) {
            SceneFactory.addToHelperGroup(helper);
        }
        else {
            scenehelper.add(helper);
        }
        gameScene.m_cameraRepos.add(camera.id, camera);
        return camera;
    }

    static createEquirectangularCamera(scenehelper = null) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        const aspect = 1920 / 1080;
        var camera = new THREE.PerspectiveCamera(40, aspect, 0.1, 100);
        const material = gameScene.m_matRepos.get(GameScene.MatKey.e_CAMERA_EQUIRECT_ICON);
        const sprite = new THREE.Sprite(material);
        sprite.name = "camera_sprite";
        sprite.layers.set(11);
        sprite.scale.set(0.1, 0.1, 0.1);
        sprite.userData = camera;

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "camera_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        camera.add(cube);

        camera.name = camera.uuid;
        camera.userData = new GameObject();
        camera.userData.m_gameObjectType = GameObject.GameObjectType.e_CAMERA_EQUIRECT;
        camera.userData.m_name = Language.LOCAL_TEXT("Equirectangular Camera", "全景相机");
        const helper = new CameraHelper(camera);
        helper.add(sprite);
        camera.userData.m_gameObjectHelper = helper;

        if (scenehelper == null) {
            SceneFactory.addToHelperGroup(helper);
        }
        else {
            scenehelper.add(helper);
        }
        gameScene.m_cameraRepos.add(camera.id, camera);
        return camera;
    }

    static createGroup(name) {
        const group = new THREE.Group();
        group.name = group.uuid;
        group.userData = new GameObject();
        group.userData.m_gameObjectType = GameObject.GameObjectType.e_GROUP;
        group.userData.m_name = name;
        return group;
    }

    static deleteObject(object, isChild = false) {
        if (object == undefined) {
            return;
        }
        const userData = object.userData;
        if (userData.m_gameObjectType == undefined || userData.m_gameObjectType == null) {
            return;
        }
        if (userData.m_gameObjectType == GameObject.GameObjectType.e_GEOMETRY_CURVE 
            || userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF_MESH 
            || userData.m_gameObjectType == GameObject.GameObjectType.e_SKY_DIR_LIGHT) {
            return;
        }
        else if (userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
            for (let index = 0; index < object.children.length; index++) {
                const element = object.children[index];
                ObjectFactory.deleteObject(element, true);
            }
        }
        CollisionsFactory.destory(MainObjects.Blueprint.m_collisionsManagerBp.m_physics, object);
        MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.removeAllSelectObjects(object);
        if (!isChild) {
            object.removeFromParent();
        }
        switch (userData.m_gameObjectType) {
            case GameObject.GameObjectType.e_GROUP:
            case GameObject.GameObjectType.e_DIR_LIGHT:
            case GameObject.GameObjectType.e_POINT_LIGHT:
            case GameObject.GameObjectType.e_SPOT_LIGHT:
            case GameObject.GameObjectType.e_AREA_LIGHT:
            case GameObject.GameObjectType.e_CAMERA_PERSP:
            case GameObject.GameObjectType.e_CAMERA_ORTHO:
            case GameObject.GameObjectType.e_CAMERA_EQUIRECT:
                const helper = userData.m_gameObjectHelper;
                if (helper != null) {
                    helper.removeFromParent();
                    helper.dispose();
                }
                const lighthelper = userData.m_gameLightCameraHelper;
                if(lighthelper != null)
                {
                    lighthelper.removeFromParent();
                    lighthelper.dispose();
                }
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                    else if (child.isSprite) {
                        child.material.dispose();
                    }
                });
                MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_cameraRepos.remove(object.id);
                break;
            case GameObject.GameObjectType.e_GLTF:
                const helperSk = userData.m_gameObjectHelper;
                if (helperSk != null) {
                    helperSk.removeFromParent();
                }
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                });
                break;
            case GameObject.GameObjectType.e_CURVE:
                object.traverse(function (child) {
                    if (child.isMesh || child.isLine) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                    else if (child.isSprite) {
                        child.material.dispose();
                    }
                });
                break;
            case GameObject.GameObjectType.e_SKY:
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                });
                break;
            case GameObject.GameObjectType.e_REAL_TIME_SKY:
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                });
                break;
            case GameObject.GameObjectType.e_LENS_FLARE:
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                });
                object.dispose();
                break;
            case GameObject.GameObjectType.e_TERRAIN_SYS:
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                });
                break;
            case GameObject.GameObjectType.e_AUDIO:
                MainObjects.Blueprint.m_audioListenerBp.remove(object);
                const helperAu = userData.m_gameObjectHelper;
                if (helperAu != null) {
                    helperAu.removeFromParent();
                    helperAu.dispose();
                }
                object.traverse(function (child) {
                    if (child.isMesh) {
                        child.material.dispose();
                        child.geometry.dispose();
                    }
                    else if (child.isSprite) {
                        child.material.dispose();
                    }
                });
                break;
            default:
                break;
        }
    }

    static createLod() {
        const lod = new THREE.LOD();
        lod.name = lod.uuid;
        lod.userData = new GameObject();
        lod.userData.m_name = Language.LOCAL_TEXT("LOD", "LOD");
        lod.userData.m_gameObjectType = GameObject.GameObjectType.e_LOD;
        return lod;
    }

    static changeSpriteSelectColor(object, select) {
        var sp = null;
        if (object.isCamera) {
            sp = object.userData.m_gameObjectHelper.getObjectByName("camera_sprite");
        }
        else if (object.isLight) {
            sp = object.userData.m_gameObjectHelper.getObjectByName("light_sprite");
        }
        else {
            return;
        }
        if (select) {
            // sp.material.color.setHex( 0xff0000 ); 
        }
        else {
            // sp.material.color.setHex( 0xffffff ); 
        }
    }

    static createAudio(res, helper = null) {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;

        var sound = new THREE.PositionalAudio(MainObjects.Scene.m_listener);

        sound.setBuffer(res);

        sound.name="sound"

        var audioSp = new THREE.Object3D();
        var material = gameScene.m_matRepos.get(GameScene.MatKey.e_AUDIO_ICON);
        const sprite = new THREE.Sprite(material);
        sprite.layers.set(11);
        sprite.name = "audio_sprite"
        sprite.scale.set(0.1, 0.1, 0.1);
        audioSp.add(sprite);

        const geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_CUBE_0_1);
        const cmaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_MAT);
        var cube = new THREE.Mesh(geometry, cmaterial);
        cube.visible = true;
        cube.name = "audio_sprite";
        cube.userData = new GameObject();
        cube.userData.m_gameObjectType = GameObject.GameObjectType.e_SELECT_HELPER_BOX;
        audioSp.add(cube);

        
        audioSp.add(sound);
        audioSp.name = sound.uuid;
        audioSp.userData = new GameObject();
        audioSp.userData.m_gameObjectType = GameObject.GameObjectType.e_AUDIO;
        audioSp.userData.m_name = Language.LOCAL_TEXT("Audio", "声音");
        audioSp.userData.m_sound = sound;

        const positionaHelper = new PositionalAudioHelper(sound);
       
        sound.add(positionaHelper);
        audioSp.userData.m_gameObjectHelper = positionaHelper;
        var sp = audioSp.getObjectByName("audio_sprite");
        sp.userData = audioSp;

        if (helper == null) {
            SceneFactory.addToHelperGroup(positionaHelper);
        }
        else {
            helper.add(positionaHelper);
        }
        audioSp.updateWorldMatrix(true);
        positionaHelper.update();
        return audioSp;
    }

}

export { ObjectFactory };