import * as THREE from 'three';
import { ObjectsRepos } from '../factory/ObjectsRepos';
import { MainObjects } from '../common/MainObjects';
import { ICollisions } from '../interface/ICollisions';
import { GameObject } from '../object/GameObject';
import { CollisionsFactory } from '../factory/CollisionsFactory';
import { IScript } from '../interface/IRes';
import { ScriptLoader } from '../loader/ScriptLoader';
import { GameScene } from '../object/GameScene';
import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils';
// import { SimplifyModifier } from 'three/examples/jsm/modifiers/SimplifyModifier';
// import * as AMMO from "@enable3d/ammo-physics"

class CollisionsManagerBp {
    constructor() {
        this.m_needColliderList = new ObjectsRepos();
        this.m_collisionRepos = new ObjectsRepos();
        this.m_collisionUpdateRepos = new ObjectsRepos();
        this.m_turnPhysics = false;
        this.m_lastPosQuate = new ObjectsRepos();
        this.m_physics = null;
        this.init(function(){});
    }

    initAmmoPhysics(needLoad = true)
    {
        if(needLoad)
        {
            if(this.m_physics != null)
            {
                return;
            }
            // physics
            this.m_physics = new MainObjects.Physics.AmmoPhysics(MainObjects.Scene.m_renderScene);
        }
       
        if(MainObjects.Setting.m_turnPhysicsHelper != undefined)
        {
           ;
        }
        else
        {
            MainObjects.Setting.m_turnPhysicsHelper = true;
        }
        this.turnOffPhysicsHelper(MainObjects.Setting.m_turnPhysicsHelper);
    }     

    turnOffPhysicsHelper(value)
    {
        for(var [id, object] of this.m_collisionRepos.m_objectsMap)
        {
            if(object.userData.m_collisionsMesh != null)
            {
                object.userData.m_collisionsMesh.visible = value;
            }
        }
    }

    updatePhysicsHelper()
    {
        // MainObjects.Scene.m_renderScene.autoUpdate = true;
    }

    setType(type, object, data = null) {
       
        if (object.isMesh || object.isGroup) {
            if (object.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE) {
                return;
            }
            this.removeCollisions(object);
            object.userData.m_collisionsType = ICollisions.CollisionsType.e_NONE;
            if (type == ICollisions.CollisionsType.e_NONE) {
                object.userData.m_collisionsType = type;
            }
            else if (type == ICollisions.CollisionsType.e_MESH) {
                this.createMeshCollisions(object);
                object.userData.m_collisionsType = type; 
            }
            else if (type == ICollisions.CollisionsType.e_CAPSULE) {
        
                this.createCapsuleCollisions(object, data);
                object.userData.m_collisionsType = type;
            }
            else if (type == ICollisions.CollisionsType.e_BOUNDING_BOX) {

                this.createBoundingBoxCollisions(object);
                object.userData.m_collisionsType = type;
            }
            else if(type == ICollisions.CollisionsType.e_GROUP_BOUNDING_BOX)
            {     
                this.createGroupBoundingBoxCollisions(object, data);
                object.userData.m_collisionsType = type;
            }
        }
    }

    createBoundingBoxCollisions(object) {
        if (this.m_collisionRepos.get(object.id) == null || this.m_collisionRepos.get(object.id) == undefined) {
            var _this = this;
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            const material = gameScene.m_matRepos.get(GameScene.MatKey.e_COLLISION_WIREFRAME_MAT);            
            object.updateMatrixWorld(true, true);
            var pos_oj = new THREE.Vector3();
            object.getWorldPosition(pos_oj);

            var geometryArray  = [];
            object.traverse(function(child){
                if(child.isMesh)
                {
                    // box
                    if(child.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF_MESH || object.userData.m_gameObjectType == GameObject.GameObjectType.e_TERRAIN_SYS)
                    {
                        child.geometry.computeBoundingBox ();   
                        var size = new THREE.Vector3();
                        child.geometry.boundingBox.getSize(size);
                        const geometry = new THREE.BoxGeometry( size.x, size.y, size.z );

                        const cube = new THREE.Mesh( geometry, material);
                        cube.applyMatrix4( child.matrixWorld );
                        var posTemp = new THREE.Vector3();
                        const box = new THREE.Box3();
                        box.copy( child.geometry.boundingBox ).applyMatrix4( child.matrixWorld );
                        box.getCenter(posTemp);
                        cube.position.copy(posTemp);
                        cube.updateMatrixWorld(true, true);

                        let matrixWorldGeometry = cube.geometry.clone().applyMatrix4(cube.matrixWorld);
                        geometryArray.push(matrixWorldGeometry);
                    }
                }
            });

            if(geometryArray.length == 0)
            {
                return;
            }
          
            const mergedGeometries = BufferGeometryUtils.mergeBufferGeometries(geometryArray, false);
            var mesh = new THREE.Mesh(mergedGeometries, material);

            CollisionsFactory.createMeshShape(this.m_physics, object, mesh);
            this.m_collisionRepos.add(object.id, object);
        }
    }

    createGroupBoundingBoxCollisions(object, data = null) {
        var rot = new THREE.Euler();
        rot.copy(object.rotation);
        object.rotation.set(0, 0, 0);
        if (this.m_collisionRepos.get(object.id) == null || this.m_collisionRepos.get(object.id) == undefined) {
  
            object.updateMatrixWorld(true, true);
            var selectBox = new THREE.Box3();
            selectBox.setFromObject(object);
            var size = new THREE.Vector3();
            selectBox.getSize(size);
           
            var pos = new THREE.Vector3();
            var pos_oj = new THREE.Vector3();
            object.getWorldPosition(pos_oj);

            if(data == null)
            {
                selectBox.getCenter(pos);
            }
            else
            {
                if(data.center)
                {
                    var center = new THREE.Vector3();
                    center.fromArray(data.center);
                    pos.copy(pos_oj);
                    pos.sub(center);
                }
                else
                {
                    selectBox.getCenter(pos);
                }
            }
          
            var offset = pos_oj.sub(pos);
            if(size.x == 0 || size.y == 0 || size.z == 0)
            {
                size.set(1, 1, 1);
                offset.set(0,0,0);
            }
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            const material = gameScene.m_matRepos.get(GameScene.MatKey.e_COLLISION_WIREFRAME_MAT);


            if(data == null)
            {

                const geometry = new THREE.BoxGeometry( size.x, size.y, size.z);
                const cube = new THREE.Mesh( geometry, material );
                cube.position.copy(pos);
                cube.updateMatrixWorld(true, true);
                CollisionsFactory.createBoxShape(this.m_physics, object, cube, size, offset);
            }
            else
            {
                if(data.size)
                {
                    size.set(data.size[0], data.size[1], data.size[2]);
                }
                var mass = 80;
                if(data.mass)
                {
                    mass = data.mass;
                }

                const geometry = new THREE.BoxGeometry( size.x, size.y, size.z);
                const cube = new THREE.Mesh( geometry, material );
                cube.position.copy(pos);
                cube.updateMatrixWorld(true, true);
                CollisionsFactory.createBoxShape(this.m_physics, object, cube, size, offset, mass);
            }
          
            this.m_collisionRepos.add(object.id, object);
            if(object.userData.m_collisionFlags == 0 || object.userData.m_collisionFlags == 2)
            {
                this.m_collisionUpdateRepos.add(object.id, object);
            }
            object.rotation.copy(rot);
            object.updateMatrixWorld(true, true);
        }
        

    }

    createMeshCollisions(object) {
        if (this.m_collisionRepos.get(object.id) == null || this.m_collisionRepos.get(object.id) == undefined) {

            object.updateMatrixWorld(true, true);
            var pos_oj = new THREE.Vector3();
            object.getWorldPosition(pos_oj);

            var geometryArray  = [];
            object.traverse(function(child){
                if(child.isMesh)
                {
                    // 合并模型
                    if(child.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF_MESH)
                    {
                        let matrixWorldGeometry = child.geometry.clone().applyMatrix4(child.matrixWorld);
                        geometryArray.push(matrixWorldGeometry);
                    }
                    else if(object.userData.m_gameObjectType == GameObject.GameObjectType.e_TERRAIN_SYS)
                    {
                        let matrixWorldGeometry = child.geometry.clone().applyMatrix4(child.matrixWorld);
                        geometryArray.push(matrixWorldGeometry);
                    }
                }
            });
            
            if(geometryArray.length == 0)
            {
                return;
            }

            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            const material = gameScene.m_matRepos.get(GameScene.MatKey.e_COLLISION_WIREFRAME_MAT);
            const mergedGeometries = BufferGeometryUtils.mergeBufferGeometries(geometryArray, false);

            object.userData.m_collisionsMesh = new THREE.Mesh(mergedGeometries, material);
            CollisionsFactory.createMeshShape(this.m_physics, object, object.userData.m_collisionsMesh);
            this.m_collisionRepos.add(object.id, object);
        }

    }

    removeCollisions(object) {
        var _this = this;
        if (this.m_collisionRepos.get(object.id) != null && this.m_collisionRepos.get(object.id) != undefined) {
            this.m_collisionRepos.remove(object.id)
            CollisionsFactory.destory(this.m_physics, object);
        }
        if (this.m_collisionUpdateRepos.get(object.id) != null && this.m_collisionUpdateRepos.get(object.id) != undefined) {
            this.m_collisionUpdateRepos.remove(object.id)
        }
    }

    createCapsuleCollisions(object, data = null) {
        if (this.m_collisionRepos.get(object.id) == null || this.m_collisionRepos.get(object.id) == undefined) {          
            object.updateMatrixWorld(true, true);

            var selectBox = new THREE.Box3();
            selectBox.setFromObject(object);
            var size = new THREE.Vector3();
            selectBox.getSize(size);

            var pos_oj = new THREE.Vector3();
            object.getWorldPosition(pos_oj);
            var pos = new THREE.Vector3();
            if(data == null)
            {
                selectBox.getCenter(pos);
            }
            else
            {
                if(data.center)
                {
                    var center = new THREE.Vector3();
                    center.fromArray(data.center);
                    pos.copy(pos_oj);
                    pos.sub(center);
                }
                else
                {
                    selectBox.getCenter(pos);
                }
            }
            
            var offset = pos_oj.sub(pos);
            var radius = Math.max(size.x, size.z) * 0.5;

            height = size.y;
      
            if(size.y == 0)
            {
                var height = 1;
                radius = 0.5;
                offset.set(0,0,0);
            }

            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            const geometry = new THREE.CapsuleGeometry( radius, height, 4, 8 );
            const material = gameScene.m_matRepos.get(GameScene.MatKey.e_COLLISION_WIREFRAME_MAT);
         

            if(data == null)
            {
                const cube = new THREE.Mesh( geometry, material ); 
                cube.position.copy(pos);
                cube.updateMatrixWorld(true, true);
                CollisionsFactory.createCapsuleShape(this.m_physics, object, cube, radius, height, offset);
            }
            else
            {
                if(data.radius)
                {
                    radius = data.radius;
                }
                if(data.height)
                {
                    height = data.height;
                }
                var mass = 80;
                if(data.mass)
                {
                    mass = data.mass;
                }

                const cube = new THREE.Mesh( geometry, material ); 
                cube.position.copy(pos);
                cube.updateMatrixWorld(true, true);
                CollisionsFactory.createCapsuleShape(this.m_physics, object, cube, radius, height, offset, mass);
            }
            
            this.m_collisionRepos.add(object.id, object);
            if(object.userData.m_collisionFlags == 0 || object.userData.m_collisionFlags == 2)
            {
                this.m_collisionUpdateRepos.add(object.id, object);
            }
        }

    }

    setCollisionsType(object, type) {
        this.setType(type, object);
    }

    setCollisionsPhyData(object, data) {

        if (this.m_collisionRepos.get(object.id) != null && this.m_collisionRepos.get(object.id) != undefined) {
            if(object.userData.m_collisionsMesh != null)
            {
                // object.userData.m_collisionsMesh.body.setCollisionFlags(data.collisionFlags);
                // object.userData.m_collisionsMesh.body.setGravity(0, data.gravity, 0);

                if(data.angularFactor)
                {
                    // object.userData.m_collisionsMesh.body.setAngularFactor(data.angularFactor[0], data.angularFactor[1], data.angularFactor[2]);
                    object.userData.m_angularFactor.fromArray(data.angularFactor);
                }
                if(data.friction)
                {
                    // object.userData.m_collisionsMesh.body.setFriction(data.friction);
                    object.userData.m_friction = data.friction;
                }
            
                object.userData.m_collisionFlags = data.collisionFlags;
                object.userData.m_gravity = data.gravity;
                if(object.userData.m_collisionFlags == 0 || object.userData.m_collisionFlags == 2)
                {
                    this.m_collisionUpdateRepos.add(object.id, object);
                }
                else
                {
                    if (this.m_collisionUpdateRepos.get(object.id) != null && this.m_collisionUpdateRepos.get(object.id) != undefined) {
                        this.m_collisionUpdateRepos.remove(object.id)
                    }
                }
            }
        }
    }

    setCollisionsBodyProp(object, data){
        if (this.m_collisionRepos.get(object.id) != null && this.m_collisionRepos.get(object.id) != undefined) {
            this.setType(object.userData.m_collisionsType, object, data);
        }
    }

    getCollisionsData(object) {

        var data = {
            type: object.userData.m_collisionsType,
            center: [object.userData.m_offset.x, object.userData.m_offset.y, object.userData.m_offset.z],
            size: [object.userData.m_size.x, object.userData.m_size.y, object.userData.m_size.z],
            radius: object.userData.m_size.x,
            height: object.userData.m_size.y,
            mass: object.userData.m_mass,
            collisionFlags: object.userData.m_collisionFlags,
            gravity: object.userData.m_gravity,
            angularFactor: [object.userData.m_angularFactor.x, object.userData.m_angularFactor.y, object.userData.m_angularFactor.z],
            friction: object.userData.m_friction,
            verson: 3
        }
        return data;
    }

    update(deltaTime) {
        if(this.m_physics != null)
        {
            if (this.m_turnPhysics) {
                if(this.m_lastPosQuate.m_objectsMap.size == 0)
                {
                    for(var [id, object] of this.m_collisionUpdateRepos.m_objectsMap)
                    {
                        if(object.userData.m_collisionsMesh != null)
                        {
                            if(object.userData.m_collisionFlags == 0)
                            {
                                var lastPos = {
                                    pos: new THREE.Vector3,
                                    quate: new THREE.Quaternion(),
                                    object: object,
                                }
                                lastPos.pos.copy(object.position);
                                lastPos.quate.copy(object.quaternion);
                                this.m_lastPosQuate.add(object.id, lastPos);
                            } 
                        }
                    }
                }
                this.m_physics.update(deltaTime * 1000)
                for(var [id, object] of this.m_collisionUpdateRepos.m_objectsMap)
                {
                    CollisionsFactory.updateCollisions(object);
                }
            }
            else
            {
                if(this.m_lastPosQuate.m_objectsMap.size > 0)
                {
                    for(var [id, data] of this.m_lastPosQuate.m_objectsMap)
                    {
                        data.object.position.copy(data.pos);
                        data.object.quaternion.copy(data.quate);
                        MainObjects.Blueprint.m_selectListenerBp.updateObject(data.object);
                    }
                    this.m_lastPosQuate.clear();
                }
            }
        }

    }

    initOneCollisions(object, data)
    {
        var _this = this;
        if(data)
        {
            if(data.verson != undefined)
            {
                _this.setType(data.type, object, data);
                _this.setCollisionsPhyData(object, data);
            }
        }
    }

    initCollisions()
    {
        var _this = this;
        this.init(function(){
            for(var [object, data] of _this.m_needColliderList.m_objectsMap)
            {
                if(data.verson != undefined)
                {
                    _this.setType(data.type, object, data);
                    _this.setCollisionsPhyData(object, data);
                }
               
            }
        });
        _this.m_needColliderList.clear();
    }

    init(callback)
    {
        this.initAmmoPhysics(false);
        callback();
        // var _this = this;
        // if(this.m_physics == null)
        // {
        //     ScriptLoader.loadScript(IScript.three, function(){
        //         ScriptLoader.loadScript(IScript.enable3d_ammoPhysics, function(){
        //             MainObjects.Physics = ENABLE3D
        //             MainObjects.Physics.PhysicsLoader(IScript.scriptPath, function(){
        //                 _this.initAmmoPhysics();
        //                 callback();
        //             });
        //         });
        //     });
        // }
        // else
        // {
        //     callback();
        // }
    }

    turnPhysics(b)
    {
        if (!this.m_turnPhysics) 
        {
            if(object.userData.m_collisionsMesh != null)
            {
                var data = {
                    mass : object.userData.m_mass, 
                }
                var dataPhy = {
                    collisionFlags: object.userData.m_collisionFlags,
                    gravity: object.userData.m_gravity,
                    angularFactor: [object.userData.m_angularFactor.x, object.userData.m_angularFactor.y, object.userData.m_angularFactor.z],
                    friction: object.userData.m_friction,
                }
                this.setType(object.userData.m_collisionsType, object, data);
                this.setCollisionsPhyData(object, dataPhy);
            }
        }
        this.m_turnPhysics = b;
        
    }

    move(object)
    { 
        
    }


}

export { CollisionsManagerBp };