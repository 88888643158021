import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { IRender } from "../interface/IRender"
import { GameObject } from "../object/GameObject"

class RenderLoader {

    constructor(mesh, webSocket) {
        this.m_mesh = mesh;
        this.m_id = mesh.id;
        this.m_webSocket = webSocket;
    }

    getImageId(texture) {
        var image = texture.image;
        var g = Math.ceil(texture.userData.m_gamma * 10000);
        var id = image.src + g;
        id = id.replace(MainObjects.Network.m_http, "");
        id = id.replace(new RegExp("/", ("gm")), "-");
        id = id.replace(".", "-");
        return id;
    }

    sentTexture(texture, channel = "default") {
        if (texture == null) {
            return;
        }
        var id = this.getImageId(texture);
        if (IRender.f_renderLogCallback != null) {
            var s = "创建纹理:" + texture.id;
            IRender.f_renderLogCallback(s);
        }
        var textureId = texture.id;
        if(channel != "default")
        {
            textureId = textureId + channel;
        }
        var sentMessage = {
            cmd: "createTexture",
            id: textureId,
            file: id,
            rotation: texture.rotation * 180 / Math.PI,
            uvscale: [texture.repeat.x, texture.repeat.y],
            uvdelta: [texture.offset.x, texture.offset.y],
            channel : channel,
            data: "",
            bEnd: true
        }
        this.m_webSocket.sent(JSON.stringify(sentMessage));
    }

    sentMesh(geometry) {
        if (geometry == null) {
            this.m_condition_mat_num = this.m_condition_mat_num - 1;
            return;
        }
        var index;
        if( geometry.index == undefined)
        {
            index = new Array();
            var inx = geometry.attributes.position.array.length;
            for(var i = 0; i < inx; i++)
            {
                index.push(i);
            }
        }
        else
        {
            index = geometry.index.array;
        }
        if (IRender.f_renderLogCallback != null) {
            var s = "创建几何体:" + geometry.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createMesh",
            id: geometry.id,
            position: geometry.attributes.position.array.toString(),
            normal: geometry.attributes.normal.array.toString(),
            index: index.toString(),
            uv: geometry.attributes.uv == undefined ? "" : geometry.attributes.uv.array.toString(),
            bEnd: true
        }
        this.m_webSocket.sent(JSON.stringify(sentMessage));
    }

    sentImage(texture) {
        var _this = this;
        if (texture == null) {
            return;
        }
        var image = texture.image;
        var id = this.getImageId(texture);
        if (IRender.f_renderLogCallback != null) {
            var s = "创建贴图:" + id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createImage",
            id: id,
            data: "",
            width: image.width,
            height: image.height,
            gamma : texture.userData.m_gamma,
            bEnd: false
        }
        this.m_webSocket.sent(JSON.stringify(sentMessage));
        var data = THREE.ImageUtils.getDataURL(image);
        this.m_webSocket.getData(data, function (message) {
            sentMessage.data = message;
            _this.m_webSocket.sent(JSON.stringify(sentMessage));
        });

        sentMessage.data = "";
        sentMessage.bEnd = true;
        this.m_webSocket.sent(JSON.stringify(sentMessage));
    }

    sentMaterial(material) {
        if (material == null) {
            return;
        }
        if (IRender.f_renderLogCallback != null) {
            var s = "创建材质:" + material.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createMaterial",
            id: material.id,
            data: "",
            side: material.side,
            transparent: material.transparent,
            opacity: material.opacity,
            map: material.map == null ? "" : material.map.id,
            color: [material.color.r, material.color.g, material.color.b],
            roughnessMap: (material.roughnessMap == null || material.roughnessMap == undefined) ? "" : material.roughnessMap.id,
            roughness: (material.roughness == undefined) ? 1 : material.roughness,
            metalnessMap: (material.metalnessMap == null || material.metalnessMap == undefined) ? "" : material.metalnessMap.id,
            metalness: (material.metalness == undefined) ? 1 : material.metalness,
            emissiveMap: (material.emissiveMap == null || material.emissiveMap == undefined) ? "" : material.emissiveMap.id,
            emissive: (material.emissive == undefined) ? [0.0, 0.0, 0.0] : [material.emissive.r, material.emissive.g, material.emissive.b],
            emissiveIntensity: (material.emissiveIntensity == undefined) ? 0 : material.emissiveIntensity,
            normalMap: (material.normalMap == null || material.normalMap == undefined) ? "" : material.normalMap.id,
            matType: material.userData.m_materialMatType,
            materialMat: material.userData.getMaterialMat(),
            bEnd: true
        }
        this.m_webSocket.sent(JSON.stringify(sentMessage));
    }

    sentObject() {
        if (this.m_mesh == null) {
            return;
        }
        if (this.m_mesh.geometry == null) {
            return;
        }
        if (this.m_mesh.material == null) {
            return;
        }
        if(this.m_mesh.isSkinMesh)
        {
            return;
        }
        var mat = new THREE.Matrix4();
        mat.makeRotationX(Math.PI / 2);
        mat.multiply(this.m_mesh.matrixWorld);
        if (IRender.f_renderLogCallback != null) {
            var s = "创建物体:" + this.m_mesh.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createObject",
            id: this.m_mesh.id,
            data: "",
            material_id: this.m_mesh.material.id,
            mesh_id: this.m_mesh.geometry.id,
            mat: mat.elements,
            visible: this.m_mesh.visible,
            bEnd: true
        }
        this.m_webSocket.sent(JSON.stringify(sentMessage));
    }

    static sentCamera(camera, webSocket) {
        if (camera == null) {
            return;
        }
        var position = new THREE.Vector3();
        var target = new THREE.Vector3();
        camera.getWorldPosition(position);
        camera.getWorldDirection(target);
        if(target.x < 0.0001 && target.x > -0.0001 && target.z > -0.0001 && target.z < 0.0001)
        {
            if((target.y > 0.9999 && target.y < 1.0001) || (target.y < -0.9999 && target.y > -1.0001))
            {
                var changeDir = new THREE.Vector3(0, 0.000001, 0.000001);
                target = target.add(changeDir).normalize();
            }
        }
        target.add(position);
        if (IRender.f_renderLogCallback != null) {
            var s = "同步相机:" + camera.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createCamera",
            id: camera.id,
            data: "",
            position: [position.x, -position.z, position.y],
            target: [target.x, -target.z, target.y],
            fov: camera.fov,
            w: camera.userData.m_w,
            h: camera.userData.m_h,
            type : "perspective",
            bEnd: true
        }
        if(camera.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_ORTHO){
            
            sentMessage.type = "orthographic";
            sentMessage.fov = camera.zoom;
        }
        else if(camera.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_EQUIRECT)
        {
            sentMessage.type = "environment";
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }

    static sentStop(webSocket, b_light = false, b_object = false){
        var sentMessage = {
            cmd: "stopRender",
            id: "stopRender",
            data: "",
            b_light: b_light,
            b_object: b_object,
            bEnd: true
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }

    static sentStart(webSocket){
        var sentMessage = {
            cmd: "startRender",
            id: "startRender",
            data: "",
            bEnd: true
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }

    static sentEnv(webSocket)
    {
        if (IRender.f_renderLogCallback != null) {
            var s = "同步环境光光";
            IRender.f_renderLogCallback(s);
        }
        var mat = new THREE.Matrix4();
        mat.makeRotationZ(MainObjects.Setting.m_env_rot * Math.PI / 180.0);

        var sentMessage = {
            cmd: "createEnv",
            id: "createEnv",
            data: "",
            total : MainObjects.Setting.m_total,
            diffuse : MainObjects.Setting.m_diffuse,
            glossy : MainObjects.Setting.m_glossy,
            specular : MainObjects.Setting.m_specular,
            haltspp : MainObjects.Setting.m_haltspp,
            gain : MainObjects.Setting.m_env_gain,
            file : MainObjects.Setting.m_env_image,
            mat : mat.elements,
            bEnd: true
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }

    static sentDirLight(light, webSocket)
    {
        if (light == null) {
            return;
        }
        var position = new THREE.Vector3();
        var target = new THREE.Vector3();
        light.getWorldPosition(position);
        light.getWorldDirection(target);
        target.add(position);
        if (IRender.f_renderLogCallback != null) {
            var s = "同步平行光:" + light.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createDirLight",
            id: light.id,
            data: "",
            gain : light.intensity,
            color : [light.color.r, light.color.g, light.color.b],
            dir: [target.x, -target.z, target.y],
            visible : light.userData.m_gameObjectHelper.visible,
            bEnd: true
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }

    static sentPointLight(light, webSocket){
        if (light == null) {
            return;
        }
        var mat = new THREE.Matrix4();
        mat.makeRotationX(Math.PI / 2);
        mat.multiply(light.matrixWorld);

        if (IRender.f_renderLogCallback != null) {
            var s = "同步点光源:" + light.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createPointLight",
            id: light.id,
            data: "",
            gain : light.intensity,
            color : [light.color.r, light.color.g, light.color.b],
            mat: mat.elements,
            visible : light.userData.m_gameObjectHelper.visible,
            bEnd: true
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }

    static sentSpotLight(light, webSocket){
        if (light == null) {
            return;
        }
        var mat = new THREE.Matrix4();
        mat.makeRotationX(Math.PI / 2);
        mat.multiply(light.matrixWorld);

        if (IRender.f_renderLogCallback != null) {
            var s = "同步聚光源:" + light.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createSpotLight",
            id: light.id,
            data: "",
            gain : light.intensity,
            color : [light.color.r, light.color.g, light.color.b],
            mat: mat.elements,
            angle: light.angle * 180.0 / Math.PI,
            visible : light.userData.m_gameObjectHelper.visible,
            bEnd: true
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }

    static createAreaLight(light, webSocket){
        if (light == null) {
            return;
        }

        var mat = new THREE.Matrix4();
        var matCopy = new THREE.Matrix4();
        matCopy.copy(light.userData.m_gameObjectHelper.matrixWorld);
        mat.makeRotationX(Math.PI / 2);
        mat.multiply(matCopy);

        if (IRender.f_renderLogCallback != null) {
            var s = "同步区域光源:" + light.id;
            IRender.f_renderLogCallback(s);
        }
        var sentMessage = {
            cmd: "createAreaLight",
            id: light.id,
            data: "",
            gain : light.intensity,
            color : [light.color.r, light.color.g, light.color.b],
            mat: mat.elements,
            visible : light.userData.m_gameObjectHelper.visible,
            bEnd: true
        }
        webSocket.sent(JSON.stringify(sentMessage));
    }
}

export { RenderLoader };