<template>
  <!-- @contextmenu.prevent="rightClick()" -->
  <div class="box">

    <div class="content" :class="hide ? 'anmDiv' : 'aaa'">
      <!-- <div class="title">层级</div> -->
      <!-- 3D对接 -->
      <div class="tree">
        <!-- :style="SceneHeight ? `height:${SceneHeight - 82}px;` : ''" -->
        <!-- `${SceneHeight - 82}px;` -->


        <vue-easy-tree ref="tree" :height="SceneHeight-180+'px'" :data="threeData" :show-checkbox="true" node-key="id"
          :props="threeDefaultProps" @node-drag-start="handleDragStart" @node-drag-end="handleDragEnd"
          @node-drop="handleDrop" @node-expand="handleNodeExpand" @node-collapse="handleNodeCollapse" draggable
          :allow-drop="allowDrop" :allow-drag="allowDrag" @node-click="handleNodeClick" @node-contextmenu="rightClick"
          @check-change="handleCheckChange" :expand-on-click-node="false" :highlight-current="true"
          :check-strictly="true" :filter-node-method="filterNode" :default-expanded-keys="unfoldIdListThree">

          <!-- 默认展开节点 -->

          <span class="custom-tree-node" :class="{'custom-tree-node':true}" slot-scope="{ node, data }">
            <!-- 非编辑状态 -->
            <span class="custom-tree-node-content">
              <!-- <i v-if="data.type==0" class="el-icon-folder-opened"></i> -->
              <!-- <i v-if="data.type == 1" class="el-icon-document"></i> -->

              <!-- <img v-if="data.children && data.children.length >0" src="../../assets/logo.png"
              style="width:0.97656vw;height:0.97656vw;" alt  />-->
              <!-- <img v-else  src="../../assets/logo.png" style="width:0.97656vw;height:0.97656vw;" alt />        -->
              <!-- 编辑状态 -->

              <!-- <el-tooltip class="item" effect="dark" :content="node.label" placement="bottom"> -->
              <el-tooltip class="item" effect="dark" :content="node.label" placement="top">
                <template v-if="data.isEdit">
                  <el-input v-model="newName" autofocus size="mini" :ref="'slotTreeInput' + data[id]"
                    @blur.stop="handleInput(node, data)" @keyup.enter.native="handleInput(node, data)"
                    style="width: 9.11458vw"></el-input>
                </template>

                <span v-else class="tree_label" @dblclick="rechristen(node, data)">



                  <div class="resourceType">
                    <!-- 模型 -->
                    <img v-if="data.type==10" class="resourceTypeImg" src="../../assets/Resource/icon_model.png" alt />
                    <img v-if="data.type==2" class="resourceTypeImg" src="../../assets/ToolBar/basics.png" alt />

                    <!-- 组 -->
                    <img v-if="data.type==1" class="resourceTypeImg" src="../../assets/ToolBar/group.png" alt />
                    <!-- 曲线组 -->
                    <img v-if="data.type==12" class="resourceTypeImg" src="../../assets/ToolBar/curve.png" alt />
                    <!-- LOD组 -->

                    <img v-if="data.type==13" class="resourceTypeImg" src="../../assets/ToolBar/lod.png" alt />
                    <!--  灯光-->
                    <img v-if="data.type==3||data.type==4||data.type==5||data.type==6" class="resourceTypeImg"
                      src="../../assets/ToolBar/lights.png" alt />
                    <!-- 相机 -->
                    <img v-if="data.type==7||data.type==8||data.type==16" class="resourceTypeImg"
                      src="../../assets/ToolBar/slr.png" alt />
                    <!-- mp3 -->
                    <img v-if="data.type==17" class="resourceTypeImg" src="../../assets/Resource/music.png" alt />
                    <!-- 天空 -->
                    <img v-if="data.type==18" class="resourceTypeImg" src="../../assets/ToolBar/sky.png" alt />

                    <img v-if="data.type==21" class="resourceTypeImg" src="../../assets/ToolBar/realTime.png" alt />


                    
                    <!-- 光晕 -->
                    <img v-if="data.type==19" class="resourceTypeImg" src="../../assets/ToolBar/halo.png" alt />
                 
                    <img v-if="data.type==23" class="resourceTypeImg" src="../../assets/Resource/particleIcon.png" alt />
                 
                    <img v-if="data.type==24" class="resourceTypeImg" src="../../assets/Resource/terrain.png" alt />
                    <img v-if="data.type==25" class="resourceTypeImg" src="../../assets/Resource/water.png" alt />

                    
                  </div>
                  {{ node.label }} 
                  <!-- {{ data.id }} -->

                </span>
              </el-tooltip>
              <div v-show="data.type==7||data.type==8 ||data.type==16"
                :class="{'camera':data.bMainCamera,NoCamera:true}" @click.stop="setMainCamera(data,$event)"></div>

              <!-- </el-tooltip> -->
              <img v-show="data.block == true&&data.canLock" src="../../assets/Resource/icon_lock.png" alt class="lock"
                @click="triggerLock(data)" />

              <img v-show="data.block == false&&data.canLock" src="../../assets/Resource/icon_unlocking.png" alt
                class="lock" @click="triggerLock(data)" />

              <div :class="{'bvisible':data.bvisible}" class="Nobvisible" @click="triggerVisible(data)"></div>
            </span>
          </span>




        </vue-easy-tree>



      </div>
    </div>

    <!-- <div class="contentTxt" @click="tabChange">{{text}}</div> -->
    <div class="myMenu">
      <div v-show="menu">
        <el-menu id="menu" @select="handleSelect" text-color="#000" class="menu" ref="menu">
          <!-- <el-menu-item v-show="Node.type==1" index="1" class="menuItem">
            <span slot="title">
              <img src="../../assets/Resource/group.png" alt class="menuIcon" />
              新建群组
            </span>
          </el-menu-item>-->

          <el-menu-item index="2" class="menuItem">
            <img src="../../assets/Resource/newName.png" alt class="menuIcon" />
            <span slot="title">重命名</span>
          </el-menu-item>

          <el-menu-item index="3" v-show="Node.canCreateGroup==true && modelUrl" class="menuItem">
            <img src="../../assets/Resource/icon_saveg_roup.png" alt class="menuIcon" />
            <span slot="title">保存到资源</span>
          </el-menu-item>

          <!-- <el-menu-item index="6" v-show="Node.canCreateGroup==true && modelUrl" class="menuItem">
            <img src="../../assets/Resource/icon_saveg_roup.png" alt class="menuIcon" />
            <span slot="title">保存静态资源</span>
          </el-menu-item> -->

          <el-menu-item v-show="Node.type!=11&&Node.type!=14" index="4" class="menuItem">
            <img src="../../assets/Resource/icon-67.png" alt class="menuIcon" />
            <span slot="title">删除</span>
          </el-menu-item>
          <el-menu-item index="5" v-show="Node.type!=11&&Node.type!=14" class="menuItem" v-if="isParent">
            <img src="../../assets/Resource/move.png" alt class="menuIcon" />
            <span slot="title">该节点移动到根目录</span>
          </el-menu-item>

          <el-menu-item index="9" v-show="Node.type!=11&&Node.type!=14" class="menuItem" v-if="isParent">
            <img src="../../assets/Resource/move.png" alt class="menuIcon" />
            <span slot="title">选中节点移动到根目录</span>
          </el-menu-item>

          <el-menu-item index="7" class="menuItem" v-if="isChecked">
            <img src="../../assets/Resource/moveMentGroup.png" alt class="menuIcon" />

            <span slot="title">添加到指定组</span>

          </el-menu-item>

          <el-menu-item index="8" class="menuItem"
            v-show="Node.canCreateGroup==true && modelUrl&& $store.state.selectNode.property==6">
            <img src="../../assets/Resource/moveMentGroup.png" alt class="menuIcon" />

            <span slot="title">更新到选中资源模型</span>

          </el-menu-item>

        </el-menu>
      </div>
    </div>

    <div v-if="recycle == false">
      <div class="recycle" v-for="item in queryFile" :key="item.id" @contextmenu.prevent="rightClick(item, item)">
        <!-- <i v-if="item.type==0 " class="el-icon-folder-opened"></i> -->
        <i class="el-icon-document"></i>
        <div class="fileName">{{ item.name }}</div>
      </div>
    </div>

    <!-- 选择组 -->
    <el-dialog :visible="groupTreeShow" width="25%" :modal="true" title="选择组" center append-to-body
      :before-close="groupTreeClose" top="23vh">

      <div class="groupTreeContent">
        <vue-easy-tree ref="groupTree" show-checkbox node-key="id" :data="groupTreeData" :props="groupTreeDefaultProps"
          @check-change="groupHandleClick" :check-strictly="true">
          <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node }}</span>

      </span> -->
        </vue-easy-tree>





      </div>

      <div class="enter">
        <div class="set verify" @click="groupTreeOk()">确认</div>
        <div class="set cancel" @click="groupTreeClose()">取消</div>
      </div>

    </el-dialog>





  </div>
</template>
<script>
import { IScene } from "../../threejs/interface/IScene";
import { ISelector } from "../../threejs/interface/ISelector";
import { IFactory } from "../../threejs/interface/IFactory";
import { ITransform } from "../../threejs/interface/ITransform";
import { ISetting } from "../../threejs/interface/ISetting";
import { ICamera } from "../../threejs/interface/ICamera";
import { IResousePreview } from "../../threejs/interface/IResousePreview";

// import SelectionArea from 'selection-area';



//导入组件树
import VueEasyTree from "@wchbrad/vue-easy-tree";

export default {
  name: "Tire",
  data() {
    return {
      hide: true,
      text: "收起",
      id: "",

      dataList: [],

      threeData: [],
      defaultProps: {
        children: "subCatalog",
        label: "name"
      },
      threeDefaultProps: {
        children: "children",
        label: "name",

        block: "block"
      },

      groupTreeDefaultProps: {
        children: "children",
        label: "name",
        object: "object",

      },
      // userData:"userData",

      //右击显示与否
      menu: false,
      //存储node
      Node: "",
      //存储当前数据
      nodedata: {},
      //存储修改过后的名字
      newName: "",
      //拖动节点
      dragNode: {},
      //目标节点
      targetNode: {},
      //根据id获取到的文件目录
      FileDirectoryList: [],
      //右击文件的类型
      rightClickType: 1,
      //查询的文件
      queryFile: [],

      //输入场景名字
      form: {
        //新建场景设置名字
        SceneName: ""
      },
      dialogFormVisible: false,

      //展开情况
      unfoldIdListThree: [],
      //点击对勾选中的
      checkedKeys: [],
      //模型路径
      modelUrl: false,
      //是否不再跟
      isParent: false,
      //树搜索字段
      filterText: "",
      //搜索类型
      SearchTypes: "1",
      //组的树
      groupTreeData: [],

      //群组树显示隐藏
      groupTreeShow: false,
      //判断树是否有被选择
      isChecked: false,


    };
  },

  components: {
    VueEasyTree
  },

  props: [
    //是否是给3D对接
    "isThree",
    //设置新名字
    //是否是回收站
    "recycle",
    //搜索的名字
    "searchName",
    //创建类型 /*创建场景，等 */
    "establishType",

    "SceneHeight"

  ],




  created() { },
  mounted() {

    ISelector.f_sceneTreeSelectCallback = this.setSelectTree.bind(this);
    window.addEventListener("click", e => {
      if (this.menu == true) {
        this.menu = false;
        this.$parent.setDragShow(true);
      }
    });

    //定时器判断3d挂载完成
    let pMountedTimer = window.setInterval(() => {
      if (window.parentMounted) {
        window.clearInterval(pMountedTimer);

        //等待3D数据
        IScene.f_sceneTreeCallback = this.getDataTree.bind(this);
        this.getDataTree();

        if (this.recycle == true) {
          this.delFolderList();
        } else if (this.recycle == false) {
          if (this.isThree == 0) {
          } else {
          }
        }
      }
    }, 500);

    document.onclick = () => {
      if (this.menu == true) {
        this.menu = false;
        this.$parent.setDragShow(true);
      }

    };
    
    // this.selectinit()
  },
  watch: {
    filterText(val) {
      let obg = {
        filterText: val,
        SearchTypes: this.SearchTypes
      };

      // this.$refs.tree.filter(obg);
      if (obg.filterText == "") {
        this.getDataTree()
      } else {
        this.$refs.tree.filter(obg);

      }



      // this.$refs.tree.filter(val);
    }
  },

  methods: {

    selectinit(){
      let config = {
        container: document.querySelector('.box'),
        area: {
          class: 'custom-area'
        },
        targets: '.tree_label',
        touchable: true,
        autostart: true,
        callback: selection => {
        //   if (selection.length == 0) alert("empty selection");
        //   else alert(`${ selection.length } items selected`);
  
          // console.log(selection.map(item => item.dataset.id))
          // document.querySelectorAll('.tree_label').forEach(item => {
          //   // item.classList.remove('active')
          // })
          // selection.forEach(item => {
          //   item.classList.add('active')
          // })
        }
      }
      let selectable = new SelectionArea(config);
    },


    // 限制单选
    groupHandleClick(data, checked, node) {
      if (checked) {


        this.$refs.groupTree.setCheckedNodes([data]);


      }
    },



    //确认组
    groupTreeOk() {

      //选中要保存的节点
      let selectList = this.$refs.tree.getCheckedNodes()
      // 要保存到的节点
      let group = this.$refs.groupTree.getCheckedNodes()



      for (let index = 0; index < selectList.length; index++) {




        IScene.addTo(group[0] == undefined ? null : group[0].object, selectList[index].object);

      }


      this.groupTreeShow = false

    },
    //关闭组
    groupTreeClose() {

      this.groupTreeShow = false
    },


    //设置搜索类型
    searchTypeChange(val) {
      this.SearchTypes = val
    },


    //设置搜索
    setFilterText(val) {
      this.filterText = val;
    },

    // 设置选中节点
    setSelectTree(data) {
      this.$refs.tree.setCheckedKeys(data);

      // this.optForUnfold(data)

    },

    //筛选树
    filterNode(value, data) {

      if (!value.filterText) return true;
      else if (value.SearchTypes == "1") {
        return data.name.indexOf(value.filterText) !== -1;
      } else if (value.SearchTypes == "2") {
        return data.name === value.filterText;
      }

      // return true
    },
    mouseLeave() {
      this.menu = false;
    },
    //选中展开
    optForUnfold(data) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        // 保存当前展开的节点
        let flag = false;
        this.unfoldIdListThree.some(item => {
          if (item === element) {
            // 判断当前节点是否存在， 存在不做处理
            flag = true;
            return true;
          }
        });
        if (!flag) {
          // 不存在则存到数组里
          this.unfoldIdListThree.push(element);
        }

        var defaultShowNodes = this.unfoldIdListThree;
        var unfoldIdList = JSON.stringify(defaultShowNodes);
        localStorage.setItem("unfoldIdListThree", unfoldIdList);
      }
    },

    // 树节点展开
    handleNodeExpand(data) {
      // 保存当前展开的节点
      let flag = false;
      this.unfoldIdListThree.some(item => {
        if (item === data.id) {
          // 判断当前节点是否存在， 存在不做处理
          flag = true;
          return true;
        }
      });
      if (!flag) {
        // 不存在则存到数组里
        this.unfoldIdListThree.push(data.id);
      }
      var defaultShowNodes = this.unfoldIdListThree;
      var unfoldIdList = JSON.stringify(defaultShowNodes);
      localStorage.setItem("unfoldIdListThree", unfoldIdList);
    },
    // 树节点关闭
    handleNodeCollapse(data) {
      this.unfoldIdListThree.some((item, i) => {
        if (item === data.id) {
          // 删除关闭节点
          this.unfoldIdListThree.length = i;
        }
      });
      var defaultShowNodes = this.unfoldIdListThree;
      var unfoldIdList = JSON.stringify(defaultShowNodes);
      localStorage.setItem("unfoldIdListThree", unfoldIdList);
    },

    //设置树的展开
    setUnfoldIdListThree() {
      //获取展开
      var unfoldIdListThree = localStorage.getItem("unfoldIdListThree");
      if (
        unfoldIdListThree !== null &&
        unfoldIdListThree != undefined &&
        unfoldIdListThree != ""
      ) {
        this.unfoldIdListThree = JSON.parse(unfoldIdListThree);
      }
    },

    //获取树状结构
    getDataTree(_data) {

      //判断与3d对接
      let data = IScene.getSceneTree(this.$store.state.threeSceneTreeType);

      var _this = this;

      this.threeData = data;

      // let sequenceList=[]
      // if (data) {
      //   data.forEach(element => {
      //   sequenceList.push(element.name)
      // });

      // }

      // console.log(this.threeData,"this.threeData")
      
      // console.log(data,"data",this.$store.state.threeSceneTreeType);


      // this.setUnfoldIdListThree();
      setTimeout(() => {
        let obg = {
          filterText: _this.filterText,
          SearchTypes: _this.SearchTypes
        };
        if (obg.filterText == "") {

        } else {
          _this.$refs.tree.filter(obg);

        }

      }, 1);

    },

    //收起还是展开
    tabChange() {
      if (this.hide) {
        this.hide = false;
        this.text = "展开";
      } else {
        this.hide = true;
        this.text = "收起";
      }
    },

    //单击
    handleNodeClick(data) {

      this.menu = false;
      var id = data.id;
      var idList = [];
      idList.push(id);

      // this.$refs.tree.setCheckedKeys(idList);
      ISelector.selectObjectByTree(data.object);

    },
    // 多选
    handleCheckChange(data, checked, indeterminate) {
      //判断是否有被选择

      let isChecked = this.$refs.tree.getCheckedNodes().length != 0
      this.isChecked = isChecked




      if (checked == true) {
        ISelector.selectObjectByTree(data.object);
        // console.log(data)
      } else {
        ISelector.removeSelectObjectByTree(data.object);
      }
    },
    //节点拖拽时触发
    handleDragStart(node, ev) {
      this.dragNode = node;
    },

    //拖拽结束时（可能未成功）触发的事件
    handleDragEnd(draggingNode, dropNode, dropType, ev) {

    },
    //拖拽成功时
    handleDrop(before, after, inner, ev) {


      // setSelectObjectName

      // IScene.addTo(after.data.object, before.data.object);
      if (after.data.type != 1) {
        if (after.level == 1) {
          IScene.addTo(null, before.data.object);
        } else {
          IScene.addTo(after.data.object, before.data.object);
        }
      } else {
        IScene.addTo(after.data.object, before.data.object);
      }


      // this.moveFolderFile(before.data.id, before.data.type, after.data.id);

      //拖动
      //addTo
    },

    /**拖拽时判定目标节点能否被放置。type 参数有三种情况：'prev'、'inner' 和 'next'，分别表示放置在目标节点前、插入至目标节点和放置在目标节点后 */
    allowDrop(draggingNode, dropNode, type) {

      if (dropNode.data.type == 1) {
        return type === "inner";
      } else {
        return type === "false";
      }
    },
    //判断节点能否被拖拽
    allowDrag(draggingNode) {
      return draggingNode.data.canDrag;
    },
    //添加
    append(data) {
      let id = "id" + Date.now();

      const newChild = { id: id, label: "testtest", children: [] };

      if (!data.children) {
        this.$set(data, "children", []);
      }

      data.children.push(newChild);
    },
    //右击
    rightClick(object, Node) {


      this.isParent = Node.object.parent.parent.parent != null;
      var modelUrl = localStorage.getItem("modelUrl");

      if (modelUrl) {
        this.modelUrl = true;
      } else {
        this.modelUrl = false;
      }

      //让其他的菜单隐藏
      this.$parent.commandMenuShow();

      this.$parent.showMenu();

      if (Node.block == false) {
        this.rightClickType = Node.type;
        //获取到菜单
        let menu = this.$refs.menu;
        let x = event.clientX;
        let y = event.clientY;
        if (y > 700) {
          menu.$el.style.top = y - 150 + "px";
        } else {
          menu.$el.style.top = y + "px";
        }
        if (x > 1280) {
          menu.$el.style.left = x - 100 + "px";
        } else {
          menu.$el.style.left = x + "px";
        }

        this.nodedata = object ? object : ""; //存当前数据
        this.Node = Node ? Node : ""; //存当前节点信息
        this.menu = true; //让菜单显示
        this.$parent.setDragShow(false);

        this.id = Node.id ? Node.id : "";
      } else {
        this.$message({
          message: "您已锁定请先解锁"
          // type: "warning"
        });
      }
    },

    showMenu() {
      this.menu = false;
    },
    //菜单
    handleSelect(key) {
      let tree = this.$refs.tree;
      let data = this.Node;

      if (key == 1) {
        //创建组
        // ISelector.selectObjectByTree(this.Node.object);
        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        );

        ISetting.isInput(true);
        this.$prompt(``, "请输入群组名称", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",

          // center: true,
          inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
          closeOnClickModal: false,
          inputValidator: value => {
            // 点击按钮时，对文本框里面的值进行验证
            if (!value) {
              return "输入不能为空";
            } else if (containSpecial.test(value)) {
              return "输入不能为特殊字符";
            } else if (value.length > 20) {
              return "输入文字不能超过20个字符";
            }
          }
          // inputErrorMessage: "输入不能为空",
          // inputValidator: value => {
          //   // 点击按钮时，对文本框里面的值进行验证
          //   if (!value) {
          //     return "输入不能为空";
          //   }
          // }
        })
          .then(({ value }) => {
            ISetting.isInput(false);
            IFactory.createGroup(value);
          })
          .catch(() => {
            ISetting.isInput(false);

            this.$message({
              // type: "info",
              message: "取消输入"
            });
          });

      } else if (key == 2) {
        //重命名

        ISelector.selectObjectByTree(this.Node.object, false);
        ISetting.isInput(true);

        var containSpecial = RegExp(
          /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\-)(\_)(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\——)(\——)(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
        );

        this.$prompt(``, "请输入新名称", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputValue: this.Node.name,
          closeOnClickModal: false,

          // center: true,
          inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
          inputValidator: value => {
            // 点击按钮时，对文本框里面的值进行验证
            if (!value) {
              return "输入不能为空";
            } else if (containSpecial.test(value)) {
              return "输入不能为特殊字符";
            } else if (value.length > 20) {
              return "输入文字不能超过20个字符";
            }
          }
        })
          .then(({ value }) => {
            ISetting.isInput(false);

            ITransform.setSelectObjectName(value);
          })
          .catch(() => {
            ISetting.isInput(false);

            this.$message({
              // type: "info",
              message: "取消输入"
            });
          });
      } else if (key == 3) {
        // 保存动态资源
        this.$store.commit("changeFolderType", 2)

        this.$store.commit("changeSynchronization", '3')
        this.$store.commit("changeDownloadShow", true)
        
        this.$parent.showPreviewGroup(false);
        this.$parent.setNode(this.Node);

      } else if (key == 4) {
        //删除
        ISelector.selectObjectByTree(this.Node.object, false);
        ITransform.deleteObject();

      } else if (key == 5) {

        //移动到跟
        IScene.addTo(null, this.Node.object);

      } else if (key == 6) {
        //保存静态资源

      } else if (key == 7) {

        this.groupTreeShow = true

        this.groupTreeData = IScene.getSceneGroup()
        // console.log(this.groupTreeData,"this.groupTreeData")

      } else if (key == 8) {
        // 覆盖更新到组

        this.$parent.showPreviewGroup(true);

        this.$parent.setNode(this.Node);
      }else if (key == 9) {
        // 所选节点移动到根目录
        let selectNode=this.$refs.tree.getCheckedNodes()
        if (selectNode.length>0) {
          
        for (let index = 0; index < selectNode.length; index++) {
          const element = selectNode[index];
          
        IScene.addTo(null, element.object);
        }

      }


      }

    },

    //双击重命名
    rechristen(_node, _data) { },
    //切换锁定状态
    triggerLock(data) {
      data.block = !data.block;
      ISelector.selectObjectByTree(data.object, false);
      // //调用方法切换锁定状态
      ITransform.changeLock();
    },

    setMainCamera(data) {
      data.bMainCamera = true;

      var camera = ICamera.setMainCamera(data.id);

      for (let index = 0; index < camera.length; index++) {
        const id = camera[index];

        var node = this.byIdToNoder(id);

        if (node != null) {
          node.bMainCamera = false;
        }
      }
    },

    //通过id获取节点
    byIdToNoder(id) {
      for (let index = 0; index < this.threeData.length; index++) {
        if (this.threeData[index].id == id) return this.threeData[index];
        if (this.threeData[index].children) {
          const res = this.inquireNode(this.threeData[index].children, id);
          if (res) return res;
        }
      }

      return null;
    },

    //通过id查找节点
    inquireNode(tree, id) {
      for (let index = 0; index < tree.length; index++) {
        if (tree[index].id == id) return tree[index];
        if (tree[index].subCatalog) {
          const res = this.inquireNode(tree[index].subCatalog, id);

          if (res) return res;
        }
      }

      return null;
    },

    //切换是否可见
    triggerVisible(data) {

      //调用方法切换是否可见

      data.bvisible = !data.bvisible;

      ISelector.selectObjectByTree(data.object, false);

      ITransform.changeVisible();
    }
  }
};
</script>
<style scoped>
.content {
  /* width: 420px; */
  /* height: 450px; */
  background: #fff;
  /* box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1); */
  border-radius: 5px;
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  display: none;
}

.content {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.anmDiv {
  transition: 1s;
}

.aaa {
  width: 45px;
  transition: 1s;
}

.contentTxt {
  width: 45px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background: #c33;
  border-radius: 5px;
  color: #fff;
  font-size: 8px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.title {
  width: 90%;
  height: 25px;
  line-height: 25px;
  color: #000;
  font-size: 10px;
  border-bottom: 2px solid #ccc;
  text-align: left;
  margin: auto;
}

.tree {
  width: 100%;
  height: 90%;
  /* background-color: black; */
  margin: 10px auto;
  /* overflow: hidden; */
}

/* 自定义tree组件 */

.tree /deep/ .el-tree-node__content {
  
  height: 23px;
  /* margin-top: 10px; */
}

.tree /deep/ .el-tree-node {
  margin-bottom: 140px;
}


.tree /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {

  background: #e6efff;
  /* border: 2px solid rgba(5, 96, 253, 0.7); */
  border-radius: 8px;
}

.tree /deep/.el-icon-caret-right:before {
  content: "";
  font-size: 25px;
  width: 10px;
  height: 10px;
  display: block;
  background: url("../../assets/Resource/arrows.png") no-repeat center;
  background-size: 10px 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.tree /deep/.el-checkbox__inner {
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-radius: 3px;
  background-color: RGBA(5, 96, 253, 1);
}

/deep/ .el-checkbox__inner::after {
  box-sizing: content-box;
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
}

.el-tree /deep/.el-tree-node__expand-icon.is-leaf::before {
  background: #ffffff;
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  font-size: 16px;
  background-size: 16px;
  margin-right: 20px;
}

.tree /deep/ .el-tree .el-tree-node__expand-icon.expanded .el-icon-caret-right:before {
  content: "";
  font-size: 25px;
  width: 10px;
  height: 10px;
  display: block;
  background: url("../../assets/Resource/arrows.png") no-repeat center;
  background-size: 10px 10px;
}

/* 显示隐藏 */
.bvisible {
  position: absolute;
  right: 60px;
  margin-top: -23px;
  width: 15px;
  height: 15px;
  background-image: url("../../assets/Resource/eye.png");
  background-size: 60%;
  background-position-y: 4.5px;
  background-position-x: 3px;

  /* background-position: center; */
  background-repeat: no-repeat;
  /* border: 2px solid #dcdfe6; */
  box-shadow: black;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
  border-radius: 2px;
}

.Nobvisible {
  position: absolute;
  right: 60px;
  margin-top: -24px;
  width: 17px;
  height: 17px;
  background-image: "";
  /* background-size: 100%; */
  background-repeat: no-repeat;
  /* border: 2px solid #dcdfe6; */

  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);

  box-shadow: black;
  right: 45px;
  border-radius: 2px;
}

/* 相机 */

.camera {
  position: absolute;
  right: 80px;
  margin-top: -23px;
  width: 17px;
  height: 17px;
  background-image: url("../../assets/Resource/icon_camera.png");
  background-size: 60%;
  /* background-position-y: 1px; */
  background-position: center;
  background-repeat: no-repeat;
  /* border: 2px solid #dcdfe6; */
  box-shadow: black;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
}

.NoCamera {
  position: absolute;
  right: 80px;
  margin-top: -24px;
  width: 17px;
  height: 17px;
  background-image: "";
  /* background-size: 100%; */
  background-repeat: no-repeat;
  /* border: 2px solid #dcdfe6; */
  box-shadow: black;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
}

/* 滚动条 */
.tree::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}

.tree::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  border-radius: 10px;
  background-color: #e9f1fe;
}

.tree::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
}

/* 设置鼠标悬停显示 */
.custom-tree-node {
  width: 100%;
}

.custom-tree-node-checked {
  border: 2px solid #333;
  border-radius: 10px;
}

.custom-tree-node-content {
  float: left;
}

.operation {
  visibility: hidden;
  margin-right: 30%;
  float: right;
}

.custom-tree-node:hover>.operation {
  visibility: visible;
}

/* 设置鼠标悬停完毕  */

/**设置右击 */

.menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
}

.menuItem {
  /* text-align:center; */
  line-height: 60px;
}

.menuIcon {
  width: 19px;
  height: 17px;

  margin-right: 20px;
}

.myMenu /deep/ .menu {
  border-radius: 10px;

  padding: 10px 0;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
}

.myMenu /deep/.el-menu-item {
  height: 40px;
  line-height: 40px;
  position: relative;

  white-space: nowrap;
  list-style: none;
}

.myMent /deep/ .el-menu-item.is-active {
  color: #000;
}

/* 图标 */
.icon {
  width: 19px;
  height: 17px;
  margin: 0 2px;
}

/* 锁 */
.lock {
  position: absolute;
  right: 20px;
  /* top: 25%; */
  width: 12px;
  height: 12px;
  margin-top: 8px;
  /* margin-left: -10px; */
  /* float: right; */
  /* margin: 3px 2px; */
  padding: 3px;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
  border-radius: 2px;
}

.recycle {
  width: 250px;
}

.fileName {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tree_label {
  display: inline-block;
  width: 280px;
  height: 28px;
  /* width: 100%; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 14px; */
  line-height: 37px;
  /* font-family: PingFang SC; */
  /* font-weight: 400; */
  /* color: #000000; */

  font-weight: bolder;
  color: #2c3e50;
  font-size: 12px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  line-height: 29px;
  margin-left: 2px;
  position: relative;
  top: 1px;

}



.resourceType {
  /* width: 15px;
  height: 15px; */
  /* background: red; */
  display: inline-block;
  /* margin-left: 5px; */
  position: relative;
}

.resourceTypeImg {
  position: relative;

  width: 15px;
  height: 15px;
  top: 4px;
  margin-left: 7px;
  margin-right: 7px;
}



/* 滚动条样式 */
/deep/ .ready::-webkit-scrollbar {
  margin-top: 5px;
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  margin-right: 2px;
}

/deep/.ready::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69a0fe;
  background: #69a0fe;
  margin-right: 2px;

}

/deep/ .ready::-webkit-scrollbar-track {
  /* 滚动条里面轨道 */
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #e9f1fe;
  margin-right: 2px;

}



.enter {
  width: 100%;
  height: 50px;
  /* position: fixed; */
  /* bottom: 0; */
  font-size: 12px;
  display: flex;
  margin-top: 30px;
}

.enter .set {
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-radius: 8px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}

.verify {
  background-color: #0560fd;
  color: #fff;
  /* margin-left: 20px; */
  /* margin-top: 15px; */
  /* margin-left: 15px; */
  margin-right: 10px;
}

.cancel {
  color: #999;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  /* margin-top: 15px; */
  /* margin-left: 15px; */
  margin-left: 10px;
}

/deep/ .el-dialog {
  border-radius: 8px;
}

.groupTreeContent{
  height: 240px;
  overflow-y: auto;
}
.child.active {
    box-shadow: 0 0 2px 1px rgba(255,0,0,0.4);
  }
  .parent>>>.custom-area {
    background: rgba(52, 152, 219, 0.1);
    border: 1px dotted #2980b9;
  }

</style>