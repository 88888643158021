import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { SceneFactory } from '../factory/SceneFactory';
import { DragControls } from "../controls/DragControls"
import { ITransform } from "../interface/ITransform"
import { GameObject } from '../object/GameObject';
import { GameScene } from '../object/GameScene';

class ScaleBoxBp
{
    constructor()
    {
        this.m_size = 0.1;
        this.m_min_size = 0.02;
        this.m_sprite_list = new Array();
   
        this.m_xLen = 1;
        this.m_yLen = 1;
        this.m_zLen = 1;
        this.m_center = new THREE.Object3D();
        this.m_center.userData = null;
        this.m_enable = false;
        this.m_lineVisible = true;
        this.m_magn_sprite_select_list = new Array();
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;

        var material = gameScene.m_matRepos.get(GameScene.MatKey.e_POINT_ICON);

        for(var i = 0; i < 6; i++)
		{
			var sprite = new THREE.Sprite(material);
			sprite.name = i + "";
			sprite.scale.set(this.m_size, this.m_size, this.m_size);
            sprite.visible = false;
			this.m_sprite_list.push(sprite);
            SceneFactory.addToHelperGroup(sprite);
            if(i == 8)
            {
                sprite.name = "Y";
                sprite.material.color.setHex( 0x00ff00 );
            }
            else if(i == 9)
            {
                sprite.name = "-Y";
                sprite.material.color.setHex( 0x00ff00 );
            }
            else if(i == 10)
            {
                sprite.name = "X";
                sprite.material.color.setHex( 0xff0000 );
            }
            else if(i == 11)
            {
                sprite.name = "-X";
                sprite.material.color.setHex( 0xff0000 );
            }
            else if(i == 12)
            {
                sprite.name = "Z";
                sprite.material.color.setHex( 0x0000ff );
            }
            else if(i == 13)
            {
                sprite.name = "-Z";
                sprite.material.color.setHex( 0x0000ff );
            }
            sprite.updateMatrixWorld(true);
		}

        //init drag
        MainObjects.Control.m_dragControls = new DragControls(this.m_sprite_list, MainObjects.Camera.m_renderCamera, MainObjects.Render.m_renderer.domElement );
        MainObjects.Control.m_dragControls.addEventListener('dragstart', this.dragstart.bind(this));
        MainObjects.Control.m_dragControls.addEventListener('drag', this.drag.bind(this));
        MainObjects.Control.m_dragControls.addEventListener('dragend', this.dragend.bind(this));
        MainObjects.Control.m_dragControls.enabled = false;

        this.m_raycaster_object_list = new Array();
        this.m_pointer = new THREE.Vector2();
        this.m_startPointer = new THREE.Vector2();
        this.m_undoData = null;

        this.b_updateMagneticLine = false;
        this.m_magn_line_list = new Array();
        const points = [];
        points.push( new THREE.Vector3( 0, 0, 0 ) );
        points.push( new THREE.Vector3( 0, 0, 1 ) );
        const geometry = new THREE.BufferGeometry().setFromPoints( points );
        for(var i = 0; i < 6; i++)
        {
            var lineMaterial = gameScene.m_matRepos.get(GameObject.GameObjectType.e_GEOMETRY_CURVE);
            const line = new THREE.Line( geometry, lineMaterial );
            line.visible = false;
            this.m_magn_line_list.push(line)
            SceneFactory.addToHelperGroup(line);
            var raycaster_line = new THREE.Raycaster();
            this.m_raycaster_object_list.push(raycaster_line);
        }
    }

    setScale(object, x, y, z)
    {
        var helper = object.userData.m_boudingBoxHelper;
        if(helper != null){
            var x = ITransform.canSetNumValue(object.scale.x, x / helper.m_xLen);
            var y = ITransform.canSetNumValue(object.scale.y, y / helper.m_yLen);
            var z = ITransform.canSetNumValue(object.scale.z, z / helper.m_zLen);
            object.scale.set(x, y, z);
            object.updateMatrixWorld(true);
        }
    }

    setLength(object, lengthList)
    {
        var helper = object.userData.m_boudingBoxHelper;
        if(helper != null){
            this.setObjectLengthPos(object, lengthList[0], 10 - 8);
            this.setObjectLengthPos(object, lengthList[1], 8 - 8);
            this.setObjectLengthPos(object, lengthList[2], 12 - 8);
            this.setObjectLengthPos(object, lengthList[3], 11 - 8);
            this.setObjectLengthPos(object, lengthList[4], 9 - 8);
            this.setObjectLengthPos(object, lengthList[5], 13 - 8);
            this.setMagneticLine(object);
        }
    }

    setObjectLengthPos(object, len, index)
    {
        var flen = ITransform.canSetNumValue(this.m_magn_line_list[index].scale.z, len);
        var dir = new THREE.Vector3();
        this.m_magn_line_list[index].getWorldDirection(dir);
        dir.multiplyScalar(this.m_magn_line_list[index].scale.z - flen);
        var pos = new THREE.Vector3();
        object.getWorldPosition(pos);
        pos.add(dir);
        object.position.set(pos.x, pos.y, pos.z);
        object.updateMatrixWorld(true);
    }

    getLength()
    {
        this.updateRayCast();
        var data = [
            this.m_magn_line_list[10 - 8].scale.z,
            this.m_magn_line_list[8 - 8].scale.z,
            this.m_magn_line_list[12 - 8].scale.z,

            this.m_magn_line_list[11 - 8].scale.z,
            this.m_magn_line_list[9 - 8].scale.z,
            this.m_magn_line_list[13 - 8].scale.z,
        ]
        return data;
    }

    dragstart(object){
        this.m_undoData = MainObjects.Blueprint.m_selectListenerBp.addUndoRedoTransform([object.object.userData.object]);
        MainObjects.Control.m_orbitControl.enabled = false;
        object.object.material.color.setHex( 0xffff00 );
        this.m_center.position.set(object.object.userData.opposite.position.x, 
            object.object.userData.opposite.position.y, 
            object.object.userData.opposite.position.z );
        this.m_center.updateMatrixWorld(true);
        var target = new THREE.Quaternion();
        object.object.userData.object.getWorldQuaternion (target);
        this.m_center.setRotationFromQuaternion (target);
        this.m_center.scale.set(object.object.userData.object.scale.x,
            object.object.userData.object.scale.y,
            object.object.userData.object.scale.z)
        this.m_center.updateMatrixWorld(true);
        object.object.userData.object.parent.add(this.m_center);
        if(object.object.userData.object.parent.id != this.m_center.id)
        {
            this.m_center.attach(object.object.userData.object);
            this.m_center.updateMatrixWorld(true);
        }
    }

    drag(object){

        var helper = object.object.userData.object.userData.m_boudingBoxHelper;
        this.m_xLen = this.m_sprite_list[10 - 8].position.distanceTo (this.m_sprite_list[11 - 8].position);
        this.m_yLen = this.m_sprite_list[8 - 8].position.distanceTo (this.m_sprite_list[9 - 8].position);
        this.m_zLen = this.m_sprite_list[12 - 8].position.distanceTo (this.m_sprite_list[13 - 8].position);

        var x = 1;
        var y = 1;
        var z = 1;
        if(this.m_center.scale.x < 0)
        {
            x = -1;
        }
        if(this.m_center.scale.y < 0)
        {
            y = -1;
        }
        if(this.m_center.scale.z < 0)
        {
            z = -1;
        }
        
        this.m_center.scale.set(x * this.m_xLen / helper.m_xLen, y * this.m_yLen / helper.m_yLen, z * this.m_zLen / helper.m_zLen);
        this.setMagneticLine(object.object.userData.object);
        object.object.material.color.setHex( 0xffff00 );
        this.m_center.updateMatrixWorld(true);
        ITransform.m_draging_scale = true;
        if(ITransform.f_selectTransformCallback != null)
        {
            ITransform.f_selectTransformCallback(true);
        }
    }

    dragend(object){  
        ITransform. m_draging_scale = false;
        object.object.material.color.setHex( 0xffffff );
        MainObjects.Control.m_orbitControl.enabled = true;
        if(object.object.userData.object.parent.id != this.m_center.parent.id)
        {
            this.m_center.parent.attach(object.object.userData.object);
            this.m_center.parent.updateMatrixWorld(true);
        }
        this.m_center.removeFromParent();
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoTransform([object.object.userData.object], this.m_undoData, true);
        this.setSizeSprite(object.object.userData.object, true);
        if(ITransform.f_selectTransformCallback != null)
        {
            ITransform.f_selectTransformCallback(true);
        }
    }

    setSizeSprite(object, visible)
    {
        if(!visible)
        {
            for(var j = 0; j < this.m_sprite_list.length; j++)
            {
                this.m_sprite_list[j].visible = false;
            }
            MainObjects.Blueprint.m_boundingBoxBp.disposeBoundingBox([object]);
        }
        if(object.isLight || object.isCamera)
        {
            return;
        }
        if(visible)
        {
            MainObjects.Blueprint.m_boundingBoxBp.computeBoundingBox([object]);
        }
        else
        {
            MainObjects.Blueprint.m_boundingBoxBp.disposeBoundingBox([object]);
        }
        if(object.userData.m_boudingBoxHelper != null)
        {
            if(object.isLight)
            {
                return;
            }
            object.userData.m_boudingBoxHelper.visible = visible;
            var object_list = object.userData.m_boudingBoxHelper.object_list;
            for(var j = 8; j < 14; j++)
            {
                var pos = new THREE.Vector3();
                object_list[j].getWorldPosition(pos)
                this.m_sprite_list[j - 8].position.copy(pos);

                var minX = object.userData.m_boudingBoxHelper.m_xLen * object.scale.x;
                var minY = object.userData.m_boudingBoxHelper.m_yLen * object.scale.y;
                var minZ = object.userData.m_boudingBoxHelper.m_zLen * object.scale.z;
                var size = Math.min(minX, minY, minZ) / 9;
                size = Math.max(size, this.m_min_size);
                this.m_sprite_list[j - 8].scale.set(size, size, size);

                this.m_sprite_list[j - 8].visible = visible;
                this.m_sprite_list[j - 8].userData.object = object;
                if(j == 8)
                {
                    this.m_sprite_list[j - 8].userData.opposite = this.m_sprite_list[9 - 8];
                    this.m_sprite_list[j - 8].material.color.setHex( 0x00ff00 );
                }
                else if(j == 9)
                {
                    this.m_sprite_list[j - 8].userData.opposite = this.m_sprite_list[8 - 8];
                    this.m_sprite_list[j - 8].material.color.setHex( 0x00ff00 );
                }
                else if(j == 10)
                {
                    this.m_sprite_list[j - 8].userData.opposite = this.m_sprite_list[11 - 8];
                    this.m_sprite_list[j - 8].material.color.setHex( 0xff0000 );
                }
                else if(j == 11)
                {
                    this.m_sprite_list[j - 8].userData.opposite = this.m_sprite_list[10 - 8];
                    this.m_sprite_list[j - 8].material.color.setHex( 0xff0000 );
                }
                else if(j == 12)
                {
                    this.m_sprite_list[j - 8].userData.opposite = this.m_sprite_list[13 - 8];
                    this.m_sprite_list[j - 8].material.color.setHex( 0x0000ff );
                }
                else if(j == 13)
                {
                    this.m_sprite_list[j - 8].userData.opposite = this.m_sprite_list[12 - 8];
                    this.m_sprite_list[j - 8].material.color.setHex( 0x0000ff );
                }

                this.m_sprite_list[j - 8].updateMatrixWorld(true);
            }
        }
    }

    setMagneticLine(object)
    {
        if(object.userData.m_boudingBoxHelper != null)
        {
            object.updateMatrixWorld (true);
            this.b_updateMagneticLine = true;
            var object_list = object.userData.m_boudingBoxHelper.object_list;
            for(var j = 8; j < 14; j++)
            {
                this.m_magn_line_list[j - 8].userData.object = object;
                this.m_magn_line_list[j - 8].userData.opposite_current = object_list[j];
                if(j == 8)
                {
                    this.m_magn_line_list[j - 8].userData.opposite = object_list[9];
                    this.m_magn_line_list[j - 8].material.color.setHex( 0x00ff00 );
                }
                else if(j == 9)
                {
                    this.m_magn_line_list[j - 8].userData.opposite = object_list[8];
                    this.m_magn_line_list[j - 8].material.color.setHex( 0x00ff00 );
                }
                else if(j == 10)
                {
                    this.m_magn_line_list[j - 8].userData.opposite = object_list[11];
                    this.m_magn_line_list[j - 8].material.color.setHex( 0xff0000 );
                }
                else if(j == 11)
                {
                    this.m_magn_line_list[j - 8].userData.opposite = object_list[10];
                    this.m_magn_line_list[j - 8].material.color.setHex( 0xff0000 );
                }
                else if(j == 12)
                {
                    this.m_magn_line_list[j - 8].userData.opposite = object_list[13];
                    this.m_magn_line_list[j - 8].material.color.setHex( 0x0000ff );
                }
                else if(j == 13)
                {
                    this.m_magn_line_list[j - 8].userData.opposite = object_list[12];
                    this.m_magn_line_list[j - 8].material.color.setHex( 0x0000ff );
                }
            }
            this.updateRayCast();
        }
    }

    updateRayCast()
    {
        if(!this.b_updateMagneticLine)
        {
            return;
        }
        for(var i = 0; i < this.m_magn_line_list.length; i++)
        {
            var pos = new THREE.Vector3();
            this.m_magn_line_list[i].userData.opposite_current.getWorldPosition(pos);
            var opposite = new THREE.Vector3();
            this.m_magn_line_list[i].userData.opposite.getWorldPosition(opposite);
            var dir = new THREE.Vector3();
            dir.copy(pos).sub(opposite).normalize();
            this.m_raycaster_object_list[i].set(pos, dir);
            this.m_raycaster_object_list[i].layers.disable( 11 );
            this.m_raycaster_object_list[i].layers.enable( 10 );
            var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
            const intersects = this.m_raycaster_object_list[i].intersectObjects(gameScene.m_selectAllMeshList);

            var index = -1;
            for(var j = 0; j < intersects.length; j++)
            {
                if(intersects[j].object.id != this.m_magn_line_list[i].userData.object.id)
                {
                    index = j;
                }
            }
            if(index >= 0)
            {
                this.m_magn_line_list[i].visible = this.m_lineVisible;
                var point = intersects[0].point;
                var len = pos.distanceTo(point);
                this.m_magn_line_list[i].position.copy(pos);
                this.m_magn_line_list[i].lookAt(point);
                this.m_magn_line_list[i].scale.set(1, 1, len);
            }
            else
            {
                this.m_magn_line_list[i].scale.set(1, 1, 0);
                this.m_magn_line_list[i].visible = false;
            }
            this.m_magn_line_list[i].updateMatrixWorld(true);
        }
    }

    setVisible()
    {
        this.m_lineVisible = !this.m_lineVisible;
        for(var i = 0; i < this.m_magn_line_list.length; i++)
        {
            this.m_magn_line_list[i].visible = this.m_lineVisible;
        }
    }

    setHideLine()
    {
        this.b_updateMagneticLine = false;
        for(var i = 0; i < this.m_magn_line_list.length; i++)
        {
            this.m_magn_line_list[i].visible = false;
        }
    }

    setCenter(object, center_name){
        if(center_name == "NoSet"){
            return;
        }
        else{
            MainObjects.Blueprint.m_boundingBoxBp.computeBoundingBox([object], false);
            var center = new THREE.Vector3();
            var list = new Array();
            for (let index = 0; index < object.children.length; index++) {
                const element = object.children[index];
                if(element.type != 'BoundingBoxHelper')
                {
                    list.push(element);
                }
            }
            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                MainObjects.Scene.m_userGroup.attach(element);
            }
            var object_list = object.userData.m_boudingBoxHelper.object_list;
            if(center_name == "Center")
            {
                object.userData.m_boudingBoxHelper.m_cube.getWorldPosition(center);
            }
            else if(center_name == "X+")
            {
                object_list[10].getWorldPosition(center);
            }
            else if(center_name == "X-")
            {
                object_list[11].getWorldPosition(center);
            }
            else if(center_name == "Y+")
            {
                object_list[8].getWorldPosition(center);
            }
            else if(center_name == "Y-")
            {
                object_list[9].getWorldPosition(center);
            }
            else if(center_name == "Z+")
            {
                object_list[12].getWorldPosition(center);
            }
            else if(center_name == "Z-")
            {
                object_list[13].getWorldPosition(center);
               
            }
        
            object.position.copy(center);

            for (let index = 0; index < list.length; index++) {
                const element = list[index];
                object.attach(element);
            }
            object.updateMatrixWorld(true);
            object.userData.m_group_center = center_name;
            MainObjects.Blueprint.m_boundingBoxBp.computeBoundingBox([object], false);
            MainObjects.Blueprint.m_selectListenerBp.setAxis();
        }   
        
    }

}

export { ScaleBoxBp };