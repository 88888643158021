<template>
  <!-- 资源列表 -->
  <!-- @mouseleave="mouseLeave" -->
  <div class="box"  v-loading.fullscreen.lock="fullscreenLoading">
    <div class="content">
      <div class="tabs">
        <div class="box1" v-show="$store.state.textureBallMasking"></div>
       
        <a >
          <div v-if="$store.state.chosenWarehouse.type==0" class="tabsItems" name="场景" @click="selectedScene">
            <div class="oneTab">
              <img v-show="iconScene" src="../../assets/Resource/icon_scene_selected.png" alt />
              <img v-show="!iconScene" src="../../assets/Resource/icon_scene.png" alt />
            </div>
          </div>
        </a>
        <a > 
          <div class="tabsItems" name="模型" @click="selectedModel">
            <img v-show="iconModel" src="../../assets/Resource/icon_model_selected.png" alt />
            <img v-show="!iconModel" src="../../assets/Resource/icon_model.png" alt />
          </div>
        </a>
        <a >
          <div class="tabsItems" name="贴图/材质" @click="selectedChartlet">
            <img v-show="iconChartlet" src="../../assets/Resource/icon_chartlet_selected.png" alt />
            <img v-show="!iconChartlet" src="../../assets/Resource/icon_chartlet.png" alt />
          </div>
        </a>
        <!-- <a >
         <div class="tabsItems" name="纹理"  @click="selectedTexture">
          <img v-show="iconTexture" src="../../assets/Resource/icon_texture_selected.png" alt />
          <img v-show="!iconTexture" src="../../assets/Resource/icon_texture.png" alt />
        </div> 
        </a>-->

        <a >
          <div class="tabsItems" name="脚本" @click="selectedScript">
            <img v-show="iconScript" src="../../assets/Resource/icon_script_scene.png" alt />
            <img v-show="!iconScript" src="../../assets/Resource/icon_script.png" alt />
          </div>
        </a>
        <a >
          <div class="tabsItems" name="媒体" @click="selectedMultimedia">
            <img v-show="iconMultimedia" src="../../assets/Resource/selectMultimedia.png" alt />
            <img v-show="!iconMultimedia" src="../../assets/Resource/multimedia.png" alt />
          </div>
        </a>



        <a >
          <div v-if="$store.state.chosenWarehouse.type==0" class="tabsItems" name="回收站" @click="selectedRecycleBin">
            <img
              v-show="iconRecycleBin"
              src="../../assets/Resource/icon_recycle_bin_selected.png"
              alt
            />
            <img v-show="!iconRecycleBin" src="../../assets/Resource/icon_recycle_bin.png" alt />
          </div>
        </a>
      </div>

      <div
        class="genre"
        v-show="iconScene"
        :style="
        bottomHeight ? `height:${bottomHeight-160}px;` : ''
      "
      >
        <!-- <div class="genre_top">
          <img class="icon" src="../../assets/Resource/icon_folder.png" alt />
          场景
        </div>-->
        
        <Tier
          :isThree="isThree"
          :resourceType="1"
          :recycle="false"
          @setFileDirectoryList="setFileDirectoryList"
          @setFileDirectoryId="setFileDirectoryId"
          @setResourceType="setResourceType"
          @getPath="getPath"
          :searchName="searchName"
          ref="scene"
          establishType="创建场景"
        />
      </div>
      <div
        class="genre"
        v-show="iconModel"
        :style="
        bottomHeight ? `height:${bottomHeight-160}px;` : ''
      "
      >
        <!-- <div class="genre_top">
          <img class="icon" src="../../assets/Resource/icon_folder.png" alt />
          模型
        </div>-->
        <Tier
          :isThree="isThree"
          :resourceType="2"
          :recycle="false"
          @setFileDirectoryList="setFileDirectoryList"
          @setFileDirectoryId="setFileDirectoryId"
          @setResourceType="setResourceType"
          @getPath="getPath"
          :searchName="searchName"
          ref="model"
          establishType="上传模型"
        />
      </div>
      <div
        class="genre"
        v-show="iconChartlet"
        :style="
        bottomHeight ? `height:${bottomHeight-160}px;` : ''
      "
      >
        <!-- <div class="genre_top">
          <img class="icon" src="../../assets/Resource/icon_folder.png" alt />
          贴图
        </div>-->
        <Tier
          :isThree="isThree"
          :resourceType="3"
          :recycle="false"
          @setFileDirectoryList="setFileDirectoryList"
          @setFileDirectoryId="setFileDirectoryId"
          @setResourceType="setResourceType"
          @getPath="getPath"
          :searchName="searchName"
          ref="chartlet"
          establishType="上传贴图"
        />
      </div>
      <!-- <div class="genre" v-show="iconTexture">

        <Tier
          :isThree="isThree"
          :resourceType="4"
          :recycle="false"
          @setFileDirectoryList="setFileDirectoryList"
          @setFileDirectoryId="setFileDirectoryId"
          @setResourceType="setResourceType"

          @getPath="getPath"

          
          :searchName="searchName"
          ref="texture"
          establishType="材质"
        />
      </div>-->
      <div
        class="genre"
        v-show="iconScript"
        :style="
        bottomHeight ? `height:${bottomHeight-160}px;` : ''
      "
      >
        <!-- <div class="genre_top">
          <img class="icon" src="../../assets/Resource/icon_folder.png" alt />
          脚本
        </div>-->
        <Tier
          :isThree="isThree"
          :resourceType="5"
          :recycle="false"
          @setFileDirectoryList="setFileDirectoryList"
          @setFileDirectoryId="setFileDirectoryId"
          @setResourceType="setResourceType"
          @getPath="getPath"
          :searchName="searchName"
          ref="script"
          establishType="创建脚本"
        />
      </div>

      <div
        class="genre"
        v-show="iconMultimedia"
        :style="
        bottomHeight ? `height:${bottomHeight-160}px;` : ''
      "
      >
        <!-- <div class="genre_top">
          <img class="icon" src="../../assets/Resource/icon_folder.png" alt />
          创建多媒体
        </div>-->
        <Tier
          :isThree="isThree"
          :resourceType="8"
          :recycle="false"
          @setFileDirectoryList="setFileDirectoryList"
          @setFileDirectoryId="setFileDirectoryId"
          @setResourceType="setResourceType"
          @getPath="getPath"
          :searchName="searchName"
          ref="multimedia"
          establishType="上传媒体"
        />
      </div>






      <div
        class="genre"
        v-show="iconRecycleBin"
        :style="
        bottomHeight ? `height:${bottomHeight-160}px;` : ''
      "
      >
        <!-- <div class="genre_top">
          <img class="icon" src="../../assets/Resource/icon_folder.png" alt />
          回收站
        </div>-->
        <Tier
          :isThree="isThree"
          :resourceType="6"
          :recycle="true"
          @setFileDirectoryList="setFileDirectoryList"
          @setFileDirectoryId="setFileDirectoryId"
          @getPath="getPath"
          :searchName="searchName"
          ref="RecycleBin"
        />
      </div>
    </div>




  </div>
</template>
<script>
import Tier from "./ResourceTree";
import { mapState } from 'vuex'

export default {
  name: "Resource",
  data() {
    return {
      //场景数据
      sceneData: [],
      //模型数据
      modelData: [],
      //贴图
      chartletData: [],
      //材质数据
      textureData: [],
      //脚本
      scriptFile: [],

      //3D数据
      treeData: [],
      //判断是3d /0 不是3d 1 是3d
      isThree: 0,
      //获取到的文件夹数据
      FileDirectoryList: [],

      iconScene: true,
      iconModel: false,
      iconChartlet: false,
      iconTexture: false,
      iconScript: false,
      iconRecycleBin: false,
      iconMultimedia:false,
      //切换库的加载
      fullscreenLoading:false,
      // bottomHeight: localStorage.getItem("bottomHeight")
    };
  },
  props: [
    //搜索的名字
    "searchName",
    "bottomHeight"
  ],
  components: {
    Tier
  },
  created() {},
  mounted() {

    // this.refreshInitialization()

  },
  computed:{
    ...mapState(['chosenWarehouse'])
  },
  watch: {
     // watch 可以监听 data 数据 也可以监听 全局 vuex数据
     chosenWarehouse () {
      this.fullscreenLoading=true
      this.getDataTree().then(
        ()=>{

          let chosenWarehouse=this.$store.state.chosenWarehouse
          if (chosenWarehouse.type==0) {
            this.selectedScene().then(()=>{
                 this.fullscreenLoading=false

            })
          }else{
            this.selectedModel().then(()=>{
                 this.fullscreenLoading=false

            })

          }

        }
      )
   
    },
  },
  methods: {

    setChecked(){
      this.$parent.setChecked()
    },
    //定位 
    locationTree(parentFolder){

      // console.log(parentFolder.type,"treeType")
      if (parentFolder.type==1) {
          this.selectedScene()
             this.$refs.scene.handleNodeClick(parentFolder);
         
      }else if (parentFolder.type==2) {
          this.selectedModel()
             this.$refs.model.handleNodeClick(parentFolder);
    
      }else if (parentFolder.type==3) {
          this.selectedChartlet()
             this.$refs.chartlet.handleNodeClick(parentFolder);
        
      }else if(parentFolder.type==4){
        this.selectedTexture()
             this.$refs.texture.handleNodeClick(parentFolder);
          
      }else if (parentFolder.type==5) {
        this.selectedScript()
             this.$refs.script.handleNodeClick(parentFolder);
         
      }else if (parentFolder.type==6) {
        this.selectedRecycleBin()
             this.$refs.RecycleBin.handleNodeClick(parentFolder);
         
      }


    },




        //设置场景名称
    setSceneName(smg){
    this.$parent.setSceneName(smg)

    },



    refreshInitialization(){
        
      var ResourceType=localStorage.getItem("ResourceType")

      
      if (ResourceType) {
          if (ResourceType==1) {
            this.selectedScene()

            var leftClickNode=localStorage.getItem("leftClickNode")



            if (leftClickNode) {
             leftClickNode= JSON.parse(leftClickNode)
          

              setTimeout(() => {
            this.$refs.scene.handleNodeClick(leftClickNode)
                
              }, 500);
              
            }
          }else if (ResourceType==2) {
            
            this.selectedModel()
            var leftClickNode=localStorage.getItem("leftClickNode")

            if (leftClickNode) {
              var leftClickNode=JSON.parse(leftClickNode)
              setTimeout(() => {
            this.$refs.model.handleNodeClick(leftClickNode)
                
              }, 500);
              
            }


          }else if (ResourceType==3) {
            var leftClickNode=localStorage.getItem("leftClickNode")

            this.selectedChartlet()
            if (leftClickNode) {
              var leftClickNode=JSON.parse(leftClickNode)
              setTimeout(() => {
            this.$refs.chartlet.handleNodeClick(leftClickNode)
                
              }, 500);
              
            }
          }else if (ResourceType==4) {
            var leftClickNode=localStorage.getItem("leftClickNode")

              this.selectedTexture()
            if (leftClickNode) {
              var leftClickNode=JSON.parse(leftClickNode)

              setTimeout(() => {
            this.$refs.texture.handleNodeClick(leftClickNode)
                
              }, 500);
              
            }

          }else if (ResourceType==5) {
            var leftClickNode=localStorage.getItem("leftClickNode")

            this.selectedScript()
            if (leftClickNode) {
              var leftClickNode=JSON.parse(leftClickNode)
              setTimeout(() => {
            this.$refs.script.handleNodeClick(leftClickNode)
                
              }, 500);
              
            }

          }else if (ResourceType==6) {
            this.selectedRecycleBin()
          }
      }
    },




    //在资源文件右击新增
    resourceFile(type){

      
      if (type==1) {
        // 场景
      this.$refs.scene.handleSelect(2)
       
      }else if (type==2) {
        //模型
      this.$refs.model.handleSelect(2);
        
      }else if (type==3) {
        // 贴图

      this.$refs.chartlet.handleSelect(2)
        
      }else if (type==5) {
        //脚本
      this.$refs.script.handleSelect(2)
      }else if (type==8) {
      this.$refs.multimedia.handleSelect(2)
        
      }
       
    },
    //创建材质球
    creationTextureBall(type,materialType){
      if (materialType==0) {
      this.$refs.chartlet.handleSelect(6)
        
      }else if (materialType==1) {
        
      this.$refs.chartlet.handleSelect(7)
      }else if(materialType==2){

        this.$refs.chartlet.handleSelect(12)

      }else if(materialType==3){

        this.$refs.chartlet.handleSelect(16)

      }

      
    },


    //控制菜单隐藏
    ControlHidden(){
      this.$parent.ControlHidden()
    },
    //传递菜单隐藏
    mouseLeave(){
      this.$refs.scene.mouseLeave();
      this.$refs.model.mouseLeave();
      this.$refs.chartlet.mouseLeave();
      this.$refs.script.mouseLeave();
      this.$refs.RecycleBin.mouseLeave();
      this.$refs.multimedia.mouseLeave();

      // this.$refs.texture.mouseLeave();
 
    },


    //设置选中id
    setCheckedId(msg) {
      this.$parent.setCheckedId(msg);
    },
    //拿到获取的文件列表
    setFileDirectoryList(msg) {
      this.FileDirectoryList = msg;

      //将从孩子组件中拿到的值传给祖父组件
      this.$emit("setFileDirectoryList", msg);
    },
    //拿到点击的id
    setFileDirectoryId(msg) {
      //将拿到的id传给父组件
      this.$emit("setFileDirectoryId", msg);
    },

    //拿到路径
    getPath(msg) {
      this.$emit("getPath", msg);

    },

    //拿到 左侧树的类型
    setResourceType(msg) {
      this.$emit("setResourceType", msg);
    },

    //查询
    folderByName(isAccurate) {
      this.$refs.scene.folderByName(isAccurate);
      this.$refs.model.folderByName(isAccurate);
      this.$refs.chartlet.folderByName(isAccurate);
      // this.$refs.texture.folderByName(isAccurate);
      this.$refs.script.folderByName(isAccurate);
      this.$refs.multimedia.folderByName(isAccurate);
      this.$refs.RecycleBin.folderByName(isAccurate);


    },
    // //材质球
    // textureBall(index, resourceType, _upDataId){
    //   this.$parent.textureBall(index, resourceType, _upDataId);

    // },

    //新建文件
    newFile(index, resourceType, _upDataId,type) {
      // index:更新还是新建，_upDataId需要更新的文件id，resourceType：文件类型
      this.$parent.handleSelect(index, resourceType, _upDataId,type);
    },
    //新建多媒体文件
    addMultimedia(updateOrAdd,nodeId){
        this.$parent.addMultimedia(updateOrAdd,nodeId)

    },



    async selectedScene() {
      

      // localStorage.setItem("ResourceType","1")
      // this.iconScene=!this.iconScene
      this.iconScene = true;
      this.iconModel = false;
      this.iconChartlet = false;
      this.iconTexture = false;
      this.iconScript = false;
      this.iconRecycleBin = false;
     this.iconMultimedia = false;

      await this.$refs.scene.getDataTree();

      var data = this.$refs.scene.dataList[0];
      if (data) {
        this.setResourceType(1);

        this.$refs.scene.handleNodeClick(data);
      } else {
        this.setFileDirectoryId("");
        this.setResourceType(1);
        this.getPath("");
      }

      document.onselectstart = function() {
        return false;
      };
    },
    async selectedModel() {
      // localStorage.setItem("ResourceType","2")
      // this.$store.commit('changeCloudsScene', null)
      this.iconScene = false;
      // this.iconModel=!this.iconModel
      this.iconModel = true;

      this.iconChartlet = false;
      this.iconTexture = false;
      this.iconScript = false;
      this.iconRecycleBin = false;
       this.iconMultimedia = false;
     await  this.$refs.model.getDataTree();
      var data = this.$refs.model.dataList[0];
      if (data) {
        this.setResourceType(2);

        this.$refs.model.handleNodeClick(data);
      } else {
        this.setResourceType(2);
        this.setFileDirectoryId("");

        this.getPath("");
      }
      document.onselectstart = function() {
        return false;
      };
    },
    selectedChartlet() {
      // this.$store.commit('changeCloudsScene', null)

      // localStorage.setItem("ResourceType","3")

      this.iconScene = false;
      this.iconModel = false;
      // this.iconChartlet=!this.iconChartlet
      this.iconChartlet = true;

      this.iconTexture = false;
      this.iconScript = false;
      this.iconRecycleBin = false;
       this.iconMultimedia = false;

      this.$refs.chartlet.getDataTree();
      var data = this.$refs.chartlet.dataList[0];
      if (data) {
        this.setResourceType(3);

        this.$refs.chartlet.handleNodeClick(data);
      } else {
        this.setResourceType(3);
        this.setFileDirectoryId("");

        this.getPath("");
      }
      document.onselectstart = function() {
        return false;
      };
    },
    selectedTexture() {
      // this.$store.commit('changeCloudsScene', null)

      // localStorage.setItem("ResourceType","4")

      this.iconScene = false;
      this.iconModel = false;
      this.iconChartlet = false;
      // this.iconTexture=!this.iconTexture
      this.iconTexture = true;

      this.iconScript = false;
      this.iconRecycleBin = false;
       this.iconMultimedia = false;
      this.$refs.texture.getDataTree();
      var data = this.$refs.texture.dataList[0];
      if (data) {
        this.setResourceType(4);

        this.$refs.texture.handleNodeClick(data);
      } else {
        this.setResourceType(4);
        this.setFileDirectoryId("");

        this.getPath("");
      }
      document.onselectstart = function() {
        return false;
      };
    },
    selectedScript() {
      // this.$store.commit('changeCloudsScene', null)

      // localStorage.setItem("ResourceType","5")

      this.iconScene = false;
      this.iconModel = false;
      this.iconChartlet = false;
      this.iconTexture = false;
      // this.iconScript=!this.iconScript
      this.iconScript = true;

      this.iconRecycleBin = false;
       this.iconMultimedia = false;

      this.$refs.script.getDataTree();
      var data = this.$refs.script.dataList[0];
      if (data) {
        this.setResourceType(5);

        this.$refs.script.handleNodeClick(data);
      } else {
        this.setResourceType(5);
        this.setFileDirectoryId("");

        this.getPath("");
      }
      document.onselectstart = function() {
        return false;
      };
    },
    selectedMultimedia(){
      // this.$store.commit('changeCloudsScene', null)

      this.iconScene = false;
      this.iconModel = false;
      this.iconChartlet = false;
      this.iconTexture = false;
 
      this.iconScript = false;

      this.iconRecycleBin = false;
      this.iconMultimedia=true
     

    
      this.$refs.multimedia.getDataTree();
      var data = this.$refs.multimedia.dataList[0];
      if (data) {
        this.setResourceType(8);

        this.$refs.multimedia.handleNodeClick(data);
      } else {
        this.setResourceType(8);
        this.setFileDirectoryId("");

        this.getPath("");
      }
      document.onselectstart = function() {
        return false;
      };









    },





    selectedRecycleBin() {
      // this.$store.commit('changeCloudsScene', null)

      // localStorage.setItem("ResourceType","6")

      this.iconScene = false;
      this.iconModel = false;
      this.iconChartlet = false;
      this.iconTexture = false;
      this.iconScript = false;
      // this.iconRecycleBin=!this.iconRecycleBin
      this.iconRecycleBin = true;
       this.iconMultimedia = false;

      this.$refs.RecycleBin.getDataTree().then(() => {
        var data = this.$refs.RecycleBin.dataList;
        this.setFileDirectoryList(data);
      });
      this.setResourceType(6);
      this.setFileDirectoryId("");

      this.getPath("");

      document.onselectstart = function() {
        return false;
      };
    },

    //获取树组件
    async getDataTree() {
     await this.$refs.scene.getDataTree();
     await  this.$refs.model.getDataTree();
     await  this.$refs.chartlet.getDataTree();
     await this.$refs.script.getDataTree();
     await this.$refs.RecycleBin.getDataTree();
     await this.$refs.multimedia.getDataTree();

      
      // this.$refs.texture.getDataTree();
    },

    setRecycleBinData(msg) {
      this.$parent.setRecycleBinData(msg);
    },

    //拖动样式
    setDragShow(msg) {

      
      this.$parent.setDragShow(msg);
    }
  }
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
}
.content {
  width: 100%;
  /* height: 432px; */
  /* background:red; */
  border-radius: 5px;
}

.tabs {
  width: 100%;
  height: 42px;
  background: #ffffff;
  position: relative;
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1); */

  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px 10px 0px 0px;
}

/* .tabs .tabsItems:hover{
  background: #000;
  border-radius: 5px;
  height: 100%;
} */

.tabs .tabsItems {
  width: 10%;
  margin: 5px 3%;

  float: left;
  vertical-align: middle;
  display: table-cell;
  text-align: center;
  height: 30px;
  border-radius: 5px;
  box-sizing: border-box;
}
.tabs .tabsItems img {
  display: block;
  margin: 7px auto;

  width: 18px;
  height: 18px;
  /* margin-top: 5px; */
}

/* .tabs .oneTab{
 
  width: 25px;
  height: 25px;
  margin: 0 auto;

  
} */
.tabs .tabsItems:hover {
  background: #fafafa;
}

/* .tabs .oneTab:hover{
  background: #cddfff;
} */

.genre {
  background: #ffffff;
  /* background: red; */
  /* box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px; */

  /* margin-top: 10px; */

  overflow: auto;


    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  /* border-radius: 10px; */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
/* 滚动条 */
/* .genre::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: rgba(240, 240, 240, 1);
}
.genre::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  border-radius: 10px;
  background-color: rgba(240, 240, 240, 0.5);
}
.genre::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
  background-color: rgba(240, 240, 240, 0.5);
} */

.genre::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
  
}
.genre::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px #69A0FE;
  background: #69A0FE;
}
.genre::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #E9F1FE ;

}

.genre_top {
  width: 100%;
  height: 38px;
  background-color: #f7f7f7;
  border-radius: 10px;
  text-align: center;
  line-height: 38px;
  size: 16px;
  color: #000000;
  
}

.icon {
  width: 18px;
  height: 16px;
}


/* 左蒙版 */
.box1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.1;
  z-index: 9999;
}

.tabsItems::after{
  content:attr(name);
  display: none;
}
.tabsItems:hover::after{
  display:block;

  position: absolute;
  min-width: 120px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  top: 40px;
  color: #000;
  font-size: 12px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #ccc;
  z-index: 999;
}
</style>