
import { ParticleFactory } from '../factory/ParticleFactory';
import json from "../mock/particle.json"
import { ObjectsRepos } from '../factory/ObjectsRepos';

class ParticleSystemBp {
    constructor() {
        this.m_particleObject = new ObjectsRepos();
    }

    init(object)
    {
        this.m_particleObject.add(object.id, object);
    }

    update(delta)
    {
        for(var [id, value] of this.m_particleObject.m_objectsMap)
        {
            ParticleFactory.update(value);
        }
    }

    destory(object)
    {
        this.m_particleObject.remove(object.id);
        ParticleFactory.destory(object);
    }
}

export { ParticleSystemBp };