class RenderWebSocket {
    constructor(ip, port) {
        this.m_max = 2048000
        this.m_url = "ws://" + ip + ":" + port; 
        this.m_websocket = new WebSocket(this.m_url); 
        this.m_websocket.onopen = this.open.bind(this);
        this.m_websocket.onclose  = this.close.bind(this);
        this.m_websocket.onmessage   = this.message.bind(this);
        this.m_websocket.onerror = this.error.bind(this);
        this.m_openCallback = null;
        this.m_messageCallback = null;
        this.m_errorCallback = null;
    }

    open(ev){
        if(this.m_openCallback != null){
            this.m_openCallback();
        }
    }
    
    close(ev){
    }

    message(ev){
        if(this.m_messageCallback != null){
            this.m_messageCallback(ev)
        }
    }

    error(ev){
        if(this.m_errorCallback != null){
            this.m_errorCallback()
        }
    }

    sent(message){
        this.m_websocket.send(message);
    }

    close(){
        this.m_websocket.close();
    }

    getData(str, callback){
        for (let i = 0; i < str.length ; i += this.m_max) {
            var message = str.slice(i, i + this.m_max);
            callback(message);
        }
    }

    getState()
    {
        return this.m_websocket.readyState;
    }
}

export {RenderWebSocket}