import * as THREE from 'three';
class CubeData {
    constructor() {
        this.m_id = ""
        this.cube = null
        this.size = {}
        this.pos = {}
        this.rot = {}
    }
    createCube(object) {
        this.m_id = object.name
        object.updateMatrixWorld(true, true);
        var rot = new THREE.Euler();

        rot.copy(object.rotation);
        object.rotation.set(0, 0, 0);

        var selectBox = new THREE.Box3();
        selectBox.setFromObject(object);
        var size = new THREE.Vector3();
        selectBox.getSize(size);
        this.size.x = size.x
        this.size.y = size.y
        this.size.z = size.z
        var pos_oj = new THREE.Vector3();
        object.getWorldPosition(pos_oj);

        var pos = new THREE.Vector3();
        selectBox.getCenter(pos);
        this.pos.x = pos.x
        this.pos.y = pos.y
        this.pos.z = pos.z

        this.rot.x = rot.x
        this.rot.y = rot.y
        this.rot.z = rot.z
 
        object.rotation.copy(rot);
        object.updateMatrixWorld(true, true);

    }

    dataByCreateCube(data, material) {
        this.size = data.size
        this.m_id = data.m_id
        this.pos = data.pos
        this.rot = data.rot
        const geometry = new THREE.BoxGeometry(data.size.x, data.size.y, data.size.z);
        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(data.pos.x, data.pos.y, data.pos.z);
        cube.rotation.set(data.rot.x, data.rot.y, data.rot.z);
        this.cube = cube;
    }
}

export { CubeData };
