import { Mesh } from 'three';
import { MeshBasicMaterial } from 'three';
import { SphereGeometry } from 'three';
import { MainObjects } from '../common/MainObjects';
import { GameScene } from '../object/GameScene';

class PointLightHelper extends Mesh {

	constructor( light, sphereSize, color ) {

		var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
		
		var geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_SPHERE);
		var material = gameScene.m_matRepos.get(GameScene.MatKey.e_BASE_WIREFRAME_MAT);

		super( geometry, material );

		this.light = light;
		this.light.updateMatrixWorld();
		this.layers.set(11);

		this.color = color;

		this.type = 'PointLightHelper';

		this.matrix = this.light.matrixWorld;
		this.matrixAutoUpdate = false;

		this.renderOrder = 100;

		this.update();
	}

	dispose() {

		// this.geometry.dispose();
		// this.material.dispose();

	}

	update() {
        this.updateMatrixWorld(true);
	}

}


export { PointLightHelper };