import { GeometryFactory } from "../factory/GeometryFactory"
import { SceneFactory } from "../factory/SceneFactory"
import { ObjectFactory } from "../factory/ObjectFactory"
import { MainObjects } from "../common/MainObjects";
import { ISelector } from "./ISelector";
import { IScene } from "./IScene";
import { ICamera } from "./ICamera";
import { Language } from "../common/Language"

import tgrass1 from "../../assets/ThreeTexture/terrain/grass1.jpg"
import tsand1 from "../../assets/ThreeTexture/terrain/sand1.jpg"
import * as T from '../object/Terrain';
import * as THREE from "three"

class IFactory {

    static createDirLight() {
        const object = ObjectFactory.createDirLight();
        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        object.rotateX(Math.PI / 2);
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
        ISelector.selectObjectByTree(object, false);
        ISelector.sceneTreeSelectCallback();
    }

    static createPointLight() {
        const object = ObjectFactory.createPointLight();
        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
        ISelector.selectObjectByTree(object, false);
        ISelector.sceneTreeSelectCallback();
    }

    static createSpotLight() {
        const object = ObjectFactory.createSpotLight();
        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        object.rotateX(Math.PI / 2);
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
        ISelector.selectObjectByTree(object, false);
        ISelector.sceneTreeSelectCallback();
    }

    static createRectAreaLight() {
        const object = ObjectFactory.createRectAreaLight();
        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        object.rotateX(-Math.PI / 2);
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
        ISelector.selectObjectByTree(object, false);
        ISelector.sceneTreeSelectCallback();
    }

    static creatPerspectiveCamera() {
        const object = ObjectFactory.creatPerspectiveCamera();
        if (MainObjects.Camera.m_renderCamera.isPerspectiveCamera) {
            object.position.copy(MainObjects.Camera.m_renderCamera.position);
            object.rotation.copy(MainObjects.Camera.m_renderCamera.rotation);
        }
        else {
            object.position.copy(MainObjects.Camera.m_renderCamera.position);
            object.rotation.copy(MainObjects.Camera.m_renderCamera.rotation);
        }
        SceneFactory.addToUserGroup(object);
        if (MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getMainCameraOnlyOne(object.id)) {
            ICamera.setMainCamera(object.id)
        }
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    static creatOrthographicCamera() {
        const object = ObjectFactory.creatOrthographicCamera();
        if (MainObjects.Camera.m_renderCamera.isPerspectiveCamera) {
            object.position.copy(MainObjects.Camera.m_renderCamera.position);
            object.rotation.copy(MainObjects.Camera.m_renderCamera.rotation);
        }
        else {
            object.position.copy(MainObjects.Camera.m_renderCamera.position);
            object.rotation.copy(MainObjects.Camera.m_renderCamera.rotation);
        }
        SceneFactory.addToUserGroup(object);
        if (MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getMainCameraOnlyOne(object.id)) {
            ICamera.setMainCamera(object.id)
        }
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    //创建用户组
    static createGroup(name) {
        const object = ObjectFactory.createGroup(name);
        var count = MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjectsCount();
        if (count > 0) {
            object.position.copy(MainObjects.Blueprint.m_selectorPropertyBp.getCenterPosition());
        }
        else {
            object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        }
        SceneFactory.addToUserGroup(object);
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);

        if (count > 0) {
            MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function (obj) {
                IScene.addTo(object, obj);
            });
        }
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        ISelector.selectObjectByTree(object, false);
        ISelector.sceneTreeSelectCallback();
    }

    //创建曲线
    static createCurve() {
        const object = GeometryFactory.createCurve(5);
        var s = MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object);
        for (var child of object.userData.m_gltf_meshList) {
            child.position.add(s);
        }
        MainObjects.Blueprint.m_curveEditorBp.initCurve(object);
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
        ISelector.selectObjectByTree(object, false);
        ISelector.sceneTreeSelectCallback();
    }

    //创建lod
    static createLod() {
        const object = ObjectFactory.createLod();
        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
        ISelector.selectObjectByTree(object, false);
        ISelector.sceneTreeSelectCallback();
    }

    //环景相机
    static createEquirectangularCamera() {
        const object = ObjectFactory.createEquirectangularCamera();
        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        SceneFactory.addToUserGroup(object);
        if (MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getMainCameraOnlyOne(object.id)) {
            ICamera.setMainCamera(object.id)
        }
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    //投影相机
    static createProjectCamera() {
        const object = ObjectFactory.creatPerspectiveCamera();
        object.userData.m_name = Language.LOCAL_TEXT("Projection", "投影");

        console.log(object,"object")
        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    //投影环景相机
    static createProjectEquirectangularCamera() {
        const object = ObjectFactory.creatPerspectiveCamera();
        object.userData.m_name = Language.LOCAL_TEXT("Whole Scene Projection", "全景投影");

        object.position.copy(MainObjects.Blueprint.m_cameraListenerBp.getCameraDirPosition(object));
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    static createSky() {
        const object = GeometryFactory.createSky();
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    static createRealTimeSky() {
        const object = GeometryFactory.createRealTimeSky();
        SceneFactory.addToUserGroup(object);
        MainObjects.Blueprint.m_skyBp.updateSkySun(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    static createLensFlare() {
        const object = GeometryFactory.createLensFlare();
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    static createTerrain() {
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        var tgrass1Texture = gameScene.m_textureRepos.get(tgrass1);
        var option = {
            textures: [
                // The first texture is the base; other textures are blended in on top.
                { texture: tgrass1Texture },
            ],
            xS: 100,
            yS: 100,
            easing: T.THREE.Terrain.Linear,
            frequency: 100,
            heightmap: T.THREE.Terrain.DiamondSquare,
            maxHeight: 5,
            minHeight: -10,
            steps: 1,
            xSize: 512,
            ySize: 512,
        }
        const object = GeometryFactory.createTerrain(option);
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }

    static createWaterBase(){
        const object = GeometryFactory.createBaseWater();
        SceneFactory.addToUserGroup(object);
        if (IScene.f_sceneTreeCallback != null) {
            IScene.f_sceneTreeCallback();
        }
        MainObjects.Blueprint.m_selectListenerBp.addUndoRedoAdd([object]);
    }
}

export { IFactory };