<template>


    <div>
        <div class="tree" v-loading="loading">


            <!-- 选择本地文件夹 -->
            <vue-easy-tree empty-text="请去项目库对应文件类型下新建文件夹"  ref="tree" :height="500" :data="dataList" :show-checkbox="true" node-key="id"
                :expand-on-click-node="false" :highlight-current="true" :check-strictly="true"
                @check-change="groupHandleClick" :props="groupTreeDefaultProps">

                <!-- 默认展开节点 -->
                <!-- class="custom-tree-node"
            :class="{'custom-tree-node':true}" -->
                <span slot-scope="{ node, data }">
                    <!-- 非编辑状态 -->
                    <span class="custom-tree-node-content">
                        <el-tooltip class="item" effect="dark" :content="data.name" placement="top">

                            <span class="tree_label">
                                {{ data.name }}
                            </span>
                        </el-tooltip>
                    </span>
                </span>
            </vue-easy-tree>

        </div>


        <div slot="footer" class="dialog-footer">
            <!--  -->
            <el-button v-if="$store.state.synchronization=='1'" type="primary" @click="confirmDownload">确 定</el-button>
            <el-button v-else-if="$store.state.synchronization=='3'" type="primary" @click="selectFolder">确 定</el-button>

            <el-button v-else type="primary" @click="fileDownload">确 定</el-button>

        </div>
    </div>

</template>

<script>
//导入组件树
import VueEasyTree from "@wchbrad/vue-easy-tree";
import { INetwork } from "../../threejs/interface/INetwork";
import { ISetting } from "../../threejs/interface/ISetting";
export default {
    name: "SelectFolder",
    data() {
        return {
            //树的数据
            dataList: [],
            //树配置
            groupTreeDefaultProps: {
                children: "subCatalog",
                label: "name",
                object: "object",

            },
            //判断树是否有被选择
            isChecked: false,
            loading: false
        }
    },
    components: {
        VueEasyTree
    },
    mounted() {
        this.loading = true
        this.getFolderList(this.$store.state.folderType)

    },
    methods: {
        //选择文件夹
        selectFolder(){
            let idList = this.$refs.tree.getCheckedNodes()
          
            if (idList.length == 0) {
                this.$message({
                    // type: "info",
                    message: "请至少选择一个文件夹"
                });
            }else{
                
                this.$store.commit("changeSelectFolderId", idList[0].id)
                this.$store.commit("changeDownloadShow", false)

              

            

            }

        },

        //文件类型的同步
        fileDownload() {

            let idList = this.$refs.tree.getCheckedNodes()
           
            if (idList.length == 0) {
                this.$message({
                    // type: "info",
                    message: "请至少选择一个文件夹"
                });
            } else {



                //所选文件夹id
                let folderId = this.$refs.tree.getCheckedNodes()[0].id

                let node = this.$store.state.downloadData
                
                var time = new Date().getTime();
                let data = {

                }

                let imageUrl = [];
                imageUrl.push(node.imageUrl.split("?")[0]);
                data.id = node.id

                data.deputyUrl = INetwork.copyPaseFilesToSql(
                    node.deputyUrl.split(","),
                    node.id,
                    () => { }
                ).toString();
                data.fileUrl = INetwork.copyPaseFilesToSql(
                    node.fileUrl.split(","),
                    node.id,

                    () => { }
                )[0];
                data.imageUrl = INetwork.copyPaseFilesToSql(
                    imageUrl,
                    node.id,
                    () => { }
                )[0] + "?" + "time=" + time;

   
                this.addFile(folderId, node.name, node.type, data.fileUrl, node.fileSize, data.imageUrl, data.deputyUrl).then(
                    () => {
                        this.$store.commit("changeDownloadShow", false)

                    }
                )



            }

        },

        //调用后台接口 添加文件
        async addFile(folderId, name, type, fileUrl, fileSize, imageUrl, deputyUrl) {
            // let fileName = this.mainUrl.split("/");
            // let name = fileName[fileName.length - 1];
            // name = name.split("?")[0];


            const res = await this.$https.addFile({
                //    this.url_list=url_list
                // this.mainUrl=mainUrl
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                folderId: folderId,
                // name: this.Node.name,
                name: name,
                type: type,
                fileUrl: fileUrl,
                fileSize: fileSize,
                imageUrl: imageUrl,
                deputyUrl: deputyUrl
            });
            if (res.success == true) {


                this.$message({
                    message: '同步成功'
                    // type: "warning"
                });
                // 上传成功
                // this.fileId = res.data;


                // INetwork.getFileList(this.fileId, () => {

                // //   this.updateFile().then(() => {
                // //     // this.$emit("setFileDirectoryId", this.FileDirectoryId);
                // //   });
                // });
                // .catch(()=>{
                //     this.$emit("setFileDirectoryId", this.FileDirectoryId);

                // })


            } else {
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },
        // setFileId(msg) {

        //   this.fileId = msg;
        // },

        //调用后台接口 更改文件
        async updateFile() {
            var fileId;
            // var updateFileId = localStorage.getItem("updateFileId");
            // if (
            //   updateFileId !== undefined &&
            //   updateFileId !== null &&
            //   updateFileId !== ""
            // ) {
            //   fileId = updateFileId;  
            // } else {
            fileId = this.fileId;
            // }

            // var name;
            // if (this.upgrading == 1) {
            //   name = this.fileName;
            // } else if (this.upgrading == 2) {
            //   name = this.upDataNode.name;
            // }


            //获取真实的数据 这里需要传文件类型

            var data = INetwork.getGroupJsonToSql(fileId, () => { });

            const loading = this.$loading({
                lock: true,
                text: "Loading",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });

            var time = new Date().getTime();

            const res = await this.$https.updateFile({
                //文件夹id
                // folderId:this.id,
                projectId: localStorage.getItem("projectId"),
                id: fileId,
                // name: name,
                fileUrl: data.mainName,
                imageUrl: data.imageName + "?" + "time=" + time,
                deputyUrl: data.otherNameList.toString(),
                fileSize: this.totalSize
            });

            if (res.errCode == null) {
                IResousePreview.close();
                loading.close();
                this.fileName = "";

                this.$parent.setFileDirectoryId(this.FileDirectoryId);

                localStorage.removeItem("updateFileId");

                this.dialogFormVisible = false;

                this.$emit("transfer", false);

                this.$parent.getSubCatalog(this.modelId);
            } else {
                loading.close();
            }
        },











        //确认下载
        confirmDownload() {
            let idList = this.$refs.tree.getCheckedNodes()
       
            if (idList.length == 0) {
                this.$message({
                    // type: "info",
                    message: "请至少选择一个文件夹"
                });
            } else {





                let downloadData = this.$store.state.downloadData
                if (downloadData.type == 0) {
                    this.copyFolder()

                }
                // this.$store.commit("changeDownloadShow", false)
            }
        },
        //查看同步情况
        async copyFolder() {

            let folderId = this.$refs.tree.getCheckedNodes()[0].id

            let data = {
                folderShowCO: this.$store.state.downloadData,
                folderId: folderId
            }
            const res = await this.$https.copyFolder(data)

            if (res.errCode == null) {
                this.$store.commit("changeDownloadShow", false)
                this.allFile(res.data)
            } else if (res.errCode == 412) {
                this.$store.commit("changeDownloadShow", false)
                //重名
                this.$confirm(res.errMessage, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    // type: "warning"
                })
                    .then(() => {
                        this.copyReplace(data)
                    })
                    .catch(() => {
                        this.$message({
                            // type: "info",
                            message: "已取消"
                        });
                    });




            }


        },
        async copyReplace(data) {


            const res = await this.$https.copyReplace(data)
            if (res.errCode == null) {
                this.updateFileOSSUrl(res.data)
            } else {

            }



        },


        //获取需要同步的文件列表
        async allFile(id) {
            let data = {
                id: id
            }
            const res = await this.$https.allFile(data)
            if (res.errCode == null) {
                this.updateFileOSSUrl(res.data)
            } else {

            }
        },


        //调取修改oss路径
        async updateFileOSSUrl(dataList) {


            let fileList = []



            for (let index = 0; index < dataList.length; index++) {
           

                let node = dataList[index]
                var time = new Date().getTime();
                let data = {

                }

                let imageUrl = [];
                imageUrl.push(node.imageUrl.split("?")[0]);
                data.id = node.id

                data.deputyUrl = INetwork.copyPaseFilesToSql(
                    node.deputyUrl.split(","),
                    node.id,
                    () => { }
                ).toString();
                data.fileUrl = INetwork.copyPaseFilesToSql(
                    node.fileUrl.split(","),
                    node.id,

                    () => { }
                )[0];
                data.imageUrl = INetwork.copyPaseFilesToSql(
                    imageUrl,
                    node.id,
                    () => { }
                )[0] + "?" + "time=" + time;

                fileList.push(data)



            }
  
            let data = {
                fileList: fileList
            }
            const res = await this.$https.updateFileOSSUrl(data)

            if (res.success == true) {

                this.$message({
                    // type: "info",
                    message: "同步成功"
                });

            } else {

                this.$message({
                    message: res.errMessage
                    // type: "warning"
                });


            }
            this.$store.commit("changeDownloadShow", false)





        },        
        //限制单选
        groupHandleClick(data, checked, node) {
            if (checked) {
                this.$refs.tree.setCheckedNodes([data]);
            }
        },



        // 选择
        handleCheckChange(data, checked, indeterminate) {
            //判断是否有被选择

            let isChecked = this.$refs.tree.getCheckedNodes().length != 0
            this.isChecked = isChecked




        },

        //获取文件列表
        async getFolderList(_type) {
            const res = await this.$https.getFolderList({
                // projectId: localStorage.getItem("projectId"),
                projectId: localStorage.getItem("projectId"),

                type: _type,
                show: 0,
                // groupId: "c943db89-a6c1-4079-8fd4-06346dbab6b5"
            });

            // const loading = this.$loading({
            //   lock: true,
            //   text: "Loading",
            //   spinner: "el-icon-loading",
            //   background: "rgba(0, 0, 0, 0.7)"
            // });

            if (res.errCode == null) {
     
                this.dataList = res.data;
                this.loading = false
            } else {
                // loading.close();
                var token = localStorage.getItem("token");
                if (token) {
                    this.$message({
                        message: res.errMessage
                        // type: "warning"
                    });
                }
            }
        },


    }


}
</script>

<style scoped>
.tree {
    width: 100%;
    height: 300px;
    /* background-color: black; */
    margin: 10px auto;
    overflow-y: auto;
    /* overflow: hidden; */
}

/* 自定义tree组件 */

.tree /deep/ .el-tree-node__content {
    height: 23px;
    /* margin-top: 10px; */

}

.tree /deep/ .el-tree-node {
    margin-bottom: 140px;
}


.tree /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    background: rgba(75, 79, 82, 0);
    border: 2px solid rgba(5, 96, 253, 0.7);
    border-radius: 8px;
}

.tree /deep/.el-icon-caret-right:before {
    content: "";
    font-size: 25px;
    width: 10px;
    height: 10px;
    display: block;
    background: url("../../assets/Resource/arrows.png") no-repeat center;
    background-size: 10px 10px;
    margin-right: 10px;
    margin-left: 10px;
}

.tree /deep/.el-checkbox__inner {
    border: none;
    box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.2);
}

/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    border-radius: 3px;
    background-color: RGBA(5, 96, 253, 1);
}

/deep/ .el-checkbox__inner::after {
    box-sizing: content-box;
    content: "";
    position: absolute;
    left: 5px;
    top: 2px;
}

.el-tree /deep/.el-tree-node__expand-icon.is-leaf::before {
    background: #ffffff;
    content: "";
    display: block;
    width: 0px;
    height: 0px;
    font-size: 16px;
    background-size: 16px;
    margin-right: 20px;
}

.tree /deep/ .el-tree .el-tree-node__expand-icon.expanded .el-icon-caret-right:before {
    content: "";
    font-size: 25px;
    width: 10px;
    height: 10px;
    display: block;
    background: url("../../assets/Resource/arrows.png") no-repeat center;
    background-size: 10px 10px;
}

/* 滚动条 */
.tree::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: rgba(240, 240, 240, 1);
}

.tree::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
    border-radius: 10px;
    background-color: #e9f1fe;
}

.tree::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px #69a0fe;
    background: #69a0fe;
}

/* 设置鼠标悬停显示 */
.custom-tree-node {
    width: 100%;
}

.custom-tree-node-checked {
    border: 2px solid #333;
    border-radius: 10px;
}

.custom-tree-node-content {
    float: left;
}

.operation {
    visibility: hidden;
    margin-right: 30%;
    float: right;
}

.custom-tree-node:hover>.operation {
    visibility: visible;
}
</style>