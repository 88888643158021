<template>
  <!-- 资源模块 -->
  <div class="box"  v-loading.fullscreen.lock="fullscreenLoading">


    <div class="content" :class="isHide ? 'anmDiv' : 'aaa'">
      <!-- <div class="title">资源</div> -->
      <div v-show="contentHide">
        <div class="handle">
          <!-- 上传 -->
          <input type="file" id="file" @change="getUrl" ref="file" :accept="canUploadType" :multiple="UploadMultiple"
            value style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />
          <!-- accept=".jpg, .jpeg, .png, .hdr, .gltf, .bin, .mtl" -->

          <!-- {{canUploadType}} -->

          <input type="file" id="file" @change="getUrlMultimedia" ref="multimedia" :multiple="false" accept=".mp3" value
            style="filter: alpha(opacity=0); opacity: 0; width: 0; height: 0" />
        </div>
        <div class="ResourcesShow"></div>
        <!-- :style="bottomHeight ? `height:${bottomHeight-70}px;` : ''" -->
        <div class="file_box" :style="bottomHeight ? `height:${bottomHeight - 70}px;` : ''">
          <div class="handle_right">
            <div class="address">
              <div class="path">
                <div class="pathIcon">
                  <img class="iconImg" src="../../assets/Resource/project_icon.png" alt />
                </div>
                <div class="projectName">{{ projectName }}</div>
                <div class="point">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
              </div>

              <div class="path">
                <div class="pathIcon" v-show="ResourceType == 1">
                  <img class="iconImg" src="../../assets/Resource/icon_scene.png" alt />
                </div>
                <div class="projectName" v-show="ResourceType == 1">场景</div>
                <div class="point" v-show="ResourceType == 1">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
                <div class="pathIcon" v-show="ResourceType == 2">
                  <img class="iconImg" src="../../assets/Resource/icon_model.png" alt />
                </div>
                <div class="projectName" v-show="ResourceType == 2">模型</div>
                <div class="point" v-show="ResourceType == 2">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
                <div class="pathIcon" v-show="ResourceType == 3">
                  <img class="iconImg" src="../../assets/Resource/icon_chartlet.png" alt />
                </div>
                <div class="projectName" v-show="ResourceType == 3">贴图</div>
                <div class="point" v-show="ResourceType == 3">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
                <div class="pathIcon" v-show="ResourceType == 4">
                  <img class="iconImg" src="../../assets/Resource/project_icon.png" alt />
                </div>
                <div class="projectName" v-show="ResourceType == 4"></div>
                <div class="point" v-show="ResourceType == 4">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
                <div class="pathIcon" v-show="ResourceType == 5">
                  <img class="iconImg" src="../../assets/Resource/icon_script.png" alt />
                </div>
                <div class="projectName" v-show="ResourceType == 5">脚本</div>
                <div class="point" v-show="ResourceType == 5">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>

                <div class="pathIcon" v-show="ResourceType == 6">
                  <img class="iconImg" src="../../assets/Resource/icon-67.png" alt />
                </div>
                <div class="projectName" v-show="ResourceType == 6">回收站</div>
                <div class="point" v-show="ResourceType == 6">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>

                <div class="pathIcon" v-show="ResourceType == 8">
                  <img class="iconImg" src="../../assets/Resource/multimedia.png" alt />
                </div>
                <div class="projectName" v-show="ResourceType == 8">媒体</div>
                <div class="point" v-show="ResourceType == 8">
                  <img class="iconImg path_point" src="../../assets/Resource/path_point.png" alt />
                </div>
              </div>

              <div class="path" v-for="(item, index) in temppathList" :key="index">
                <div class="pathIcon" v-show="index != temppathList.length - 1">
                  <img class="iconImg" v-show="index != temppathList.length - 1"
                    src="../../assets/Resource/path_file_list.png" alt />
                </div>

                <div class="pathIcon" v-show="index == temppathList.length - 1">
                  <img v-show="index == temppathList.length - 1" class="iconImg Path_file"
                    src="../../assets/Resource/Path_file_list_checked.png" alt />
                </div>

                <div :class="{
                  pathTitle: index != temppathList.length - 1,
                  endpathTitle: index == temppathList.length - 1,
                }" :v-if="item == true">{{ item }}</div>
                <div class="point" v-show="index != temppathList.length - 1">
                  <img v-show="index != temppathList.length - 1" class="iconImg path_point"
                    src="../../assets/Resource/path_point.png" alt />
                </div>
              </div>

              <!-- <div class="rightSearch"> -->
              <div class="rightSearch">
                <el-input :disabled="$store.state.chosenWarehouse.type != 0" v-model="queryFileName" placeholder="请输入文件名称"
                  @keyup.enter.native="searchRight" clearable value @focus="Focus" @blur="Blur">
                  <!-- suffix-icon="el-icon-search" -->
                  <template slot="prefix">
                    <!-- <select name="" id="" class="indistinctOrExact">
                      <option value="1">模糊</option>
                      <option value="2">精准</option>

                    </select>-->

                    <div class="select">
                      <el-select :disabled="$store.state.chosenWarehouse.type != 0" v-model="searchType" placeholder="请选择"
                        class="indistinctOrExact">
                        <el-option label="模糊" value="1"></el-option>
                        <el-option label="精准" value="2"></el-option>
                      </el-select>
                    </div>
                  </template>

                  <i class="el-icon-search" slot="suffix"></i>

                  <!-- <el-button slot="prepend" icon="el-icon-search" @click="findByName"></el-button> -->
                </el-input>
                <!-- <input type="text" class="seek_input"> -->
                <!-- </div> -->
              </div>
            </div>

            <!-- <div class="address">路径:{{ treeName }}> {{ temppath }}</div> -->
          </div>
          <div class="detail">
            <ResourceDetail v-loading="InTheQuery" :FileDirectoryList="FileDirectoryList"
              :FileDirectoryId="FileDirectoryId" :ResourceType="ResourceType" @setFileDirectoryId="setFileDirectoryId"
              @setResourceType="setResourceType" @setModelProcess="setModelProcess" @uploading="handleSelect"
              :childSia="sid" @getPath="getPath" :temppath="temppathList" :bottomHeight="bottomHeight"
              ref="ResourceDetail" :textureBall="textureBall" :upgrading="upgrading" :ballChildSia="ballChildSia" />
          </div>
        </div>


        <div class="documentTree">
          <!-- <div class="box1" v-show="$store.state.textureBallMasking"></div> -->

          <div class="handle_left">
            <div class="seek">
              <el-input :disabled="$store.state.chosenWarehouse.type != 0" v-model="searchName" placeholder="请输入文件夹名称"
                @keyup.enter.native="folderByName" clearable value @focus="Focus" @blur="Blur">
                <!-- prefix-icon="el-icon-search" -->

                <template slot="prefix">
                  <!-- <select name="" id="" class="indistinctOrExact">
                      <option value="1">模糊</option>
                      <option value="2">精准</option>

                  </select>-->
                  <!-- -->
                  <div class="select">
                    <el-select :disabled="$store.state.chosenWarehouse.type != 0" v-model="folderSearchType"
                      placeholder="请选择" class="indistinctOrExact">
                      <el-option label="模糊" value="1"></el-option>
                      <el-option label="精准" value="2"></el-option>
                    </el-select>
                  </div>
                </template>

                <i class="el-icon-search" slot="suffix"></i>

                <!-- <el-button slot="prepend" icon="el-icon-search" @click="findByName"></el-button> -->
              </el-input>
              <!-- <input type="text" class="seek_input"> -->
            </div>
          </div>

          <div class="hierarchical">
            <ResourceList @setFileDirectoryList="setFileDirectoryList" @setFileDirectoryId="setFileDirectoryId"
              @setResourceType="setResourceType" @getPath="getPath" :bottomHeight="bottomHeight" :searchName="searchName"
              ref="resourceList" />
          </div>
        </div>


      </div>

      <!-- <div class="bottomToolBar">
        <div class="bottomToolBar_one">资源</div>
      </div>-->
    </div>
    <!-- 上传预览 -->
    <!-- ." -->
    <div class="preview" v-if="previewShow">
      <!--    <i class="cancel el-icon-close" @click="previewShow=false"></i> -->
      <!--3D预览div -->
      <!-- <div class="fileName">文件名称</div> -->

      <div class="UploadComponent">
        <Preview ref="previewSave" :files="files" :fileUrl="url" :ext="ext" @transfer="getPreviewShow"
          :upDataId="upDataId" :upgrading="upgrading" @setFileDirectoryId="setFileDirectoryId"
          :FileDirectoryId="FileDirectoryId" :ResourceType="ResourceType" :upDataNode="upDataNode" />
      </div>
    </div>
    <!-- v-if="textureBall" -->
    <div class="preview">
      <!--    <i class="cancel el-icon-close" @click="previewShow=false"></i> -->
      <!--3D预览div -->
      <!-- <div class="fileName">文件名称</div> -->
      <div>
        <!-- <setEditor
          ref="previewSave"
          :files="files"
          :fileUrl="url"
          :ext="ext"
          @transfer="getPreviewShow"
          :upDataId="upDataId"
          :upgrading="upgrading"
          @setFileDirectoryId="setFileDirectoryId"
          :FileDirectoryId="FileDirectoryId"
          :ResourceType="ResourceType"
          :upDataNode="upDataNode"
        />-->
      </div>
    </div>

    <div class="EditorBox">
      <setEditor v-if="textureBall" :files="files" :fileUrl="url" :ext="ext" @transfer="getPreviewShow"
        :upDataId="upDataId" :upgrading="upgrading" @setFileDirectoryId="setFileDirectoryId" @passVaule="passVaule"
        :FileDirectoryId="FileDirectoryId" :ResourceType="ResourceType" :upDataNode="upDataNode" ref="setEditorChild" />
    </div>


    <!-- <div class="masking" v-show="previewShow"></div> -->
    <!-- <div class="warehouse">

    </div> -->

    <!-- 分享库 -->
    <el-dialog :visible="$store.state.historyShow" width="25%" :modal="true" title="上传至共享库" append-to-body
      :before-close="selectHistoryClose" @open="openHistoryShow" top="23vh">
      <el-table empty-text="暂无数据，请先创建共享库" :border="true" :row-key="getRowKey" :data="historyData"
        @selection-change="handleSelectionChange">

        <el-table-column property="name" label="名称"></el-table-column>
        <el-table-column property="createTime" label="创建时间"></el-table-column>


        <el-table-column type="selection" width="55">
        </el-table-column>

      </el-table>
      <div class="paging">

        <div class="totalNumber">
          共{{ totals }}条数据
        </div>

        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
          :page-sizes="[10, 20, 15]" :page-size="size" layout="prev, pager, next" :total="totals" align="right">
        </el-pagination>
      </div>
      <div slot="footer" class="dialog-footer">

        <el-button type="primary" @click="determinedUploading">确 定</el-button>
      </div>


    </el-dialog>
    <!-- 同步到本地库 -->
    <el-dialog :visible="$store.state.downloadShow" width="25%" :modal="true" title="请选择要保存的文件夹" append-to-body
      :before-close="downloadClose" top="23vh">

      <SelectFolder v-if="$store.state.downloadShow"></SelectFolder>








    </el-dialog>




  </div>
</template>
<script>
import setEditor from "../layout/SetMaterialEditor";
import ResourceList from "./ResourceList";
import SelectFolder from "./SelectFolder";

import ResourceDetail from "./ResourceDetail";
import Preview from "./Preview";
import { INetwork } from "../../threejs/interface/INetwork";
import { ISetting } from "../../threejs/interface/ISetting";
import { ILoader } from '../../threejs/interface/ILoader';
import { IScene } from '../../threejs/interface/IScene';



export default {
  name: "Resource",
  props: {
    //接受材质
    sid: {
      type: String,
      default: "0"
    },
    bottomHeight: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {
      //文件夹搜索类型
      folderSearchType: "1",
      //文件搜索类型
      searchType: "1",
      isHide: true,
      packUp: "收起",
      contentHide: true,
      //上传显示隐藏
      previewShow: false,
      image1_list: [],
      image1: [],
      imageShow1: false,
      storeId: "test",
      menu: false,
      url: "",
      files: [],
      ext: "",
      //拿到文件价数据
      FileDirectoryList: [],
      //拿到的文件id
      FileDirectoryId: "",

      // 拿到左侧文件树类型
      ResourceType: 1,

      //当前在那个树下
      treeName: "",

      //更改还是上传
      upgrading: "",

      //更改文件路径 文件id

      upDataId: "",

      //搜索名字
      searchName: "",

      //路径
      temppathList: [],
      //是否可以多选上传
      UploadMultiple: "multiple",

      // bottomHeight: localStorage.getItem("bottomHeight")
      //项目名称
      projectName: "",

      upDataNode: "",
      //模型还是材质
      modelOrMap: "",

      //如果是1表示其他上传 2 表示材质球上传
      textureType: "",

      // 材质球上传
      textureBall: false,
      //球材质
      ballChildSia: "",
      //查询文件名
      queryFileName: "",
      //多媒体数据
      MultimediaData: {
        url: "",
        files: "",
        name: ""
      },
      //媒体的文件夹id
      MultimediaFolderId: "",
      //媒体更新或创建
      MultimediaUpdateOrAdd: "",
      //媒体的文件id
      MultimediaFileId: "",

      //媒体文件名
      MultimediaName: "",
      oldMediaNode: {},

      //定位数据
      //文件夹信息
      parentFolder: {},
      //文件信息
      file: {},
      //可上传的文件类型
      canUploadType: "",
      //文件查询加载
      InTheQuery: false,

      //选择共享库
      historyData: [


      ],
      //被选中的数据
      selectOrderArr: [],

      //分页

      totals: 10,
      size: 5,
      page: 1,
      MultimediaSize: 0,
      fullscreenLoading:false,
    };
  },
  components: {
    ResourceList,
    ResourceDetail,
    Preview,
    setEditor,
    SelectFolder
  },
  created() { },
  mounted() {
    this.projectName = localStorage.getItem("projectName");
    // IScene.f_saveSceneCallback=this.updataSaveScene.bind(this)
  },
  watch: {
    //搜索文件类型改变
    searchType(val) {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.searchFile(this.queryFileName);
      }, 1000);
    },
    //搜索文件 搜索名改变
    queryFileName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.searchFile(this.queryFileName);
      }, 1000);
    },
    //搜索文件夹类型改变
    searchName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.$refs.resourceList.folderByName(this.folderSearchType);
      }, 1000);
    },
    //搜索文件 搜索值改变
    folderSearchType() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.$refs.resourceList.folderByName(this.folderSearchType);
      }, 1000);
    }
  },

  methods: {

    handleSizeChange(val) {
      this.size = val;
      this.listLoading = true;
      this.getWarehouseList(this.page, this.size)


    },
    //分页
    handleCurrentChange(val) {
      this.page = val;
      this.listLoading = true;
      this.getWarehouseList(this.page, this.size)
    },


    //下载到本地
    downloadClose() {
      this.$store.commit("changeDownloadShow", false)
      let synchronization = this.$store.state.synchronization
      console.log(synchronization, "synchronization")
      if (synchronization == '3') {

        this.$parent.getPreviewShow(false)
      }


    },
    //上传到共享库
    async folderFileUpload(id, libraryIds, type, property) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });


      let data = {
        id: id,
        libraryIds: libraryIds,
        type: type,
        property: property
      }

      const res = await this.$https.folderFileUpload(data)
      if (res.success == true) {
        // console.log(res.data);
        // this.$message({
        //     message: "恭喜您上传成功",
        //     // type: "warning"
        //   });
        let len = res.data.libraryIds.length

        this.$confirm(res.data.hint, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {


            if (len > 0) {
              this.folderFileReplace(id, res.data.libraryIds, type, property)
              this.page = 1
            }

            this.$refs.resourceList.getDataTree()
            this.getSubCatalog(this.FileDirectoryId)





          })
          .catch(() => {
            this.page = 1

            this.$message({
              type: "info",
              message: "已取消"
            });

            this.$refs.resourceList.getDataTree()
            this.getSubCatalog(this.FileDirectoryId)
          });













        loading.close();

      } else {
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }

        loading.close();
      }

    },
    //重名上传
    async folderFileReplace(id, libraryIds, type, property) {
      let data = {
        id: id,
        libraryIds: libraryIds,
        type: type,
        property: property
      }
      const res = await this.$https.folderFileReplace(data)



    },



    //确定上传
    determinedUploading() {

      if (this.selectOrderArr.length == 0) {
        this.$message({
          message: "请选择文件夹"
          // type: "warning"
        });
      } else {
        let id = this.$store.state.historyData.id
        let type = this.$store.state.historyData.type
        let selectIdList = []
        let selectOrderArr = this.selectOrderArr
        for (let index = 0; index < selectOrderArr.length; index++) {
          selectIdList.push(selectOrderArr[index].id)

        }




        this.folderFileUpload(id, selectIdList, type, this.$store.state.uploadingParentType)

        this.$store.commit("changeHistoryShow", false)
        this.page = 1
      }



    },



    //跨页多选
    getRowKey(row) {
      return row.id;
    },

    //被选中的数据
    handleSelectionChange(val) {
      this.selectOrderArr = val;

    },
    openHistoryShow() {

      this.getWarehouseList(this.page, this.size)
    },

    //获取库列表
    async getWarehouseList(page, size) {
      let projectId = localStorage.getItem("projectId")
      const data = {
        page: page,
        count: size,
        projectId: projectId,
        type: 2,
      }

      const res = await this.$https.sharedLibraryList(data)
      if (res.success == true) {

        this.historyData = res.data.sharedLibraryListCOS
        this.totals = res.data.total;

        // this.total = Math.ceil(res.data.total / this.size)



      } else {
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }



    },




    //隐藏选择库
    selectHistoryClose() {

      this.$store.commit("changeHistoryShow", false);
    },



    //清空单选
    setChecked() {
      this.$refs.ResourceDetail.setChecked()
    },
    //定位
    orientation(parameter) {
      this.InTheQuery = true

      this.getFolderByFileId(parameter).then(() => {
        this.$refs.resourceList.locationTree(this.parentFolder);

        setTimeout(() => {
          if (this.searchType == 2 && this.queryFileName == this.file.name) {
            this.searchFile(this.file.name);
          } else {
            this.searchType = "2";
            this.queryFileName = this.file.name;
          }

          // loading.close()

        }, 2000);

      });

    },
    //获取定位数据
    async getFolderByFileId(id) {
      let data = {
        fileId: id
      };

      const res = await this.$https.getFolderByFileId(data);
      if (res.success == true) {
        this.parentFolder = res.data.parentFolder;
        this.file = res.data.file;
      }
    },

    //设置场景名称
    setSceneName(smg) {
      this.$parent.setSceneName(smg);
    },

    //搜索获取焦点
    Focus() {
      ISetting.isInput(true);
    },
    //搜索失去焦点
    Blur() {
      ISetting.isInput(false);
    },

    searchRight() {
      this.searchFile(this.queryFileName);
    },

    async searchFile(name) {
      this.InTheQuery = true
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)"
      // });
      const res = await this.$https.findByName({
        name: name,
        isDelete: 0,
        folderId: this.FileDirectoryId,
        projectId: localStorage.getItem("projectId"),
        type: this.searchType
      });

      if (res.success == true) {
        this.FileDirectoryList = res.data;


        this.InTheQuery = false
        // loading.close();
      } else {
        // loading.close();
        this.InTheQuery = false


        if (res.errCode == 400) {
          this.getSubCatalog(this.FileDirectoryId);
        } else {
          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage
              // type: "warning"
            });
          }
        }
      }
    },

    passVaule(msg) {
      this.ballChildSia = msg;
    },

    titleChange() {
      this.setDragShow(true);
      this.textureBall = false;
      this.$store.commit("showOrHide", false);
    },

    resourceFile(type) {
      //创建文件
      this.$refs.resourceList.resourceFile(type);
    },

    creationTextureBall(type, materialType) {

      //创建材质球
      this.$refs.resourceList.creationTextureBall(type, materialType);
    },

    // 控制菜单隐藏
    ControlHidden() {
      this.$parent.commandMenuShow();
    },

    //传递菜单隐藏
    commandMenuShow() {
      this.$refs.ResourceDetail.mouseLeave();
      this.$refs.resourceList.mouseLeave();
    },

    //设置选中的id
    setCheckedId(msg) {
      // this.$refs.ResourceDetail.setCheckedId(msg);
    },

    tabChange() {
      if (timer) {
        clearInterval(timer);
      }
      let timer;
      if (this.isHide) {
        this.isHide = false;
        this.packUp = "展开";

        this.contentHide = false;
      } else {
        this.isHide = true;
        this.packUp = "收起";

        timer = setTimeout(() => {
          this.contentHide = true;
        }, 800);
      }
    },
    //设置更新的node
    setUpDataNode(msg) {
      this.upDataNode = msg;
    },
    // // 显示上传
    // uploadFiles() {
    //   this.previewShow = true;
    // },

    // uploading() {
    //   this.$refs.upload.submit();

    // },

    //更新媒体
    updateTheMedia(updateOrAdd, node) {
      //设置要跟新的id
      this.MultimediaFileId = node.id;

      this.oldMediaNode = node
      //设置为更新
      this.MultimediaUpdateOrAdd = updateOrAdd;
      //设置文件夹id
      this.MultimediaFolderId = node.parentId;

      this.$refs.multimedia.value = "";
      this.$refs.multimedia.click();
    },

    //新建更新多媒体
    addMultimedia(updateOrAdd, nodeId) {
      ISetting.isInput(true);
      var containSpecial = RegExp(
        /[(\ )(\~)(\!)(\@)(\#)(\$)(\%)(\^)(\&)(\*)(\()(\))(\+)(\=)(\[)(\])(\{)(\})(\|)(\\)(\;)(\:)(\')(\")(\,)(\.)(\/)(\<)(\>)(\?)(\)]+/
      );

      this.$prompt(``, `新建音频媒体提示`, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        // center: true,
        inputPlaceholder: `请输入音频媒体的文件名称`,
        closeOnClickModal: false,
        inputErrorMessage: "输入不能为空，且不能有特殊字符 最大20字符",
        inputValidator: value => {
          // 点击按钮时，对文本框里面的值进行验证
          if (!value) {
            return "输入不能为空";
          } else if (containSpecial.test(value)) {
            return "输入不能为特殊字符";
          } else if (value.length > 20) {
            return "输入文字不能超过20个字符";
          }
        }
      })
        .then(({ value }) => {
          ISetting.isInput(false);
          this.$refs.multimedia.value = "";
          this.$refs.multimedia.click();
          this.MultimediaFolderId = nodeId;
          this.MultimediaUpdateOrAdd = updateOrAdd;
          this.MultimediaName = value;

        })
        .catch(() => {
          ISetting.isInput(false);
          this.$message({
            // type: "info",
            message: "取消输入"
          });
        });
    },
    //监听input 值改变
    getUrlMultimedia() {

      let url = this.$refs.multimedia.value;
      let files = "";
      let name = "";

      if (url != "") {
        files = this.$refs.multimedia.files;
        name = this.MultimediaName;

        let size = 0

        files.forEach(element => {

          size = size + element.size

        });
        this.MultimediaSize = size
      }





      let MultimediaData = {
        url: url,
        files: files,
        name: name
      };
      this.MultimediaData = MultimediaData;

      var key = INetwork.getUrl(this.oldMediaNode.fileUrl)
      ILoader.clearCache(key)



      INetwork.uploadFilesToPreview(
        files,
        this.process.bind(this),
        this.finish.bind(this)
      );
    },

    process(p) {

      // this.progressBar = parseInt(p * 100);

      // this.progressText = "正在上传";

      this.setModelProcess(parseInt(p * 100));

      if (this.MultimediaUpdateOrAdd == 1 && p >= 1) {
        this.addMultimediaToSql();
      } else if (this.MultimediaUpdateOrAdd == 2 && p >= 1) {



        INetwork.copyFilesToSql(
          this.MultimediaData.files,
          this.MultimediaFileId,
          "mp3",
          () => {
            this.updataMultimediaToSql().then(() => {
              this.setFileDirectoryId(this.MultimediaFolderId);
            });
          }
        );
      }
    },
    finish(data) {



      // var url_list = new Array();
      // var totalSize = 0;
      // var name_list = new Array();
      // for (let index = 0; index < data.length; index++) {
      //   const element = data[index];
      //   url_list.push(element.url);
      //   name_list.push(element.name);
      //   totalSize = totalSize + element.size;
      // }
      // this.name_list = name_list;

      // this.totalSize = totalSize;
      // this.progressBar = 100;
      // //显示加载进度条 拿到大小 totalSize url  mainName 附属url  name_list

      // IResousePreview.loadFile(
      //   url_list,
      //   this.ext,
      //   this.processLoad.bind(this),
      //   this.finishLoad.bind(this)
      // );
    },

    //在数据库创建媒体
    async addMultimediaToSql() {
      const res = await this.$https.addFile({
        //    this.url_list=url_list
        // this.mainUrl=mainUrl
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        folderId: this.MultimediaFolderId,
        name: this.MultimediaData.name,
        type: this.ResourceType,
        fileUrl: "",
        fileSize: 0,
        imageUrl: "",
        deputyUrl: ""
      });
      if (res.success == true) {
        this.MultimediaFileId = res.data;

        // console.log(this.MultimediaData.files, res.data, "mp3", "创建");

        INetwork.copyFilesToSql(
          this.MultimediaData.files,
          res.data,
          "mp3",
          () => {
            this.updataMultimediaToSql().then(() => {
              this.setFileDirectoryId(this.MultimediaFolderId);
            });
          }
        );

        //获取文件
        this.getSubCatalog(this.MultimediaFolderId);
      } else {
        this.$message({
          message: res.errMessage
          // type: "warning"
        });
      }
    },

    async updataMultimediaToSql() {
      var fileId = this.MultimediaFileId;

      var name = this.MultimediaData.name;


      //获取真实的数据 这里需要传文件类型


      //   更新

      var data = INetwork.getAudioFilesNameToSql(
        this.MultimediaData.files,
        this.MultimediaFileId,
        "mp3"
      );



      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      var time = new Date().getTime();

      const res = await this.$https.updateFile({
        //文件夹id
        // folderId:this.id,
        projectId: localStorage.getItem("projectId"),
        id: fileId,
        // name: name,
        fileUrl: data.mainName,
        imageUrl: data.imageName + "?" + "time=" + time,
        deputyUrl: data.otherNameList.toString(),
        fileSize: this.MultimediaSize
      });

      if (res.errCode == null) {



        loading.close();


        if (this.MultimediaUpdateOrAdd == 2) {
          this.fullscreenLoading=true
          ILoader.upDateAudio(this.oldMediaNode.fileUrl, data.mainName, () => {
            this.fullscreenLoading=false
          
          })
        }

        this.setFileDirectoryId(this.MultimediaFolderId);
      } else {
        loading.close();
      }
    },

    //选择上传材质
    handleSelect(key, resourceType, _upDataId, type) {




      if (type) {
        this.textureType = type;
      }



      this.upDataId = _upDataId;
      this.modelOrMap = resourceType;

      if (resourceType == 2) {
        this.ext = "gltf";
      } else if (resourceType == 3 || resourceType == 4) {
        this.ext = "jpg|jpeg|png|hdr";
      }

      if (key == 1) {
        if (type == 2) {
          this.upgrading = 1;
          this.setDragShow(false);

          this.textureBall = true;

          this.$store.commit("showOrHide", true);
        } else if (type == 1) {
          this.$refs.file.value = "";
          this.upgrading = 1;

          //点击 input 框 选择文件
          this.$refs.file.click();


          // this.menu = false;
        }
      } else if (key == 2) {
        if (type == 2) {
          this.upgrading = 2;
          this.setDragShow(false);

          this.textureBall = true;
          this.$store.commit("showOrHide", true);
        } else if (type == 1) {


          if (this.ResourceType == 3 || this.resourceType == 4) {
            this.ext = "jpg|jpeg|png|hdr";
          } else if (this.resourceType == 2) {
            this.ext = "gltf";
          }
          //更改文件路径
          this.$refs.file.value = "";
          this.upgrading = 2;

          this.upDataId = _upDataId;
          this.$refs.file.click();
          let url = this.$refs.file.value;
          // this.ext='gltf'
          this.menu = false;

          // this.$refs.previewSave.setFileId(_fileId)

        }
      }
    },

    //获取到input选择的文件
    getUrl() {
      let url = this.$refs.file.value;
      // this.url=url
      let files = this.$refs.file.files;
      this.files = files;
      var examine = this.toExamine(this.files, this.modelOrMap);

      if (examine) {




        // if (this.textureType==1) {
        this.previewShow = true;

        // }else if (this.textureType==2) {
        //   this.textureBall=true
        // }

        this.url = url;


      } else {
        this.$message({
          message: "请检查您上传的模型或贴图是否正确"
          // type: 'warning'
        });
      }
    },

    //校验文件类型
    toExamine(arr, type) {
      if (type == 2) {
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          var name = arr[index].name;
          var item = name.lastIndexOf(".");
          var ext = name.substr(item + 1);
          var acceptFileTypes = /^(gltf)$/i;
          if (acceptFileTypes.test(ext)) {
            //该文件为 gltf其中一种
            return true;
          } else {
          }

        }
      } else if (type == 3 || 4) {
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          var name = arr[index].name;
          var item = name.lastIndexOf(".");
          var ext = name.substr(item + 1);
          var acceptFileTypes = /^(jpg|jpeg|png|hdr)$/i;
          if (acceptFileTypes.test(ext)) {
            //该文件为 jpg|jpeg|png|hdr其中一种
            return true;
          } else {
          }

        }
      }
      return false;
    },

    //子组件传参子组件调用这个函数来让 弹框显示隐藏
    getPreviewShow(msg) {
      this.previewShow = msg;
    },

    //接收树 文件夹列表数据
    setFileDirectoryList(msg) {
      this.FileDirectoryList = msg;

    },
    //点击回收站
    clickRecycle() {
      this.$refs.resourceList.selectedRecycleBin();
    },

    //传选中的id
    setFileDirectoryId(msg) {


      this.FileDirectoryId = msg;

      //调用获取方法
      this.getSubCatalog(msg);
    },
    //传左侧文件树的类型
    setResourceType(msg) {
      this.ResourceType = msg;

      if (msg == 2) {
        this.canUploadType = ".gltf,.bin"
      } else if (msg == 3) {
        this.canUploadType = ".jpg, .jpeg, .png, .hdr, .mtl"

      }


      //判断是模型可以多选上传
      if (this.ResourceType == 2) {
        this.UploadMultiple = "multiple";
        //  不是模型不能多选
      } else {
        this.UploadMultiple = false;
      }

      var treeNameList = ["场景", "模型", "贴图", "材质", "脚本", "回收站"];

      this.treeName = treeNameList[msg - 1];
    },

    //传路径
    getPath(msg) {
      this.temppathList = msg;
      this.$store.commit("setTemppathList", msg)
    },

    //调用子组件方法查询
    folderByName() {
      this.$refs.resourceList.folderByName(this.folderSearchType);
    },

    //根据id 获取文件夹内容
    //根据文件夹id获取子目录
    async getSubCatalog(FileDirectoryId) {

      if (FileDirectoryId == "" || FileDirectoryId == undefined || FileDirectoryId == null) {
        this.FileDirectoryList = [];
      } else {
        var loading
        if (this.InTheQuery == false) {
          loading = this.$loading({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
        }


        const res = await this.$https.getSubCatalog({
          id: FileDirectoryId,
          // projectId: localStorage.getItem("projectId"),


          projectId: this.$store.state.chosenWarehouse.id ? this.$store.state.chosenWarehouse.id : localStorage.getItem("projectId"),

          type: this.ResourceType,

          isDelete: this.ResourceType == 6 ? 1 : 0,
        });

        if (res.errCode == null) {
          if (loading) {
            loading.close();
          }
          this.FileDirectoryList = res.data;
        } else {
          if (loading) {
            loading.close();
          }

          var token = localStorage.getItem("token");
          if (token) {
            this.$message({
              message: res.errMessage
              // type: "warning"
            });
          }
        }
      }
    },

    //调用文件删除
    ondelFile(type, id, FileDirectoryId) {
      this.delFile(type, id, FileDirectoryId).then(() => {
        this.getDataTree();
      });

      this.getSubCatalog(FileDirectoryId);
    },

    //文件删除
    async delFile(type, id) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await this.$https.delFile({
        type: type,
        id: id
      });

      if (res.success == true) {

        loading.close();
        setTimeout(() => {
          this.getSubCatalog(this.FileDirectoryId);
        }, 500);
      } else {
        loading.close();
        var token = localStorage.getItem("token");
        if (token) {
          this.$message({
            message: res.errMessage
            // type: "warning"
          });
        }
      }
    },
    //设置模型加载进度
    setModelProcess(msg) {
      this.$emit("setModelProcess", msg);
    },
    //获取树
    getDataTree() {
      this.$refs.resourceList.getDataTree();
    },

    //设置 回收站数据
    setRecycleBinData(msg) {
      this.FileDirectoryList = msg;
    },

    //控制拖动样式
    setDragShow(msg) {
      this.$parent.setDragShow(msg);
    }
  }
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.content {
  width: 100%;
  /* height: 300px; */

  height: 100%;

  border-radius: 5px;
  overflow: hidden;
}

.anmDiv {
  transition: 1s;
}

.aaa {
  height: 45px;
  transition: 1s;
}

.contentTxt {
  width: 45px;
  height: 20px;
  text-align: center;
  border-radius: 5px;
  line-height: 20px;
  background: #c33;
  color: #fff;
  font-size: 8px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.title {
  width: 42px;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}

.documentTree {
  position: relative;
  /* width: 200px; */
  width: 19%;
  min-width: 120px;
  /* height: 1200px; */
  float: right;
  margin-left: 20px;
  margin-right: 10px;
}

.hierarchical {
  margin-top: 10px;
  /* float: left; */
  /* width: 387px; */
  /* height: 1200px; */
  /* height: 90%; */

  /* background:red; */

  /* background-color:blue; */
  /* margin-left:20px ; */

  /*   
  overflow: auto;

  
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px; */
}

.file_box {
  width: 78%;
  /* height: 1200px; */
  float: right;
}

.detail {
  /* float: right; */
  /* height: inherit; */
  /* min-height: 500px; */
  /* height: 94%; */
  /* background-color:red; */
  /* width: 1125px; */
  /* height: 466px; */
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 2%;
}

/* 顶部菜单 */
.handle {
  width: 100%;
  height: 8px;
  /* height: 78px; */
  /* background-color: aquamarine; */
  /* margin-top: 20px; */
}

.handle_right {
  /* float: right; */
  width: 100%;
}

.handle_left {
  /* float: left; */
  height: 38px;
  /* width: 30%; */
}

.seek {
  /* width: 520px; */
  width: 100%;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-top: 1px;
  line-height: 36px;
}

.seek .select /deep/ .el-input {
  width: 65px !important;
  height: 35px !important;
  outline: none !important;
  line-height: 38px !important;
  text-align: center;
  border: none;
}

.seek .select /deep/ .el-input__inner {
  border-radius: 7px;
  border: none;
  background-color: #ffffff;
}

.seek .select /deep/ .el-input--suffix .el-input__inner {
  padding: 0px;
  padding-left: 10px;
}

.seek .select /deep/.el-input__inner {
  height: 24px;
  margin-top: 0px;
}

.seek .select /deep/.el-input__icon {
  line-height: 35px;
}

.seek .select /deep/.el-select .el-input .el-select__caret {
  font-size: 10px;
}

.seek /deep/ .el-input--suffix .el-input__inner {
  padding-right: 50px;
}

.seek /deep/ .el-input--prefix .el-input__inner {
  padding-left: 100px;
}

.seek /deep/.el-input__inner {
  /* width: 300px; */
  height: 35px;
  background: #f7f7f7;
  border-radius: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #929292;
  box-shadow: none;
}

.seek /deep/.el-input__inner .handle_conter {
  height: 20px;
  float: left;
  width: 30px;
  margin-right: 20px;
  margin-left: 20px;

  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}

. .seek /deep/.el-input__suffix .el-input__suffix-inner .el-icon-circle-close:before {
  content: "\e79d" !important;
  font-size: 18px;
  /* line-height: 60px */
}

.genre_top {
  width: 100%;
  height: 38px;
  background-color: #f7f7f7;
  border-radius: 10px;
  text-align: center;
  line-height: 38px;
  size: 16px;
  color: #000000;
}

.address {
  padding-left: 10px;
  /* width: 925px; */
  width: 97%;
  height: 38px;
  background: #f7f7f7;
  border-radius: 10px;
  text-align: left;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #929292;
  line-height: 38px;
  overflow: hidden;
  /* overflow-x: auto; */
}

.ResourcesShow {
  margin-top: 10px;
}

/* 上传 */
.preview {
  /* width: 650px;
  height: 650px; */
  width: 0.1px;
  height: 0.1px;

  position: fixed;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;

  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 10px;
  z-index: 999999999;
}

.fileName {
  position: relative;
  top: 20px;
  left: 70px;
}

/* 3D预览 */
.examine {
  width: 500px;
  height: 500px;
  border-radius: 10px;
  margin: 30px auto;
  box-shadow: 0px 0px 4px 0px rgba(33, 35, 49, 0.1);
}

.affirm {
  width: 100%;
  position: absolute;
}

.affirmOk {
  position: relative;
  width: 100px;
  height: 40px;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%, -50%);
}

.upload-demo {
  width: 100px;
  height: 50px;
  margin: 0 auto;
}

.cancel {
  position: absolute;
  right: 20px;
  top: 10px;
  /* width: 50px;
  height: 50px; */
  /* background-color: red ; */
  /* text-align: center;
  line-height: 50px */
}

/* 蒙版 */
.masking {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);

  z-index: 99999999;
}

/* 上传 */

.menu {
  position: absolute;
  left: 230px;

  width: 200px;
  height: 209px;
  box-shadow: 0px 0px 6px 0px rgba(33, 35, 49, 0.1);
  z-index: 99999;
}

.menuItem {
  color: #000000ff;
}

.UploadComponent {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999999999;
}

/* 路径 */
.path {
  box-sizing: border-box;
  /* width: 120px; */
  height: 40px;
  /* background-color: red; */
  /* float: left; */
  display: inline-block;
  padding: 7px 5px;
}

.path .pathIcon {
  margin-left: 2px;
  width: 16px;
  height: 16px;
  float: left;
}

.path .pathTitle {
  /* width: 100px; */
  float: left;
  line-height: 23px;
  font-size: 14px;
  margin-left: 6px;
  margin-right: 10px;
  color: #636363;
}

.projectName {
  float: left;
  line-height: 1.4375rem;
  font-size: 0.875rem;
  margin-left: 0.375rem;
  margin-right: 0.625rem;

  color: #000;
}

.endpathTitle {
  float: left;
  line-height: 23px;
  font-size: 14px;
  margin-left: 10px;
  color: #0560fd;
}

.point {
  float: left;
}

.iconImg {
  float: left;
  margin-top: 4px;

  width: 16px;
  height: 16px;
}

.Path_file {
  width: 15px;
  height: 15px;
}

.path_point {
  margin-top: 7px;

  width: 10px;
  height: 10px;
}

.bottomToolBar {
  width: 100%;
  height: 30px;
  background-color: #f7f7f7;
  position: absolute;
  bottom: 0;
}

.bottomToolBar_one {
  float: left;
  height: 30px;
  width: 100px;
  background-color: white;
  color: #0560fd;
  text-align: center;
  line-height: 30px;
}

/* 左蒙版 */
.box1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.1;
  z-index: 9999;
}

.EditorBox {
  position: fixed;
  z-index: 9999;

}

/* 右边搜索 */
.rightSearch {
  width: 350px;
  /* background-color: red; */
  float: right;
  margin: 2px;
  margin-top: 0px;
}

.rightSearch /deep/.el-input__inner {
  /* width: 300px; */
  height: 31px;
  /* background: #f7f7f7; */
  border-radius: 10px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #929292;
  box-shadow: none;
}

/* .indistinctOrExact {
  width: 80px;
  color: #666;
  height: 25px;
  border-radius: 8px;
  border: none;
  background-color: #f7f7f7;
  outline: none;
  text-align: center;
} */
.indistinctOrExact {
  width: 20px;
}

.rightSearch .select /deep/ .el-input {
  width: 65px !important;
  height: 35px !important;
  outline: none !important;
  line-height: 38px !important;
  text-align: center;
  border: none;
}

.rightSearch .select /deep/ .el-input__inner {
  border-radius: 7px;
  border: none;
  background-color: #f7f7f7;
}

.rightSearch .select /deep/ .el-input--suffix .el-input__inner {
  padding: 0px;
  padding-left: 10px;
}

.rightSearch .select /deep/.el-input__inner {
  height: 24px;
  margin-top: 0px;
}

.rightSearch .select /deep/.el-input__icon {
  line-height: 35px;
}

.rightSearch .select /deep/.el-select .el-input .el-select__caret {
  font-size: 10px;
}

.rightSearch .select /deep/.el-input__suffix {
  right: 2px;
}

.rightSearch /deep/.el-input__inner .handle_conter {
  height: 20px;
  float: left;
  width: 30px;
  margin-right: 20px;
  margin-left: 20px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}

.rightSearch /deep/ .el-input--suffix .el-input__inner {
  padding-right: 50px;
}

.rightSearch /deep/ .el-input--prefix .el-input__inner {
  padding-left: 100px;
}

.rightSearch /deep/.el-input__suffix .el-input__suffix-inner .el-icon-circle-close:before {
  content: "\e79d" !important;
  font-size: 18px;
}


/* 库相关 */
.warehouse {
  width: 100%;
  height: 30px;

  position: absolute;
  bottom: 0;
  background: #000;
  z-index: 9999;
}
</style>