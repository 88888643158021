import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { SceneFactory } from '../factory/SceneFactory';
import { Color, Vector3 } from 'three';
import { DragControls } from "../controls/DragControls"
import { ISelector } from '../interface/ISelector';
import { GameObject } from '../object/GameObject';
import { BoxHelper } from '../helper/BoxHelper';
import { GameScene } from '../object/GameScene';

class BoundingBoxBp
{
    constructor()
    {
        this.m_size = 0.1;
        this.m_min_size = 0.02;
        this.m_sprite_list = new Array();
        this.m_magn_sprite_list = new Array();
        this.m_xLen = 1;
        this.m_yLen = 1;
        this.m_zLen = 1;
        this.m_enable = false;
        this.m_currentObject = null;
        this.m_secondCurrentObject = null;
        var gameScene = MainObjects.Blueprint.m_sceneManagerBp.m_gameScene;
        var material = gameScene.m_matRepos.get(GameScene.MatKey.e_LIT_POINT_ICON);

        for(var i = 0; i < 52; i++)
        {
			var sprite = new THREE.Sprite(material);
			sprite.name = i + "";
			sprite.scale.set(this.m_size * 0.8, this.m_size * 0.8, this.m_size * 0.8);
            sprite.visible = false;
            if(i < 26)
            {
                this.m_sprite_list.push(sprite);
            }
            else
            {
                this.m_magn_sprite_list.push(sprite);
            }
            sprite.updateMatrixWorld(true);
            SceneFactory.addToHelperGroup(sprite);
        }

        //init drag
        MainObjects.Control.m_dragBoxControls = new DragControls(this.m_sprite_list, MainObjects.Camera.m_renderCamera, MainObjects.Render.m_renderer.domElement );
        MainObjects.Control.m_dragBoxControls.addEventListener('dragstart', this.dragstart.bind(this));
        MainObjects.Control.m_dragBoxControls.addEventListener('drag', this.drag.bind(this));
        MainObjects.Control.m_dragBoxControls.addEventListener('dragend', this.dragend.bind(this));
        MainObjects.Control.m_dragBoxControls.enabled = false;

        this.m_raycaster_sprite = new THREE.Raycaster();
        this.m_raycaster_mesh = new THREE.Raycaster();
        this.m_pointer = new THREE.Vector2();
        this.m_startPointer = new THREE.Vector2();
        this.m_undoData = null;

        const points = [];
        points.push( new THREE.Vector3( 0, 0, 0 ) );
        points.push( new THREE.Vector3( 0, 0, 1 ) );
        var geometry = gameScene.m_geoRepos.get(GameScene.GeoKey.e_LINE)
        var lineMaterial = gameScene.m_matRepos.get(GameScene.MatKey.e_LINE);
        this.m_line = new THREE.Line( geometry, lineMaterial );
        this.m_line.visible = false;
        this.m_line.renderOrder = 100;
        SceneFactory.addToHelperGroup(this.m_line);
        this.m_starpos = new THREE.Vector3();
        this.m_endpos = new THREE.Vector3();
        this.m_move = false;
        this.m_startPointer = new THREE.Vector2();
        this.m_selectBox = new THREE.Box3();
        this.m_helper = new THREE.Box3Helper( this.m_selectBox, 0xffff00 );
    }

    resetMagnColor()
    {
        for(var i = 0; i < this.m_magn_sprite_list.length; i++)
        {
            this.m_magn_sprite_list[i].material.color = new Color(1, 1, 1);
        }
    }

    dragstart(evnet){
        if(this.m_currentObject == null)
        {
            return;
        }
        MainObjects.Control.m_orbitControl.enabled = false;
        evnet.object.material.color.setHex( 0xffff00 );
        evnet.object.getWorldPosition(this.m_starpos);
        this.m_line.position.copy(this.m_starpos);
        this.m_startPointer.x = evnet.even.x;
        this.m_startPointer.y = evnet.even.y;
    }

    drag(evnet){
        this.m_move = false;
        if(!evnet.object.visible)
        {
            return;
        }
        if(this.m_currentObject == null)
        {
            return;
        }
        var min_size = 2;
      
        if (Math.abs(this.m_startPointer.x - evnet.even.x) < min_size && Math.abs(this.m_startPointer.y - evnet.even.y) < min_size) {
            return;
        }
        evnet.object.material.color.setHex(0xff0000);
        this.resetMagnColor();

        const width = MainObjects.Render.m_container.clientWidth;
        const height = MainObjects.Render.m_container.clientHeight;
        const offsetLeft = MainObjects.Render.m_container.offsetLeft;
        const offsetTop = MainObjects.Render.m_container.offsetTop;

        this.m_pointer.x = ((evnet.even.clientX - offsetLeft) / width) * 2 - 1;
        this.m_pointer.y = - ((evnet.even.clientY - offsetTop) / height) * 2 + 1;

        this.m_raycaster_sprite.setFromCamera(this.m_pointer, MainObjects.Camera.m_renderCamera);
        this.m_raycaster_mesh.setFromCamera(this.m_pointer, MainObjects.Camera.m_renderCamera);
        this.m_raycaster_mesh.layers.disable( 10 );
        const intersects = this.m_raycaster_sprite.intersectObjects(this.m_magn_sprite_list);
        const intersects_obj = this.m_raycaster_mesh.intersectObjects(MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.m_selectAllMeshList);
 
        if (intersects.length > 0) {
            const object = intersects[0].object;
            object.material.color.setHex(0x00ff00);
            evnet.object.position.copy(object.position);
            evnet.object.updateMatrixWorld(true);
            this.m_move = true;
        }
        else{
            var b = false;
            if(intersects_obj.length > 0)
            {
                const object = MainObjects.Blueprint.m_selectListenerBp.getRootObject(intersects_obj[0].object);
                if(object.id != this.m_currentObject.id)
                {
                    if (!MainObjects.Blueprint.m_eventListenerBp.m_bShift)
                    {
                        this.setSecondMagneticSprite(object);
                    }     
                    // if(ISelector.m_transformMode == ISelector.TransformMode.e_MAGNETIC)
                    {
                        evnet.object.position.copy(intersects_obj[0].point);
                        evnet.object.updateMatrixWorld(true);
                    }
                    b = true;
                    this.m_move = true;
                }
            }
            if(!b)
            {
                if (!MainObjects.Blueprint.m_eventListenerBp.m_bShift)
                {
                    if(this.m_secondCurrentObject != null)
                    {
                        this.disposeBoundingBox([this.m_secondCurrentObject]);
                    }
                    for (let index = 0; index < this.m_magn_sprite_list.length; index++) {
                        const element = this.m_magn_sprite_list[index];
                        element.visible = false;
                    }  
                }
                this.m_move = false;
            }
        }

        this.m_endpos.copy(evnet.object.position);
        var len = this.m_endpos.distanceTo(this.m_starpos);
        this.m_line.lookAt( this.m_endpos);
        this.m_line.scale.set(1, 1, len);
        this.m_line.visible = true;
        this.m_line.updateMatrixWorld(true);
    }

    dragend(evnet){
        if(this.m_currentObject == null)
        {
            MainObjects.Control.m_orbitControl.enabled = true;
            return;
        }
        if(evnet.object.userData.object != undefined && this.m_move && MainObjects.Blueprint.m_eventListenerBp.m_bShift)
        {
            //change
            var dir = new THREE.Vector3();
            dir.copy(this.m_endpos);
            dir.sub(this.m_starpos).normalize();
            var len = this.m_endpos.distanceTo(this.m_starpos);
            dir.multiplyScalar(len);
            var pos = new THREE.Vector3();
            pos.copy(evnet.object.userData.object.position);
            if(ISelector.m_transformMode == ISelector.TransformMode.e_ALIGN)
            {
                //
                var oppos = new THREE.Vector3();
                var op = evnet.object.userData.opposite;
                op.getWorldPosition(oppos);
                oppos.sub(this.m_starpos).normalize().multiplyScalar(-1);
                dir.projectOnVector(oppos);
            }
            pos.add(dir);
            this.m_undoData = MainObjects.Blueprint.m_selectListenerBp.addUndoRedoTransform([evnet.object.userData.object]);
            evnet.object.userData.object.position.set(pos.x, pos.y, pos.z);
            MainObjects.Blueprint.m_selectListenerBp.updateObject(evnet.object)
            MainObjects.Blueprint.m_selectListenerBp.addUndoRedoTransform([evnet.object.userData.object], this.m_undoData, true);            
        }
       
        MainObjects.Control.m_orbitControl.enabled = true;
        evnet.object.material.color.setHex(0xffffff);
        for (let index = 0; index < this.m_magn_sprite_list.length; index++) {
            const element = this.m_magn_sprite_list[index];
            element.visible = false;
        }
        if(this.m_currentObject != null)
        {
            this.setMagneticSprite(this.m_currentObject, true);       
            MainObjects.Blueprint.m_scaleBoxBp.setMagneticLine(this.m_currentObject); 
        }
        if(this.m_secondCurrentObject != null)
        {
            this.disposeBoundingBox([this.m_secondCurrentObject]);
        }
        this.m_line.visible = false;
    }

    close()
    {
        if(MainObjects.Control.m_dragBoxControls.enabled)
        {
            this.m_currentObject = null;
            this.m_move = false;
            this.m_line.visible = false;
            for (let index = 0; index < this.m_magn_sprite_list.length; index++) {
                const element = this.m_magn_sprite_list[index];
                element.visible = false;
            }
            for (let index = 0; index < this.m_sprite_list.length; index++) {
                const element = this.m_sprite_list[index];
                element.visible = false;
            }
            if(this.m_secondCurrentObject != null)
            {
                this.disposeBoundingBox([this.m_secondCurrentObject]);
            }
        }
    }

    computeBoundingBox(objects, visible = true)
    {
        for(var element of objects)
        {
            if(element.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE || 
                element.userData.m_gameObjectType == GameObject.GameObjectType.e_GEOMETRY_CURVE || 
                element.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY ||
                element.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY)
            {
                continue;
            }
            if(element.userData.m_boudingBoxHelper == null)
            {
                if(element.isLight || element.isCamera)
                {
                    continue;
                }
                element.userData.m_boudingBoxHelper = new BoxHelper(element);
            }
            else
            {
                if(element.isGroup)
                {
                    element.userData.m_boudingBoxHelper.update();
                }
            }
            element.userData.m_boudingBoxHelper.visible = visible;
        }
    }

    disposeBoundingBox(objects)
    {
        for(var element of objects)
        {
            if(element.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE || 
                element.userData.m_gameObjectType == GameObject.GameObjectType.e_GEOMETRY_CURVE ||
                element.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY ||
                element.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY)
            {
                continue;
            }
            if(element.userData.m_boudingBoxHelper != null)
            {
                element.userData.m_boudingBoxHelper.dispose();
                element.userData.m_boudingBoxHelper.removeFromParent();
                element.userData.m_boudingBoxHelper = null;
            }
        }
        if(this.m_helper != null)
        {
            this.m_helper.removeFromParent();
        }
    }

    selectBoundingBoxVisible(objects)
    {
        if(this.m_helper != null)
        {
            this.m_helper.removeFromParent();
        }

        if(objects.length > 0)
        {
            this.m_selectBox.setFromObject(objects[0]);
        }
        var index = 0;
        for(var element of objects)
        {
            if(index > 0)
            {
                const box = new THREE.Box3();
                box.setFromObject(element);
                this.m_selectBox.union(box);
            }

            index++;
        }
        this.m_helper.box = this.m_selectBox;
        SceneFactory.addToHelperGroup(this.m_helper);
        this.m_helper.updateMatrixWorld(true);
        // this.computeBoundingBox(objects);
    }

    setMagneticSprite(object, visible, index = 0)
    {
        if(!visible)
        {
            for(var j = 0; j < this.m_sprite_list.length; j++){
                this.m_sprite_list[j].visible = false;
            }
            this.disposeBoundingBox([object]);
            this.m_currentObject = null;
            return;
        }
        if(object.isLight || object.isCamera)
        {
            return;
        }
        if(visible)
        {
            this.computeBoundingBox([object]);
        }
        else
        {
            this.disposeBoundingBox([object]);
        }
        if(object.userData.m_boudingBoxHelper != null)
        {
            var object_list = object.userData.m_boudingBoxHelper.object_list;
            for(var j = 0; j < object_list.length; j++)
            {
                var pos = new THREE.Vector3();
                object_list[j].getWorldPosition(pos)
                this.m_sprite_list[index].position.copy(pos);
                this.m_sprite_list[index].visible = visible;
                this.m_sprite_list[index].userData.object = object;
                this.m_sprite_list[index].layers.set(0);
                var minX = object.userData.m_boudingBoxHelper.m_xLen * object.scale.x;
                var minY = object.userData.m_boudingBoxHelper.m_yLen * object.scale.y;
                var minZ = object.userData.m_boudingBoxHelper.m_zLen * object.scale.z;
                var size = Math.min(minX, minY, minZ) / 10;
                size = Math.max(size, this.m_min_size);
                this.m_sprite_list[index].scale.set(size, size, size);
 
                if(ISelector.m_transformMode == ISelector.TransformMode.e_ALIGN)
                {
                    if(j == 8)
                    {
                        this.m_sprite_list[index].userData.opposite = object_list[9];
                    }
                    else if(j == 9)
                    {
                        this.m_sprite_list[index].userData.opposite = object_list[8];
                    }
                    else if(j == 10)
                    {
                        this.m_sprite_list[index].userData.opposite = object_list[11];
                    }
                    else if(j == 11)
                    {
                        this.m_sprite_list[index].userData.opposite = object_list[10];
                    }
                    else if(j == 12)
                    {
                        this.m_sprite_list[index].userData.opposite = object_list[13];
                    }
                    else if(j == 13)
                    {
                        this.m_sprite_list[index].userData.opposite = object_list[12];
                    }
                    if(visible && (j != 10 && j != 8 && j != 12 && j != 11 && j != 9 && j != 13))
                    {
                        this.m_sprite_list[index].visible = false;
                        this.m_sprite_list[index].layers.set(10);
                    }
                }
                this.m_sprite_list[index].updateMatrixWorld(true);
                index = index + 1;
            }
            this.m_currentObject = object;
        }
    }

    setSecondMagneticSprite(object, index = 0){

        if(this.m_secondCurrentObject != null)
        {
            this.disposeBoundingBox([this.m_secondCurrentObject]);
        }
        if(object.isLight || object.isCamera)
        {
            return;
        }
        this.computeBoundingBox([object]);
        if(object.userData.m_boudingBoxHelper != null)
        {
            object.userData.m_boudingBoxHelper.updateMatrixWorld(true);
            var object_list = object.userData.m_boudingBoxHelper.object_list;
            for(var j = 0; j < object_list.length; j++)
            {
                var pos = new THREE.Vector3();
                object_list[j].getWorldPosition(pos)
                this.m_magn_sprite_list[index].position.copy(pos);
                this.m_magn_sprite_list[index].visible = true;
                this.m_magn_sprite_list[index].userData.object = object;
                object.userData.m_boudingBoxHelper.visible = true;
                var minX = object.userData.m_boudingBoxHelper.m_xLen * object.scale.x;
                var minY = object.userData.m_boudingBoxHelper.m_yLen * object.scale.y;
                var minZ = object.userData.m_boudingBoxHelper.m_zLen * object.scale.z;
                var size = Math.min(minX, minY, minZ) / 10;
                size = Math.max(size, this.m_min_size);
                this.m_magn_sprite_list[index].scale.set(size, size, size);
                this.m_magn_sprite_list[index].updateMatrixWorld(true);
                if(ISelector.m_transformMode == ISelector.TransformMode.e_ALIGN)
                {
                    if(j == 8)
                    {
                        this.m_magn_sprite_list[index].userData.opposite = object_list[9];
                    }
                    else if(j == 9)
                    {
                        this.m_magn_sprite_list[index].userData.opposite = object_list[8];
                    }
                    else if(j == 10)
                    {
                        this.m_magn_sprite_list[index].userData.opposite = object_list[11];
                    }
                    else if(j == 11)
                    {
                        this.m_magn_sprite_list[index].userData.opposite = object_list[10];
                    }
                    else if(j == 12)
                    {
                        this.m_magn_sprite_list[index].userData.opposite = object_list[13];
                    }
                    else if(j == 13)
                    {
                        this.m_magn_sprite_list[index].userData.opposite = object_list[12];
                    }
                    if((j != 10 && j != 8 && j != 12 && j != 11 && j != 9 && j != 13))
                    {
                        this.m_magn_sprite_list[index].visible = false;
                    }
                }
               
                index = index + 1;
            }
            this.m_secondCurrentObject = object;
        }
    }

    
}

export { BoundingBoxBp };