<template>
  <div>
    <div class="service_box" :class="active?'anmDiv':'aaa'">
      <div class="header">
        <div @click="shrinkChange"><i class="el-icon-arrow-down"></i></div>
        <p>相机渲染</p>
      </div>
      <div class="resolution">
        <div class="title">分辨率</div>
        <div class="txt_box">
            <div class="title">宽</div>
          <input type="number" name="" id="" placeholder="" v-model="w" @change="changeNum($event)" oninput="if(value>99999999999999999999)value=99999999999999999999;if(value<1)value=1" />
        </div>
        <div class="txt_box">
            <div class="title">高</div>
          <input
            oninput="if(value>99999999999999999999)value=99999999999999999999;if(value<1)value=1"
            type="number"
            name=""
            id=""
            placeholder=""
            v-model="h"
            @change="changeNum($event)"
          />
        </div>
      </div>
      <!-- <div class="firstHalf2">
          <p>预览</p>
          <div class="ipt_box1">
              <input class="input" type="checkbox" @change="previewChange($event)"/>
          </div>
      </div> -->
    </div>
  </div>
</template>
<script>

import { ICamera } from "../../threejs/interface/ICamera";
import { IParameter } from "../../threejs/interface/IParameter";
export default {
  name: "RenderCamera",
  data() {
    return {
      active: true,
      w: 1920,
      h: 1080,
      CameraState:false,
    };
  },
  mounted() {
  },
  methods: {
     IParameterCamera(){
        let IParameterList =  IParameter.getProperty();
        if(IParameterList != null){
            this.w = IParameterList.w;
            this.h = IParameterList.h;
        }
    },
    changeNum(){
        ICamera.setWH(Number(this.w),Number(this.h))
        // console.log(Number(this.w),Number(this.h))
    },
    shrinkChange(){
        if (this.active) {
          this.active = false
        } else {
          this.active = true;
        }
    },
  },
};
</script>
<style scoped>
.service_box {
  width: 80%;
  height: 80px;
  box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
  border-radius: 5px;
  margin-top: 20px;
  margin-left: 70px;
  font-size: 10px;
  background: #ffff;
  overflow-y: auto;
  margin-bottom: 20px;
  padding-bottom: 20px;
  overflow-x: hidden;
}
.service_box::-webkit-scrollbar {
  display: none; 
}
.service_box{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 30px;
transition: 1S;
}
.header {
  display: flex;
  width: 90%;
  height: 40px;
  margin: auto;
  align-items: center;
  color: #000;
  font-weight: bold;
  font-size: 12px;
}
.header p {
  margin-left: 10px;
  overflow: hidden;
}
.header i {
  font-weight: bold;
  cursor: pointer;
}
.resolution {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.resolution .title {
  width: 80px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: bold;
}
.resolution .txt_box {
  width: 30%;
  height: 30px;
  box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.txt_box .title{
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
}
.resolution input {
  width: 95%;
  height: 20px;
  outline: none;
  color: #808080;
  border: none;
  border-radius: 4px;
  text-align: center;
}
.ContainerRenderCamera::-webkit-scrollbar {
  display: none; 
}
.ContainerRenderCamera{
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.ContainerRenderCamera{
  width: 220px;
  height: 120px;
  /* overflow-y: auto;
  overflow-x: hidden; */
  overflow: hidden;
  position: fixed;
  left: 1px;
  top: 58px;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #000;
  border: 2px solid #0560FD;
}
.canvasBox{
  /* border: 1px solid #fff; */
  background:#000;
}
.firstHalf2{
    display: flex;
    align-items: center;
    /* margin-top: 10px; */
    /* margin-left: 30px; */
}
.firstHalf2  p{
    width: 120px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.ipt_box1{
    width: 65px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.input:checked {
	background:#1673ff
}
.input {
	width:16px;
	height:16px;
    outline: none;
	background-color:rgba(125, 128, 133, 0.4);
	border:solid 1px #dddddd;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
	outline: none;
}
.input:checked::after {
	content:'';
	top:3px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:4px;
	width:6px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
</style>