import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { IScene } from "../interface/IScene"
import { SceneFactory } from "../factory/SceneFactory"
import { GameObject } from "../object/GameObject"
import { ObjectFactory } from "../factory/ObjectFactory"
import { GeometryFactory } from "../factory/GeometryFactory"
import { Loader } from "../loader/Loader"
import { INetwork } from "../interface/INetwork"
import { GameScene } from '../object/GameScene';
import { ISetting } from '../interface/ISetting';
import { IRender } from '../interface/IRender';
import { ISelector } from '../interface/ISelector';
import { Dexie } from 'dexie'
import { MaterialObject } from '../object/MaterialObject';
import { MaterialFactory } from '../factory/MaterialFactory';
import { ICollisions } from '../interface/ICollisions';

class SceneManagerBp {
    constructor() {
        MainObjects.Scene.m_ambientLight = new THREE.AmbientLight(0x404040); // soft white light
        MainObjects.Scene.m_ambientLight.userData = null;
        MainObjects.Scene.m_hemisphereLight = new THREE.HemisphereLight(0x404040, 0x080820, 1);
        MainObjects.Scene.m_hemisphereLight.userData = null;
        this.activeAmbientLight(true);
        this.m_gameScene = new GameScene();

        this.m_db = new Dexie("SceneManager");
        this.m_db.version(2).stores({
            scene: "id, value",
            copyData: "id, value",
        });
        
    }

    activeAmbientLight(b) {
        if (b) {
            MainObjects.Scene.m_userGroup.add(MainObjects.Scene.m_ambientLight)
        }
        else {
            MainObjects.Scene.m_ambientLight.removeFromParent();
        }
    }

    activeHemisphere(b) {
        if (b) {
            MainObjects.Scene.m_userGroup.add(MainObjects.Scene.m_hemisphereLight);
        }
        else {
            MainObjects.Scene.m_hemisphereLight.removeFromParent();
        }
    }

    setEnvLightMode(mode) {
        switch (mode) {
            case ISetting.EnvLightMode.e_AMBIENT:
                MainObjects.Blueprint.m_sceneManagerBp.activeAmbientLight(true);
                MainObjects.Blueprint.m_sceneManagerBp.activeHemisphere(false);
                MainObjects.Setting.m_envLightMode = mode;
                break;
            case ISetting.EnvLightMode.e_HEMISPHERE:
                MainObjects.Blueprint.m_sceneManagerBp.activeAmbientLight(false);
                MainObjects.Blueprint.m_sceneManagerBp.activeHemisphere(true);
                MainObjects.Setting.m_envLightMode = mode;
                break;
            case ISetting.EnvLightMode.e_NONE:
                MainObjects.Blueprint.m_sceneManagerBp.activeAmbientLight(false);
                MainObjects.Blueprint.m_sceneManagerBp.activeHemisphere(false);
                MainObjects.Setting.m_envLightMode = mode;
                break;
            default:
                break;
        }
    }

    changeMaterialMode(b) {
        MainObjects.Scene.m_userGroup.traverse(function (child) {
            if (child.isMesh) {
                child.material.wireframe = b;
            }
        });
        if (b) {
            MainObjects.Setting.m_renderMode = IRender.RenderMode.e_WIRE;
        }
        else {
            MainObjects.Setting.m_renderMode = IRender.RenderMode.e_SOLID;
        }
    }

    onKeyUp(even) {
        switch (even.keyCode) {
            case 79:  //O
                {
                    if (MainObjects.Setting.m_renderMode == IRender.RenderMode.e_SOLID) {
                        this.changeMaterialMode(true);
                    }
                    else {
                        this.changeMaterialMode(false);
                    }
                    if (IRender.f_renderModeCallback != null) {
                        IRender.f_renderModeCallback(MainObjects.Setting.m_renderMode);
                    }
                }
                break;
        }
    }

    // GROUP
    //   GLTF
    //     GLTF_MESH
    //   MESH
    //   LIGHT
    getTree(object) {
        var list = new Array();
        for (var i = 0; i < object.children.length; i++) {
            var iterator =  object.children[i];
            if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {
                var data = iterator.userData.getTreeData(iterator);
                list.push(data);
                if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP 
                    || iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY) {
                    var chird = this.getTree(iterator);
                    if (chird != null) {
                        data.children = chird;
                    }
                }
            }
        }
        if (list.length > 0) {
            return list;
        }
        else {
            return null;
        }
    }

    getLightTree(object)
    {
        var list = new Array();
        for (var i = 0; i < object.children.length; i++) {
            var iterator =  object.children[i];
            if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {
                var data = iterator.userData.getTreeData(iterator);
                if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
                    var chird = this.getTree(iterator);
                    var b = false;
                    if (chird != null) {
                        data.children = [];
                        for(var j = 0; j < chird.length; j++)
                        {
                            if(chird[j].object.isLight)
                            {
                                data.children.push(chird[j]);
                                b = true;
                            }
                        }
                        if(b)
                        {
                            list.push(data);
                        }
                    }
                }
                else if(data.object.isLight)
                {
                    list.push(data);
                }
            }
        }
        if (list.length > 0) {
            return list;
        }
        else {
            return null;
        }
    }

    getCameraTree(object)
    {
        var list = new Array();
        for (var i = 0; i < object.children.length; i++) {
            var iterator =  object.children[i];
            if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {
                var data = iterator.userData.getTreeData(iterator);
                if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
                    var chird = this.getTree(iterator);
                    var b = false;
                    if (chird != null) {
                        data.children = [];
                        for(var j = 0; j < chird.length; j++)
                        {
                            if(chird[j].object.isCamera)
                            {
                                data.children.push(chird[j]);
                                b = true;
                            }
                        }
                        if(b)
                        {
                            list.push(data);
                        }
                    }
                }
                else if(data.object.isCamera)
                {
                    list.push(data);
                }
            }
        }
        if (list.length > 0) {
            return list;
        }
        else {
            return null;
        }
    }

    getSelectTree()
    {
        var list = new Array();
        var _this = this;
        var rootList = new Array();
        MainObjects.Blueprint.m_selectorPropertyBp.getSelectObjects(function(iterator){

            var root = MainObjects.Blueprint.m_selectListenerBp.getAlwaysGltfObject(iterator);
            rootList.push(root);
        });
        rootList.forEach(iterator => {
            if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {
                var data = iterator.userData.getTreeData(iterator);
                list.push(data);
                if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
                    var chird = _this.getTree(iterator);
                    if (chird != null) {
                        data.children = chird;
                    }
                }
            }
        });
        if (list.length > 0) {
            return list;
        }
        else {
            return null;
        }

    }

    getCollisions(object){
        var list = new Array();
        for (var i = 0; i < object.children.length; i++) {
            var iterator =  object.children[i];
            if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {
                var data = iterator.userData.getTreeData(iterator);
                if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
                    var chird = this.getTree(iterator);
                    var b = false;
                    if (chird != null) {
                        data.children = [];
                        for(var j = 0; j < chird.length; j++)
                        {
                            if(chird[j].object.userData.m_collisionsMesh)
                            {
                                data.children.push(chird[j]);
                                b = true;
                            }
                        }
                        if(data.object.userData.m_collisionsMesh)
                        {
                            b = true;
                        }
                        if(b)
                        {
                            list.push(data);
                        }
                    }
                }
                else 
                {
                    if(data.object.userData.m_collisionsMesh)
                    {
                        list.push(data);
                    }
                }
            }
        }
        if (list.length > 0) {
            return list;
        }
        else {
            return null;
        }
    }

    getParticle(object){
        var list = new Array();
        for (var i = 0; i < object.children.length; i++) {
            var iterator =  object.children[i];
            if(iterator.userData != null && iterator.userData.m_gameObjectType != GameObject.GameObjectType.e_SELECT_HELPER_BOX)
            {
                var data = iterator.userData.getTreeData(iterator);
                if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
                    var chird = this.getTree(iterator);
                    var b = false;
                    if (chird != null) {
                        data.children = [];
                        for(var j = 0; j < chird.length; j++)
                        {
                            if(chird[j].object.userData.m_gameObjectType == GameObject.GameObjectType.e_PARTICLE_SYS)
                            {
                                data.children.push(chird[j]);
                                b = true;
                            }
                        }
                        if(data.object.userData.m_gameObjectType == GameObject.GameObjectType.e_PARTICLE_SYS)
                        {
                            b = true;
                        }
                        if(b)
                        {
                            list.push(data);
                        }
                    }
                }
                else 
                {
                    if(data.object.userData.m_gameObjectType == GameObject.GameObjectType.e_PARTICLE_SYS)
                    {
                        list.push(data);
                    }
                }
            }
        }
        if (list.length > 0) {
            return list;
        }
        else {
            return null;
        }
    }

    getTypeObjects(type)
    {
        var list = new Array();

        MainObjects.Scene.m_userGroup.traverse(function (child) {
            if(child != null && child.userData != null)
            {
                if(child.userData.m_gameObjectType != null && child.userData.m_gameObjectType != undefined && child.userData.m_gameObjectType == type)
                {
                    list.push(child);
                }
            }

        });
        return list;
    }

    getObjectJsonInfo(iterator) {
        var data = iterator.userData.getObjectJson(iterator);
        data.userData = iterator.userData.getGameObjectJson();
        var lightData = iterator.userData.getLightJson(iterator);
        if(lightData != null)
        {
            data.lightData = lightData;
        }
        var cameraData = iterator.userData.getCameraJson(iterator);
        if(cameraData != null)
        {
            data.cameraData = cameraData;
        }
        if(iterator.userData.m_collisionsType != ICollisions.CollisionsType.e_NONE)
        {
            data.collisionsData = MainObjects.Blueprint.m_collisionsManagerBp.getCollisionsData(iterator);
        }
        if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
            var list = new Array();
            for (const child of iterator.children) {
                if(child.userData != null)
                {
                    var childData = this.getObjectJsonInfo(child);
                    list.push(childData);
                }
            }
            data.children = list;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF)
        {
            data.materials = new Array();
            data.objectsJsons = new Array();
            for (let index = 0; index < iterator.userData.m_gltf_meshList.length; index++) {
                const element = iterator.userData.m_gltf_meshList[index];
                const json = element.userData.getObjectJson(element);
                json.save_name = element.userData.m_name;
                data.objectsJsons.push(json);
                var mat = MainObjects.Blueprint.m_materialEditorBp.getMaterialJson(element);
                data.materials.push(mat);
            }
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE)
        {
            var list = new Array();
            for (let index = 0; index < iterator.userData.m_gltf_meshList.length; index++) {
                const child = iterator.userData.m_gltf_meshList[index];
                var childData = this.getObjectJsonInfo(child);
                list.push(childData);
            }
            data.children = list;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY)
        {
            data.skyData = MainObjects.Blueprint.m_skyBp.getSkyEffectController(iterator);
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY)
        {
            data.realTimeSkyData = MainObjects.Blueprint.m_skyBp.getRealTimeSkyEffectController(iterator);
            var list = new Array();
            for (const child of iterator.children) {
                if(child.userData != null)
                {
                    var childData = this.getObjectJsonInfo(child);
                    list.push(childData);
                }
            }
            data.children = list;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_TERRAIN_SYS)
        {
            data.terrainData = iterator.userData.m_terrain_data.getJson();
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_WATER_BASE)
        {
            data.waterData = iterator.userData.m_water_data.getJson();
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_LENS_FLARE)
        {
            data.lensFlareData = MainObjects.Blueprint.m_lensFlareBp.getLensFlareProp(iterator);
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_AUDIO)
        {
            data.audioData = MainObjects.Blueprint.m_audioListenerBp.getAudioProperty(iterator);
        }
        return data;
    }

    getObjectFromJson(iterator, callback = null, bCopy = false, manager = null, scene = null) {
        var object = null;
        var needCall = false;
        if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
            object = ObjectFactory.createGroup();
            needCall = true;
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_DIR_LIGHT) {
            object = ObjectFactory.createDirLight(scene);
            object.userData.setLightJson(object, iterator.lightData);
            needCall = true;
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_POINT_LIGHT) {
            object = ObjectFactory.createPointLight(scene);
            object.userData.setLightJson(object, iterator.lightData);
            needCall = true;
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_SPOT_LIGHT) {
            object = ObjectFactory.createSpotLight(scene);
            object.userData.setLightJson(object, iterator.lightData);
            needCall = true;
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_AREA_LIGHT) {
            object = ObjectFactory.createRectAreaLight(scene);
            object.userData.setLightJson(object, iterator.lightData);
            needCall = true;
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_PERSP) {
            object = ObjectFactory.creatPerspectiveCamera(scene);
            object.userData.setCameraJson(object, iterator.cameraData);
            needCall = true;
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_ORTHO) {
            object = ObjectFactory.creatOrthographicCamera(scene);
            object.userData.setCameraJson(object, iterator.cameraData);
            needCall = true;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CAMERA_EQUIRECT)
        {
            object = ObjectFactory.createEquirectangularCamera(scene);
            object.userData.setCameraJson(object, iterator.cameraData);
            needCall = true;
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF) {
            var _this = this;
            var url = INetwork.getUrl(iterator.userData.m_url);
         

            Loader.loadFileToScene(url, iterator.userData.m_url, iterator.userData.m_id, function (p) {
                if (bCopy) {
                    if (IScene.f_copyProcessCallback != null) {
                        IScene.f_copyProcessCallback(p);
                    }
                }
            }, function (res) {
                object = res;
                _this.setObjectCallback(object, iterator, bCopy, callback, manager, scene);
            }, manager, scene);
            needCall = false;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE)
        {
            object = GeometryFactory.createCurve(iterator.children.length, iterator.userData.m_arcSegments);
            needCall = true;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_SKY)
        {
            object = GeometryFactory.createSky();
            MainObjects.Blueprint.m_skyBp.initSky(object, iterator.skyData);
            needCall = true;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_REAL_TIME_SKY)
        {
            object = GeometryFactory.createRealTimeSky();
            MainObjects.Blueprint.m_skyBp.initRealTimeSky(object, iterator.realTimeSkyData);
            
            if (iterator.children != undefined && iterator.children.length > 0) {
                for (const child of iterator.children) {
                    if(child.userData != null)
                    {
                        if (bCopy) {
                            child.parentName = object.userData.m_gameObjectHelper.name;
                        }
                        object.userData.m_gameObjectHelper.userData.setLightJson(object.userData.m_gameObjectHelper, child.lightData);
                    }
                }
            }
            needCall = true;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_PARTICLE_SYS)
        {
            var _this = this;
            INetwork.downloadJson(iterator.userData.m_url, function(data){
                Loader.loadParticleSystemToScene(iterator.userData.m_url, iterator.userData.m_id, iterator.userData.m_name, data, function(p){
                    if (bCopy) {
                        if (IScene.f_copyProcessCallback != null) {
                            IScene.f_copyProcessCallback(p);
                        }
                    }
                }, function(res){
                    object = res;
                    _this.setObjectCallback(object, iterator, bCopy, callback, manager, scene);
                    setTimeout(function(){
                        if(IScene.f_sceneTreeCallback != null)
                        {
                            IScene.f_sceneTreeCallback();
                        }
                    }, 100);
                });
            });
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_LENS_FLARE)
        {
            object = GeometryFactory.createLensFlare();
            MainObjects.Blueprint.m_lensFlareBp.setLensFlareProp(object, iterator.lensFlareData);
            needCall = true;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_AUDIO)
        {
           
            var _this = this;
            var url = INetwork.getUrl(iterator.userData.m_url);
 
            Loader.loadFileToScene(url, iterator.userData.m_url, iterator.userData.m_id, function (p) {
                if (bCopy) {
                    if (IScene.f_copyProcessCallback != null) {
                        IScene.f_copyProcessCallback(p);
                    }
                }
            }, function (res) {
                object = res;
                MainObjects.Blueprint.m_audioListenerBp.setAudioProperty(object, iterator.audioData);
                _this.setObjectCallback(object, iterator, bCopy, callback, manager, scene);
                setTimeout(function(){
                    if(IScene.f_sceneTreeCallback != null)
                    {
                        IScene.f_sceneTreeCallback();
                    }
                }, 100);
            }, manager, scene);
            needCall = false;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_TERRAIN_SYS)
        {
            var _this = this;
            MainObjects.Blueprint.m_terrainBp.createTerrain(iterator.terrainData, function (p) {
                if (bCopy) {
                    if (IScene.f_copyProcessCallback != null) {
                        IScene.f_copyProcessCallback(p);
                    }
                }
            }, function(res){
                object = res;
                _this.setObjectCallback(object, iterator, bCopy, callback, manager, scene);
                MainObjects.Blueprint.m_collisionsManagerBp.initOneCollisions(object, iterator.collisionsData);
                setTimeout(function(){
                    if(IScene.f_sceneTreeCallback != null)
                    {
                        IScene.f_sceneTreeCallback();
                    }
                }, 100);
            });
            needCall = false;
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_WATER_BASE)
        {
            object = GeometryFactory.createBaseWater();
            MainObjects.Blueprint.m_waterBp.setProperty(object, iterator.waterData);
            needCall = true;
        }
        if( needCall )
        {
            this.setObjectCallback(object, iterator, bCopy, callback, manager, scene);
        }

    }

    setObjectCallback(object, iterator, bCopy, callback, manager = null, scene = null) {
        if (object == null) {
            return null;
        }
        if (!bCopy) {
            object.name = iterator.name;
        }
        object.castShadow = iterator.castShadow;
        object.receiveShadow = iterator.receiveShadow;
        object.renderOrder = iterator.renderOrder;
        object.position.fromArray(iterator.position);
        object.rotation.fromArray(iterator.rotation);
        object.scale.fromArray(iterator.scale);
        object.userData.setObjectJson(object, iterator);
        object.userData.setGameObjectJson(iterator.userData, object);

        if(object.userData.m_gameObjectType != GameObject.GameObjectType.e_GEOMETRY_CURVE)
        {
            if(scene == null)
            {
                const partent = MainObjects.Scene.m_userGroup.getObjectByName(iterator.parentName);
                if (partent == null || partent == undefined) {
                    SceneFactory.addToUserGroup(object);
                }
                else {
                    partent.add(object);
                    MainObjects.Blueprint.m_sceneManagerBp.m_gameScene.getAllSelectObjects(object);
                }
            }
            else
            {
                const partent = scene.getObjectByName(iterator.parentName);
                if (partent == null || partent == undefined) {
                    scene.add(object);
                }
                else {
                    partent.add(object);
                }
            }
        }
  
        if(object.isLight)
        {
            if(!object.userData.m_gameObjectHelper.visible){
                MainObjects.Blueprint.m_selectorPropertyBp.setObjectVisible(object, false)
            }
        }
        else
        {
            if(!iterator.visible)
            {
                MainObjects.Blueprint.m_selectorPropertyBp.setObjectVisible(object, false)
            }
        }

        if(iterator.collisionsData != undefined)
        {
            if(object.userData.m_gameObjectType != GameObject.GameObjectType.e_TERRAIN_SYS)
            {
                MainObjects.Blueprint.m_collisionsManagerBp.m_needColliderList.add(object, iterator.collisionsData);
            }
        }

        if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GROUP) {
            if (iterator.children != undefined && iterator.children.length > 0) {
                for (const child of iterator.children) {
                    if(child.userData != null)
                    {
                        if (bCopy) {
                            child.parentName = object.name;
                        }

                        this.getObjectFromJson(child, null, bCopy, manager, scene);
                    }
                }
            }
        }
        else if (iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_GLTF) {
            for (let index = 0; index < iterator.materials.length; index++) {
                const element = iterator.materials[index];
                var child = object.getObjectByName(element.objectName);
                if(child != null || child != undefined)
                {
                    if(element.edType != undefined && element.edType == MaterialObject.MaterialEditorType.e_NET)
                    {
                        var material = null;
                        if(element.type == MaterialObject.MaterialType.e_BASIC)
                        {
                            material = MaterialFactory.createBasicMaterial(null, MaterialObject.MaterialEditorType.e_NET, element.url, element.name, element.id);
                        }
                        else if(element.type == MaterialObject.MaterialType.e_STANDARD)
                        {
                            material = MaterialFactory.createStandardMaterial(null, MaterialObject.MaterialEditorType.e_NET, element.url, element.name, element.id);
                        }
                        else if(element.type == MaterialObject.MaterialType.e_PHONE)
                        {
                            material = MaterialFactory.createPhoneMaterial(null, MaterialObject.MaterialEditorType.e_NET, element.url, element.name, element.id);
                        }
                        if(material != null)
                        {
                            child.material = material;
                        }
                        Loader.loadMaterialJson(INetwork.getUrl(element.name), child, element, manager);
                    }
                    else
                    {
                        MainObjects.Blueprint.m_materialEditorBp.getMaterialFromJson(child, element, manager);
                    }
      
                  
                }
            }
            for (let index = 0; index < iterator.objectsJsons.length; index++) {
                const element = iterator.objectsJsons[index];
                var child = object.getObjectByName(element.name);
                if(child != null || child != undefined)
                {
                    child.userData.setObjectJson(child, element);
                    child.userData.m_name = element.save_name
                }
            }
            if(object.userData.m_skeleton != null)
            {       
                MainObjects.Blueprint.m_animationManagerBp.m_skinList.push(object);
            }
        }
        else if(iterator.userData.m_gameObjectType == GameObject.GameObjectType.e_CURVE)
        {
            var index = 0;
            for (const child of iterator.children) {
                if (bCopy) {
                    child.parentName = object.name;
                }
                this.setObjectCallback(object.children[index], child, bCopy, null, null, null);
                index++;
            }
            MainObjects.Blueprint.m_curveEditorBp.initCurve(object);
        }
        MainObjects.Blueprint.m_selectListenerBp.updateObject(object);
        if (object.isLight)
        {
            object.userData.m_gameObjectHelper.update();
        }
        else if(object.isCamera)
        {
            if (bCopy) {
                object.userData.m_mainCamera = false;
            }
            object.userData.m_gameObjectHelper.update();
        }
     
        if (callback != null) {
            callback(object);
        }
    }

    addTo(parent, child) {
        if (parent == null) {
            if(MainObjects.Scene.m_userGroup.id != child.parent.id)
            {
                MainObjects.Scene.m_userGroup.attach(child);
            } 
        }
        else {
            if(parent.id != child.parent.id)
            {
                parent.attach(child);
            }
        }
    }
    
    addUndoRedoAddTo(objectParent, object, undoData = null, end = false) {
        var _this = this;
        var parentName;
        var parentId;
        if (objectParent == null) {
            parentName = null;
        }
        else {
            parentName = objectParent.name;
            parentId = objectParent.id;
        }
        var _data = {
            parentId: parentId,
            parentName: parentName,
            objectName: object.name,
            objectId: object.id
        };
        if (end) {
            MainObjects.Blueprint.m_historyListenerBp.addUndoRedo(undoData, _data, function (data) {
                var parent;
                if (data.parentName == null) {
                    parent = null;
                }
                else {
                    parent = MainObjects.Scene.m_userGroup.getObjectById(data.parentId);
                    if(parent.id == MainObjects.Scene.m_userGroup.id)
                    {
                        parent = null;
                    }
                }
                const object = MainObjects.Scene.m_userGroup.getObjectById(data.objectId);
                _this.addTo(parent, object);
                if(IScene.f_sceneTreeCallback != null){
                    IScene.f_sceneTreeCallback();
                }
            });
        }
        return _data;
    }

}

export { SceneManagerBp };