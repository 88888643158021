import * as THREE from 'three';
import { MainObjects } from "../common/MainObjects"
import { MaterialObject } from "../object/MaterialObject"
import { IMaterial } from './IMaterial';
import { ITransform } from './ITransform';

class IMaterialPreview {

    static f_updateMaterialCallback = null;
    static f_getMaterialCallback = null;

    static getMaterial() {

        var firstObj = MainObjects.MainRender.m_previewResouse.sphere;

        if (firstObj.isMesh || firstObj.isSkinnedMesh) {
            if(firstObj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_BASIC)
            {
                return IMaterial.getBaseMatData(firstObj);
            }
            else if(firstObj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_STANDARD)
            {
                return IMaterial.getStandMatData(firstObj);
            }
            else if(firstObj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_PHONE)
            {
                return IMaterial.getPhoneMatData(firstObj);
            }
        }
        return null;
    }

    static setBasicMaterial(data) {
        if(MainObjects.MainRender.m_previewResouse != null)
        {
            var obj = MainObjects.MainRender.m_previewResouse.sphere;
            IMaterialPreview.setBasicMaterialData(data, obj, true);
            MainObjects.MainRender.m_previewResouse.loadFinish([MainObjects.MainRender.m_previewResouse.sphere], 1.5, function(){
       
            });
        }
    }

    static setBasicMaterialData(data, obj) {
        if(obj.material.userData.m_materialObjectType != undefined && obj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_BASIC)
        {
            var obj = MainObjects.MainRender.m_previewResouse.sphere;
            obj.material.fog = data.fog;
            obj.material.opacity = data.opacity;
            obj.material.side = data.side;
            obj.material.transparent = data.transparent;
            obj.material.aoMapIntensity = data.aoMapIntensity;
            obj.material.color.fromArray(data.color);
            obj.material.lightMapIntensity = data.lightMapIntensity;
            obj.material.refractionRatio = data.refractionRatio;
  
            obj.material.needsUpdate = true;
        }
    } 

    static setPhoneMaterial(data) {
        if(MainObjects.MainRender.m_previewResouse != null)
        {
            var obj = MainObjects.MainRender.m_previewResouse.sphere;
            IMaterialPreview.setPhoneMaterialData(data, obj, true);
            MainObjects.MainRender.m_previewResouse.loadFinish([MainObjects.MainRender.m_previewResouse.sphere], 1.5, function(){
       
            });
        }
    }

    static setPhoneMaterialData(data, obj) {
        if(obj.material.userData.m_materialObjectType != undefined && obj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_PHONE)
        {
            obj.material.fog = data.fog;
            obj.material.opacity = data.opacity;
            obj.material.side = data.side;
            obj.material.transparent = data.transparent;
            obj.material.aoMapIntensity = data.aoMapIntensity;
            obj.material.color.fromArray(data.color);
            obj.material.specular.fromArray(data.specular);
            obj.material.lightMapIntensity = data.lightMapIntensity;

            obj.material.refractionRatio = data.refractionRatio;
            obj.material.shininess = data.shininess;
            obj.material.reflectivity = data.reflectivity;
            
            obj.material.emissive.fromArray(data.emissive);
            obj.material.emissiveIntensity = data.emissiveIntensity;

            obj.material.needsUpdate = true;
        }
    }

    static setStandardMaterial(data) {
        if(MainObjects.MainRender.m_previewResouse != null)
        {
            var obj = MainObjects.MainRender.m_previewResouse.sphere;
            IMaterialPreview.setStandardMaterialData(data, obj, true);
            MainObjects.MainRender.m_previewResouse.loadFinish([MainObjects.MainRender.m_previewResouse.sphere], 1.5, function(){
       
            });
        }
    }

    
    static setStandardMaterialData(data, obj) {
        if(obj.material.userData.m_materialObjectType != undefined && obj.material.userData.m_materialObjectType == MaterialObject.MaterialType.e_STANDARD)
        {
            obj.material.userData.setMaterialMatType(data.matType);
            obj.material.userData.setMaterialMat(data.materialMat);
            obj.material.fog = data.fog;
            obj.material.opacity = data.opacity;
            obj.material.side = data.side;
            obj.material.transparent = data.transparent;
            obj.material.aoMapIntensity = data.aoMapIntensity;
            obj.material.color.fromArray(data.color);
            obj.material.lightMapIntensity = data.lightMapIntensity;
            obj.material.emissive.fromArray(data.emissive);
            obj.material.emissiveIntensity = data.emissiveIntensity;
            obj.material.envMapIntensity = data.envMapIntensity;
            obj.material.metalness = data.metalness;
            obj.material.roughness = data.roughness;
            obj.material.needsUpdate = true;
        }
    }

    static setMap(texture, type) {
        if(MainObjects.MainRender.m_previewResouse != null)
        {
            var obj = MainObjects.MainRender.m_previewResouse.sphere;
            IMaterialPreview.setMapData(texture, type, obj);
            MainObjects.MainRender.m_previewResouse.loadFinish([MainObjects.MainRender.m_previewResouse.sphere], 1.5, function(){
       
            });
            if (IMaterialPreview.f_updateMaterialCallback != null) {
                IMaterialPreview.f_updateMaterialCallback(true);
            }
        }

    }

    static setMapData(texture, type, obj) {
        var obj = MainObjects.MainRender.m_previewResouse.sphere;
        switch (type) {
            case "specularMap":
                obj.material.specularMap = texture;
                break;
            case "emissiveMap":
                obj.material.emissiveMap = texture;
                if(texture != null)
                {
                    obj.material.emissiveMap.encoding = THREE.sRGBEncoding;
                }
                break;
            case "metalnessMap":
                obj.material.metalnessMap = texture;
                break;
            case "normalMap":
                obj.material.normalMap = texture;
                break;
            case "roughnessMap":
                obj.material.roughnessMap = texture;
                break;
            case "aoMap":
                obj.material.aoMap = texture;
                break;
            case "envMap":
                obj.material.envMap = texture;
                break;
            case "lightMap":
                obj.material.lightMap = texture;
                break;
            case "map":
                obj.material.map = texture;
                if(texture != null)
                {
                    obj.material.map.userData.m_gamma = 2.2;
                    obj.material.map.encoding = THREE.sRGBEncoding;
                }
               
                break;
            default:
                break;
        }
        obj.material.needsUpdate = true;
    }

    static setTextureUv(data, type, mode)
    {
        // var data = {
        //     offset: [0, 0],
        //     repeat: [1, 1],
        //     rotation: 0
        // }
        if(MainObjects.MainRender.m_previewResouse != null)
        {
            var obj = MainObjects.MainRender.m_previewResouse.sphere;
            IMaterialPreview.setTextureUvData(data, type, mode, obj);
            MainObjects.MainRender.m_previewResouse.loadFinish([MainObjects.MainRender.m_previewResouse.sphere], 1.5, function(){
       
            });
            if (IMaterialPreview.f_updateMaterialCallback != null) {
                IMaterialPreview.f_updateMaterialCallback(true);
            }
        }
       
    }

    static setTextureUvData(data, type, mode, obj)
    {
        data.offset  = ITransform.mmTomArray(data.offset);
        data.rotation = ITransform.angleToRad(data.rotation);
        var obj = MainObjects.MainRender.m_previewResouse.sphere;
        switch (type) {
            case "specularMap":
                if(mode == 0)
                {  
                    obj.material.specularMap.repeat.set(data.repeat[0], data.repeat[1]);
                }
                else if(mode == 1)
                {
                    obj.material.specularMap.offset.set(data.offset[0], data.offset[1]);
                }
                else if(mode == 2)
                {
                    obj.material.specularMap.rotation = data.rotation;
                }
                else if(mode == 3)
                {
                    obj.material.specularMap.userData.m_gamma = data.gamma;
                } 
                obj.material.specularMap.updateMatrix();
                break;
            case "emissiveMap":
                if(mode == 0)
                {  
                    obj.material.emissiveMap.repeat.set(data.repeat[0], data.repeat[1]);
                }
                else if(mode == 1)
                {
                    obj.material.emissiveMap.offset.set(data.offset[0], data.offset[1]);
                }
                else if(mode == 2)
                {
                    obj.material.emissiveMap.rotation = data.rotation;
                }
                else if(mode == 3)
                {
                    obj.material.emissiveMap.userData.m_gamma = data.gamma;
                } 
                obj.material.emissiveMap.updateMatrix();
                break;
            case "metalnessMap":
                if(mode == 0)
                {
                    obj.material.metalnessMap.repeat.set(data.repeat[0], data.repeat[1]);
                }
                else if(mode == 1)
                {
                    obj.material.metalnessMap.offset.set(data.offset[0], data.offset[1]);
                }
                else if(mode == 2)
                {
                    obj.material.metalnessMap.rotation = data.rotation;
                }
                else if(mode == 3)
                {
                    obj.material.metalnessMap.userData.m_gamma = data.gamma;
                } 
                obj.material.metalnessMap.updateMatrix();
                break;
            case "normalMap":
                if(mode == 0)
                {  
                    obj.material.normalMap.repeat.set(data.repeat[0], data.repeat[1]);
                }
                else if(mode == 1)
                {
                    obj.material.normalMap.offset.set(data.offset[0], data.offset[1]);
                }
                else if(mode == 2)
                {
                    obj.material.normalMap.rotation = data.rotation;
                } 
                else if(mode == 3)
                {
                    obj.material.normalMap.userData.m_gamma = data.gamma;
                } 
                obj.material.normalMap.updateMatrix();
                break;
            case "roughnessMap":
                if(mode == 0)
                {
                    obj.material.roughnessMap.repeat.set(data.repeat[0], data.repeat[1]);
                }
                else if(mode == 1)
                {
                    obj.material.roughnessMap.offset.set(data.offset[0], data.offset[1]);
                }
                else if(mode == 2)
                {
                    obj.material.roughnessMap.rotation = data.rotation;
                }  
                else if(mode == 3)
                {
                    obj.material.roughnessMap.userData.m_gamma = data.gamma;
                }
                obj.material.roughnessMap.updateMatrix();
                break;
            case "map":
                if(mode == 0)
                {
                    obj.material.map.repeat.set(data.repeat[0], data.repeat[1]);
                }
                else if(mode == 1)
                {
                    obj.material.map.offset.set(data.offset[0], data.offset[1]);
                }
                else if(mode == 2)
                {
                    obj.material.map.rotation = data.rotation;
                }    
                else if(mode == 3)
                {
                    obj.material.map.userData.m_gamma = data.gamma;
                }
                obj.material.map.updateMatrix();
                break;
            default:
                break;
        }
        obj.material.needsUpdate = true;
    }
}

export { IMaterialPreview };