import { IImage } from "../interface/IRes"

class MainObjects {
    static Camera =
        {
            m_frustumSize: 1,
            m_renderCamera: null,
            m_mainPerspectiveCamera: null,
            m_mainOrthographicCamera: null,
        };

    static Scene =
        {
            m_renderScene: null,
            m_userGroup: null,
            m_helperGroup: null,
            m_helperGride: null,
            m_viewHelper: null,
            m_ambientLight: null,
            m_hemisphereLight: null,
            m_listener : null,
            m_fogExp : null,
        };

    static Render =
        {
            m_container: null,
            m_stats : null,
            m_renderer: null,
            m_renderPass: null,
            m_composer: null,
            m_outlinePass: null
        };

    static Control =
        {
            m_dragControls : null,
            m_dragBoxControls : null,
            m_orbitControl: null,
            m_transformControl: null,
            m_isInput : false
        };

    static MainRender =
        {
            m_this: null,
            m_previewResouse: null,
            m_previewCamera: null,
        };

    static Blueprint =
        {
            m_eventListenerBp: null,
            m_selectListenerBp: null,
            m_cameraListenerBp: null,
            m_selectorPropertyBp: null,
            m_sceneManagerBp: null,
            m_materialEditorBp: null,
            m_historyListenerBp: null,
            m_animationManagerBp : null,
            m_renderManagerBp : null,
            m_runtimeJsManagerBp : null,
            m_boundingBoxBp : null,
            m_curveEditorBp : null,
            m_collisionsManagerBp : null,
            m_scaleBoxBp : null,
            m_audioListenerBp : null,
            m_skyBp : null,
            m_lensFlareBp : null,
            m_particleSystemBp : null,
            m_grassBp: null,
            m_terrainBp: null,
            m_waterBp: null,
            m_behaviorBp: null
        }

    static Network =
        {
            m_bucketName: "yidascenespace",
            // 测试 dev 
            m_folderName: "dev/",
            // 正式 prd
            // m_folderName: "prd/",
            m_resName: "res/",
            m_previewFloder: "previewFloder",
            m_projectId: "tempProject",
            m_userPhone: "tempUser",
            //这个正式不用改
            // m_http: "http://yidascenespace.oss-cn-hangzhou.aliyuncs.com/",
            m_http: "https://file.yicreator.com/",
        }

    static Setting =
        {
            m_use_envMap : true,
            m_envMap : IImage.hdr,
            m_envMapImage : IImage.hdr_image,
            m_envLightMode: 0,
            m_ip : "127.0.0.1",
            m_port : 1618,
            m_total : 15,
            m_diffuse : 15,
            m_glossy : 15,
            m_specular : 15,
            m_haltspp : 150,
            m_env_rot : 0,
            m_env_gain : 1,
            m_env_image : "1347_v5",
            m_renderMode : 0,
            m_turnStats : true,
            m_grideVisible : true,
            m_exposure : 1,
            m_turnPhysicsHelper : true,
            m_turnFog : false,
        }

    static MathValue = {
        m_x : null,
        m_y : null,
        m_z : null
    }

    static Physics = {
        AmmoPhysics : null, 
        PhysicsLoader : null, 
    }
}

export { MainObjects };