class TextureObject {
    static TextureObjectType =
        {
            e_DEFLUT_MAP: 0,
            e_NET_MAP: 1,
        };
    constructor() {
        this.m_name = "-";
        this.m_id = "";
        this.m_url = "";
        this.m_texture = null;
        this.m_textureObjectType = TextureObject.TextureObjectType.e_DEFLUT_MAP;
        this.m_gamma = 1;
    }
}

export { TextureObject };