<template>
    <div>
        <div class="box" :class="isActive?'anmDiv':'aaa'">
           <div class="header">
               <div @click="shrinkChange"><i class="el-icon-arrow-down"></i> </div>
               <p>地形碰撞器</p>
           </div>
            <div class="filter">
               <div class="title">选择模型</div>
               <select class="select">
                   <option value="0">模型名称</option>
               </select>
           </div>
           <div class="shadow">
               <div class="title">启用树碰撞器</div>
               <div class="ipt_box">
                   <input class="input" type="checkbox" />
               </div>
           </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"Terrain",
    data(){
        return{
            isActive:true,
            value:50,
        }
    },
    methods:{
        shrinkChange(){
            if (this.isActive) {
              this.isActive = false
            } else {
              this.isActive = true;
            }
        }
    }
}
</script>
<style scoped>
.box{
    width: 80%;
    height: 150px;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 5px;
    margin-top:8px;
    margin-left: 70px;
    font-size: 10px;
    background: #fff;
    overflow: hidden;
}
.anmDiv{
transition: 1S;
 
}
.aaa{
height: 40px;
transition: 1S;
}
.header{
    display: flex;
    width: 90%;
    height: 40px;
    margin: auto;
    align-items: center;
    color: #000;
    font-weight: bold;
    font-size: 12px;
}
.header p{
    margin-left: 10px;
}
.header i{
    font-weight: bold;
    cursor: pointer;
}
.filter{
    display: flex;
    width: 90%;
    height: 20px;
    align-items: center;
    margin: 20px auto;
}
.filter .title{
    width: 120px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bolder;
    overflow: hidden;
}
.filter .select{
    width: 190px;
    height: 30px;
    outline: none;
    color: #808080;
    box-shadow: 0px 0px 2px 0px rgba(33, 35, 49, 0.1);
    border:none;
    border-radius:4px ;
}
.shadow{
    display: flex;
    width: 90%;
    height: 20px;
    align-items: center;
    margin: 25px auto;
}
.shadow .title{
    width: 120px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
    overflow: hidden;
}
.input:checked {
	background:#1673ff
}
.input {
	width:16px;
	height:16px;
    outline: none;
	background-color:rgba(125, 128, 133, 0.4);
	border:solid 1px #dddddd;
	-webkit-border-radius:50%;
	border-radius:50%;
	font-size:0.8rem;
	padding:0;
	position:relative;
	display:inline-block;
	vertical-align:top;
	cursor:default;
	-webkit-appearance:none;
	-webkit-user-select:none;
	user-select:none;
	-webkit-transition:background-color ease 0.1s;
	transition:background-color ease 0.1s;
	outline: none;
}
.input:checked::after {
	content:'';
	top:3px;
	left:3px;
	position:absolute;
	background:transparent;
	border:#fff solid 2px;
	border-top:none;
	border-right:none;
	height:4px;
	width:6px;
	-moz-transform:rotate(-45deg);
	-ms-transform:rotate(-45deg);
	-webkit-transform:rotate(-45deg);
	transform:rotate(-45deg);
}
.ipt_box{
    width: 65px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px 0px rgba(33, 35, 49, 0.1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>