import { MaterialObject } from "../object/MaterialObject"

class MaterialFactory {
    static createStandardMaterial(mat = null, ed_type = MaterialObject.MaterialEditorType.e_DEFLUT, url = null, name = null, id = null) {
        const userData = new MaterialObject({
            mat : mat,
            type : MaterialObject.MaterialType.e_STANDARD,
            ed_type : ed_type,
            url : url,
            name : name,
            id : id
        });
        return userData.getMaterial();
    }

    static createBasicMaterial(mat = null, ed_type = MaterialObject.MaterialEditorType.e_DEFLUT, url = null, name = null, id = null) {
        const userData = new MaterialObject({
            mat : mat,
            type : MaterialObject.MaterialType.e_BASIC,
            ed_type : ed_type,
            url : url,
            name : name,
            id : id
        });
        return userData.getMaterial();
    }

    static createPhoneMaterial(mat = null, ed_type = MaterialObject.MaterialEditorType.e_DEFLUT, url = null, name = null, id = null) {
        const userData = new MaterialObject({
            mat : mat,
            type : MaterialObject.MaterialType.e_PHONE,
            ed_type : ed_type,
            url : url,
            name : name,
            id : id
        });
        return userData.getMaterial();
    }

}

export { MaterialFactory };