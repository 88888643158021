import * as THREE from 'three';
import { MainObjects } from '../common/MainObjects';
import { SceneFactory } from '../factory/SceneFactory';
import { GameObject } from '../object/GameObject';
import { Grass } from '../object/Grass';
import { GrassMaterial } from '../shader/GrassMaterial';


class GrassBp {
    constructor() {
        // this.grass = new Grass();
        // this.time = 0;
        // this.lastFrame = Date.now();
        // this.thisFrame;
        // SceneFactory.addToHelperGroup(this.grass);
    }

    update(delta) {
        //Update time
        // this.thisFrame = Date.now();
        // var dT = (this.thisFrame - this.lastFrame) / 200.0;
        // this.time += dT;
        // this.lastFrame = this.thisFrame;
        // this.grass.material.uniforms.time.value = this.time;

    }
}

export { GrassBp };