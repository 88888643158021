import { MainObjects } from "../common/MainObjects"

class IHistory {
    static undo() {
        MainObjects.Blueprint.m_historyListenerBp.undo();
    }

    static redo() {
        MainObjects.Blueprint.m_historyListenerBp.redo();
    }
}

export { IHistory };